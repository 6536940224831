import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Checkbox, List, ListItem, ListItemText, Avatar, Typography } from '@mui/material';
import { db } from '../firebase';
import { collection, addDoc, setDoc, doc, serverTimestamp, getDocs } from 'firebase/firestore';
import { useAuth } from '../auth';

const BulkNotificationSender = () => {
  const [notification, setNotification] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]); // State to store selected users
  const [allUsers, setAllUsers] = useState([]); // All available users
  const { currentUser } = useAuth(); // Get the current user
  const [selectAll, setSelectAll] = useState(false); // State to manage select all/none toggle

  // Fetch all users from Firestore (assuming they are in a 'users' collection)
  useEffect(() => {
    const fetchUsers = async () => {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersList = usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setAllUsers(usersList);
    };

    fetchUsers();
  }, []);

  const handleUserSelect = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleSelectAllToggle = () => {
    if (selectAll) {
      // Deselect all users
      setSelectedUsers([]);
    } else {
      // Select all users
      const allUserIds = allUsers.map((user) => user.id);
      setSelectedUsers(allUserIds);
    }
    setSelectAll(!selectAll); // Toggle the select all state
  };

  const handleSendNotification = async () => {
    if (notification.trim() === '') return;

    try {
      await Promise.all(
        selectedUsers.map(async (userId) => {
          // Create notification for each selected user
          const notificationRef = doc(collection(db, `users/${userId}/notifications`));
          await setDoc(notificationRef, {
            senderId: currentUser.uid,
            message: notification,
            timestamp: serverTimestamp(),
            read: false, // Mark as unread
          });
        })
      );

      setNotification(''); // Clear notification field after sending
      setSelectedUsers([]); // Clear selected users after sending
      setSelectAll(false); // Reset select all toggle
    } catch (error) {
      console.error('Error sending notifications: ', error);
    }
  };

  return (
    <Box>
      <TextField
        fullWidth
        variant="outlined"
        label="Notification"
        value={notification}
        onChange={(e) => setNotification(e.target.value)}
        multiline
        rows={4}
        sx={{ marginBottom: 2 }}
      />

      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
        <Button variant="outlined" onClick={handleSelectAllToggle}>
          {selectAll ? 'Select None' : 'Select All'}
        </Button>
        <Typography variant="body2">
          {selectedUsers.length} of {allUsers.length} users selected
        </Typography>
      </Box>

      <List>
        {allUsers.map((user) => (
          <ListItem key={user.id} button onClick={() => handleUserSelect(user.id)}>
            <Checkbox checked={selectedUsers.includes(user.id)} />
            <Avatar alt={user.username} src={user.photoURL || '/default-avatar.png'} />
            <ListItemText primary={user.username} />
          </ListItem>
        ))}
      </List>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSendNotification}
        disabled={selectedUsers.length === 0 || notification.trim() === ''}
      >
        Send Notification to {selectedUsers.length} {selectedUsers.length === 1 ? 'User' : 'Users'}
      </Button>
    </Box>
  );
};

export default BulkNotificationSender;
