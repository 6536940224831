import React, { useState } from 'react';
import {
  Box, FormControl, Select, MenuItem, TextField, InputAdornment, IconButton, ToggleButton, ToggleButtonGroup,
  Typography, Avatar, Button, useMediaQuery, useTheme, Drawer, Toolbar, Paper, Badge
} from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import { rarityIcons, inkColors, inkColorMap, cardTypes } from './constants';
import '../App.css'; // Import the CSS file

const Filters = ({
  state, handleSetChange, handleRarityChange, handleInkChange, handleCardTypeChange,
  handleSearchChange, handleClearSearch, handleSortChange, handleViewToggle, handleListingsChange, searchTerm, listingsCount
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const sortedSets = [...state.sets].sort((a, b) => {
    const codeA = a.code;
    const codeB = b.code;

    const isNumericA = !isNaN(codeA);
    const isNumericB = !isNaN(codeB);

    if (isNumericA && isNumericB) {
      return Number(codeA) - Number(codeB);
    } else if (isNumericA) {
      return -1;
    } else if (isNumericB) {
      return 1;
    } else {
      return codeA.localeCompare(codeB);
    }
  });

  const handleAllOn = (section) => {
    if (section === 'rarity') {
      handleRarityChange(null, Object.keys(rarityIcons));
    } else if (section === 'ink') {
      handleInkChange(null, inkColors);
    } else if (section === 'cardType') {
      handleCardTypeChange(null, cardTypes);
    }
  };

  const handleAllOff = (section) => {
    if (section === 'rarity') {
      handleRarityChange(null, []);
    } else if (section === 'ink') {
      handleInkChange(null, []);
    } else if (section === 'cardType') {
      handleCardTypeChange(null, []);
    }
  };
  

  return (
    <>

      {/* Search and Sort Section */}
      <Box className={`search-sort-container ${isMobile ? "search-sort-container-mobile" : ""}`}>
        {/* Sets Filter */}
        <Paper elevation={3} sx={{ padding: 2 }}>
          <ToggleButtonGroup
            value={state.selectedSet}
            exclusive
            onChange={(event, value) => {
              if (value !== null) handleSetChange(event, value);
            }}
            aria-label="set selection"
            sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}
            size={isMobile ? "small" : "medium"}
          >
            {sortedSets.map((set) => (
              <Badge
              key={set.id}
              badgeContent={
                listingsCount && Array.isArray(listingsCount)
                  ? (listingsCount.find((listingSet) => listingSet.id === set.id)?.totalListings || 0)
                  : 0
              }
              color="primary"
            >
              <ToggleButton
                key={set.id}
                value={set.id}
                aria-label={set.name}
                sx={{
                  width: isMobile ? "90px" : "150px",
                  height: isMobile ? "36px" : "50px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  bgcolor: state.selectedSet === set.id ? "#0C0A5A" : "#f5f5f5",
                  color: state.selectedSet === set.id ? "white" : "grey",
                  border: state.selectedSet === set.id ? "#0C0A5A" : "transparent",
                  "&:hover": {
                    bgcolor: "lightgrey",
                  },
                  "&.Mui-selected": {
                    bgcolor: "#0C0A5A",
                    color: "white",
                  },
                  "&.Mui-selected:hover": {
                    bgcolor: "#0C0A5A",
                  },
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ fontSize: isMobile ? "0.625rem" : "0.75rem" }}
                >
                  {set.name}
                </Typography>
              </ToggleButton>
              </Badge>
            ))}
          </ToggleButtonGroup>
        </Paper>
      </Box>

      {/* Filter Toolbar */}
      <Box className={`search-sort-container ${isMobile ? "search-sort-container-mobile" : ""}`}>
        <Toolbar className="filters-toolbar">
          <Box className="filter-controls">
            <Button
              color="inherit"
              variant="outlined"
              onClick={toggleDrawer(true)}
              className="filter-button"
            >
              Filters
            </Button>
          </Box>
          <Box className="filter-controls">
            <TextField
              variant="outlined"
              label="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClearSearch}
                      edge="end"
                      size={isMobile ? "small" : "medium"}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              size={isMobile ? "small" : "medium"}
              className="white-textfield"
            />
          </Box>
        </Toolbar>
      </Box>

      {/* View Toggle and Sort Control */}
      <Box className="filter-controls" sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <IconButton onClick={handleViewToggle} color="inherit">
          {state.view === "grid" ? <ViewListIcon /> : <ViewModuleIcon />}
        </IconButton>
        <FormControl variant="outlined" className="white-formcontrol">
          <Select
            value={state.sortBy}
            onChange={handleSortChange}
            size={isMobile ? "small" : "medium"}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="az">A-Z</MenuItem>
            <MenuItem value="za">Z-A</MenuItem>
            <MenuItem value="price_high">Highest Price</MenuItem>
            <MenuItem value="price_low">Lowest Price</MenuItem>
            <MenuItem value="card_low">Lowest #</MenuItem>
            <MenuItem value="card_high">Highest #</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Drawer containing the filters */}
      <Drawer anchor={isMobile ? "bottom" : "right"} open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: "100%",
            padding: 2,
            overflowY: "auto",
            overflowX: "hidden",
            boxSizing: "border-box",
          }}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <IconButton onClick={toggleDrawer(false)} sx={{ position: "absolute", top: 2, right: 2 }}>
            <CloseIcon />
          </IconButton>

          {/* Rarity Filter */}
          <Paper elevation={3} sx={{ padding: 2, marginBottom: 2, marginTop: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Rarity
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 1 }}>
              <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={() => handleAllOn('rarity')}>All On</Typography>
              <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={() => handleAllOff('rarity')}>All Off</Typography>
            </Box>
            <ToggleButtonGroup
              value={state.selectedRarities}
              onChange={handleRarityChange}
              aria-label="rarity selection"
              className="toggle-button-group"
              sx={{ display: "flex", flexWrap: "wrap" }}
              size="large"
              color="primary"
            >
              {Object.keys(rarityIcons).map((rarity) => (
                <ToggleButton
                  key={rarity}
                  value={rarity}
                  aria-label={rarity}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    variant="square"
                    src={rarityIcons[rarity]}
                    alt={rarity}
                    sx={{
                      width: isMobile ? 20 : 24,
                      height: isMobile ? 20 : 24,
                    }}
                  />
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Paper>

          {/* Ink Filter */}
          <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Ink
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 1 }}>
              <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={() => handleAllOn('ink')}>All On</Typography>
              <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={() => handleAllOff('ink')}>All Off</Typography>
            </Box>
            <ToggleButtonGroup
              value={state.selectedInks}
              onChange={handleInkChange}
              aria-label="ink selection"
              sx={{ display: "flex", flexWrap: "wrap" }}
              size="large"
            >
              {inkColors.map((ink) => (
                <ToggleButton
                  key={ink}
                  value={ink}
                  aria-label={ink}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "lightgrey",

                    "&.Mui-selected": {
                      bgcolor: inkColorMap[ink],
                    },
                    "&.Mui-selected:hover": {
                      bgcolor: inkColorMap[ink],
                    },
                    "&:hover": {
                      bgcolor: inkColorMap[ink],
                    },
                  }}
                >
                  <Avatar
                    variant="square"
                    sx={{
                      bgcolor: inkColorMap[ink],
                      width: isMobile ? 20 : 24,
                      height: isMobile ? 20 : 24,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{ fontSize: isMobile ? "0.625rem" : "0.75rem" }}
                    >
                      {ink.substring(0, 3)}
                    </Typography>
                  </Avatar>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Paper>

          {/* Card Type Filter */}
          <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Card Type
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 1 }}>
              <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={() => handleAllOn('cardType')}>All On</Typography>
              <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={() => handleAllOff('cardType')}>All Off</Typography>
            </Box>
            <ToggleButtonGroup
              value={state.selectedCardTypes}
              onChange={handleCardTypeChange}
              aria-label="card type selection"
              sx={{ display: "flex", flexWrap: "wrap" }}
              size="large"
            >
              {cardTypes.map((type) => (
                <ToggleButton
                  key={type}
                  value={type}
                  aria-label={type}
                  sx={{
                    height: "110%",
                    bgcolor: "lightgrey",
                    color: "#fff",
                    "&.Mui-selected": {
                      bgcolor: type === "Normal" ? "#0C0A5A" : "gold",
                    },
                    "&.Mui-selected:hover": {
                      bgcolor: type === "Normal" ? "#0C0A5A" : "gold",
                    },
                    "&:hover": {
                      bgcolor: "lightgrey",
                    },
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: isMobile ? "0.625rem" : "0.75rem",
                      color: type === "Normal" ? "#FFFFFF" : "#000000",
                    }}
                  >
                    {type}
                  </Typography>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Paper>
        </Box>
      </Drawer>

      <Box sx={{ textAlign: "center", my: 2 }}>
        <Button
          variant="outlined"
          onClick={() => handleListingsChange(null, !state.showWithListings)}
          sx={{ width: "100%", fontSize: isMobile ? "0.75rem" : "1rem" }}
        >
          {state.showWithListings
            ? "Showing Only Items with Listings - Click to Show All Items"
            : "Showing All Items - Click to Show Only Items with Listings"}
        </Button>
      </Box>
    </>
  );
};

export default Filters;

