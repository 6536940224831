// src/auth.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from './firebase'; // Adjust the import to your Firebase setup file
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import Loading from './components/Loading';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser({ ...user, ...userData });
        } else {
          setCurrentUser(user);
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
      console.log('Loading set to false');  // Debugging line
    });
  
    return unsubscribe;
  }, []);

  const logout = async () => {
    await auth.signOut();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider value={{ currentUser, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
  
};
