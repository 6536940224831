import React, { useEffect, useState, useReducer } from 'react';
import { Container, Typography, Button, Box, Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { collection, getDocs, getDoc, setDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import MobileCollectionCardList from './MobileCollectionCardList'; // Mobile version
import CollectionCardList from './CollectionCardList'; // Desktop version
import CollectionCardGrid from './CollectionCardGrid';
import CollectionFilters from './CollectionFilters';
import ImportExportCollectionComponent from './ImportExportCollectionComponent';
import { useAuth } from '../auth';
import { useAppBarContext } from '../components/AppBarContext';
import { currencySymbols, rarityIcons, inkColors, cardTypes } from '../marketplace/constants';
import Loading from '../components/Loading';
import CollectionCardDetail from './CollectionCardDetail';
import CollectionDashboard from './CollectionDashboard';
import WishList from './WishList';
import { useMediaQuery, useTheme, Alert, Collapse } from '@mui/material';

const initialState = {
  sets: [],
  selectedSet: '', 
  selectedRarities: Object.keys(rarityIcons), // All rarities selected by default
  selectedInks: inkColors, // All inks selected by default
  selectedCardTypes: cardTypes, // All card types selected by default
  searchTerm: '',
  showInCollection: {
    collected: true,
    notCollected: true,
    wishlistOnly: false,
  },
  sortBy: 'card_low',
  view: 'list',
  cards: [],
  collectionItems: {},
  wishlistItems: [],
  loading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, ...action.payload, loading: false };
    case 'SET_SELECTED_SET':
      return { ...state, selectedSet: action.payload };
    case 'SET_SELECTED_RARITIES':
      return { ...state, selectedRarities: action.payload };
    case 'SET_SELECTED_INKS':
      return { ...state, selectedInks: action.payload };
    case 'SET_SELECTED_CARD_TYPES':
      return { ...state, selectedCardTypes: action.payload };
    case 'SET_SEARCH_TERM':
      return { ...state, searchTerm: action.payload };
    case 'SET_SHOW_IN_COLLECTION':
      return { ...state, showInCollection: { ...state.showInCollection, ...action.payload }};
    case 'SET_SORT_BY':
      return { ...state, sortBy: action.payload };
    case 'SET_VIEW':
      return { ...state, view: action.payload };
    case 'UPDATE_CARDS':
      return { ...state, cards: action.payload };
    case 'SET_COLLECTION_ITEMS':
      return { ...state, collectionItems: action.payload };
    case 'SET_WISHLIST_ITEMS':
      return { ...state, wishlistItems: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'TOGGLE_COLLECTION_FILTER':
      return {
        ...state,
        showInCollection: {
          ...state.showInCollection,
          [action.payload]: !state.showInCollection[action.payload],
        },
      };
    default:
      return state;
  }
};

const MyCollection = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentUser } = useAuth();
  const { selectedCountry, exchangeRates } = useAppBarContext();
  const [selectedCard, setSelectedCard] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [importExportDialogOpen, setImportExportDialogOpen] = useState(false);
  const [viewWishlist, setViewWishlist] = useState(false); // Add this state
  


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showDashboard, setShowDashboard] = useState(!isMobile); 

  const handleOpenImportExportDialog = () => {
    setImportExportDialogOpen(true);
  };
  
  const handleCloseImportExportDialog = () => {
    setImportExportDialogOpen(false);
  };

  useEffect(() => {
    const fetchSets = async () => {
      dispatch({ type: 'SET_LOADING', payload: true });
      const setsSnapshot = await getDocs(collection(db, 'sets'));
      const setsData = setsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      dispatch({ type: 'SET_DATA', payload: { sets: setsData, selectedSet: setsData.find(set => set.code === "1")?.id || setsData[0]?.id } });
    };

    fetchSets();
  }, [currentUser]);

  useEffect(() => {
    const fetchCardsAndCollectionData = async () => {
      dispatch({ type: 'SET_LOADING', payload: true });
    
      const collectionItems = {};
      const wishlistItems = {};
      let allCards = [];
    
      const selectedSet = state.selectedSet;
      if (selectedSet) {
        const collectionRef = collection(db, 'users', currentUser.uid, 'mycollection', selectedSet, 'cards');
        const collectionSnapshot = await getDocs(collectionRef);
    
        collectionSnapshot.docs.forEach(doc => {
          const cardData = doc.data();
          collectionItems[doc.id] = cardData.quantity || 0;
          if (cardData.inWishlist) wishlistItems[doc.id] = !!cardData.inWishlist;
        });
    
        const cardsSnapshot = await getDocs(collection(db, 'sets', selectedSet, 'cards'));
        const cardsData = cardsSnapshot.docs.map(doc => ({
          docName: doc.id,
          ...doc.data(),
          setId: selectedSet,
          inWishlist: wishlistItems[doc.id] || false,
          inCollection: !!collectionItems[doc.id]
        }));
    
        allCards = cardsData;

       // Apply filter for wishlist items first (primary filter)
allCards = allCards.filter(card => {
  const isCollected = collectionItems[card.docName] > 0;
  const isInWishlist = wishlistItems[card.docName];

  // Show items in the wishlist, regardless of collected/notCollected if wishlistOnly is active
  if (state.showInCollection.wishlistOnly) {
    return isInWishlist; // Only return wishlist items if wishlistOnly is active
  }

  // Apply collected and not collected filters if wishlistOnly is not active
  if (state.showInCollection.collected && isCollected) return true;
  if (state.showInCollection.notCollected && !isCollected) return true;

  return false; // If none of the conditions match, exclude the card
});
      

        // Filter based on search term, sort, and selected filters
        allCards = allCards.filter(card =>
          (!state.searchTerm ||
            card.name?.toLowerCase().includes(state.searchTerm.toLowerCase()) ||
            card.collector_number?.toLowerCase().includes(state.searchTerm.toLowerCase())
          )
        );
    
        if (state.sortBy === 'card_low') {
          allCards.sort((a, b) => parseInt(a.collector_number) - parseInt(b.collector_number));
        } else if (state.sortBy === 'card_high') {
          allCards.sort((a, b) => parseInt(b.collector_number) - parseInt(a.collector_number));
        }
    
        // Filter based on selected rarities, inks, and card types
        if (state.selectedRarities.length > 0) {
          allCards = allCards.filter(card => state.selectedRarities.includes(card.rarity));
        }
        if (state.selectedInks.length > 0) {
          allCards = allCards.filter(card => state.selectedInks.includes(card.ink));
        }
        if (state.selectedCardTypes.length > 0) {
          allCards = allCards.filter(card => state.selectedCardTypes.includes(card.type));
        }
    
        dispatch({ type: 'SET_COLLECTION_ITEMS', payload: collectionItems });
        dispatch({ type: 'SET_WISHLIST_ITEMS', payload: Object.keys(wishlistItems) });
        dispatch({ type: 'UPDATE_CARDS', payload: allCards });
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    };

    if (state.selectedSet) {
      fetchCardsAndCollectionData();
    }
  }, [state.selectedSet, state.showInCollection, state.sortBy, state.selectedRarities, state.selectedInks, state.selectedCardTypes, state.searchTerm, currentUser]);

  const convertPrice = (usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice;
    return `${selectedCountry} ${currencySymbols[selectedCountry]}${(usdPrice * exchangeRates[selectedCountry]).toFixed(2)}`;
  };

  const handleCollectionStatusChange = (type) => {
    const updatedStatus = !state.showInCollection[type];
    dispatch({ type: 'SET_SHOW_IN_COLLECTION', payload: { [type]: updatedStatus } });
  };
  

  const handleAddToList = async (card, listType) => {
    try {
      const collectionPath = `users/${currentUser.uid}/mycollection/${card.setId}/cards`;
      const cardRef = doc(db, collectionPath, card.docName);
      const cardDoc = await getDoc(cardRef);

      let updatedCardData = {
        docName: card.docName,
        name: card.name,
        type: card.type,
        rarity: card.rarity,
        ink: card.ink,
        price: card.price || 0,
        collector_number: card.collector_number,
        setId: card.setId,
        setName: card.set.name,
        image_uris: card.image_uris,
      };

      if (listType === 'wishlist') {
        const isInWishlist = cardDoc.exists() ? cardDoc.data().inWishlist : false;
        updatedCardData.inWishlist = !isInWishlist;

        if (isInWishlist) {
          dispatch({ type: 'SET_WISHLIST_ITEMS', payload: state.wishlistItems.filter(item => item !== card.docName) });
        } else {
          dispatch({ type: 'SET_WISHLIST_ITEMS', payload: [...state.wishlistItems, card.docName] });
        }
      } else {
        updatedCardData.quantity = (cardDoc.exists() ? cardDoc.data().quantity : 0) + 1;
        dispatch({ type: 'SET_COLLECTION_ITEMS', payload: { ...state.collectionItems, [card.docName]: updatedCardData.quantity } });
      }

      await setDoc(cardRef, updatedCardData, { merge: true });

      setAlertMessage(`${card.name} has been ${listType === 'wishlist' ? (updatedCardData.inWishlist ? 'added to' : 'removed from') : 'added to'} your ${listType === 'wishlist' ? 'Wishlist' : 'Collection'}.`);
      setAlertOpen(true);
    } catch (error) {
      console.error("Error handling Add to List:", error);
    }
  };

  const handleUpdateQuantity = async (cardId, quantity) => {
    const card = state.cards.find(c => c.docName === cardId);  // Find the correct card object
    if (!card) {
      console.error("Card not found in state");
      return;
    }
  
    const collectionPath = `users/${currentUser.uid}/mycollection/${card.setId}/cards`;
    const cardRef = doc(db, collectionPath, cardId);
    const cardDoc = await getDoc(cardRef);
  
    // Update the card data to set the quantity to 0, but do not delete the document
    let updatedCardData = {
      ...cardDoc.data(),
      quantity: quantity < 1 ? 0 : quantity, // Set quantity to 0 if it's less than 1
    };
  
    await setDoc(cardRef, updatedCardData, { merge: true });
  
    dispatch({
      type: 'SET_COLLECTION_ITEMS',
      payload: {
        ...state.collectionItems,
        [cardId]: updatedCardData.quantity,
      }
    });
  
    setAlertMessage(quantity < 1 
      ? `${card.name} has been set to quantity 0 in your collection.` 
      : `Quantity of ${card.name} updated to ${quantity}.`);
    setAlertOpen(true);
  };
  

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleCloseDialog = () => {
    setSelectedCard(null);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const handleSetCardClick = (setId) => {
    dispatch({ type: 'SET_SELECTED_SET', payload: setId });
  };

  const toggleWishlistView = () => {
    setViewWishlist(!viewWishlist);
  };

  const toggleDashboardVisibility = () => {
    setShowDashboard(!showDashboard);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {viewWishlist ? "My Wishlist" : "My Collection"}{" "}
        {/* Change title based on the view */}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          position: "relative",
        }}
      >
        {!viewWishlist && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row", // Stack in column on mobile, row on larger screens
                gap: isMobile ? 2 : 1, // Gap between buttons, larger gap on mobile
                mt: 2,
              }}
            >
              <Button
                variant="outlined"
                onClick={toggleWishlistView}
                fullWidth={isMobile}
              >
                View Wishlist
              </Button>
              <Button
                variant="outlined"
                onClick={handleOpenImportExportDialog}
                fullWidth={isMobile}
              >
                Import/Export My Collection
              </Button>
              
            </Box>
          </>
        )}
        {viewWishlist && (
          <Box sx={{ position: "absolute", right: 0, top: 0 }}>
            <Button
              variant="contained"
              color="warning"
              onClick={toggleWishlistView}
            >
              Back to Collection
            </Button>
          </Box>
        )}
      </Box>

      <Box sx={{ mt: 8 }}>
        {viewWishlist ? (
          <WishList />
        ) : (
          <>
              <Button variant="text" onClick={toggleDashboardVisibility} fullWidth={isMobile}>
                {showDashboard ? "Hide Collection Overview" : "Show Collection Overview"}
              </Button>
            {/* Your existing collection components (filters, lists, etc.) */}
            {showDashboard && <CollectionDashboard onSetCardClick={handleSetCardClick} />}

            <CollectionFilters
              state={state}
              handleSetChange={(e, value) =>
                dispatch({ type: "SET_SELECTED_SET", payload: value })
              }
              handleRarityChange={(e, value) =>
                dispatch({ type: "SET_SELECTED_RARITIES", payload: value })
              }
              handleInkChange={(e, value) =>
                dispatch({ type: "SET_SELECTED_INKS", payload: value })
              }
              handleCardTypeChange={(e, value) =>
                dispatch({ type: "SET_SELECTED_CARD_TYPES", payload: value })
              }
              handleSearchChange={(e) =>
                dispatch({ type: "SET_SEARCH_TERM", payload: e.target.value })
              }
              handleClearSearch={() =>
                dispatch({ type: "SET_SEARCH_TERM", payload: "" })
              }
              handleSortChange={(e) =>
                dispatch({ type: "SET_SORT_BY", payload: e.target.value })
              }
              handleViewToggle={() =>
                dispatch({
                  type: "SET_VIEW",
                  payload: state.view === "grid" ? "list" : "grid",
                })
              }
              handleCollectionStatusChange={handleCollectionStatusChange}
            />

            {/* Alert Notification */}
            <Collapse in={alertOpen}>
              <Alert severity="info" onClose={closeAlert} sx={{ mt: 2 }}>
                {alertMessage}
              </Alert>
            </Collapse>

            {state.loading ? (
              <Loading />
            ) : state.view === "list" ? (
              isMobile ? (
                <MobileCollectionCardList
                  cards={state.cards}
                  collectionItems={state.collectionItems}
                  wishlistItems={state.wishlistItems}
                  convertPrice={convertPrice}
                  handleAddToList={handleAddToList}
                  handleUpdateQuantity={handleUpdateQuantity}
                  handleCardClick={handleCardClick}
                />
              ) : (
                <CollectionCardList
                  cards={state.cards}
                  collectionItems={state.collectionItems}
                  wishlistItems={state.wishlistItems}
                  convertPrice={convertPrice}
                  handleAddToList={handleAddToList}
                  handleUpdateQuantity={handleUpdateQuantity}
                  handleCardClick={handleCardClick}
                />
              )
            ) : (
              <CollectionCardGrid
                cards={state.cards}
                collectionItems={state.collectionItems}
                wishlistItems={state.wishlistItems}
                handleCardClick={handleCardClick}
                convertPrice={convertPrice}
                handleAddToList={handleAddToList}
                handleUpdateQuantity={handleUpdateQuantity}
              />
            )}
          </>
        )}
      </Box>

      <Dialog
        open={Boolean(selectedCard)}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <IconButton
            onClick={handleCloseDialog}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedCard && (
            <CollectionCardDetail
              setId={selectedCard.setId}
              id={selectedCard.docName}
            />
          )}
        </DialogContent>
      </Dialog>

      <ImportExportCollectionComponent
        open={importExportDialogOpen}
        onClose={handleCloseImportExportDialog}
      />
    </Container>
  );
};

export default MyCollection;
