import React, { useCallback } from 'react';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Box,
  Typography,
  Avatar,
  Stack,
  CardActionArea,
  Chip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { inkColorMap, rarityIcons } from './constants';
import LazyLoad from 'react-lazyload';

const CardGrid = ({ cards, handleCardClick, convertPrice, openListingModal }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderCard = useCallback(
    (card) => (
      <Grid item key={card.docName} xs={6} sm={4} md={3} lg={3}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            backgroundColor: card.type === "Foil" ? "#fffbea" : "#ffffff",
            border:
              card.type === "Foil" ? "2px solid #ffd700" : "1px solid #e0e0e0",
            margin: isMobile ? "8px 0" : "auto",
            boxShadow: isMobile ? "none" : "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          {/* Image Section */}
          <Box
            sx={{
              position: "relative",
              paddingTop: "138%",
              overflow: "hidden",
            }}
            className={card.type === "Foil" ? "foil-image-container" : ""}
          >
            <LazyLoad height={400} offset={1600}>
              <CardMedia
                component="img"
                alt={card.name}
                image={
                  card.image_uris?.digital?.normal ||
                  card.image_uris?.digital?.large
                }
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "auto",
                  objectFit: "contain",
                  border: `4px solid ${inkColorMap[card.ink] || "#F2D18B"}`,
                }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "fallback_image_url";
                }}
              />
            </LazyLoad>
          </Box>

          {/* Card Content */}
          <CardContent sx={{ padding: isMobile ? "8px" : "16px" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Avatar
                variant="square"
                sx={{
                  bgcolor: "#0C0A5A",
                  color: "white",
                  height: 28,
                  width: 28,
                  fontSize: isMobile ? "0.75rem" : "1rem",
                }}
              >
                {card.collector_number}
              </Avatar>
              <Avatar
                variant="square"
                sx={{ bgcolor: "#0C0A5A", height: 28, width: 28 }}
              >
                <img
                  src={rarityIcons[card.rarity]}
                  alt={card.rarity}
                  style={{ width: 20, height: 20 }}
                />
              </Avatar>
              {card.type === "Foil" && (
                <Avatar
                  variant="square"
                  sx={{
                    width: 64,
                    height: 28,
                    bgcolor: "#ffd700",
                    border: "2px solid #000000",
                    color: "#000000",
                    fontSize: isMobile ? "0.5rem" : "0.75rem",
                  }}
                >
                  <Typography variant="caption">COLD FOIL</Typography>
                </Avatar>
              )}
            </Box>
            <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
              <Typography
                variant="h6"
                sx={{ fontSize: isMobile ? "1rem" : "1.25rem" }}
              >
                {card.name}
              </Typography>
              {card.version && (
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  sx={{ fontSize: isMobile ? "0.875rem" : "1rem" }}
                >
                  {card.version}
                </Typography>
              )}
            </Stack>
          </CardContent>

          {/* Card Actions */}
          <CardActions
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              padding: isMobile ? "8px" : "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: 1,
              }}
            >
              <Chip
                sx={{
                  bgcolor: "secondary.main",
                  color: "white",
                  height: "auto",
                  borderRadius: "4px",
                  padding: "4px 8px",
                  cursor: "pointer",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                  },
                }}
                label={`Listings: ${card.listingsCount || 0}`}
              />
              <Chip
                sx={{
                  bgcolor: "green",
                  color: "white",
                  height: "auto",
                  borderRadius: "4px",
                  padding: "4px 8px",
                  cursor: "pointer",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                  },
                }}
                label={`TCGP Price: ${convertPrice(card.price || 0)}`}
              />
            </Box>
          </CardActions>

          {/* Add/Update Listing Button */}
          <CardActionArea
            onClick={() =>
              handleCardClick({ setId: card.setId, docName: card.docName })
            }
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "8px",
              bgcolor: "primary.main",
              color: "white",
              "&:hover": { bgcolor: "primary.dark" },
              fontSize: isMobile ? "0.75rem" : "1rem",
            }}
          >
            <Typography variant="button">View Details</Typography>
          </CardActionArea>
        </Card>
      </Grid>
    ),
    [handleCardClick, convertPrice, isMobile]
  );

  return (
    <Grid container columnSpacing={3} rowSpacing={3} sx={{ padding: isMobile ? '8px' : '16px' }}>
      {cards.map(renderCard)}
    </Grid>
  );
};

export default CardGrid;
