import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import {
  Box, Typography, Dialog, DialogContent, DialogTitle, IconButton, Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BundleCardListMarketplace from './BundleCardListMarketplace';
import { useAuth } from '../auth';

const BundleMarketplaceDetails = ({ bundle, onClose }) => {
  const { currentUser } = useAuth();
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchCards = async () => {
      if (!bundle || !bundle.cards || !bundle.cards.length) return;

      const cardDetails = await Promise.all(bundle.cards.map(async ({ docId, setId, quantity }) => {
        const cardDoc = await getDoc(doc(db, 'sets', setId, 'cards', docId));
        return cardDoc.exists() ? { docId, setId, quantity, ...cardDoc.data() } : null;
      }));

      setCards(cardDetails.filter(card => card !== null));
    };

    fetchCards();
  }, [bundle]);

  const totalCardQuantity = cards.reduce((sum, card) => sum + card.quantity, 0);

  const addToCart = async () => {
    const userId = currentUser.uid;
    const cartRef = doc(db, 'carts', userId);
    const cartDoc = await getDoc(cartRef);
    let cartData = cartDoc.exists() ? cartDoc.data() : { items: [] };

    // Check if the bundle is already in the cart
    const existingItemIndex = cartData.items.findIndex(item => item.id === bundle.id && item.type === 'bundle');
    if (existingItemIndex > -1) {
      cartData.items[existingItemIndex].quantity += 1;
    } else {
      const newBundle = {
        type: 'bundle',
        id: bundle.id,
        userId: bundle.userId,
        quantity: 1,
        price: bundle.price,
        image_uris: bundle.image_uris || {},
        name: bundle.name || '',
        setId: bundle.setId || '',
        docName: bundle.docName || '',
      };

      // Ensure no undefined values
      Object.keys(newBundle).forEach(key => {
        if (newBundle[key] === undefined) {
          newBundle[key] = '';
        }
      });

      cartData.items.push(newBundle);
    }

    await setDoc(cartRef, cartData);
    alert('Bundle added to cart!');
  };

  return (
    <Dialog open={Boolean(bundle)} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="h4" gutterBottom>
            {bundle.name}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Price: ${bundle.price}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Bundle Quantity: {bundle.quantity}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Cards in Bundle: {totalCardQuantity}
          </Typography>
          <BundleCardListMarketplace
            cards={cards}
            addingBundle={false}
            convertPrice={(price) => `$${price}`}
          />
          <Box mt={2} textAlign="center">
            <Button variant="contained" color="primary" onClick={addToCart}>
              Add to Cart
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default BundleMarketplaceDetails;
