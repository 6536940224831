import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Box,
  Typography,
  Avatar,
  Stack,
  CardActionArea,
  Popper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { inkColorMap, rarityIcons } from './constants';
import LazyLoad from 'react-lazyload';
import Loading from '../components/Loading';

const InventoryGridList = ({ cards, handleCardClick, convertPrice, openListingModal, currentUser }) => {
  const [listings, setListings] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchListings = async (card) => {
    try {
      const listingsSnapshot = await getDocs(
        collection(db, 'sets', card.setId, 'cards', card.docId, 'listings')
      );
      const listingsData = listingsSnapshot.docs.reduce((acc, doc) => {
        // Only include listings that belong to the current user
        if (doc.id === currentUser.uid) {
          const data = doc.data();
          acc[doc.id] = Object.entries(data).map(([condition, listing]) => ({
            condition,
            ...listing,
          }));
        }
        return acc;
      }, {});
      setListings((prevListings) => ({
        ...prevListings,
        [card.docId]: listingsData,
      }));
    } catch (error) {
      console.error('Error fetching listings:', error);
    }
  };

  useEffect(() => {
    cards.forEach((card) => {
      fetchListings(card);
    });
  }, [cards]);

  const handlePopoverToggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const renderCard = useCallback(
    (card) => (
      <Grid item key={card.docId} xs={6} sm={4} md={3} lg={3}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            backgroundColor: card.type === 'Foil' ? '#fffbea' : '#ffffff',
            border: card.type === 'Foil' ? '2px solid #ffd700' : '1px solid #e0e0e0',
            position: 'relative',
            margin: isMobile ? '8px 0' : 'auto',
            boxShadow: isMobile ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.1)',
            width: '100%',
          }}
        >
          <Box
            sx={{ position: 'relative', paddingTop: '138%', overflow: 'hidden', height: 'auto', }}
            className={card.type === 'Foil' ? 'foil-image-container' : ''}
          >
            <LazyLoad height={400} offset={1600}>
              <CardMedia
                className="foil-image"
                component="img"
                alt={card.name}
                image={card.image_uris?.digital?.normal || card.image_uris?.digital?.large}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: 'auto',
                  objectFit: 'contain',
                  boxSizing: 'border-box', // Ensure borders are counted in the element dimensions
                  border: `4px solid ${inkColorMap[card.ink] || '#F2D18B'}`,
                }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'fallback_image_url';
                }}
              />
            </LazyLoad>
          </Box>

          <CardContent sx={{ padding: isMobile ? '8px' : '16px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Avatar
                variant="square"
                sx={{
                  bgcolor: '#0C0A5A',
                  color: 'white',
                  height: 28,
                  width: 28,
                  fontSize: isMobile ? '0.75rem' : '1rem',
                }}
              >
                {card.collector_number}
              </Avatar>
              <Avatar
                variant="square"
                sx={{ bgcolor: '#0C0A5A', height: 28, width: 28 }}
              >
                <img
                  src={rarityIcons[card.rarity]}
                  alt={card.rarity}
                  style={{ width: 20, height: 20 }}
                />
              </Avatar>
              {card.type === 'Foil' && (
                <Avatar
                  variant="square"
                  sx={{
                    width: 64,
                    height: 28,
                    bgcolor: '#ffd700',
                    border: '2px solid #000000',
                    color: '#000000',
                    fontSize: isMobile ? '0.5rem' : '0.75rem',
                  }}
                >
                  <Typography variant="caption">
                    COLD FOIL
                  </Typography>
                </Avatar>
              )}
            </Box>
            <Stack direction="column" spacing={1} sx={{ mt: 1 }}>
              <Typography gutterBottom variant="h6" component="div" sx={{ fontSize: isMobile ? '1rem' : '1.25rem' }}>
                {card.name}
              </Typography>
              {card.version && (
                <Typography variant="subtitle2" color="textSecondary" sx={{ fontSize: isMobile ? '0.875rem' : '1rem' }}>
                  {card.version}
                </Typography>
              )}
            </Stack>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center', alignItems: 'center', padding: isMobile ? '8px' : '16px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                alignItems: 'center', // Centers items horizontally
                justifyContent: 'center', // Centers items vertically if there's available space
              }}
            >
              {listings[card.docId] ? (
                Object.values(listings[card.docId]).flatMap((userListings) => {
                  const firstListing = userListings[0];
                  const remainingListings = userListings.slice(1);

                  return (
                    <React.Fragment key={card.docId}>
                      <Box
                        sx={{
                          bgcolor: 'secondary.main',
                          color: 'white',
                          padding: '4px 8px',
                          borderRadius: '4px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                          fontSize: isMobile ? '0.75rem' : '1rem',
                        }}
                        aria-owns={open ? 'mouse-over-popper' : undefined}
                        aria-haspopup="true"
                        onClick={handlePopoverToggle}
                      >
                        <Typography variant="body2"
                        sx={{
                          fontSize: (theme) => theme.breakpoints.down('sm') ? '0.8rem' : '1rem',
                        }}
                         >
                          {firstListing.condition} - Qty: {firstListing.quantity}
                          {remainingListings.length > 0 && (
                            <Typography variant="body2" component="span" sx={{ marginLeft: '4px', fontSize: (theme) => theme.breakpoints.down('sm') ? '0.8rem' : '1rem' }}>
                              +{remainingListings.length} more
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                      <Popper
                        id="mouse-over-popper"
                        sx={{
                          pointerEvents: 'auto',
                          bgcolor: 'secondary.main',
                          color: 'white',
                          zIndex: 10,
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        disableRestoreFocus
                      >
                        <Stack direction="column" spacing={1} sx={{ p: 1 }}>
                          {remainingListings.map((listing, index) => (
                            <Typography key={index} variant="body2">
                              {listing.condition} - Qty: {listing.quantity}
                            </Typography>
                          ))}
                        </Stack>
                      </Popper>
                    </React.Fragment>
                  );
                })
              ) : (
                <Loading />
              )}
              <Box
                sx={{
                  bgcolor: 'green',
                  color: 'white',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  fontSize: isMobile ? '0.75rem' : '1rem',
                }}
                onClick={() => window.open(`https://www.tcgplayer.com/product/${card.tcgplayer_id}`, "_blank")}
              >
                <Typography variant="body2"
                sx={{
                  fontSize: (theme) => theme.breakpoints.down('sm') ? '0.8rem' : '1rem',
                }}>
                  TCGP Price: {convertPrice(card.price || 0)}
                </Typography>
              </Box>
            </Box>
          </CardActions>
          <CardActionArea
            onClick={() => openListingModal(card)}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px',
              bgcolor: 'primary.main',
              color: 'white',
              "&:hover": {
                bgcolor: 'primary.dark',
              },
              fontSize: isMobile ? '0.5rem' : '1rem',
            }}
          >
            <Typography
              variant="button"
              sx={{
                fontSize: (theme) => theme.breakpoints.down('sm') ? '0.7rem' : '1rem',
              }}
            >
              Add/Update Listing
            </Typography>

          </CardActionArea>
        </Card>
      </Grid>
    ),
    [listings, handleCardClick, convertPrice, openListingModal, anchorEl, isMobile]
  );

  return (
    <Grid container columnSpacing={3} rowSpacing={3} sx={{ padding: isMobile ? '8px' : '16px' }}>
      {cards.map(renderCard)}
    </Grid>
  );
};

export default InventoryGridList;
