import React, { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { db } from '../firebase';
import { collection, addDoc, setDoc, doc, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../auth';

const SendMessage = ({ conversationId, recipientId, recipientName }) => {
  const [message, setMessage] = useState('');
  const { currentUser } = useAuth();

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (message.trim() === '') return;

    try {
      // Add the message to Firestore with `movedToBin` set to `false`
      await addDoc(collection(db, `conversations/${conversationId}/messages`), {
        senderId: currentUser.uid,
        recipientId: recipientId,
        message: message,
        timestamp: serverTimestamp(),
        read: false,  // Mark the new message as unread
        movedToBin: false,  // Initialize as not moved to bin
      });

      // Create or update the conversation document with participants and last message
      await setDoc(doc(db, 'conversations', conversationId), {
        participants: [currentUser.uid, recipientId],
        lastMessage: message,
        lastUpdated: serverTimestamp(),
      }, { merge: true });

      setMessage(''); // Clear the input after sending the message
    } catch (error) {
      console.error('Error sending message: ', error);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSendMessage}
      display="flex"
      alignItems="center"
      p={1}
      sx={{
        width: '100%',
        maxWidth: '100vw', // Prevent overflow
        boxSizing: 'border-box',
        bgcolor: '#fff',
        position: 'sticky',
        bottom: 0,
      }}
    >
      <TextField
        variant="outlined"
        fullWidth
        multiline
        placeholder={`Message ${recipientName}`}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        sx={{ marginRight: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        sx={{
          flexShrink: 0, // Prevent the button from shrinking
          minWidth: '75px', // Ensure the button is large enough
        }}
      >
        Send
      </Button>
    </Box>
  );
};

export default SendMessage;
