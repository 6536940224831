import React, { useCallback, useState, useEffect } from "react";
import {
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Box,
  Typography,
  Stack,
  ListItemIcon,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Paper,
  Switch,
  useMediaQuery,
  useTheme,
  Chip,
  Button,
  Tooltip,
  MenuItem,
  Select,
  Fab,
} from "@mui/material";
import { rarityIcons, inkColorMap, conditionOptions } from "./constants";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import QuantityInput from "../components/QuantityInput";
import PercentageInput from "../components/PercentageInput";
import PriceInputWithNumberPad from "../components/PriceInputWithNumberPad";
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, deleteField } from "firebase/firestore";
import Loading from "../components/Loading";
import { useAppBarContext } from "../components/AppBarContext";

const InventoryCardList = ({
  cards,
  convertPrice,
  currentUser,
}) => {
  const MAX_CHIPS_DISPLAY = 2;
  const [expandedCard, setExpandedCard] = useState(null);
  const { selectedCountry, exchangeRates } = useAppBarContext();
  const [loading, setLoading] = useState(true);
  const [currentListings, setCurrentListings] = useState({});
  const [showAddRow, setShowAddRow] = useState(false);
  const [newCondition, setNewCondition] = useState("");
  const [newQuantity, setNewQuantity] = useState(1);
  const [newPrice, setNewPrice] = useState("");
  const [newAutoUpdate, setNewAutoUpdate] = useState(false);
  const [newAllowOffers, setNewAllowOffers] = useState(false);
  const [savedNotification, setSavedNotification] = useState({});
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchListing = async (cardId, userId, setId) => {
    console.log(`Fetching listing for Card ID: ${cardId}, Set ID: ${setId}`);
    const listingRef = doc(db, 'sets', setId, 'cards', cardId, 'listings', userId);
    const listingDoc = await getDoc(listingRef);
  
    if (listingDoc.exists()) {
      const data = listingDoc.data();
      console.log("Fetched listing data:", data);
      return data;  // Return the listing data
    } else {
      console.error("No listing found for this card and user.");
      return null;
    }
  };
  

  const handleAccordionChange = useCallback(
    (card) => async (event, isExpanded) => {
      setExpandedCard(isExpanded ? card.docId : null);  // Toggle accordion expansion
  
      if (isExpanded && !currentListings[card.docId]) {
        // Fetch listings only if expanded and listings not already fetched
        try {
          setLoading(true);
          const cardId = card.docId;
          const setId = card.setId;
  
          if (!setId || !cardId) {
            console.error("Set ID or Card ID is missing.");
            return;
          }
  
          // Fetch the listing for the specific card and current user
          const listing = await fetchListing(cardId, currentUser.uid, setId);
          setCurrentListings((prev) => {
            const updatedListings = { ...prev, [cardId]: listing };
            console.log("Updated currentListings:", updatedListings);
  
            // Define currentConditions and availableConditions here, based on listingsArray and conditionOptions
            const currentConditions = listing
              ? Object.keys(listing).map((condition) => listing[condition].condition)
              : [];
            const availableConditions = conditionOptions.filter(
              (condition) => !currentConditions.includes(condition)
            );
  
            const sortedFirestoreListings = listing
              ? Object.keys(listing)
                  .map((condition) => listing[condition])
                  .sort((a, b) =>
                    conditionOptions.indexOf(a.condition) - conditionOptions.indexOf(b.condition)
                  )
              : [];
  
            // Add these sorted listings or conditions to the state or UI elements as needed.
  
            return updatedListings;
          });
        } catch (error) {
          console.error("Error fetching listing:", error);
        } finally {
          setLoading(false);
        }
      }
    },
    [currentListings, currentUser.uid]
  );

  
  
  

  const handleDeleteListing = async (card, condition) => {
    await saveListing(card, condition, 0);  // Save with quantity 0 to delete
  };
  
  const saveListing = async (card, condition, quantity, price, percentage, autoUpdatePrice, allowOffers) => {
    const { docId: cardId, setId } = card;
    const listingRef = doc(db, "sets", setId, "cards", cardId, "listings", currentUser.uid);
    let listingData = {};
  
    try {
      const listingDoc = await getDoc(listingRef);
      if (listingDoc.exists()) {
        listingData = listingDoc.data();
      }
  
      const exchangeRate = exchangeRates[selectedCountry] || 1;
      const priceInUSD = (parseFloat(price) / exchangeRate).toFixed(2); // Convert price to USD
  
      if (quantity === 0) {
        await updateDoc(listingRef, { [condition]: deleteField() });
        delete listingData[condition];
        if (Object.keys(listingData).length === 0) {
          await setDoc(listingRef, {}, { merge: false });
        }
      } else {
        listingData[condition] = {
          userId: currentUser.uid,
          condition,
          quantity,
          price: priceInUSD,
          autoUpdatePrice: autoUpdatePrice || false,
          percentage: percentage || 100,
          allowOffers: allowOffers || false,
        };
        await setDoc(listingRef, listingData, { merge: true });
      }
  
      setCurrentListings((prevListings) => {
        const updatedListings = { ...prevListings };
        updatedListings[cardId] = listingData;
        return updatedListings;
      });
  
      setSavedNotification((prev) => ({
        ...prev,
        [cardId]: { ...prev[cardId], [condition]: true },
      }));
      setTimeout(() => {
        setSavedNotification((prev) => ({
          ...prev,
          [cardId]: { ...prev[cardId], [condition]: false },
        }));
      }, 2000);
  
      console.log("Listing saved successfully:", listingData);
    } catch (error) {
      console.error("Error saving listing:", error);
    }
  };
  

  const handleQuantityChange = (card, condition, newQuantity) => {
    clearTimeout(debounceTimeout); // Clear any existing debounce timeout

    // Set a new debounce timeout
    const timeout = setTimeout(async () => {
      const listing = currentListings[card.docId]?.[condition];
      if (listing && newQuantity !== listing.quantity) {
        await saveListing(
          card,
          condition,
          newQuantity,
          listing.price || 0, // Maintain the existing price
          listing.percentage || 100, // Maintain the existing percentage
          listing.autoUpdatePrice || false // Maintain the existing auto-update toggle
        );
      }
    }, 1000);

    setDebounceTimeout(timeout);
  };

  // Cleanup timeout on component unmount
  useEffect(() => {
    return () => clearTimeout(debounceTimeout);
  }, [debounceTimeout]);
  

  const handlePriceChange = async (card, condition, quantity, newPrice) => {
    const listing = currentListings[card.docId]?.[condition];
  
    if (listing && newPrice !== listing.price) {
      await saveListing(
        card,
        condition,
        quantity,
        newPrice, // Update the new price
        listing.percentage || 100,
        listing.autoUpdatePrice || false
      );
    }
  };
  

  const handlePercentageChange = async (card, condition, percentage, quantity, price) => {
    const listing = currentListings[card.docId]?.[condition];
  
    if (listing && percentage !== listing.percentage) {
      await saveListing(
        card,
        condition,
        quantity,
        price,
        percentage, // Update the new percentage
        listing.autoUpdatePrice || false
      );
    }
  };
  
  const handleToggleAllowOffers = async (card, condition, checked) => {
    const listing = currentListings[card.docId]?.[condition];
  
    // Calculate the price in the selected currency
    const price = (
      listing.price *
      (exchangeRates[selectedCountry] || 1)
    ).toFixed(2);
  
    if (listing && checked !== listing.allowOffers) {
      await saveListing(
        card,
        condition,
        listing.quantity, // Pass the current quantity
        price, // Pass the converted price
        listing.percentage || 100, // Maintain the current percentage
        listing.autoUpdatePrice || false, // Maintain the auto-update state
        checked // Toggle allowOffers based on the switch
      );
    }
  };
  

  const handleToggleUseTCGPPrice = async (card, condition, quantity, price, percentage, checked) => {
    const listing = currentListings[card.docId]?.[condition];
  
    if (listing && checked !== listing.autoUpdatePrice) {
      await saveListing(
        card,
        condition,
        quantity,
        price,
        percentage,
        checked,
        listing.allowOffers
      );
    }
  };
  

  const handleNewConditionSave = async (card) => {
    await saveListing(
      card,
      newCondition,
      newQuantity,
      newPrice || "0.00",
      100,
      newAutoUpdate,
      newAllowOffers
    );
    setShowAddRow(false);
    setNewCondition("");
    setNewQuantity(1);
    setNewPrice("");
    setNewAutoUpdate(false);
    setNewAllowOffers(false);
  };

  // const handleSetAllAutoUpdateForAllCards = async () => {
//   try {
//     for (const card of cards) {
//       const listings = card.listings?.[currentUser.uid] || {};
//       console.log("Listings for card:", card.docId, listings);
        
//       // Loop over the condition keys in the current user's listings
//       for (const condition of Object.keys(listings)) {
//         const listing = listings[condition];
//         if (!listing.autoUpdatePrice) {
//           // Call saveListing to set autoUpdatePrice to true
//           await saveListing(
//             card,
//             listing.condition, // Use the actual condition string from the listing
//             listing.quantity,
//             listing.price,
//             listing.percentage,
//             true // Set autoUpdatePrice to true
//           );
//         }
//       }
//     }
//     console.log("All listings set to auto-update.");
//   } catch (error) {
//     console.error("Error setting all listings to auto-update:", error);
//   }
// };

  
  
  
  

const handleAddCondition = (condition) => {
  setShowAddRow(true);
  setNewCondition(condition); // Set the condition to the selected one
};

  const renderCard = useCallback(
    (card) => {
      const userId = currentUser.uid;
      const listingsArray = card.listings?.[userId] || [];

      const currentConditions = listingsArray.map((listing) => listing.condition);
    const availableConditions = conditionOptions.filter(
      (condition) => !currentConditions.includes(condition)
    );
  
      return (
        <Accordion
          key={card.docId}
          expanded={expandedCard === card.docId}
          onChange={handleAccordionChange(card)}
        >
          <AccordionSummary
            expandIcon={
              !isMobile &&
              (expandedCard === card.docId ? <RemoveIcon /> : <AddIcon />)
            }
            sx={{
              "& .MuiAccordionSummary-expandIconWrapper": {
                marginLeft: "20px",
              },
            }}
          >
            <Stack
              direction={isMobile ? "column" : "row"}
              spacing={2}
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  gap: isMobile ? 0 : 1,
                }}
              >
                <ListItemAvatar sx={{ width: isMobile ? 60 : 100 }}>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      className={card.type === "Foil" ? "foil-avatar" : ""}
                      variant="square"
                      src={
                        card.image_uris?.digital?.small ||
                        card.image_uris?.digital?.large
                      }
                      sx={{
                        height: isMobile ? "10%" : "20%",
                        width: "80%",
                        objectFit: "contain",
                        border: `4px solid ${
                          inkColorMap[card.ink] || "#F2D18B"
                        }`,
                      }}
                    />

                    {card.type === "Foil" && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: 4,
                          bgcolor: "#ffd700",
                          border: isMobile
                            ? "2px solid #000000"
                            : "3px solid #000000",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: isMobile ? 44 : 74,
                          height: isMobile ? 8 : 10,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: isMobile ? "8px" : "10px",
                            lineHeight: 1,
                            color: "#000000",
                          }}
                        >
                          COLD FOIL
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </ListItemAvatar>

                <Stack direction="column" spacing={1} sx={{ mr: -2 }}>
                  <ListItemIcon>
                    <Avatar
                      variant="square"
                      sx={{
                        bgcolor: "#0C0A5A",
                        width: isMobile ? 30 : 40,
                        height: isMobile ? 30 : 40,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: isMobile ? "16px" : "20px",
                        }}
                      >
                        {card.collector_number}
                      </Typography>
                    </Avatar>
                  </ListItemIcon>
                  <ListItemIcon>
                    <Avatar
                      variant="square"
                      sx={{
                        bgcolor: "#0C0A5A",
                        width: isMobile ? 30 : 40,
                        height: isMobile ? 30 : 40,
                      }}
                    >
                      <img
                        src={rarityIcons[card.rarity]}
                        alt={card.rarity}
                        style={{
                          width: isMobile ? 16 : 24,
                          height: isMobile ? 16 : 24,
                        }}
                      />
                    </Avatar>
                  </ListItemIcon>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: isMobile ? "0.75rem" : "1rem",
                          whiteSpace: "normal", // Allow text wrapping
                          wordBreak: "break-word", // Handle long words gracefully
                        }}
                      >
                        {card.name}
                      </Typography>
                    }
                    secondary={
                      card.version && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontSize: isMobile ? "0.6rem" : "0.8rem",
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                          }}
                        >
                          {card.version}
                        </Typography>
                      )
                    }
                  />
                  {!isMobile && card.type === "Foil" && (
                    <Avatar
                      variant="square"
                      sx={{
                        width: isMobile ? 60 : 76,
                        height: isMobile ? 20 : 24,
                        bgcolor: "#ffd700",
                        border: "2px solid #000000",
                        color: "#000000",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "10px", lineHeight: 1 }}
                      >
                        COLD FOIL
                      </Typography>
                    </Avatar>
                  )}
                </Stack>
              </Box>
            </Stack>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                width: isMobile ? "35%" : "50%",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: 1,
                  width: isMobile ? "100%" : "50%",
                  padding: "8px",
                  overflow: "hidden", // Prevent overflow of content
                }}
              >
                {(currentListings[card.docId] &&
                Object.keys(currentListings[card.docId]).length > 0
                  ? Object.values(currentListings[card.docId]) // Use current listings if available
                  : listingsArray
                ).length > 0 ? ( // Fall back to main fetched listings if currentListings is empty or undefined
                  (currentListings[card.docId] &&
                  Object.keys(currentListings[card.docId]).length > 0
                    ? Object.values(currentListings[card.docId])
                    : listingsArray
                  ).map((listing, listingIndex) => (
                    <Box
                    key={`${card.docId}-${listing.condition}-${listingIndex}`}
                    sx={{
                      width: "100%", // Full width for each listing box
                      display: "flex",
                      backgroundColor: "#D5D5D5", // Light grey background
                      borderRadius: "4px", // Rounded corners
                      flexDirection: "column",
                      gap: 0.5,
                      padding: "4px", // Optional padding for spacing within the box
                    }}
                  >
                    {/* Condition on its own line */}
                    <Chip
                      label={listing.condition}
                      sx={{
                        width: "100%", // Full width chip
                        borderRadius: "4px",
                        bgcolor: "secondary.main",
                        color: "white",
                        fontSize: isMobile ? "0.6rem" : "0.875rem",
                        height: "20px", // Slimline height
                      }}
                    />
                  
                    {/* Quantity and Price with Labels */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                        width: "100%", // Ensure box takes full width
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "50%" }}>
                        <Typography variant="caption" color="textSecondary" 
                        sx={{fontSize: isMobile ? "0.6rem" : "0.875rem",
                        }}
                            >
                          Qty
                        </Typography>
                        <Chip
                          label={listing.quantity}
                          sx={{
                            minWidth: "100%", // Full width within its container
                            borderRadius: "4px",
                            bgcolor: "primary.main",
                            color: "white",
                            fontSize: isMobile ? "0.6rem" : "0.875rem",
                            height: "20px", // Slimline height
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            direction: "rtl", // Show end of text if wrapped
                          }}
                        />
                      </Box>
                  
                      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "50%" }}>
                        <Typography variant="caption" color="textSecondary" 
                        sx={{fontSize: isMobile ? "0.6rem" : "0.875rem",
                      }}
                        >
                          Price
                        </Typography>
                        <Chip
                          label={`${
                            listing.autoUpdatePrice
                              ? convertPrice((card.price * (listing.percentage / 100)).toFixed(2))
                              : convertPrice(listing.price)
                          }`}
                          sx={{
                            minWidth: "100%", // Full width within its container
                            borderRadius: "4px",
                            bgcolor: "success.main",
                            color: "white",
                            fontSize: isMobile ? "0.6rem" : "0.875rem",
                            height: "20px", // Slimline height
                            textAlign: "center",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            direction: "rtl", // Show end of text if wrapped
                          }}
                        />
                      </Box>
                    </Box>
                      {savedNotification[card.docId]?.[listing.condition] && (
                        <Typography
                          variant="caption"
                          color="success.main"
                          sx={{ mt: 0.5 }}
                        >
                          Saved
                        </Typography>
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      width: "100%", // Full width for each listing box
                      display: "flex",
                      backgroundColor: "#D5D5D5", // Light grey background
                      borderRadius: "4px", // Rounded corners
                      flexDirection: "column",
                      gap: 0.5,
                      padding: "4px", // Optional padding for spacing within the box
                    }}
                  >
                    <Chip
                      label="No Listings Found"
                      sx={{
                        width: "100%", // Full width chip
                        borderRadius: "4px",
                        bgcolor: "warning.main",
                        color: "white",
                        fontSize: isMobile ? "0.6rem" : "0.875rem",
                        height: "20px", // Slimline height
                      }}
                    />
                    <Typography variant="caption" color="textSecondary" 
                        sx={{fontSize: isMobile ? "0.6rem" : "0.875rem",
                        textAlign: "center",
                        }}
                            >
                          ___
                        </Typography>
                    <Chip
                      label="Click to Add"
                      sx={{
                        width: "100%", // Full width chip
                        borderRadius: "4px",
                        bgcolor: "warning.main",
                        color: "white",
                        fontSize: isMobile ? "0.6rem" : "0.875rem",
                        height: "20px", // Slimline height
                      }}
                    />
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  gap: 1,
                  width: isMobile ? "100%" : "50%",
                }}
              >
                <Chip
                  clickable
                  component="a"
                  href={`https://www.tcgplayer.com/product/${card.tcgplayer_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    height: "auto",
                    width: isMobile ? "100%" : "90%",
                    borderRadius: "4px",
                    fontSize: isMobile ? "0.6rem" : "0.875rem",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                    },
                    bgcolor: "green",
                    color: "white",
                  }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <span>TCGP Price</span>
                      {convertPrice(card.price || 0)}
                    </Box>
                  }
                />
              </Box>
            </Box>
          </AccordionSummary>

          <AccordionDetails>
            <>
              {isMobile && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                    padding: "8px 0",
                  }}
                >
                  Rotate your device or try Grid mode for a better view.
                </Typography>
              )}
            </>

            {expandedCard === card.docId && (
              <>
                {/* Define conditions and listings */}
                {(() => {
                  const listingsArray = Array.isArray(
                    card.listings?.[currentUser.uid]
                  )
                    ? card.listings?.[currentUser.uid]
                    : [];
                  const currentConditions = listingsArray.map(
                    (listing) => listing.condition
                  );
                  const availableConditions = conditionOptions.filter(
                    (condition) => !currentConditions.includes(condition)
                  );

                  const sortedListings = currentListings[card.docId]
                    ? Object.keys(currentListings[card.docId])
                        .map(
                          (condition) => currentListings[card.docId][condition]
                        )
                        .sort(
                          (a, b) =>
                            conditionOptions.indexOf(a.condition) -
                            conditionOptions.indexOf(b.condition)
                        )
                    : [];

                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        mt: 2,
                        width: "100%",
                      }}
                    >
                      {sortedListings.map((listing, listingIndex) => (
                        <Paper
                          key={`${card.docId}-${listing.condition}-${listingIndex}`}
                          sx={{
                            p: 1.5,
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            bgcolor: "background.paper",
                            width: "auto",
                          }}
                        >
                          <Grid container spacing={2} alignItems="center">
                            {/* Condition */}
                            <Grid item xs={12} sm={3}>
                              <Chip
                                label={listing.condition}
                                sx={{
                                  height: "auto",
                                  width: isMobile ? "100%" : "80%",
                                  fontSize: isMobile ? "0.7rem" : "0.875rem",
                                  borderRadius: "4px",
                                  bgcolor: "secondary.main",
                                  color: "white",
                                  padding: "0.25rem 0.5rem",
                                }}
                              />
                            </Grid>

                            {/* Quantity */}
                            <Grid
                              item
                              xs={12}
                              sm={2}
                              sx={{
                                transform: isMobile ? "scale(0.9)" : "none",
                                transformOrigin: "top left",
                              }}
                            >
                              <Typography variant="body2" sx={{ mb: 0.5 }}>
                                Quantity
                              </Typography>
                              <QuantityInput
                                value={listing.quantity || 0}
                                onChange={(newValue) =>
                                  handleQuantityChange(
                                    card,
                                    listing.condition,
                                    newValue
                                  )
                                }
                              />
                            </Grid>

                            {/* Auto Update Toggle */}
                            <Grid
                              item
                              xs={6}
                              sm={2}
                              sx={{
                                transform: isMobile ? "scale(0.9)" : "none",
                                transformOrigin: "top left",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: isMobile ? "row" : "column",
                                  alignItems: "center",
                                  gap: isMobile ? 1 : 0,
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    mb: isMobile ? 0 : 0.5,
                                  }}
                                >
                                  Auto Update Price from TCGP
                                </Typography>
                                <Switch
                                  checked={listing.autoUpdatePrice || false}
                                  onChange={(e) => {
                                    handleToggleUseTCGPPrice(
                                      card,
                                      listing.condition,
                                      listing.quantity,
                                      listing.price,
                                      listing.percentage,
                                      e.target.checked
                                    );
                                  }}
                                />
                              </Box>
                            </Grid>

                            {/* Price or Percentage */}
                            <Grid
                              item
                              xs={6}
                              sm={3}
                              sx={{
                                transform: isMobile ? "scale(0.9)" : "none",
                                transformOrigin: "top left",
                              }}
                            >
                              <Typography variant="body2" sx={{ mb: 0.5 }}>
                                {listing.autoUpdatePrice
                                  ? "Percentage"
                                  : "Price"}
                              </Typography>
                              {listing.autoUpdatePrice ? (
                                <PercentageInput
                                  percentage={listing.percentage || 100}
                                  onPercentageChange={(percentage) =>
                                    handlePercentageChange(
                                      card,
                                      listing.condition,
                                      percentage,
                                      listing.quantity,
                                      listing.price
                                    )
                                  }
                                />
                              ) : (
                                <PriceInputWithNumberPad
                                  price={(
                                    listing.price *
                                    (exchangeRates[selectedCountry] || 1)
                                  ).toFixed(2)}
                                  onPriceChange={(newPrice) => {
                                    const parsedPrice =
                                      newPrice === ""
                                        ? 0
                                        : parseFloat(newPrice);
                                    handlePriceChange(
                                      card,
                                      listing.condition,
                                      listing.quantity,
                                      parsedPrice
                                    );
                                  }}
                                />
                              )}
                            </Grid>

                            <Grid
                              item
                              xs={6}
                              sm={1}
                              sx={{
                                transform: isMobile ? "scale(0.9)" : "none",
                                transformOrigin: "top left",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: isMobile ? "row" : "column",
                                  alignItems: "center",
                                  gap: isMobile ? 1 : 0,
                                }}
                              >
                                <Typography variant="body2">
                                  Allow Offers
                                </Typography>
                                <Switch
                                  checked={listing.allowOffers || false}
                                  onChange={(e) =>
                                    handleToggleAllowOffers(
                                      card,
                                      listing.condition,
                                      e.target.checked
                                    )
                                  }
                                />
                              </Box>
                            </Grid>

                            {/* Delete Button */}
                            <Grid
                              item
                              xs={12}
                              sm={1}
                              sx={{ textAlign: isMobile ? "left" : "right" }}
                            >
                              <IconButton
                                color="error"
                                onClick={() =>
                                  handleDeleteListing(card, listing.condition)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Paper>
                      ))}

                      {showAddRow && (
                        <Paper
                          sx={{
                            p: 1.5,
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            bgcolor: "background.default",
                            width: "auto",
                          }}
                        >
                          <Grid container spacing={2} alignItems="center">
                            {/* Add New Condition Heading */}
                            <Grid item xs={12} sm={3}>
                              <Chip
                                label={newCondition}
                                sx={{
                                  height: "auto",
                                  width: isMobile ? "100%" : "80%",
                                  fontSize: isMobile ? "0.7rem" : "0.875rem",
                                  borderRadius: "4px",
                                  bgcolor: "secondary.main",
                                  color: "white",
                                  padding: "0.25rem 0.5rem",
                                }}
                              />
                            </Grid>

                            {/* Quantity */}
                            <Grid item xs={12} sm={2}>
                              <Typography variant="body2" sx={{ mb: 0.5 }}>
                                Quantity
                              </Typography>
                              <QuantityInput
                                value={newQuantity}
                                onChange={(value) => setNewQuantity(value)}
                              />
                            </Grid>

                            {/* Auto Update Toggle */}
                            <Grid
                              item
                              xs={6}
                              sm={2}
                              sx={{
                                transform: isMobile ? "scale(0.9)" : "none",
                                transformOrigin: "top left",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: isMobile ? "row" : "column",
                                  alignItems: "center",
                                  gap: isMobile ? 1 : 0,
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    mb: isMobile ? 0 : 0.5,
                                  }}
                                >
                                  Auto Update Price from TCGP
                                </Typography>
                                <Switch
                                  checked={newAutoUpdate}
                                  onChange={(e) =>
                                    setNewAutoUpdate(e.target.checked)
                                  }
                                />
                              </Box>
                            </Grid>

                            {/* Price or Percentage */}
                            <Grid item xs={12} sm={3}>
                              <Typography variant="body2" sx={{ mb: 0.5 }}>
                                {newAutoUpdate ? "Percentage" : "Price"}
                              </Typography>
                              {newAutoUpdate ? (
                                <PercentageInput
                                  percentage={100}
                                  onPercentageChange={(value) =>
                                    setNewPrice(value)
                                  }
                                />
                              ) : (
                                <PriceInputWithNumberPad
                                  price={newPrice}
                                  onPriceChange={(value) => setNewPrice(value)}
                                />
                              )}
                            </Grid>

                            <Grid item xs={6} sm={2}>
                              <Box
                                display="flex"
                                flexDirection={isMobile ? "row" : "column"}
                                alignItems="center"
                                gap={1}
                              >
                                <Typography variant="body2">
                                  Allow Offers
                                </Typography>
                                <Switch
                                  checked={newAllowOffers}
                                  onChange={(e) =>
                                    setNewAllowOffers(e.target.checked)
                                  }
                                />
                              </Box>
                            </Grid>

                            {/* Save Button */}
                            <Grid
                              item
                              xs={12}
                              sm={2}
                              sx={{ textAlign: "right" }}
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNewConditionSave(card)}
                                startIcon={<CheckIcon />}
                                sx={{
                                  bgcolor: "green",
                                  "&:hover": { bgcolor: "darkgreen" },
                                  color: "white",
                                  fontSize: "0.8rem",
                                  px: 2,
                                }}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </Paper>
                      )}
                    </Box>
                  );
                })()}
              </>
            )}

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap={2}
              mt={2}
            >
              <Typography variant={isMobile ? "body" : "body1"}>
                Add New Listing
              </Typography>
              <Box
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                justifyContent="flex-start"
                alignItems="center"
                gap={1}
              >
                {availableConditions.map((condition) => (
                  <Button
                    key={condition}
                    variant="contained"
                    onClick={() => handleAddCondition(condition)}
                    size="small"
                    sx={{
                      bgcolor: "green",
                      "&:hover": { bgcolor: "darkorange" },
                      fontSize: isMobile ? "0.6rem" : "0.8rem",
                      width: isMobile ? "100%" : "auto",
                      px: 2, // Padding for extra spacing
                    }}
                  >
                    {condition}
                  </Button>
                ))}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    },
    [expandedCard, handleAccordionChange, handleQuantityChange, handlePriceChange, handlePercentageChange, handleToggleUseTCGPPrice, currentUser.uid]
  );

  return (
    <>
      {/*
      Add the button for setting all listings to auto-update
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Fab color="secondary" onClick={handleSetAllAutoUpdateForAllCards}>
          Set All Listings to Auto-Update
        </Fab>
      </Box>
*/}

  
      {/* Render the list of cards */}
      <List>
        {cards.length > 0 ? (
          cards.map((card) => renderCard(card))
        ) : (
          <Typography variant="body2" color="textSecondary" align="center">
            No cards available.
          </Typography>
        )}
      </List>
    </>
  );
};

export default React.memo(InventoryCardList);
