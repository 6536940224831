import React, { useEffect } from 'react';
import { logEvent } from "firebase/analytics";
import { analytics } from '../firebase';

const withAnalytics = (WrappedComponent) => {
  return (props) => {
    useEffect(() => {
      if (analytics) {
        logEvent(analytics, 'page_view', {
          page_title: WrappedComponent.name,  // Use component name dynamically
          page_path: window.location.pathname,
        });
      }
    }, []);

    return <WrappedComponent {...props} />;
  };
};

export default withAnalytics;
