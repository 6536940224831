import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Autocomplete, TextField, Box, CircularProgress, InputAdornment } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { debounce } from 'lodash';
import { useAuth } from '../auth'; // Import useAuth to get currentUser

const UserSearch = ({ onSelectUser }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth(); // Get the current user

  // Debounced fetch users function
  const fetchUsers = debounce(async (term) => {
    if (term.trim() === '') {
      setUsers([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('username', '>=', term), where('username', '<=', term + '\uf8ff'));

    const querySnapshot = await getDocs(q);
    const userList = querySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .filter((user) => user.id !== currentUser.uid); // Exclude the current user

    setUsers(userList);
    setLoading(false);
  }, 300); // Adjust debounce timing as needed

  // Trigger fetchUsers when searchTerm changes
  useEffect(() => {
    fetchUsers(searchTerm);
    return () => {
      fetchUsers.cancel(); // Cancel debounce on cleanup
    };
  }, [searchTerm]);

  return (
    <Box sx={{ width: { xs: '100%', sm: 300 }, padding: { xs: 2, sm: 0 } }}>
      <Autocomplete
        options={users}
        getOptionLabel={(user) => user.username || ''}
        loading={loading}
        noOptionsText="Type username..."
        onInputChange={(event, value) => setSearchTerm(value)}
        onChange={(event, newUser) => {
          if (newUser) {
            // Send both user ID and username to the parent
            onSelectUser(newUser.id, newUser.username);
          }
        }}
        renderInput={(params) => (
            <TextField
            {...params}
            variant="standard"
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={40} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default UserSearch;
