import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../auth';

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscription = async () => {
      if (!currentUser) {
        // If no user is logged in, set a default free subscription state
        setSubscription({ status: 'free', plan: 'LCM Free', features: [] });
        setLoading(false);
        return;
      }

      try {
        // Fetch the user's subscription details
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          const userSubscription = userData.subscription || { status: 'free', plan: 'LCM Free' };

          // Fetch the plans from the 'products' document in Firestore
          const productsDocRef = doc(db, 'subscriptions', 'products');
          const productsDocSnap = await getDoc(productsDocRef);

          if (productsDocSnap.exists()) {
            const productsData = productsDocSnap.data();
            const plans = productsData.plans || [];

            // Match the user's subscription plan to the available plans
            const matchedPlan = plans.find((plan) => plan.name === userSubscription.plan) || { features: [] };

            // Update subscription state with the user's plan and the associated features
            setSubscription({
              ...userSubscription,
              features: matchedPlan.features || [],
            });
          }
        }
      } catch (error) {
        console.error("Error fetching subscription: ", error);
        setSubscription({ status: 'free', plan: 'LCM Free', features: [] });
      } finally {
        setLoading(false);
      }
    };

    fetchSubscription();
  }, [currentUser]);

  return (
    <SubscriptionContext.Provider value={{ subscription, loading }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
