import React, { useState } from 'react';
import { TextField, Box, Button, Typography, Autocomplete, Grid, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const predefinedOptions = [
  {
    label: "Small envelope",
    maxWeight: "Up to 125g",
    dimensions: "130mm x 240mm (Up to 5mm thickness)",
    chargePerItem: "$4.75"
  },
  {
    label: "Medium envelope",
    maxWeight: "Up to 500g",
    dimensions: "240mm x 162mm (Up to 20mm thickness)",
    chargePerItem: "$6.65"
  },
  {
    label: "Large envelope",
    maxWeight: "Up to 500g",
    dimensions: "229mm x 324mm (Up to 20mm thickness)",
    chargePerItem: "$8.35"
  },
  {
    label: "Parcel S (up to 500g)",
    maxWeight: "Up to 500g",
    dimensions: "",
    chargePerItem: "$10.95"
  },
  {
    label: "Parcel M (500g to 1kg)",
    maxWeight: "500g to 1kg",
    dimensions: "",
    chargePerItem: "$14.95"
  },
  {
    label: "Parcel L (1kg to 3kg)",
    maxWeight: "1kg to 3kg",
    dimensions: "",
    chargePerItem: "$18.95"
  },
  {
    label: "Parcel XL (3kg to 5kg)",
    maxWeight: "3kg to 5kg",
    dimensions: "",
    chargePerItem: "$22.85"
  }
];


const courierOptions = ['Australia Post', 'Other'];

const PostageOptions = ({ profile, addPostageOption, removePostageOption }) => {
  
  const [selectedCourier, setSelectedCourier] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [methodFields, setMethodFields] = useState({
    method: '',
    maxWeight: '',
    dimensions: '',
    chargePerItem: ''
  });

  const postageOptions = profile.sellerPreferences.postageOptions || [];

  const handleOpenDialog = () => {
    setSelectedCourier('');
    setMethodFields({
      method: '',
      maxWeight: '',
      dimensions: '',
      chargePerItem: ''
    });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCourierChange = (value) => {
    setSelectedCourier(value);
    setMethodFields({
      method: '',
      maxWeight: '',
      dimensions: '',
      chargePerItem: ''
    });
  };

  const handleMethodChange = (event, newValue) => {
    if (selectedCourier === 'Australia Post') {
      const selectedOption = predefinedOptions.find(option => option.label === newValue);
      if (selectedOption) {
        setMethodFields({
          method: selectedOption.label,
          maxWeight: selectedOption.maxWeight,
          dimensions: selectedOption.dimensions,
          chargePerItem: selectedOption.chargePerItem
        });
      }
    } else {
      setMethodFields(prevFields => ({
        ...prevFields,
        method: newValue
      }));
    }
  };

  const handleFieldChange = (field, value) => {
    setMethodFields(prevFields => ({
      ...prevFields,
      [field]: value
    }));
  };

  const handleSaveOption = async () => {
    const newOption = {
      ...methodFields,
      courier: selectedCourier,
    };
    addPostageOption(newOption);
    handleCloseDialog();
  };

  const handleRemoveOption = async (index) => {
    removePostageOption(index);
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Postage Options
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Set your Postage Options you will offer to your buyers.
      </Typography>
      {postageOptions.map((option, index) => (
        <Box key={index} mb={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                value={option.courier || ''}
                label="Courier"
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                value={option.method || ''}
                label="Method"
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                value={option.maxWeight || ''}
                label="Max. Weight"
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                value={option.chargePerItem || ''}
                label="Charge per Item"
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
          <Box textAlign="right" mt={1}>
            <Button
              onClick={() => handleRemoveOption(index)}
              variant="contained"
              color="secondary"
            >
              Remove
            </Button>
          </Box>
        </Box>
      ))}
      <Box mt={2} display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', sm: 'row' }}>
        <Button onClick={handleOpenDialog} variant="contained" color="primary" sx={{ mb: { xs: 1, sm: 0 } }}>
          Add Postage Option
        </Button>
      </Box>

      {/* Dialog for adding a new postage option */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Select Courier</DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Courier"
            value={selectedCourier}
            onChange={(e) => handleCourierChange(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          >
            {courierOptions.map(courier => (
              <MenuItem key={courier} value={courier}>
                {courier}
              </MenuItem>
            ))}
          </TextField>

          {selectedCourier === 'Australia Post' ? (
            <>
              <Autocomplete
                freeSolo
                options={predefinedOptions.map(option => option.label)}
                onChange={handleMethodChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Method"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
              <TextField
                label="Max. Weight"
                value={methodFields.maxWeight}
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e) => handleFieldChange('maxWeight', e.target.value)}
              />
              <TextField
                label="Dimensions"
                value={methodFields.dimensions}
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e) => handleFieldChange('dimensions', e.target.value)}
              />
              <TextField
                label="Charge per Item"
                value={methodFields.chargePerItem}
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e) => handleFieldChange('chargePerItem', e.target.value)}
              />
            </>
          ) : selectedCourier === 'Other' ? (
            <>
              <TextField
                label="Method(eg:- Envelope, Parcel, etc.)"
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e) => handleFieldChange('method', e.target.value)}
              />
              <TextField
                label="Max. Weight"
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e) => handleFieldChange('maxWeight', e.target.value)}
              />
              <TextField
                label="Dimensions"
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e) => handleFieldChange('dimensions', e.target.value)}
              />
              <TextField
                label="Charge per Item"
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e) => handleFieldChange('chargePerItem', e.target.value)}
              />
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveOption} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PostageOptions;
