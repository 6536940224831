import React from 'react';
import { Card, CardMedia, CardContent, CardActions, Box, Typography, Chip, Stack } from '@mui/material';
import cardImage from './card.png';

const CustomAdCard = ({ title, subtitle, text, actionText, onActionClick }) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        backgroundColor: "#fff9c4", // Pale yellow background
        border: "2px solid #0C0A5A",
        position: "relative", 
      }}
    >
      <Box sx={{ position: "relative", paddingTop: "130%" }}>
        <CardMedia
          component="img"
          image={cardImage}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            objectFit: "contain",
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "fallback_image_url";
          }}
        />
      </Box>

      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>

          <Stack direction="column" spacing={1} sx={{ marginLeft: 2 }}>
            <Typography variant="h6">{title}</Typography>
            {subtitle && (
              <Typography variant="body2" color="textSecondary">
                {subtitle}
              </Typography>
            )}
          </Stack>
        </Box>
        <Typography variant="body2" color="textPrimary" sx={{ marginTop: 1 }}>
          {text}
        </Typography>
      </CardContent>

      <CardActions sx={{ justifyContent: "center", paddingBottom: 2 }}>
        <Chip
          label={actionText}
          onClick={onActionClick}
          clickable
          sx={{
            bgcolor: "secondary.main",
            color: "white",
            borderRadius: '4px',
            '& .MuiChip-label': {
              whiteSpace: 'normal',
            },
          }}
        />
      </CardActions>
    </Card>
  );
};

export default CustomAdCard;
