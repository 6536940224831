import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; // Add icons for reordering

const ManageSubscriptionPlans = () => {
  const [plans, setPlans] = useState([]);
  const [newFeature, setNewFeature] = useState({});
  const [newFeatureLimit, setNewFeatureLimit] = useState({});
  const [loading, setLoading] = useState(false);
  const [newPlan, setNewPlan] = useState({
    name: '',
    price: '',
    description: '',
    priceId: '',
    features: [],
    billingFrequency: '',
  });
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchPlans = async () => {
      const docRef = doc(db, 'subscriptions', 'products');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setPlans(docSnap.data().plans || []);
      }
    };
    fetchPlans();
  }, []);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setPlans((prevPlans) => {
      const updatedPlans = [...prevPlans];
      updatedPlans[index] = {
        ...updatedPlans[index],
        [name]: value,
      };
      return updatedPlans;
    });
  };

  const handleAddFeature = (index) => {
    if (newFeature[index]?.trim() !== '') {
      setPlans((prevPlans) => {
        const updatedPlans = [...prevPlans];
        const feature = {
          name: newFeature[index],
          limit: newFeatureLimit[index] !== '' ? Number(newFeatureLimit[index]) : null,
        };
        updatedPlans[index].features.push(feature);
        return updatedPlans;
      });
      setNewFeature((prev) => ({ ...prev, [index]: '' }));
      setNewFeatureLimit((prev) => ({ ...prev, [index]: '' }));
    }
  };

  const handleRemoveFeature = (planIndex, featureIndex) => {
    setPlans((prevPlans) => {
      const updatedPlans = [...prevPlans];
      updatedPlans[planIndex].features = updatedPlans[planIndex].features.filter((_, i) => i !== featureIndex);
      return updatedPlans;
    });
  };

  const handleAddPlan = () => {
    setPlans((prevPlans) => [...prevPlans, { ...newPlan, features: [] }]);
    setNewPlan({
      name: '',
      price: '',
      description: '',
      priceId: '',
      features: [],
      billingFrequency: '',
    });
    setOpenDialog(false);
  };

  const handleRemovePlan = (index) => {
    setPlans((prevPlans) => prevPlans.filter((_, i) => i !== index));
  };

  // Move Plan Up
  const movePlanUp = (index) => {
    if (index > 0) {
      setPlans((prevPlans) => {
        const updatedPlans = [...prevPlans];
        [updatedPlans[index], updatedPlans[index - 1]] = [updatedPlans[index - 1], updatedPlans[index]];
        return updatedPlans;
      });
    }
  };

  // Move Plan Down
  const movePlanDown = (index) => {
    if (index < plans.length - 1) {
      setPlans((prevPlans) => {
        const updatedPlans = [...prevPlans];
        [updatedPlans[index], updatedPlans[index + 1]] = [updatedPlans[index + 1], updatedPlans[index]];
        return updatedPlans;
      });
    }
  };

  // Move Feature Up
  const moveFeatureUp = (planIndex, featureIndex) => {
    if (featureIndex > 0) {
      setPlans((prevPlans) => {
        const updatedPlans = [...prevPlans];
        const features = updatedPlans[planIndex].features;
        [features[featureIndex], features[featureIndex - 1]] = [features[featureIndex - 1], features[featureIndex]];
        return updatedPlans;
      });
    }
  };

  // Move Feature Down
  const moveFeatureDown = (planIndex, featureIndex) => {
    if (featureIndex < plans[planIndex].features.length - 1) {
      setPlans((prevPlans) => {
        const updatedPlans = [...prevPlans];
        const features = updatedPlans[planIndex].features;
        [features[featureIndex], features[featureIndex + 1]] = [features[featureIndex + 1], features[featureIndex]];
        return updatedPlans;
      });
    }
  };

  const handleSavePlans = async () => {
    setLoading(true);
    try {
      await setDoc(doc(db, 'subscriptions', 'products'), { plans });
      alert('Plans updated successfully!');
    } catch (error) {
      console.error('Error updating plans:', error);
      alert('Failed to update plans.');
    }
    setLoading(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewPlan({
      name: '',
      price: '',
      description: '',
      priceId: '',
      features: [],
      billingFrequency: '',
    });
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Manage Subscription Plans
      </Typography>

      <Grid container spacing={4}>
        {plans.map((plan, planIndex) => (
          <Grid item xs={12} sm={6} key={planIndex}>
            <Paper elevation={3} sx={{ padding: 3 }}>
              <Typography variant="h6">{`Plan ${planIndex + 1}`}</Typography>
              <TextField
                label="Name"
                name="name"
                value={plan.name}
                onChange={(e) => handleChange(e, planIndex)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Price"
                name="price"
                value={plan.price}
                onChange={(e) => handleChange(e, planIndex)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description"
                name="description"
                value={plan.description}
                onChange={(e) => handleChange(e, planIndex)}
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
              <TextField
                label="Price ID"
                name="priceId"
                value={plan.priceId}
                onChange={(e) => handleChange(e, planIndex)}
                fullWidth
                margin="normal"
              />

              <FormControl fullWidth margin="normal">
                <InputLabel>Billing Frequency</InputLabel>
                <Select
                  value={plan.billingFrequency}
                  name="billingFrequency"
                  onChange={(e) => handleChange(e, planIndex)}
                  label="Billing Frequency"
                >
                  <MenuItem value="month">Month</MenuItem>
                  <MenuItem value="year">Year</MenuItem>
                  <MenuItem value="quarter">Quarter</MenuItem>
                  <MenuItem value="one time">One Time</MenuItem>
                </Select>
              </FormControl>

              <Typography variant="subtitle1" gutterBottom>
                Features:
              </Typography>
              <List>
                {plan.features.map((feature, featureIndex) => (
                  <ListItem key={featureIndex}>
                    <ListItemText primary={`${feature.name}${feature.limit ? ` (Limit: ${feature.limit})` : ''}`} />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => moveFeatureUp(planIndex, featureIndex)}>
                        <ArrowUpwardIcon />
                      </IconButton>
                      <IconButton onClick={() => moveFeatureDown(planIndex, featureIndex)}>
                        <ArrowDownwardIcon />
                      </IconButton>
                      <IconButton edge="end" onClick={() => handleRemoveFeature(planIndex, featureIndex)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <Box display="flex" alignItems="center">
                <TextField
                  label="Feature Name"
                  value={newFeature[planIndex] || ''}
                  onChange={(e) => setNewFeature({ ...newFeature, [planIndex]: e.target.value })}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Quantity Limit (optional)"
                  type="number"
                  value={newFeatureLimit[planIndex] || ''}
                  onChange={(e) => setNewFeatureLimit({ ...newFeatureLimit, [planIndex]: e.target.value })}
                  fullWidth
                  margin="normal"
                />
                <IconButton color="primary" onClick={() => handleAddFeature(planIndex)}>
                  <AddCircleIcon />
                </IconButton>
              </Box>

              {/* Move Plan Up and Down */}
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button
                  variant="outlined"
                  onClick={() => movePlanUp(planIndex)}
                  disabled={planIndex === 0} // Disable move up if it's the first plan
                >
                  Move Up
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => movePlanDown(planIndex)}
                  disabled={planIndex === plans.length - 1} // Disable move down if it's the last plan
                >
                  Move Down
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleRemovePlan(planIndex)}
                >
                  Remove Plan
                </Button>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Button to open the Add New Plan dialog */}
      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={handleOpenDialog}>
          Add New Plan
        </Button>
      </Box>

      {/* Dialog for Adding New Plan */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Add New Plan</DialogTitle>
        <DialogContent>
          <TextField
            label="Plan Name"
            name="name"
            value={newPlan.name}
            onChange={(e) => setNewPlan({ ...newPlan, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Plan Price"
            name="price"
            value={newPlan.price}
            onChange={(e) => setNewPlan({ ...newPlan, price: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Plan Description"
            name="description"
            value={newPlan.description}
            onChange={(e) => setNewPlan({ ...newPlan, description: e.target.value })}
            fullWidth
            margin="normal"
            multiline
            rows={3}
          />
          <TextField
            label="Price ID"
            name="priceId"
            value={newPlan.priceId}
            onChange={(e) => setNewPlan({ ...newPlan, priceId: e.target.value })}
            fullWidth
            margin="normal"
          />

          {/* Billing Frequency Dropdown for new plan */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Billing Frequency</InputLabel>
            <Select
              value={newPlan.billingFrequency}
              name="billingFrequency"
              onChange={(e) => setNewPlan({ ...newPlan, billingFrequency: e.target.value })}
              label="Billing Frequency"
            >
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="year">Year</MenuItem>
              <MenuItem value="quarter">Quarter</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddPlan} color="primary">
            Add Plan
          </Button>
        </DialogActions>
      </Dialog>

      {/* Save All Plans */}
      <Box mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSavePlans}
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save Plans'}
        </Button>
      </Box>
    </Container>
  );
};

export default ManageSubscriptionPlans;
