import React, { useEffect, useState } from 'react';
import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

const FloatingUserBar = ({ username }) => {
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Calculate the offset based on the app bar height
  const appBarOffset = isMobile ? 56 : 64;

  const handleShowAllListings = () => {
    navigate('/marketplace', { replace: true });
    window.location.reload(); // Optional: Ensure a full reload if needed
  };

  // Monitor scroll position to toggle sticky behavior
  useEffect(() => {
    const handleScroll = () => {
      const userBarOffset = document.getElementById("floating-user-bar")?.offsetTop;
      if (window.scrollY > userBarOffset - appBarOffset) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [appBarOffset]);

  // Delay to let the layout stabilize before applying sticky positioning
  useEffect(() => {
    setTimeout(() => {
      setIsSticky(window.scrollY > document.getElementById("floating-user-bar")?.offsetTop - appBarOffset);
    }, 100);
  }, [appBarOffset]);

  return (
    username && (
      <Box
        id="floating-user-bar"
        sx={{
          position: isSticky ? "fixed" : "relative",
          top: isSticky ? `${appBarOffset}px` : "auto",
          left: 0,
          right: 0,
          zIndex: 1100,
          backgroundColor: "#fff",
          padding: "10px",
          width: "100%", // Ensure it spans the full container width
          textAlign: "center",
          borderBottom: "2px solid #ddd",
          boxShadow: isSticky ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none",
          transition: "top 0.3s ease-in-out",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "red",
            fontWeight: "bold",
          }}
        >
          Displaying listings for user: {username}
        </Typography>
        <Box
          sx={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
            fontWeight: "normal",
            marginTop: 1,
          }}
          onClick={handleShowAllListings}
        >
          Click here to show all listings
        </Box>
      </Box>
    )
  );
};

export default FloatingUserBar;
