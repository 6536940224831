import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Checkbox, List, ListItem, ListItemText, Avatar, Typography } from '@mui/material';
import { db } from '../firebase';
import { collection, addDoc, setDoc, doc, serverTimestamp, getDoc } from 'firebase/firestore';
import { useAuth } from '../auth';
import { getDocs, query, where } from 'firebase/firestore'; // Add query, where to check for existing conversations

const BulkMessageSender = () => {
  const [message, setMessage] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]); // State to store selected users
  const [allUsers, setAllUsers] = useState([]); // All available users
  const { currentUser } = useAuth(); // Get the current user
  const [selectAll, setSelectAll] = useState(false); // State to manage select all/none toggle

  // Fetch all users from Firestore (assuming they are in a 'users' collection)
  useEffect(() => {
    const fetchUsers = async () => {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersList = usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setAllUsers(usersList);
    };
    
    fetchUsers();
  }, []);

  const handleUserSelect = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleSelectAllToggle = () => {
    if (selectAll) {
      // Deselect all users
      setSelectedUsers([]);
    } else {
      // Select all users
      const allUserIds = allUsers.map((user) => user.id);
      setSelectedUsers(allUserIds);
    }
    setSelectAll(!selectAll); // Toggle the select all state
  };

  const handleSendMessage = async () => {
    if (message.trim() === '') return;

    try {
      await Promise.all(
        selectedUsers.map(async (recipientId) => {
          const conversationId = [currentUser.uid, recipientId].sort().join('_');

          // Check if the conversation already exists
          const conversationRef = doc(db, 'conversations', conversationId);
          const conversationSnap = await getDoc(conversationRef);

          if (conversationSnap.exists()) {
            // Conversation exists, just add the message to it
            await addDoc(collection(db, `conversations/${conversationId}/messages`), {
              senderId: currentUser.uid,
              recipientId: recipientId,
              message: message,
              timestamp: serverTimestamp(),
              read: false,
            });

            // Update the conversation with the latest message
            await setDoc(conversationRef, {
              lastMessage: message,
              lastUpdated: serverTimestamp(),
            }, { merge: true });

          } else {
            // Conversation doesn't exist, create a new conversation
            await setDoc(conversationRef, {
              participants: [currentUser.uid, recipientId],
              lastMessage: message,
              lastUpdated: serverTimestamp(),
            });

            // Add the first message to the conversation
            await addDoc(collection(db, `conversations/${conversationId}/messages`), {
              senderId: currentUser.uid,
              recipientId: recipientId,
              message: message,
              timestamp: serverTimestamp(),
              read: false,
            });
          }
        })
      );
      setMessage(''); // Clear message field after sending
      setSelectedUsers([]); // Clear selected users after sending
      setSelectAll(false); // Reset select all toggle
    } catch (error) {
      console.error('Error sending bulk messages: ', error);
    }
  };

  return (
    <Box>
      <TextField
        fullWidth
        variant="outlined"
        label="Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        multiline
        rows={4}
        sx={{ marginBottom: 2 }}
      />

      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
        <Button variant="outlined" onClick={handleSelectAllToggle}>
          {selectAll ? 'Select None' : 'Select All'}
        </Button>
        <Typography variant="body2">
          {selectedUsers.length} of {allUsers.length} users selected
        </Typography>
      </Box>

      <List>
        {allUsers.map((user) => (
          <ListItem key={user.id} button onClick={() => handleUserSelect(user.id)}>
            <Checkbox checked={selectedUsers.includes(user.id)} />
            <Avatar alt={user.username} src={user.photoURL || '/default-avatar.png'} />
            <ListItemText primary={user.username} />
          </ListItem>
        ))}
      </List>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSendMessage}
        disabled={selectedUsers.length === 0 || message.trim() === ''}
      >
        Send Message to {selectedUsers.length} {selectedUsers.length === 1 ? 'User' : 'Users'}
      </Button>
    </Box>
  );
};

export default BulkMessageSender;
