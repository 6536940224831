import React, { useEffect, useState } from "react";
import { doc, getDoc, collection, getDocs, deleteDoc, setDoc } from "firebase/firestore";
import { useAppBarContext } from "../components/AppBarContext";
import { db } from "../firebase";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Avatar,
  CardMedia,
  useMediaQuery,
  ButtonBase,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import Listings from "./Listings"; // Import the Listings component
import { useAuth } from "../auth";
import Loading from "../components/Loading";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import { inkColorMap, rarityIcons } from "./constants";

const currencySymbols = {
  AUD: "$",
  USD: "$",
  EUR: "€",
  GBP: "£",
  JPY: "¥",
  // Add more currencies as needed
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const CardDetail = ({ setId, id, userId }) => {
  const [card, setCard] = useState(null);
  const [listings, setListings] = useState([]);
  const [usernames, setUsernames] = useState({});
  const [sortBy, setSortBy] = useState("price"); // State for sorting criteria
  const [filterCondition, setFilterCondition] = useState(""); // State for filtering condition
  const { selectedCountry, exchangeRates } = useAppBarContext();
  const { currentUser } = useAuth();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [expanded, setExpanded] = React.useState(false);
  const [openOfferDialog, setOpenOfferDialog] = useState(false);
  const [offerPrice, setOfferPrice] = useState('');
  const [selectedListing, setSelectedListing] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const fetchCardDetails = async () => {
      try {
        const cardDoc = await getDoc(doc(db, "sets", setId, "cards", id));
        setCard({ id: cardDoc.id, ...cardDoc.data() });
      } catch (error) {
        console.error("Error fetching card details:", error);
      }
    };

    const fetchListings = async () => {
      try {
        const listingsSnapshot = await getDocs(
          collection(db, "sets", setId, "cards", id, "listings")
        );
        const listingsData = listingsSnapshot.docs.flatMap((doc) =>
          Object.entries(doc.data())
            // Filter listings by userId if it exists
            .filter(([condition, data]) => !userId || doc.id === userId)
            .map(([condition, data]) => ({
              ...data,
              docName: doc.id,
              condition,
            }))
        );
    
        // Fetch usernames for the filtered listings
        const userIds = [...new Set(listingsData.map((listing) => listing.userId))];
        const usernamesData = {};
        for (const userId of userIds) {
          const userDoc = await getDoc(doc(db, "users", userId));
          if (userDoc.exists()) {
            usernamesData[userId] = userDoc.data().username;
          }
        }
    
        setUsernames(usernamesData);
        setListings(listingsData);
      } catch (error) {
        console.error("Error fetching listings:", error);
      }
    };
    

    fetchCardDetails();
    fetchListings();
  }, [id, setId, userId]);

  const convertPrice = (usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice;
    return `${currencySymbols[selectedCountry]}${(
      usdPrice * exchangeRates[selectedCountry]
    ).toFixed(2)}`;
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterCondition(event.target.value);
  };

  const handleOpenOfferDialog = (listing) => {
    setSelectedListing(listing);
    setOpenOfferDialog(true);
  };

  const handleCloseOfferDialog = () => {
    setOpenOfferDialog(false);
    setOfferPrice("");
    setQuantity(1);
  };

  const sendOffer = async () => {
    if (!selectedListing) {
      console.error("No listing selected for the offer.");
      alert("Please select a listing before sending an offer.");
      return;
    }
  
    // Ensure required fields are available in selectedListing
    if (!selectedListing.docName || !selectedListing.userId) {
      console.error("Selected listing is missing necessary fields:", selectedListing);
      alert("Failed to send offer. The selected listing is missing required information.");
      return;
    }
  
    const usdOfferPrice = parseFloat(offerPrice) / (exchangeRates[selectedCountry] || 1);

    const offerData = {
      offerPrice: usdOfferPrice, // Converted to USD for storage
      quantity: parseInt(quantity, 10),
      status: 'Pending',
      buyerId: currentUser.uid,
      listingId: selectedListing.docName,
      timestamp: new Date(),
      setId: setId,
      cardId: id,
      sellerId: selectedListing.userId,
      condition: selectedListing.condition,
      image_uris: selectedListing.image_uris || {},
      name: card.name || "",
      ink: card.ink || "",
      rarity: card.rarity || "",
      version: card.version || "",
      collector_number: card.collector_number || "",
    };
  
    try {
      const buyerOfferRef = doc(db, "users", currentUser.uid, "sentOffers", `${selectedListing.userId}_${selectedListing.docName}`);
      await setDoc(buyerOfferRef, offerData);
  
      const sellerOfferRef = doc(db, "users", selectedListing.userId, "offers", `${currentUser.uid}_${selectedListing.docName}`);
      await setDoc(sellerOfferRef, offerData);
  
      alert("Offer sent successfully!");
      handleCloseOfferDialog();
    } catch (error) {
      console.error("Error sending offer:", error);
      alert("Failed to send offer.");
    }
  };
  
  
  const addToCart = async (listing, price, setId) => {
    if (!card) {
      console.error("Card data is not available for addToCart.");
      return;
    }
  
    const userId = currentUser.uid;
    const cardId = id;
    const cartRef = doc(db, "carts", userId);
    const cartDoc = await getDoc(cartRef);
    let cartData = cartDoc.exists() ? cartDoc.data() : { items: [] };
  
    const existingItemIndex = cartData.items.findIndex(
      (item) =>
        item.docName === cardId &&
        item.type === "listing" &&
        item.listingId === listing.docName
    );
  
    if (existingItemIndex > -1) {
      // If item exists in the cart, increase the quantity
      cartData.items[existingItemIndex].quantity += 1;
    } else {
      // Define a new item with full listing details under `listing`
      const newItem = {
        type: "listing",
        setId: setId,
        docName: cardId,
        listingId: listing.docName,
        addedAt: Date.now(),
        quantity: 1,
        price: price,
        name: card.name || "",
        sellerId: listing.userId || "",
        ink: card.ink || "",
        rarity: card.rarity || "",
        version: card.version || "",
        collector_number: card.collector_number || "",
        cardData: {
          setId: setId,
          id: card.id,
          name: card.name || "",
          ink: card.ink || "",
          rarity: card.rarity || "",
          version: card.version || "",
          collector_number: card.collector_number || "",
          image_uris: card.image_uris || {},
        },
        listing: { ...listing, price }, // Store entire listing data here
      };
  
      // Remove undefined fields
      for (const key in newItem) {
        if (newItem[key] === undefined) {
          delete newItem[key];
        }
      }
  
      cartData.items.push(newItem);
    }
  
    // Update cart in Firestore
    await setDoc(cartRef, cartData);
    alert("Item added to cart!");
  
    // Update inventory quantity or delete the listing if quantity is zero
    const updatedQuantity = listing.quantity - 1;
    const listingRef = doc(db, "sets", setId, "cards", cardId, "listings", listing.docName);
    if (updatedQuantity <= 0) {
      await deleteDoc(listingRef); // Remove listing if quantity reaches zero
    } else {
      await setDoc(
        listingRef,
        {
          [listing.condition]: {
            ...listing,
            quantity: updatedQuantity,
          },
        },
        { merge: true }
      );
    }
  };

  if (!card) return <Loading />;

  const DetailRow = ({ label, value }) =>
    value ? (
      <Typography variant={isMobile ? "body2" : "body1"}>
        <strong>{label}:</strong> {value}
      </Typography>
    ) : null;

  return (
    <Box sx={{ padding: 1 }}>
      <Card
        sx={{
          width: "100%",
          border: `4px solid ${inkColorMap[card.ink] || "#F2D18B"}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* CardHeader */}
          <CardHeader
            avatar={
              <Box sx={{ display: "flex", flexDirection: "column"}}>
                <Avatar variant="square" sx={{ bgcolor: "#0C0A5A", mb: 1 }}>
                  {card.collector_number}
                </Avatar>
                <Avatar variant="square" sx={{ bgcolor: "#0C0A5A" }}>
                  <img
                    src={rarityIcons[card.rarity]}
                    alt={card.rarity}
                    style={{ width: 24, height: 24 }}
                  />
                </Avatar>
              </Box>
            }
            title={<Typography variant={isMobile ? "h6" : "h5"}>{card.name}</Typography>}
            subheader={
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography variant={isMobile ? "body2" : "body1"}>{card.version}</Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1rem", color: "gray", fontStyle: "italic" }}
                >
                  {card.set.name}
                </Typography>
              </Box>
            }
            sx={{ paddingBottom: 2 }}
          />

          {/* CardMedia */}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: isMobile ? 1 : 3
            }}
          >
            <CardMedia
              component="img"
              alt={card.name}
              image={
                card.image_uris?.digital?.normal ||
                card.image_uris?.digital?.large
              }
              title={card.name}
              sx={{
                // Responsive size control
                height: isMobile ? "200px" : "400px",
                width: "auto",
                objectFit: "contain",
                margin: "auto",
                borderRadius: 2
              }}
            />
          </Box>
        </Box>

        <CardActions
          disableSpacing
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <ButtonBase
              href={`https://www.tcgplayer.com/product/${card.tcgplayer_id}`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: isMobile ? "100%" : "70%",
                padding: isMobile ? 1 : 2,
                border: "1px solid",
                textAlign: "left",
                borderRadius: 1,
                marginBottom: isMobile ? 1 : 0,
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                TCG Player Price:
              </Typography>
              <Typography variant="body1" sx={{ marginLeft: 1 }}>
                {convertPrice(card.price || 0)}
              </Typography>
            </ButtonBase>
          </Box>

          <ButtonBase
            onClick={handleExpandClick} // This will make the entire section clickable
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "30%",
              padding: 1,
              border: "1px solid",
              textAlign: "left",
              borderRadius: 1,
            }}
          >
            <Typography variant="body2" sx={{ color: "gray" }}>
              Card Info
            </Typography>
            <ExpandMore
              expand={expanded}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </ButtonBase>
        </CardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <DetailRow label="Version" value={card.version} />
            <DetailRow label="Detail" value={card.text} />
            <DetailRow label="Rarity" value={card.rarity} />
            <DetailRow label="Ink Type" value={card.ink} />
            <DetailRow label="Cost" value={card.cost} />
            <DetailRow
              label="Type"
              value={
                Array.isArray(card.type) ? card.type.join(", ") : card.type
              }
            />
            <DetailRow
              label="Classifications"
              value={card.classifications?.join(", ")}
            />
            <DetailRow label="Flavor Text" value={card.flavor_text} />
            <DetailRow
              label="Illustrators"
              value={card.illustrators?.join(", ")}
            />
            <DetailRow label="Strength" value={card.strength} />
            <DetailRow label="Willpower" value={card.willpower} />
          </CardContent>
        </Collapse>
      </Card>

      <Listings
        cardId={id}
        setId={setId}
        listings={listings}
        usernames={usernames} // Pass usernames to Listings component
        sortBy={sortBy}
        filterCondition={filterCondition}
        convertPrice={convertPrice}
        addToCart={(listing, price) => addToCart(listing, price, setId)}
        handleSortChange={handleSortChange}
        handleFilterChange={handleFilterChange}
        card={card} // Pass card data to Listings component
        openOfferDialog={handleOpenOfferDialog}
        userId={userId}
      />

<Dialog open={openOfferDialog} onClose={handleCloseOfferDialog}>
        <DialogTitle>Send the Seller an Offer</DialogTitle>
        <DialogContent>
          <TextField
            label={`Offer Price per Card (${currencySymbols[selectedCountry] || ''})`}
            value={offerPrice}
            onChange={(e) => setOfferPrice(e.target.value)}
            type="number"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Quantity you want to buy"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            type="number"
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOfferDialog} color="secondary">Cancel</Button>
          <Button onClick={sendOffer} color="primary">Send Offer</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CardDetail;
