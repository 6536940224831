export const rarityIcons = {
    Common: '/icons/Common.png',
    Uncommon: '/icons/Uncommon.png',
    Rare: '/icons/Rare.png',
    Super_rare: '/icons/Super_rare.png',
    Legendary: '/icons/Legendary.png',
    Enchanted: '/icons/Enchanted.png',
    Promo: '/icons/Promo.png',
};

export const cardTypes = ['Normal', 'Foil'];

export const inkColors = ['Amber', 'Sapphire', 'Emerald', 'Ruby', 'Amethyst', 'Steel'];

export const rarities = [
    'Common',
    'Uncommon',
    'Rare',
    'Super_rare',
    'Legendary',
    'Enchanted',
    'Promo',
];

export const rarityOrder = {
    'Promo': 0,
    'Enchanted': 1,
    'Legendary': 2,
    'Rare': 3,
    'Super_rare': 4,
    'Uncommon': 5,
    'Common': 6
};

export const inkColorMap = {
    Amber: '#F4B302',
    Sapphire: '#3689C3',
    Emerald: '#348A34',
    Ruby: '#D3242E',
    Amethyst: '#80387B',
    Steel: '#9FA9B3',
};

export const conditionOptions = [
    'Mint',
    'Near Mint',
    'Lightly Played',
    'Moderately Played',
    'Heavily Played',
    'Damaged'
];

export const currencySymbols = { AUD: '$', USD: '$', EUR: '€', GBP: '£', JPY: '¥' };

