import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

const MessageBox = ({ open, onClose, title, message }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the device is mobile

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm" // Limit the dialog width to "small"
      sx={{
        '& .MuiDialog-paper': {
          margin: isMobile ? '16px' : '32px', // Provide some margin for better spacing on mobile
          borderRadius: 4, // Keep rounded corners
          width: isMobile ? '90%' : '100%', // Slightly smaller width on mobile for better visibility
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: message }}
          sx={{
            lineHeight: 1.6,
            fontSize: isMobile ? '0.8rem' : '1.1rem', // Adjust font size for mobile screens
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          sx={{
            width: isMobile ? '100%' : 'auto', // Full-width button on mobile
            padding: isMobile ? '12px' : '8px 16px', // Larger touch area on mobile
          }}
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageBox;
