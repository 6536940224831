import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Box,
  Typography,
  Chip,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  TextField,
  MenuItem,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { inkColorMap } from '../marketplace/constants';
import { useAuth } from '../auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

const FacebookListingCreator = ({ cards, currentUser, convertPrice, selectedItems, setSelectedItems }) => {
  const [listings, setListings] = useState({});
  const { currentUser: authUser } = useAuth();
  const [base64Images, setBase64Images] = useState({});
  const [selectedSet, setSelectedSet] = useState('');
  const [selectedCardType, setSelectedCardType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('collector_number');
  const [drawerOpen, setDrawerOpen] = useState(false);

  const borderWidth = 4;

  useEffect(() => {
    if (cards.length > 0) {
      cards.forEach(fetchListings);
    }
  }, [cards]);

  const fetchListings = async (card) => {
    try {
      // Fetch the listings for the card
      const listingsSnapshot = await getDocs(
        collection(db, 'sets', card.setId, 'cards', card.docId, 'listings')
      );

      const listingsData = {};
      for (const docSnap of listingsSnapshot.docs) {
        if (docSnap.id === currentUser.uid) {
          const data = docSnap.data();

          const updatedListings = await Promise.all(
            Object.entries(data).map(async ([condition, listing]) => {
              if (listing.autoUpdatePrice) {
                const cardDocRef = doc(db, 'sets', card.setId, 'cards', card.docId);
                const cardDoc = await getDoc(cardDocRef);
                if (cardDoc.exists()) {
                  listing.price = cardDoc.data().price * (listing.percentage / 100);
                }
              }
              return { condition, ...listing, docName: docSnap.id };
            })
          );
          listingsData[docSnap.id] = updatedListings;
        }
      }

      // Merge new listings with the previous ones
      setListings((prevListings) => ({
        ...prevListings,
        [card.docId]: listingsData,
      }));
    } catch (error) {
      console.error('Error fetching listings:', error);
    }
  };

  useEffect(() => {
    if (selectedItems.length > 0) {
      selectedItems.forEach(({ card }) => {
        fetchBase64Images(card);
      });
    }
  }, [selectedItems]);

  const fetchBase64Images = async (card) => {
    try {
      // Fetch images for the card only if selected
      const functions = getFunctions();
      const convertImagesToBase64 = httpsCallable(functions, 'convertImagesToBase64');
      const imageUrls = Object.values(card.image_uris?.digital || {});

      if (imageUrls.length > 0) {
        const result = await convertImagesToBase64({ imageUrls });
        setBase64Images((prevImages) => ({
          ...prevImages,
          [card.docId]: result.data.base64Images,
        }));
      }
    } catch (error) {
      console.error('Error fetching base64 images:', error);
    }
  };

  const toggleSelectItem = (listing, card) => {
    const itemId = `${card.docId}-${listing.condition}`;
    setSelectedItems((prevSelected) => {
      if (prevSelected.some((item) => item.itemId === itemId)) {
        return prevSelected.filter((item) => item.itemId !== itemId);
      } else {
        return [...prevSelected, { listing, card, itemId }];
      }
    });
  };

  const handleSetChange = (event) => {
    setSelectedSet(event.target.value);
  };

  const handleCardTypeChange = (event) => {
    setSelectedCardType(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortField(event.target.value);
  };

  const sortCards = (cards) => {
    switch (sortField) {
      case 'collector_number':
        return cards.sort((a, b) => parseInt(a.collector_number, 10) - parseInt(b.collector_number, 10));
      case 'name':
        return cards.sort((a, b) => a.name.localeCompare(b.name));
      case 'set':
        return cards.sort((a, b) => {
          if (a.set.name === b.set.name) {
            return parseInt(a.collector_number, 10) - parseInt(b.collector_number, 10);
          } else {
            return a.set.name.localeCompare(b.set.name);
          }
        });
      default:
        return cards;
    }
  };

  const filteredCards = cards.filter((card) => {
    const matchesSet = selectedSet ? card.set.name === selectedSet : true;
    const matchesType = selectedCardType ? card.type === selectedCardType : true;
    const matchesSearchTerm = searchTerm
      ? Object.values(listings[card.docId] || {}).some((listingArray) =>
          listingArray.some((listing) =>
            [card.name, card.set.name, card.type, listing.condition, listing.quantity, listing.price]
              .join(' ')
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          )
        )
      : true;

    return matchesSet && matchesType && matchesSearchTerm;
  });

  const sortedFilteredCards = sortCards(filteredCards);

  const handleSelectAll = () => {
    const allItems = sortedFilteredCards.flatMap(card => {
      const cardListings = listings[card.docId] || [];
      return Object.values(cardListings).flatMap(userListings => 
        userListings.map(listing => ({
          listing,
          card,
          itemId: `${card.docId}-${listing.condition}`
        }))
      );
    });

    setSelectedItems(prevSelected => {
      const existingItemIds = new Set(prevSelected.map(item => item.itemId));
      const newItems = allItems.filter(item => !existingItemIds.has(item.itemId));
      return [...prevSelected, ...newItems];
    });
  };

  const handleSelectNone = () => {
    setSelectedItems([]);
  };

  const generateFacebookPost = () => {
    // Function to pad strings to a fixed length for alignment
    const padRight = (str, length) => str.padEnd(length);
  
    // Define fixed lengths for the columns
    const collectorNumberLength = 6; // Adjust based on the typical length of collector numbers
    const nameLength = 20; // Adjust based on the typical length of card names
    const quantityLength = 15; // Adjust based on the typical length of quantity descriptions
    const priceLength = 10; // Adjust based on the typical length of price descriptions
    const conditionLength = 20; // Adjust based on the typical length of condition descriptions
  
    const profileLink = `https://buylorcana.cards/public/${currentUser.username}`;
  
    // Group by set name
    const groupedBySet = selectedItems.reduce((acc, { listing, card }) => {
      if (!acc[card.set.name]) {
        acc[card.set.name] = {};
      }
  
      // Group by card type within each set
      if (!acc[card.set.name][card.type || 'Normal']) {
        acc[card.set.name][card.type || 'Normal'] = [];
      }
  
      acc[card.set.name][card.type || 'Normal'].push({ listing, card });
      return acc;
    }, {});
  
    // Generate the Facebook post with sorting by collector_number
    const facebookPost = Object.entries(groupedBySet)
      .map(([setName, types]) => {
        const setHeader = `${setName}:\n`;
        const typeSections = Object.entries(types)
          .map(([type, items]) => {
            // Sort items by collector_number before generating the post
            items.sort((a, b) => parseInt(a.card.collector_number, 10) - parseInt(b.card.collector_number, 10));
  
            const typeHeader = `  ${type}:\n`;
            const cardsList = items
              .map(({ listing, card }) => {
                const collectorNumber = padRight(`#${card.collector_number}`, collectorNumberLength);
                const name = padRight(card.name, nameLength);
                const price = padRight(`${convertPrice(listing.price)}ea`, priceLength);
                const quantity = padRight(`${listing.quantity} Available`, quantityLength);
                const condition = `${listing.condition} Condition`;
  
                return `    ${collectorNumber} ${name} ${price} ${quantity} ${condition}`;
              })
              .join('\n');
            return typeHeader + cardsList;
          })
          .join('\n');
        return setHeader + typeSections;
      })
      .join('\n\n');
  
    // Add the profile link at the end of the post
    return `${facebookPost}\n\nCheck out all my listings at: ${profileLink}`;
  };

  // Calculate the total number of selected cards
  const totalSelectedCards = selectedItems.reduce((total, { listing }) => total + listing.quantity, 0);

  const handleImageDownload = async () => {
    const cardAspectRatio = 2.5 / 3.5;

    // Group items by set name, then by card type, and sort by collector_number
    const groupedAndSortedItems = selectedItems.reduce((acc, { listing, card }) => {
      const setName = card.set.name;
      const cardType = card.type || 'Normal';

      if (!acc[setName]) {
        acc[setName] = {};
      }

      if (!acc[setName][cardType]) {
        acc[setName][cardType] = [];
      }

      acc[setName][cardType].push({ listing, card });
      return acc;
    }, {});

    // Sort items within each group by collector_number
    Object.keys(groupedAndSortedItems).forEach((setName) => {
      Object.keys(groupedAndSortedItems[setName]).forEach((cardType) => {
        groupedAndSortedItems[setName][cardType].sort(
          (a, b) => parseInt(a.card.collector_number, 10) - parseInt(b.card.collector_number, 10)
        );
      });
    });

    const createCanvas = async (items) => {
      let positions = [];
      let cardWidth, cardHeight, canvasWidth, canvasHeight;

      // Calculate dimensions and positions based on the number of items
      switch (items.length) {
        case 1:
          cardHeight = 630;
          cardWidth = cardHeight * cardAspectRatio;
          canvasWidth = cardWidth + borderWidth * 2;
          canvasHeight = cardHeight + borderWidth * 2 + 60; // Extra space for the profile link
          positions = [{ x: borderWidth, y: borderWidth, width: cardWidth, height: cardHeight }];
          break;
        case 2:
          cardHeight = 630;
          cardWidth = cardHeight * cardAspectRatio;
          canvasWidth = cardWidth * 2 + borderWidth * 3;
          canvasHeight = cardHeight + borderWidth * 2 + 60; // Extra space for the profile link
          positions = [
            { x: borderWidth, y: borderWidth, width: cardWidth, height: cardHeight },
            { x: canvasWidth - cardWidth - borderWidth, y: borderWidth, width: cardWidth, height: cardHeight },
          ];
          break;
        case 3:
          cardHeight = 630 / 1.2;
          cardWidth = cardHeight * cardAspectRatio;
          canvasWidth = cardWidth * 3 + borderWidth * 4;
          canvasHeight = cardHeight + borderWidth * 2 + 60; // Extra space for the profile link
          positions = [
            { x: borderWidth, y: borderWidth, width: cardWidth, height: cardHeight },
            { x: (canvasWidth - cardWidth) / 2, y: borderWidth, width: cardWidth, height: cardHeight },
            { x: canvasWidth - cardWidth - borderWidth, y: borderWidth, width: cardWidth, height: cardHeight }
          ];
          break;
        case 4:
          cardHeight = 630 / 2;
          cardWidth = cardHeight * cardAspectRatio;
          canvasWidth = cardWidth * 2 + borderWidth * 3;
          canvasHeight = cardHeight * 2 + borderWidth * 3 + 60; // Extra space for the profile link
          positions = [
            { x: borderWidth, y: borderWidth, width: cardWidth, height: cardHeight },
            { x: canvasWidth - cardWidth - borderWidth, y: borderWidth, width: cardWidth, height: cardHeight },
            { x: borderWidth, y: canvasHeight - cardHeight - borderWidth - 60, width: cardWidth, height: cardHeight },
            { x: canvasWidth - cardWidth - borderWidth, y: canvasHeight - cardHeight - borderWidth - 60, width: cardWidth, height: cardHeight }
          ];
          break;
        case 5:
        case 6:
          cardHeight = 630 / 2;
          cardWidth = cardHeight * cardAspectRatio;
          canvasWidth = cardWidth * 3 + borderWidth * 4;
          canvasHeight = cardHeight * 2 + borderWidth * 3 + 60; // Extra space for the profile link
          positions = [
            { x: borderWidth, y: borderWidth, width: cardWidth, height: cardHeight },
            { x: (canvasWidth - cardWidth) / 2, y: borderWidth, width: cardWidth, height: cardHeight },
            { x: canvasWidth - cardWidth - borderWidth, y: borderWidth, width: cardWidth, height: cardHeight },
            { x: borderWidth, y: canvasHeight - cardHeight - borderWidth - 60, width: cardWidth, height: cardHeight },
            { x: (canvasWidth - cardWidth) / 2, y: canvasHeight - cardHeight - borderWidth - 60, width: cardWidth, height: cardHeight },
            ...(items.length === 6
              ? [{ x: canvasWidth - cardWidth - borderWidth, y: canvasHeight - cardHeight - borderWidth - 60, width: cardWidth, height: cardHeight }]
              : [])
          ];
          break;
        default:
          return null;
      }

      const canvas = document.createElement('canvas');
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      const ctx = canvas.getContext('2d');

      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      await Promise.all(items.map((item, index) => {
        return new Promise((resolve) => {
          const { x, y, width, height } = positions[index];
          const img = new Image();
          img.src = base64Images[item.card.docId]?.[0] || 'fallback_image_url';
          img.onload = () => {
            ctx.strokeStyle = '#EFCE89'; // Border color
            ctx.lineWidth = borderWidth;
            ctx.strokeRect(x, y, width, height);
            ctx.drawImage(img, x + borderWidth / 2, y + borderWidth / 2, width - borderWidth, height - borderWidth);

            // Draw the price and card type at the top
            ctx.fillStyle = 'rgba(0, 0, 0, 0.6)';
            ctx.fillRect(x + borderWidth / 2, y, width - borderWidth, 40);
            ctx.fillStyle = '#FFFFFF';
            ctx.font = '30px Arial';
            ctx.fillText(
              `${convertPrice(item.listing.price || 0)} ${item.card.type || 'Normal'}`,
              x + 10 + borderWidth / 2,
              y + 30 // Position at the top
            );
            resolve();
          };
        });
      }));

      // Add the user's profile link at the bottom of the canvas
      const profileLink = `https://buylorcana.cards/public/${currentUser.username}`;
      ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
      ctx.fillRect(0, canvasHeight - 60, canvasWidth, 60);
      ctx.fillStyle = '#FFFFFF';
      ctx.font = 'bold 24px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(profileLink, canvasWidth / 2, canvasHeight - 30);

      return canvas.toDataURL('image/png');
    };

    // Loop through each set and card type group to create and download canvases
    for (const [setName, cardTypes] of Object.entries(groupedAndSortedItems)) {
      for (const [cardType, items] of Object.entries(cardTypes)) {
        // Split the items into chunks of 6
        const chunks = [];
        for (let i = 0; i < items.length; i += 6) {
          chunks.push(items.slice(i, i + 6));
        }

        for (let i = 0; i < chunks.length; i++) {
          const dataUrl = await createCanvas(chunks[i]);
          if (dataUrl) {
            const link = document.createElement('a');
            link.download = `facebook-listing-${setName}-${cardType}-${i + 1}.png`;
            link.href = dataUrl;
            link.click();
          }
        }
      }
    }
  };

  const renderCard = (card) => {
    const cardListings = listings[card.docId] || [];

    return Object.values(cardListings).reduce((acc, userListings) => {
      return acc.concat(
        userListings.map((listing, index) => {
          const itemId = `${card.docId}-${listing.condition}`;
          const isSelected = selectedItems.some((item) => item.itemId === itemId);
          const imageUrl = isSelected
            ? base64Images[card.docId]?.[0] || 'fallback_image_url'
            : card.image_uris?.digital?.small || card.image_uris?.digital?.large;

          return (
            <ListItem key={itemId} sx={{ border: '2px solid #0C0A5A' }}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} sm={2}>
                  <ListItemAvatar sx={{ width: 100 }}>
                    <Box position="relative">
                      <Avatar
                        variant="square"
                        src={imageUrl}
                        sx={{
                          height: '100%',
                          width: '100%',
                          objectFit: 'contain',
                          border: `4px solid ${inkColorMap[card.ink] || '#F2D18B'}`,
                          boxSizing: 'border-box',
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = 'fallback_image_url';
                        }}
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          bgcolor: 'rgba(0, 0, 0, 0.7)',
                          color: 'white',
                          padding: '2px 4px',
                          fontSize: '0.8rem',
                        }}
                      >
                        {convertPrice(listing.price || 0)} {card.type || 'Normal'}
                      </Typography>
                    </Box>
                  </ListItemAvatar>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ListItemText
                    primary={<Typography variant="body1">#{card.collector_number} - {card.name}</Typography>}
                    secondary={
                      card.version && (
                        <Typography variant="body2" color="textSecondary">
                          {card.version}
                        </Typography>
                      )
                    }
                  />
                  <ListItemText
                    primary={<Typography variant="body1">{card.set.name}</Typography>}
                    secondary={
                      <Typography variant="body2" color="textSecondary">
                        {card.type}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                    <Chip label={`Condition: ${listing.condition}`} sx={{ bgcolor: 'green', color: 'white' }} />
                    <Chip label={`Qty: ${listing.quantity}`} sx={{ bgcolor: 'secondary.main', color: 'white' }} />
                    <FormControlLabel
                      control={
                        <Checkbox checked={isSelected} onChange={() => toggleSelectItem(listing, card)} />
                      }
                      label=""
                    />
                  </Box>
                </Grid>
              </Grid>
            </ListItem>
          );
        })
      );
    }, []);
  };

  return (
    <Box sx={{ pt: 2, pb: '150px' }}>
      {/* Filters and Sort Options */}
      <Box sx={{ marginBottom: 2, display: 'flex', gap: 2 }}>
        <TextField
          select
          label="Filter by Set"
          value={selectedSet}
          onChange={handleSetChange}
          fullWidth
        >
          <MenuItem value="">
            <em>All Sets</em>
          </MenuItem>
          {[...new Set(cards.map(card => card.set.name))].map(setName => (
            <MenuItem key={setName} value={setName}>
              {setName}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          label="Filter by Card Type"
          value={selectedCardType}
          onChange={handleCardTypeChange}
          fullWidth
        >
          <MenuItem value="">
            <em>All Types</em>
          </MenuItem>
          {[...new Set(cards.map(card => card.type || 'Normal'))].map(type => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
        />

        <TextField
          select
          label="Sort By"
          value={sortField}
          onChange={handleSortChange}
          fullWidth
        >
          <MenuItem value="">
            <em>No Sorting</em>
          </MenuItem>
          <MenuItem value="collector_number">Card Number</MenuItem>
          <MenuItem value="name">Card Name</MenuItem>
          <MenuItem value="set">Set Name</MenuItem>
        </TextField>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="body1">
          {selectedItems.length} card{selectedItems.length !== 1 ? 's' : ''} selected
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button variant="contained" onClick={handleSelectAll}>
            Select All
          </Button>
          <Button variant="contained" onClick={handleSelectNone}>
            Select None
          </Button>
        </Box>
      </Box>

      <List>{sortedFilteredCards.flatMap(renderCard)}</List>

      {selectedItems.length > 0 && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: 'lightgrey',
            boxShadow: 3,
            zIndex: 1000,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'left' }}>
            <Typography variant="body1">Listings Selected: {selectedItems.length}</Typography>
            <Typography variant="body1">Total Available Cards: {totalSelectedCards}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button
              variant="text"
              onClick={() => setDrawerOpen(!drawerOpen)}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography variant="body2" sx={{ mr: 1 }}>
                {drawerOpen ? 'Collapse' : 'Expand'}
              </Typography>
              {drawerOpen ? <ExpandMore /> : <ExpandLess />}
            </Button>
          </Box>
          </Box>
    

          {drawerOpen && (
            <Box sx={{ maxHeight: '60vh', overflowY: 'auto', mt: 2 }}>
              <Typography variant="h6">Post Details:</Typography>
              <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', marginBottom: 2 }}>
                {generateFacebookPost()}
              </Typography>
            </Box>
          )}
    
          {/* Buttons remain fixed */}
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigator.clipboard.writeText(generateFacebookPost())}
            >
              Copy Post Details to Clipboard
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ ml: 2 }}
              onClick={handleImageDownload}
            >
              Download Post Images
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FacebookListingCreator;
