import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Card, CardContent, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useAuth } from '../auth';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween); // Enable isBetween plugin for dayjs

const SalesSummary = () => {
  const { currentUser } = useAuth();
  const [sales, setSales] = useState([]);
  const [totalQty, setTotalQty] = useState(0);
  const [cardSalesTotal, setCardSalesTotal] = useState(0);
  const [postageTotal, setPostageTotal] = useState(0);
  const [feesTotal, setFeesTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [groupBy, setGroupBy] = useState('');
  const [groupedData, setGroupedData] = useState({});
  const [selectedRange, setSelectedRange] = useState('All Time');

  // Fetch sales data
  const fetchSales = async () => {
    if (currentUser) {
      const salesSnapshot = await getDocs(collection(db, 'users', currentUser.uid, 'sales'));
      const salesData = salesSnapshot.docs.map(doc => {
        const sale = { id: doc.id, ...doc.data() };
        return sale;
      });
      setSales(salesData);
    }
  };

  useEffect(() => {
    fetchSales();
  }, [currentUser]);

  useEffect(() => {
    const filteredSales = sales.filter(sale => {
      const transactionDate = sale.transactionDate;
      return filterByDateRange(transactionDate);
    });

    const totalCardsSold = filteredSales.reduce((total, sale) => total + getTotalCards(sale.items), 0);
    const totalCardSales = filteredSales.reduce((total, sale) => total + getCardSalesTotal(sale.items), 0);
    const totalPostage = filteredSales.reduce((total, sale) => total + (sale.shippingCost || 0), 0);
    const totalFees = filteredSales.reduce((total, sale) => total + getPaymentFee(sale), 0);
    const totalGrand = totalCardSales + totalPostage + totalFees;

    setTotalQty(totalCardsSold);
    setCardSalesTotal(totalCardSales);
    setPostageTotal(totalPostage);
    setFeesTotal(totalFees);
    setGrandTotal(totalGrand);

    // Group the filtered sales
    if (groupBy) {
      const grouped = groupSalesData(filteredSales, groupBy);
      setGroupedData(grouped);
    }
  }, [sales, selectedRange, groupBy]);

  const getStartAndEndDates = (range) => {
    const today = dayjs();
    switch (range) {
      case 'This Month':
        return { start: today.startOf('month'), end: today.endOf('month') };
      case 'Last Month':
        return { start: today.subtract(1, 'month').startOf('month'), end: today.subtract(1, 'month').endOf('month') };
      case 'This Week':
        return { start: today.startOf('week'), end: today.endOf('week') };
      case 'Last Week':
        return { start: today.subtract(1, 'week').startOf('week'), end: today.subtract(1, 'week').endOf('week') };
      case 'All Time':
      default:
        return { start: null, end: null }; // No filter for 'All Time'
    }
  };

  const filterByDateRange = (transactionDate) => {
    if (!transactionDate) {
      return false;
    }

    // Check if transactionDate is a Firestore Timestamp and convert to Date if needed
    const saleDate = transactionDate.toDate ? dayjs(transactionDate.toDate()) : dayjs(transactionDate);
    const { start, end } = getStartAndEndDates(selectedRange);

    if (!start && !end) {
      return true; // No filtering for "All Time"
    }

    const isInRange = saleDate.isBetween(start, end, null, '[]');
    return isInRange;
  };

  const groupSalesData = (sales, groupBy) => {
    const groupMap = {};
    sales.forEach(sale => {
      sale.items.forEach(item => {
        const groupKey = groupBy === 'Set' ? item.setName :
                         groupBy === 'Rarity' ? item.cardRarity :
                         groupBy === 'Ink' ? item.cardInk :
                         groupBy === 'Type' ? item.cardType : 'Other';

        if (!groupMap[groupKey]) {
          groupMap[groupKey] = [];
        }
        groupMap[groupKey].push(item);
      });
    });
    return groupMap;
  };

  const getTotalCards = (items) => items.reduce((total, item) => total + item.staticQuantity, 0);

  const getCardSalesTotal = (items) => items.reduce((total, item) => total + parseFloat(item.staticPrice) * item.staticQuantity, 0);

  const getPaymentFee = (sale) => sale.paymentFee || 0;

  const renderTable = () => {
    if (!groupBy || Object.keys(groupedData).length === 0) return null;

    let totalGroupedCards = 0;
    let totalGroupedSales = 0;

    return (
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{groupBy}</TableCell>
              <TableCell>Total Cards Sold</TableCell>
              <TableCell>Total Sales</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedData).map(([groupKey, items]) => {
              const totalCardsForGroup = items.reduce((total, item) => total + item.staticQuantity, 0);
              const totalSalesForGroup = items.reduce((total, item) => total + parseFloat(item.staticPrice) * item.staticQuantity, 0);
              
              // Add to the overall totals for the footer
              totalGroupedCards += totalCardsForGroup;
              totalGroupedSales += totalSalesForGroup;

              return (
                <TableRow key={groupKey}>
                  <TableCell>{groupKey}</TableCell>
                  <TableCell>{totalCardsForGroup}</TableCell>
                  <TableCell>${totalSalesForGroup.toFixed(2)}</TableCell>
                </TableRow>
              );
            })}

            {/* Footer row for totals */}
            <TableRow>
              <TableCell><strong>Total</strong></TableCell>
              <TableCell><strong>{totalGroupedCards}</strong></TableCell>
              <TableCell><strong>${totalGroupedSales.toFixed(2)}</strong></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Total Sales Summary</Typography>

      {/* Time Range Dropdown */}
      <FormControl fullWidth margin="normal">
        <InputLabel id="time-range-label">Date Period</InputLabel>
        <Select
          labelId="time-range-label"
          value={selectedRange}
          onChange={(e) => setSelectedRange(e.target.value)}
        >
          <MenuItem value="All Time">All Time</MenuItem>
          <MenuItem value="This Month">This Month</MenuItem>
          <MenuItem value="Last Month">Last Month</MenuItem>
          <MenuItem value="This Week">This Week</MenuItem>
          <MenuItem value="Last Week">Last Week</MenuItem>
        </Select>
      </FormControl>

      {/* Totals Section */}
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">Total Card Quantity Sold</Typography>
              <Typography variant="h6">{totalQty !== null ? totalQty : <CircularProgress size={20} />}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">Total Card Sales</Typography>
              <Typography variant="h6">{cardSalesTotal !== null ? `$${cardSalesTotal.toFixed(2)}` : <CircularProgress size={20} />}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">Total Postage</Typography>
              <Typography variant="h6">{postageTotal !== null ? `$${postageTotal.toFixed(2)}` : <CircularProgress size={20} />}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" color="textSecondary">Total Fees</Typography>
              <Typography variant="h6">{feesTotal !== null ? `$${feesTotal.toFixed(2)}` : <CircularProgress size={20} />}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent sx={{ textAlign: 'center' }}>
              <Typography variant="subtitle1" color="textSecondary">Grand Total</Typography>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {grandTotal !== null ? `$${grandTotal.toFixed(2)}` : <CircularProgress size={20} />}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Display Buttons for Grouping */}
      <Button
        variant={groupBy === 'Set' ? 'contained' : 'outlined'}
        onClick={() => setGroupBy('Set')}
        sx={{ margin: 1 }}
      >
        Show by Set
      </Button>
      <Button
        variant={groupBy === 'Rarity' ? 'contained' : 'outlined'}
        onClick={() => setGroupBy('Rarity')}
        sx={{ margin: 1 }}
      >
        Show by Rarity
      </Button>
      <Button
        variant={groupBy === 'Ink' ? 'contained' : 'outlined'}
        onClick={() => setGroupBy('Ink')}
        sx={{ margin: 1 }}
      >
        Show by Ink
      </Button>
      <Button
        variant={groupBy === 'Type' ? 'contained' : 'outlined'}
        onClick={() => setGroupBy('Type')}
        sx={{ margin: 1 }}
      >
        Show by Type
      </Button>

      {/* Table View for Grouped Data */}
      {sales.length === 0 ? <CircularProgress /> : renderTable()}
    </Container>
  );
};

export default SalesSummary;

