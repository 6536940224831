import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { doc, getDoc, getDocs, collection, updateDoc } from 'firebase/firestore';
import { List, ListItem, Box, Typography, Button, Link, Divider, Grid, Avatar, Paper, Table, TableRow, TableCell, TableContainer } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useAppBarContext } from '../components/AppBarContext';

const OfferManagement = ({ currentUser }) => {
  const [offers, setOffers] = useState([]);
  const [counterOffer, setCounterOffer] = useState('');
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [openCounterDialog, setOpenCounterDialog] = useState(false);
  const { selectedCountry, exchangeRates } = useAppBarContext();

  const currencySymbols = {
    AUD: '$',
    USD: '$',
    EUR: '€',
    GBP: '£',
    JPY: '¥',
  };

  useEffect(() => {
    const fetchOffers = async () => {
      const offersRef = collection(db, 'users', currentUser.uid, 'offers');
      const offersSnapshot = await getDocs(offersRef);

      const offersWithDetails = await Promise.all(
        offersSnapshot.docs.map(async (offerDoc) => {
          const offerData = { id: offerDoc.id, ...offerDoc.data() };

          if (offerData.setId && offerData.cardId) {
            const cardDoc = await getDoc(doc(db, 'sets', offerData.setId, 'cards', offerData.cardId));
            if (cardDoc.exists()) {
              offerData.cardData = cardDoc.data();
            }
          }

          if (offerData.buyerId) {
            const buyerDoc = await getDoc(doc(db, 'users', offerData.buyerId));
            if (buyerDoc.exists()) {
              offerData.buyerUsername = buyerDoc.data().username;
            }
          }

          return offerData;
        })
      );

      setOffers(offersWithDetails);
    };

    fetchOffers();
  }, [currentUser]);

  const convertPrice = (usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice;
    return (usdPrice * exchangeRates[selectedCountry]).toFixed(2);
  };

  const handleAcceptOffer = async (offer) => {
    const offerRef = doc(db, 'users', currentUser.uid, 'offers', offer.id);
    const buyerOfferRef = doc(db, 'users', offer.buyerId, 'sentOffers', offer.id);

    await updateDoc(offerRef, { status: 'Accepted' });
    await updateDoc(buyerOfferRef, { status: 'Accepted' });
    setOffers(offers.map(o => (o.id === offer.id ? { ...o, status: 'Accepted' } : o)));
  };

  const handleDeclineOffer = async (offer) => {
    const offerRef = doc(db, 'users', currentUser.uid, 'offers', offer.id);
    const buyerOfferRef = doc(db, 'users', offer.buyerId, 'sentOffers', offer.id);

    await updateDoc(offerRef, { status: 'Declined' });
    await updateDoc(buyerOfferRef, { status: 'Declined' });
    setOffers(offers.map(o => (o.id === offer.id ? { ...o, status: 'Declined' } : o)));
  };

  const handleSendCounterOffer = async (offer) => {
    const offerRef = doc(db, 'users', currentUser.uid, 'offers', offer.id);

    await updateDoc(offerRef, { status: 'Counter Offer Sent' });
    setOffers(offers.map(o => (o.id === offer.id ? { ...o, status: 'Counter Offer Sent' } : o)));
    setOpenCounterDialog(false);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Accepted':
        return '#28a745';
      case 'Declined':
        return '#dc3545';
      case 'Counter':
        return '#ffc107';
      case 'Counter Offer Sent':
        return '#ff9800';
      default:
        return '#6c757d';
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Offer Management
      </Typography>
      <List>
        {offers.map((offer) => (
          <React.Fragment key={offer.id}>
            <ListItem sx={{ border: "2px solid #0C0A5A", borderRadius: 2, mb: 2, p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 2, backgroundColor: "#f9f9f9", borderRadius: 2 }}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>Buyer:</Typography>
                      <Link color="primary" sx={{ display: "flex", alignItems: "center", cursor: "pointer", ml: 1 }}>
                        <PersonIcon fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>{offer.buyerUsername || offer.buyerId}</Typography>
                      </Link>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{
                        backgroundColor: getStatusColor(offer.status),
                        color: "#fff",
                        fontWeight: "bold",
                        padding: "4px 10px",
                        borderRadius: "4px",
                      }}
                    >
                      {offer.status}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={8}>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="body1">
                      Buyers Offer: <span style={{ fontWeight: "bold" }}>{currencySymbols[selectedCountry] || '$'}{convertPrice(offer.offerPrice)}</span>
                    </Typography>
                    {offer.counterOffer && (
                      <Typography variant="body1">
                        Your Counter Offer: <span style={{ fontWeight: "bold" }}>{currencySymbols[selectedCountry] || '$'}{convertPrice(offer.counterOffer)}</span>
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4} sx={{ textAlign: "right" }}>
                  {offer.status === 'Pending' && (
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Button variant="contained" color="primary" onClick={() => handleAcceptOffer(offer)}>
                        Accept
                      </Button>
                      <Button variant="contained" color="error" onClick={() => handleDeclineOffer(offer)}>
                        Decline
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => { setSelectedOffer(offer); setOpenCounterDialog(true); }}
                      >
                        Counter
                      </Button>
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                      <TableRow>
                        <TableCell component="th" scope="row" sx={{ width: { xs: "50%", sm: "40%" }, maxWidth: "50%" }}>
                          <Box display="flex" alignItems="center">
                            <Avatar
                              variant="square"
                              src={offer.cardData?.image_uris?.digital?.small || 'fallback_image_url'}
                              sx={{
                                height: "80px",
                                width: "60px",
                                objectFit: "contain",
                                marginRight: 2,
                                borderRadius: 1,
                              }}
                            />
                            <Box>
                              <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: { xs: "0.75rem", sm: "1.2rem" } }}>
                                #{offer.cardData?.collector_number} - {offer.cardData?.name}
                              </Typography>
                              <Typography variant="body1" sx={{ fontSize: { xs: "0.75rem", sm: "1.2rem" } }}>
                                {offer.cardData?.version || offer.version}
                              </Typography>
                              <Typography variant="body2" color="textSecondary" sx={{ fontSize: { xs: "0.65rem", sm: "1.2rem" } }}>
                                {offer.cardData?.rarity || offer.rarity}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body2" color="textSecondary">Qty: {offer.quantity}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body2" color="textSecondary">Listing Price: {currencySymbols[selectedCountry] || '$'}{convertPrice(offer.cardData?.price || 0)}</Typography>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default OfferManagement;
