import React from 'react';
import {
  TextField, Box, Button, Typography, FormControlLabel, RadioGroup, Radio, Divider, Grid
} from '@mui/material';

const SellerPreferences = ({
  profile,
  setProfile,
  handleSaveSellerPreferences,
  addOtherField,
  handleOtherFieldChange,
}) => {
  const handleSellerPreferenceChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');

    setProfile((prev) => {
      const updatedPreferences = { ...prev.sellerPreferences };

      let current = updatedPreferences;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) current[keys[i]] = {};
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;

      return {
        ...prev,
        sellerPreferences: updatedPreferences,
      };
    });
  };

  const handleFeeTypeChange = (paymentMethod, feeType) => {
    setProfile((prev) => ({
      ...prev,
      sellerPreferences: {
        ...prev.sellerPreferences,
        paymentOptions: {
          ...prev.sellerPreferences.paymentOptions,
          [paymentMethod]: {
            ...prev.sellerPreferences.paymentOptions[paymentMethod],
            feeType,
          },
        },
      },
    }));
  };

  return (
    <Box>
      {/* PayPal Section */}
      <Divider sx={{ mb: 3 }} />
      <Typography variant="h6" gutterBottom>
        PayPal
      </Typography>
      <TextField
        name="paymentOptions.paypal.paypalID"
        label="PayPal ID"
        value={profile.sellerPreferences.paymentOptions?.paypal?.paypalID || ''}
        onChange={handleSellerPreferenceChange}
        fullWidth
        variant="outlined"
        margin="normal"
      />
      <RadioGroup
        row
        name="paypalFeeType"
        value={profile.sellerPreferences.paymentOptions?.paypal?.feeType || '%'}
        onChange={(e) => handleFeeTypeChange('paypal', e.target.value)}
      >
        <FormControlLabel value="%" control={<Radio />} label="%" />
        <FormControlLabel value="$" control={<Radio />} label="$" />
      </RadioGroup>
      <TextField
        name="paymentOptions.paypal.fee"
        label="PayPal Fee"
        value={profile.sellerPreferences.paymentOptions?.paypal?.fee || ''}
        onChange={handleSellerPreferenceChange}
        fullWidth
        variant="outlined"
        margin="normal"
      />

      {/* Bank Deposit Section */}
      <Divider sx={{ my: 3 }} />
      <Typography variant="h6" gutterBottom>
        Bank Deposit
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="paymentOptions.bankDeposit.accountName"
            label="Bank Account Name"
            value={profile.sellerPreferences.paymentOptions?.bankDeposit?.accountName || ''}
            onChange={handleSellerPreferenceChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="paymentOptions.bankDeposit.bsb"
            label="Bank BSB"
            value={profile.sellerPreferences.paymentOptions?.bankDeposit?.bsb || ''}
            onChange={handleSellerPreferenceChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="paymentOptions.bankDeposit.accountNumber"
            label="Bank Account Number"
            value={profile.sellerPreferences.paymentOptions?.bankDeposit?.accountNumber || ''}
            onChange={handleSellerPreferenceChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RadioGroup
            row
            name="bankDepositFeeType"
            value={profile.sellerPreferences.paymentOptions?.bankDeposit?.feeType || '$'}
            onChange={(e) => handleFeeTypeChange('bankDeposit', e.target.value)}
          >
            <FormControlLabel value="%" control={<Radio />} label="%" />
            <FormControlLabel value="$" control={<Radio />} label="$" />
          </RadioGroup>
        </Grid>
      </Grid>
      <TextField
        name="paymentOptions.bankDeposit.fee"
        label="Bank Transfer Fee"
        value={profile.sellerPreferences.paymentOptions?.bankDeposit?.fee || ''}
        onChange={handleSellerPreferenceChange}
        fullWidth
        variant="outlined"
        margin="normal"
      />

      {/* PayID Section */}
      <Divider sx={{ my: 3 }} />
      <Typography variant="h6" gutterBottom>
        PayID
      </Typography>
      <TextField
        name="paymentOptions.payID.payID"
        label="PayID"
        value={profile.sellerPreferences.paymentOptions?.payID?.payID || ''}
        onChange={handleSellerPreferenceChange}
        fullWidth
        variant="outlined"
        margin="normal"
      />
      <RadioGroup
        row
        name="payIDFeeType"
        value={profile.sellerPreferences.paymentOptions?.payID?.feeType || '%'}
        onChange={(e) => handleFeeTypeChange('payID', e.target.value)}
      >
        <FormControlLabel value="%" control={<Radio />} label="%" />
        <FormControlLabel value="$" control={<Radio />} label="$" />
      </RadioGroup>
      <TextField
        name="paymentOptions.payID.fee"
        label="PayID Fee"
        value={profile.sellerPreferences.paymentOptions?.payID?.fee || ''}
        onChange={handleSellerPreferenceChange}
        fullWidth
        variant="outlined"
        margin="normal"
      />

      {/* Other Payment Options Section */}
      <Divider sx={{ my: 3 }} />
      <Typography variant="h6" gutterBottom>
        Other Payment Options
      </Typography>
      {profile.sellerPreferences.paymentOptions?.other?.map((option, index) => (
        <Box key={index} mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Payment Name"
                value={option.field}
                onChange={(e) => handleOtherFieldChange(index, 'field', e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Payment Details"
                value={option.value}
                onChange={(e) => handleOtherFieldChange(index, 'value', e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <RadioGroup
                row
                name={`otherFeeType_${index}`}
                value={option.feeType || '%'}
                onChange={(e) => handleOtherFieldChange(index, 'feeType', e.target.value)}
              >
                <FormControlLabel value="%" control={<Radio />} label="%" />
                <FormControlLabel value="$" control={<Radio />} label="$" />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label="Fee"
                value={option.fee || ''}
                onChange={(e) => handleOtherFieldChange(index, 'fee', e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      ))}
      <Button onClick={addOtherField} variant="contained" color="secondary" sx={{ mt: 2, mb: 3 }}>
        Add Another Payment Option
      </Button>

      {/* Save Button */}
      <Divider sx={{ my: 3 }} />
      <Button onClick={handleSaveSellerPreferences} variant="contained" color="primary" fullWidth>
        Save All Seller Preferences
      </Button>
    </Box>
  );
};

export default SellerPreferences;
