import React, { useState, useEffect, useCallback, useRef } from "react";
import { Autocomplete, TextField, Box, CircularProgress, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [showOnlyWithListings, setShowOnlyWithListings] = useState(true);
  const navigate = useNavigate();
  const abortControllerRef = useRef(null);

  const flattenData = (cardData) => ({
    ...cardData,
    classifications: cardData.classifications?.join(" ").toLowerCase() || "",
    illustrators: cardData.illustrators?.join(" ").toLowerCase() || "",
    name: cardData.name?.toLowerCase() || "",
    version: cardData.version?.toLowerCase() || "",
    collector_number: cardData.collector_number?.toString() || "",
    type: cardData.type?.toLowerCase() || "",
    rarity: cardData.rarity?.toLowerCase() || "",
    text: cardData.text?.toLowerCase() || "",
    flavor_text: cardData.flavor_text?.toLowerCase() || "",
    ink: cardData.ink?.toLowerCase() || "",
  });

  const fetchSearchResults = useCallback(
    async (term) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
  
      const controller = new AbortController();
      abortControllerRef.current = controller;
  
      setLoading(true);
      setNoResults(false);
      try {
        const setsRef = collection(db, "sets");
        const setsSnapshot = await getDocs(setsRef);
        const results = [];
        const termLower = term.toLowerCase();
  
        await Promise.all(
          setsSnapshot.docs.map(async (setDoc) => {
            const setData = setDoc.data();
            const cardsSnapshot = await getDocs(
              collection(db, `sets/${setDoc.id}/cards`),
              { signal: controller.signal }
            );
  
            // Filter cards based on the search term
            const filteredCards = await Promise.all(
              cardsSnapshot.docs
                .filter((cardDoc) => {
                  const cardData = flattenData(cardDoc.data());
                  return (
                    cardData.name.includes(termLower) ||
                    cardData.version.includes(termLower) ||
                    cardData.collector_number.includes(termLower) ||
                    cardData.type.includes(termLower) ||
                    cardData.rarity.includes(termLower) ||
                    cardData.text.includes(termLower) ||
                    cardData.flavor_text.includes(termLower) ||
                    cardData.classifications.includes(termLower) ||
                    cardData.illustrators.includes(termLower) ||
                    cardData.ink.includes(termLower)
                  );
                })
                .map(async (cardDoc) => {
                  const cardData = cardDoc.data();
  
                  // Fetch listings regardless of the checkbox state
                  const listingsSnapshot = await getDocs(
                    collection(db, `sets/${setDoc.id}/cards/${cardDoc.id}/listings`),
                    { signal: controller.signal }
                  );
                  const hasListings = listingsSnapshot.docs.some(
                    (listingDoc) => Object.keys(listingDoc.data()).length > 0
                  );
  
                  // Include the card based on the checkbox state
                  if (showOnlyWithListings && !hasListings) return null;
  
                  return {
                    ...cardData,
                    setName: setData.name,
                    cardName: cardData.name,
                    collectorNumber: cardData.collector_number,
                    listingsCount: hasListings ? 1 : 0,
                    cardId: cardDoc.id,
                    setId: setDoc.id,
                    cardVersion: cardData.version || "",
                  };
                })
            );
  
            results.push(...filteredCards.filter((card) => card !== null));
          })
        );
  
        if (results.length === 0) {
          setNoResults(true);
        }
  
        setSearchResults(results);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching search results:", error);
        }
      } finally {
        setLoading(false);
      }
    },
    [showOnlyWithListings]
  );
  
  
  // Debounce the search input change handler using useCallback
  const debouncedFetchSearchResultsRef = useRef(
    debounce((term, fetchFn) => {
      fetchFn(term);
    }, 300)
  );
  
  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    debouncedFetchSearchResultsRef.current(term, fetchSearchResults);
  };
  
  // Effect to update the debounce function if `fetchSearchResults` changes
  useEffect(() => {
    debouncedFetchSearchResultsRef.current = debounce((term, fetchFn) => {
      fetchFn(term);
    }, 300);
  }, [fetchSearchResults]);
  
  // Effect to fetch results when the checkbox changes or the search term changes
  useEffect(() => {
    if (searchTerm) {
      fetchSearchResults(searchTerm);
    }
  }, [showOnlyWithListings, searchTerm, fetchSearchResults]);

  const handleCheckboxChange = (event) => {
    setShowOnlyWithListings(event.target.checked);
  };

  const handleSelectResult = (event, value) => {
    if (value && value.setId && value.cardId) {
      navigate(`/marketplace/${value.setId}/${value.cardId}`, {
        state: { selectedCard: value },
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !searchResults.find(result => result.name === searchTerm)) {
      event.preventDefault(); // Prevent Enter key if no item is selected
    }
  };

  const formatRarity = (rarity) => {
    if (!rarity) return "";
    return rarity
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <Box sx={{ width: "100%" }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={showOnlyWithListings}
            onChange={handleCheckboxChange} // Call the handler here to update results
          />
        }
        label="Only show items with listings"
      />
      <Autocomplete
        freeSolo
        options={searchResults}
        getOptionLabel={(option) =>
          `${option.setName}
          ${option.cardName}
          ${option.cardVersion}
          ${option.collectorNumber}
          ${option.rarity}
          ${option.type}
          ${option.classifications}`
        }
        loading={loading}
        onChange={handleSelectResult}
        noOptionsText={noResults ? "No results found" : "Start typing to search..."}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            key={`${option.setId}-${option.cardId}`}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              backgroundColor: option.listingsCount ? "#B2E6B2" : "inherit",
              padding: "8px",
            }}
          >
            <img
              src={option.image_uris?.digital?.small}
              alt={option.cardName}
              style={{
                width: 50,
                height: 70,
                objectFit: "contain",
                border: "1px solid #ccc",
              }}
            />
            <Box>
              <Typography variant="body1">
                {`${option.cardName} - ${option.cardVersion} (#${option.collector_number})`}
              </Typography>
              <Typography variant="body2">
                {`${option.setName} - ${option.type} - ${formatRarity(option.rarity)}`}
              </Typography>
              <Typography variant="body2">
                {`Listings: ${option.listingsCount ? "Yes" : "No"}`}
              </Typography>
            </Box>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown} // Disable Enter key if no item is selected
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            fullWidth
          />
        )}
      />
      {noResults && !loading && (
        <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center', marginTop: 2 }}>
          No results found.
        </Typography>
      )}
    </Box>
  );
};

export default SearchBar;
