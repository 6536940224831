import React from 'react';
import { ListItem, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction, Box, Stack, Typography, Chip } from '@mui/material';
import cardImage from './card.png';

const CustomAdListItem = ({ imageUrl, title, subtitle, text, actionText, onActionClick }) => {
  return (
    <ListItem sx={{ border: "2px solid #0C0A5A", backgroundColor: "#fff9c4" }}>
      <ListItemAvatar sx={{ width: 100 }}>
        <Avatar
          variant="square"
          src={cardImage}
          sx={{
            height: "20%",
            width: "80%",
            objectFit: "contain",
            border: "4px solid #F2D18B",
          }}
        />
      </ListItemAvatar>
      <Stack spacing={1}>
        <ListItemText
          primary={<Typography variant="body1">{title}</Typography>}
          secondary={
            subtitle && (
              <Typography variant="body2" color="textSecondary">
                {subtitle}
              </Typography>
            )
          }
        />
      </Stack>
      <ListItemSecondaryAction>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="body2" color="textPrimary">
            {text}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Chip
              label={actionText}
              onClick={onActionClick}
              clickable
              sx={{
                bgcolor: "secondary.main",
                color: "white",
                borderRadius: '4px',
                '& .MuiChip-label': {
                  whiteSpace: 'normal',
                },
              }}
            />
          </Box>
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default CustomAdListItem;
