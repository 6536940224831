import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

export const getUserIdFromUsername = async (username) => {
  try {
    const usersRef = collection(db, 'users');
    const querySnapshot = await getDocs(usersRef);
    const userDoc = querySnapshot.docs.find(doc => doc.data().username === username);

    if (userDoc) {
      return userDoc.id; // Assuming user ID is the document ID
    } else {
      console.error('No user found with the username:', username);
      return null;
    }
  } catch (error) {
    console.error('Error fetching user ID from username:', error);
    return null;
  }
};
