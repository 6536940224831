import React, { useCallback } from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  Box,
  Typography,
  Stack,
  Chip,
} from '@mui/material';
import { rarityIcons, inkColorMap } from './constants';

const BundleCardListMarketplace = React.memo(({
  cards,
  convertPrice,
}) => {
  const renderCard = useCallback((card) => {
    const isSelected = card.quantity > 0;
    return (
      <ListItem key={card.docId} sx={{ border: '2px solid #0C0A5A', bgcolor: isSelected ? 'lightgreen' : 'inherit' }}>
        <ListItemAvatar sx={{ width: 100 }}>
          <Avatar
            className={card.type === 'Foil' ? 'foil-avatar' : ''}
            variant="square"
            src={card.image_uris?.digital?.small || 'fallback_image_url'}
            sx={{
              height: '20%',
              width: '80%',
              objectFit: 'contain',
              border: `4px solid ${inkColorMap[card.ink] || '#F2D18B'}`,
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = 'fallback_image_url';
            }}
          />
          {card.type === 'Foil' && (
            <Avatar
              variant="square"
              sx={{
                width: 76,
                height: 10,
                bgcolor: '#ffd700',
                border: '2px solid #000000',
                color: '#000000',
                bottom: 18,
                left: 4,
              }}
            >
              <Typography variant="h6" sx={{ fontSize: '10px' }}>
                COLD FOIL
              </Typography>
            </Avatar>
          )}
        </ListItemAvatar>
        <Stack spacing={1}>
          <ListItemIcon>
            <Avatar variant="square" sx={{ bgcolor: '#0C0A5A' }}>
              {card.collector_number}
            </Avatar>
          </ListItemIcon>
          <ListItemIcon>
            <Avatar variant="square" sx={{ bgcolor: '#0C0A5A' }}>
              <img
                src={rarityIcons[card.rarity]}
                alt={card.rarity}
                style={{ width: 24, height: 24 }}
              />
            </Avatar>
          </ListItemIcon>
        </Stack>
        <ListItemText
          primary={<Typography variant="body1">{card.name}</Typography>}
          secondary={
            card.version && (
              <Typography variant="body2" color="textSecondary">
                {card.version}
              </Typography>
            )
          }
        />
        <ListItemText
          primary={<Chip
            sx={{
              height: 'auto',
              '& .MuiChip-label': { display: 'block', whiteSpace: 'normal' },
              bgcolor: card.tcgPrice ? 'success.main' : 'info.main',
              color: 'white',
              cursor: 'pointer',
            }}
            label={
              <Box
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <span>TCGP Price</span>
                {convertPrice(card.price || 0)}
              </Box>
            }
          />}
        />
        <ListItemSecondaryAction>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>

              <Typography>Quantity: {card.quantity}</Typography>
            </Box>
          </Box>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }, [convertPrice]);

  return (
    <List>
      {cards.map(renderCard)}
    </List>
  );
});

export default BundleCardListMarketplace;
