import React, { useState, useEffect } from 'react';
import {
  ListSubheader,
  Grid,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  Link,
  IconButton,
  Divider,
  FormControlLabel,
  Switch,
  Select,
  MenuItem
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchSortToolbar from './SearchSortToolbar';
import SaleItemsTable from './SaleItemsTable';

const groupSales = (sales, groupBy, usernames) => {
  switch (groupBy) {
    case 'status':
      return sales.reduce((groups, sale) => {
        const key = sale.status || 'Unknown Status';
        if (!groups[key]) groups[key] = [];
        groups[key].push(sale);
        return groups;
      }, {});
    case 'buyer':
      return sales.reduce((groups, sale) => {
        const key = usernames[sale.buyerId] || sale.buyerId || 'Unknown Buyer';
        if (!groups[key]) groups[key] = [];
        groups[key].push(sale);
        return groups;
      }, {});
    case 'date':
    default:
      return sales.reduce((groups, sale) => {
        const date = sale.timestamp
          ? new Date(sale.timestamp.seconds * 1000).toLocaleDateString()
          : 'Unknown Date';
        if (!groups[date]) groups[date] = [];
        groups[date].push(sale);
        return groups;
      }, {});
  }
};

// Sorting logic
const sortSales = (sales, sortOption) => {
  return sales.sort((a, b) => {
    if (sortOption === 'date') {
      return b.timestamp.seconds - a.timestamp.seconds; // Newest first
    } else if (sortOption === 'total') {
      return b.total - a.total; // Highest total first
    }
    return 0;
  });
};

const SalesList = ({
  sales,
  usernames,
  onOpenProfile,
  onCancelSale,
  onSelectSale,
  onSetPaymentRequestDialogOpen,
  onSetPrepareShippingDialogOpen,
  onSetMarkAsShippedDialogOpen,
  onRefundRequest,
  onSave,
  onUpdateRefundStatus,
  onViewDispute,
  onSetPickingSlipDialogOpen,
  getTotalCards,
  onMessageBuyer,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('date');
  const [collapsedSections, setCollapsedSections] = useState({});
  const [collapsedSales, setCollapsedSales] = useState({});
  const [allCollapsed, setAllCollapsed] = useState(false);
  const [showCancelled, setShowCancelled] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showShipped, setShowShipped] = useState(false);
  const [groupBy, setGroupBy] = useState('date');

  useEffect(() => {
    // Initialize collapsed state for all sales as true (collapsed)
    const initialCollapsedState = sales.reduce((acc, sale) => {
      acc[sale.id] = true;
      return acc;
    }, {});
    setCollapsedSales(initialCollapsedState);
  }, [sales]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Paid':
        return '#28a745'; // Dark Green
      case 'Completed':
        return '#90ee90'; // Light Green
      case 'Payment Requested':
        return '#3333cc'; // Blue
      case 'Shipped':
        return '#007bff'; // Blue
      case 'Received':
        return '#9c27b0'; // Purple
      case 'Cancelled':
        return '#dc3545'; // Red
      case 'Pending':
        return '#ffc107'; // Yellow
      default:
        return '#ffc107'; // Grey for other statuses
    }
  };

  

  // Filtered and sorted sales based on search term and selected sort option
  const filteredSales = sales.filter((sale) => {
    const buyerName = usernames[sale.buyerId] || sale.buyerId;
    const searchInItems = sale.items?.some((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    const showCancelledSale = sale.status === 'Cancelled' && showCancelled;
    const showCompletedSale = sale.status === 'Completed' && showCompleted;
    const showShippedSale = sale.status === 'Shipped' && showShipped;

    return (
      (buyerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sale.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(sale.total).includes(searchTerm) || // Allow search by total
        searchInItems) &&
        ((sale.status !== 'Cancelled' || showCancelledSale) &&
        (sale.status !== 'Completed' || showCompletedSale) &&
        (sale.status !== 'Shipped' || showShippedSale))
    );
  });

  const sortedSales = sortSales(filteredSales, sortOption);
  const groupedSales = groupSales(sortedSales, groupBy, usernames);

  // Toggle collapse/expand for a specific date
  const toggleCollapse = (date) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  // Toggle collapse/expand for a specific sale
  const toggleSaleCollapse = (saleId) => {
    setCollapsedSales((prev) => ({
      ...prev,
      [saleId]: !prev[saleId],
    }));
  };

  // Expand all or collapse all sections
  const toggleAllSections = (collapse) => {
    const newState = Object.keys(groupedSales).reduce((acc, date) => {
      acc[date] = collapse;
      return acc;
    }, {});
    setCollapsedSections(newState);
    setAllCollapsed(collapse);
  };

  return (
    <Box>
      <SearchSortToolbar
        searchTerm={searchTerm}
        sortOption={sortOption}
        onSearchChange={setSearchTerm}
        onSortChange={setSortOption}
        onToggleCollapse={toggleAllSections}
        allCollapsed={allCollapsed}
      />

      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={showCancelled}
              onChange={() => setShowCancelled(!showCancelled)}
              color="success"
            />
          }
          label="Cancelled"
        />
        <FormControlLabel
          control={
            <Switch
              checked={showCompleted}
              onChange={() => setShowCompleted(!showCompleted)}
              color="success"
            />
          }
          label="Completed"
        />
        <FormControlLabel
          control={
            <Switch
              checked={showShipped}
              onChange={() => setShowShipped(!showShipped)}
              color="success"
            />
          }
          label="Shipped"
        />
        <Select
          value={groupBy}
          onChange={(e) => setGroupBy(e.target.value)}
          sx={{ mr: 2 }}
        >
          <MenuItem value="date">Group by Date</MenuItem>
          <MenuItem value="status">Group by Status</MenuItem>
          <MenuItem value="buyer">Group by Buyer</MenuItem>
        </Select>
      </Box>

      {/* List of Sales */}
      <List>
        {Object.keys(groupedSales).map((date) => (
          <React.Fragment key={date}>
            <ListSubheader
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">{date}</Typography>
              <IconButton onClick={() => toggleCollapse(date)}>
                {collapsedSections[date] ? (
                  <ExpandMoreIcon />
                ) : (
                  <ExpandLessIcon />
                )}
              </IconButton>
            </ListSubheader>

            {/* Check if section is collapsed */}
            {!collapsedSections[date] &&
              groupedSales[date].map((sale) => (
                <React.Fragment key={sale.id}>
                  <ListItem
                    sx={{
                      border: "2px solid #0C0A5A",
                      borderRadius: 2,
                      marginBottom: 2,
                      padding: 2,
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={8}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            padding: 2,
                            backgroundColor: "#f5f5f5",
                            borderRadius: 2,
                            boxShadow: 1,
                          }}
                        >
                          {/* Buyer Information */}
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold", marginRight: 1 }}
                              >
                                Buyer:
                              </Typography>
                              <Link
                                onClick={() => onOpenProfile(sale.buyerId)}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  color: "#007bff",
                                  textDecoration: "none",
                                }}
                              >
                                <PersonIcon
                                  fontSize="small"
                                  sx={{ marginRight: "5px" }}
                                />
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {usernames[sale.buyerId]}
                                </Typography>
                              </Link>
                            </Box>
                            <IconButton
                              color="secondary"
                              onClick={() =>
                                onMessageBuyer(
                                  sale.buyerId,
                                  usernames[sale.buyerId]
                                )
                              } // Pass buyerId and buyerName
                            >
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                              >
                                <SendIcon />
                                <Typography
                                  variant="caption"
                                  sx={{ fontSize: "0.7rem", marginTop: "2px" }}
                                >
                                  MESSAGE
                                </Typography>
                              </Box>
                            </IconButton>
                          </Box>

                          <Divider sx={{ my: 1 }} />

                          {/* Total and Sale Status */}
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {/* Total Amount */}
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "1.1rem" }}
                            >
                              Total:{" "}
                              <span style={{ fontWeight: "bold" }}>
                                ${sale.total.toFixed(2)}
                              </span>
                            </Typography>

                            {/* Sale Status with Payment Method */}
                            <Typography
                              variant="body2"
                              sx={{
                                backgroundColor: getStatusColor(sale.status),
                                color: "#fff",
                                fontWeight: "bold",
                                padding: "4px 10px",
                                borderRadius: "4px",
                                display: "flex",
                                alignItems: "center",
                                gap: 1, // Adds space between status and payment method
                              }}
                            >
                              {sale.status}
                              {sale.status === "Paid" &&
                                sale.paymentOption?.method && (
                                  <>
                                    {" "}
                                    by{" "}
                                    <strong>
                                      {
                                        sale.paymentOption.method
                                          .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before capital letters
                                          .replace(/^\w/, (c) =>
                                            c.toUpperCase()
                                          ) // Capitalize the first letter
                                      }
                                    </strong>
                                  </>
                                )}
                            </Typography>
                          </Box>

                          <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                            Cards:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {getTotalCards(sale.items)}
                            </span>
                          </Typography>

                          {sale.refundStatus && (
                            <Typography variant="body2" color="error">
                              Refund Status: {sale.refundStatus}
                            </Typography>
                          )}

                          {/* Cancel Sale Button */}
                          {[
                            "Pending",
                            "Invoice Generated",
                            "Payment Requested",
                            "Buyer Approved",
                          ].includes(sale.status) && (
                            <Button
                              variant="text"
                              color="error"
                              onClick={() => onCancelSale(sale)}
                              fullWidth
                            >
                              Cancel Sale
                            </Button>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={4} sx={{ textAlign: "right" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          {["Pending", "Buyer Approved"].includes(
                            sale.status
                          ) && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                onSelectSale(sale);
                                onSetPaymentRequestDialogOpen(true);
                              }}
                              endIcon={<SendIcon />}
                            >
                              Request Payment
                            </Button>
                          )}
                          {![
                            "Payment Requested",
                            "Cancelled",
                            "Completed",
                          ].includes(sale.status) && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                onSelectSale(sale);
                                onSetPickingSlipDialogOpen(true);
                              }}
                            >
                              Picking Slip
                            </Button>
                          )}

                          {sale.status === "Paid" && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                onSelectSale(sale);
                                onSetPrepareShippingDialogOpen(true);
                              }}
                            >
                              Prepare for Shipping
                            </Button>
                          )}
                          {sale.status === "Preparing for Shipping" && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                onSelectSale(sale);
                                onSetMarkAsShippedDialogOpen(true);
                              }}
                            >
                              Mark as Shipped
                            </Button>
                          )}
                          {sale.status === "Refund Requested" && (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => onRefundRequest(sale)}
                            >
                              View Refund Request
                            </Button>
                          )}
                          {sale.status === "Refund Approved" && (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => onUpdateRefundStatus(sale)}
                            >
                              Update Refund Status
                            </Button>
                          )}
                          {sale.status === "Dispute Requested" && (
                            <Button
                              variant="contained"
                              color="warning"
                              onClick={() => onViewDispute(sale)}
                            >
                              View Dispute
                            </Button>
                          )}
                        </Box>
                      </Grid>

                      {/* Sale Items Table with toggle button */}
                      <Grid item xs={12}>
                        <Divider />
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 2,
                          }}
                        >
                          <IconButton
                            onClick={() => toggleSaleCollapse(sale.id)}
                          >
                            {collapsedSales[sale.id] ? (
                              <Typography variant="body1">
                                Show Details
                              </Typography>
                            ) : (
                              <Typography variant="body1">
                                Hide Details
                              </Typography>
                            )}
                          </IconButton>
                        </Grid>

                        {!collapsedSales[sale.id] && (
                          <SaleItemsTable
                            sale={sale}
                            isSeller={true}
                            onSave={(updatedItems, selectedReason) => {
                              onSelectSale(sale);
                              onSave(updatedItems, selectedReason);
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default SalesList;
