import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

const AllWishlistSummary = () => {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [groupBy, setGroupBy] = useState('Card');
  const [groupedData, setGroupedData] = useState({});
  const [loading, setLoading] = useState(true);
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const fetchAllWishlistItems = async () => {
    setLoading(true);
    try {
      let allWishlistItems = [];

      const usersSnapshot = await getDocs(collection(db, 'users'));
      const users = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      for (let user of users) {
        const collectionPath = `users/${user.id}/mycollection`;
        const setsSnapshot = await getDocs(collection(db, collectionPath));

        for (let setDoc of setsSnapshot.docs) {
          const setId = setDoc.id;
          const cardsSnapshot = await getDocs(collection(db, collectionPath, setId, 'cards'));
          cardsSnapshot.docs.forEach((cardDoc) => {
            const cardData = cardDoc.data();
            if (cardData.inWishlist) {
              allWishlistItems.push({
                ...cardData,
                docId: cardDoc.id,
                setId: setId,
                userId: user.id,
                username: user.username || 'Unknown User',
              });
            }
          });
        }
      }

      setWishlistItems(allWishlistItems);
    } catch (error) {
      console.error('Error fetching wishlist items:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllWishlistItems();
  }, []);

  useEffect(() => {
    if (groupBy) {
      const grouped = groupWishlistData(wishlistItems, groupBy);
      setGroupedData(grouped);
    }
  }, [wishlistItems, groupBy]);

  const groupWishlistData = (items, groupBy) => {
    const groupMap = {};

    items.forEach((item) => {
      const groupKey =
        groupBy === 'User' ? item.username :
        groupBy === 'Set' ? item.setName :
        groupBy === 'Rarity' ? item.rarity :
        groupBy === 'Ink' ? item.ink :
        groupBy === 'Type' ? item.type :
        groupBy === 'Card' ? `${item.name}-${item.collector_number}-${item.setId}-${item.type || 'Normal'}` : 'Other';

      if (!groupMap[groupKey]) {
        groupMap[groupKey] = {
          items: [],
          breakdown: {
            set: {},
            type: {},
            ink: {},
            rarity: {},
            totalItems: 0,
            wishlistCount: 0,
          },
          cardInfo: {
            name: item.name,
            collectorNumber: item.collector_number,
            rarity: item.rarity,
            ink: item.ink,
            setName: item.setName,
            type: item.type || 'Normal',
          }
        };
      }

      const group = groupMap[groupKey];
      group.items.push(item);
      group.breakdown.totalItems += 1;
      group.breakdown.wishlistCount += 1;

      if (item.setName) {
        group.breakdown.set[item.setName] = (group.breakdown.set[item.setName] || 0) + 1;
      }
      if (item.type) {
        group.breakdown.type[item.type] = (group.breakdown.type[item.type] || 0) + 1;
      }
      if (item.ink) {
        group.breakdown.ink[item.ink] = (group.breakdown.ink[item.ink] || 0) + 1;
      }
      if (item.rarity) {
        group.breakdown.rarity[item.rarity] = (group.breakdown.rarity[item.rarity] || 0) + 1;
      }
    });

    return groupMap;
  };

  const handleSortChange = (field) => {
    setSortField(field);
    setSortDirection((prev) => (sortField === field && prev === 'asc' ? 'desc' : 'asc'));
  };

  const sortedGroupedData = Object.entries(groupedData).sort(([keyA, dataA], [keyB, dataB]) => {
    const aValue = dataA.cardInfo[sortField] || '';
    const bValue = dataB.cardInfo[sortField] || '';
    if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  const renderCardDetailsTable = () => (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell onClick={() => handleSortChange('name')} style={{ cursor: 'pointer' }}>
              Card Name {sortField === 'name' && (sortDirection === 'asc' ? '↑' : '↓')}
            </TableCell>
            <TableCell onClick={() => handleSortChange('collectorNumber')} style={{ cursor: 'pointer' }}>
              Collector Number {sortField === 'collectorNumber' && (sortDirection === 'asc' ? '↑' : '↓')}
            </TableCell>
            <TableCell onClick={() => handleSortChange('rarity')} style={{ cursor: 'pointer' }}>
              Rarity {sortField === 'rarity' && (sortDirection === 'asc' ? '↑' : '↓')}
            </TableCell>
            <TableCell onClick={() => handleSortChange('ink')} style={{ cursor: 'pointer' }}>
              Ink {sortField === 'ink' && (sortDirection === 'asc' ? '↑' : '↓')}
            </TableCell>
            <TableCell onClick={() => handleSortChange('type')} style={{ cursor: 'pointer' }}>
              Type {sortField === 'type' && (sortDirection === 'asc' ? '↑' : '↓')}
            </TableCell>
            <TableCell onClick={() => handleSortChange('setName')} style={{ cursor: 'pointer' }}>
              Set {sortField === 'setName' && (sortDirection === 'asc' ? '↑' : '↓')}
            </TableCell>
            <TableCell onClick={() => handleSortChange('wishlistCount')} style={{ cursor: 'pointer' }}>
              Wishlist Count {sortField === 'wishlistCount' && (sortDirection === 'asc' ? '↑' : '↓')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedGroupedData.map(([groupKey, data]) => (
            <TableRow key={groupKey}>
              <TableCell>{data.cardInfo.name}</TableCell>
              <TableCell>{data.cardInfo.collectorNumber}</TableCell>
              <TableCell>{data.cardInfo.rarity}</TableCell>
              <TableCell>{data.cardInfo.ink}</TableCell>
              <TableCell>{data.cardInfo.type}</TableCell>
              <TableCell>{data.cardInfo.setName}</TableCell>
              <TableCell>{data.breakdown.wishlistCount || 0}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderTable = () => (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{groupBy}</TableCell>
            <TableCell>Total Items</TableCell>
            {groupBy === 'Card' && <TableCell>Wishlist Count</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(groupedData).map(([groupKey, data]) => (
            <React.Fragment key={groupKey}>
              <TableRow>
                <TableCell>{groupKey}</TableCell>
                <TableCell>{data.breakdown.totalItems}</TableCell>
                {groupBy === 'Card' && <TableCell>{data.breakdown.wishlistCount || 0}</TableCell>}
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} sx={{ paddingLeft: 4 }}>
                  <Typography variant="subtitle1" gutterBottom>Breakdown:</Typography>
                  <Typography variant="body2">
                    <strong>By Set:</strong>{" "}
                    {Object.entries(data.breakdown.set).map(([setName, count]) => (
                      <span key={setName}>{setName}: {count}, </span>
                    ))}
                  </Typography>
                  <Typography variant="body2">
                    <strong>By Type:</strong>{" "}
                    {Object.entries(data.breakdown.type).map(([type, count]) => (
                      <span key={type}>{type}: {count}, </span>
                    ))}
                  </Typography>
                  <Typography variant="body2">
                    <strong>By Ink:</strong>{" "}
                    {Object.entries(data.breakdown.ink).map(([ink, count]) => (
                      <span key={ink}>{ink}: {count}, </span>
                    ))}
                  </Typography>
                  <Typography variant="body2">
                    <strong>By Rarity:</strong>{" "}
                    {Object.entries(data.breakdown.rarity).map(([rarity, count]) => (
                      <span key={rarity}>{rarity}: {count}, </span>
                    ))}
                  </Typography>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container>
      <Typography variant="h4" gutterBottom>All Wishlist Summary</Typography>

      <FormControl fullWidth margin="normal">
        <InputLabel id="group-by-label">Group By</InputLabel>
        <Select
          labelId="group-by-label"
          value={groupBy}
          onChange={(e) => setGroupBy(e.target.value)}
        >
          <MenuItem value="User">User</MenuItem>
          <MenuItem value="Set">Set</MenuItem>
          <MenuItem value="Rarity">Rarity</MenuItem>
          <MenuItem value="Ink">Ink Color</MenuItem>
          <MenuItem value="Type">Type</MenuItem>
          <MenuItem value="Card">Card</MenuItem>
        </Select>
      </FormControl>

      <Grid container spacing={1} sx={{ marginTop: 2 }}>
        <Grid item>
          <Button variant={groupBy === 'User' ? 'contained' : 'outlined'} onClick={() => setGroupBy('User')}>
            Group by User
          </Button>
        </Grid>
        <Grid item>
          <Button variant={groupBy === 'Set' ? 'contained' : 'outlined'} onClick={() => setGroupBy('Set')}>
            Group by Set
          </Button>
        </Grid>
        <Grid item>
          <Button variant={groupBy === 'Rarity' ? 'contained' : 'outlined'} onClick={() => setGroupBy('Rarity')}>
            Group by Rarity
          </Button>
        </Grid>
        <Grid item>
          <Button variant={groupBy === 'Ink' ? 'contained' : 'outlined'} onClick={() => setGroupBy('Ink')}>
            Group by Ink Color
          </Button>
        </Grid>
        <Grid item>
          <Button variant={groupBy === 'Type' ? 'contained' : 'outlined'} onClick={() => setGroupBy('Type')}>
            Group by Type
          </Button>
        </Grid>
        <Grid item>
          <Button variant={groupBy === 'Card' ? 'contained' : 'outlined'} onClick={() => setGroupBy('Card')}>
            Group by Card
          </Button>
        </Grid>
      </Grid>

      {loading ? <CircularProgress /> : groupBy === 'Card' ? renderCardDetailsTable() : renderTable()}
    </Container>
  );
};

export default AllWishlistSummary;
