import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';

const addToCart = async ({ currentUser, listing, price, setId, card, offer, selectedListings }) => {
  // Check if user is authenticated
  if (!currentUser) {
    alert('You must be logged in to add items to the cart.');
    return;
  }

  const userId = currentUser.uid;
  const cartRef = doc(db, "carts", userId);

  let cartData;
  try {
    const cartDoc = await getDoc(cartRef);
    cartData = cartDoc.exists() ? cartDoc.data() : { items: [] };
    if (!Array.isArray(cartData.items)) {
      cartData.items = [];
    }
  } catch (error) {
    console.error("Error fetching cart:", error);
    cartData = { items: [] };
  }

  // Handle multiple listings (for PublicProfileCardList.js)
  if (selectedListings) {
    await handleMultipleListings(selectedListings, cartData, cartRef, currentUser);
    return;
  }

  // Determine source of data (card + listing vs offer)
  const cardDocName = card?.docName || offer?.cardId || listing?.card?.docName;
  const listingDocName = listing?.docName || offer?.id || listing?.userId;
  const listingSetId = setId || card?.setId || offer?.setId || listing?.card?.setId;

  if (!cardDocName || !listingDocName || !listingSetId) {
    console.error("Missing necessary data for addToCart:", { cardDocName, listingDocName, listingSetId });
    return;
  }

  // Fetch the latest listing data
  const listingRef = doc(db, "sets", listingSetId, "cards", cardDocName, "listings", listingDocName);
  const listingDoc = await getDoc(listingRef);

  if (!listingDoc.exists()) {
    console.error("Listing not found");
    return;
  }

  const condition = listing?.condition || offer?.condition;
  const latestListingData = listingDoc.data()[condition];
  if (!latestListingData) {
    console.error("Listing data missing for specified condition");
    return;
  }

  const finalPrice = listing?.autoUpdatePrice
    ? (card?.price * (listing?.percentage / 100)).toFixed(2)
    : price || offer?.offerPrice;

  // Check if the item already exists in the cart
  const existingItemIndex = cartData.items.findIndex(
    (item) => item.docName === cardDocName && item.type === "listing" && item.listingId === listingDocName
  );

  if (existingItemIndex > -1) {
    cartData.items[existingItemIndex].quantity += 1;
  } else {
    // Define a new item
    const newItem = {
      type: "listing",
      setId: listingSetId,
      listingId: listingDocName,
      docName: cardDocName,
      addedAt: Date.now(),
      quantity: 1,
      price: finalPrice,
      name: card?.name || offer?.cardData?.name || "",
      sellerId: listing?.userId || offer?.sellerId || "",
      ink: card?.ink || offer?.cardData?.ink || "",
      rarity: card?.rarity || offer?.cardData?.rarity || "",
      version: card?.version || offer?.cardData?.version || "",
      collector_number: card?.collector_number || offer?.cardData?.collector_number || "",
      cardData: card || offer?.cardData || {},
      listing: { ...latestListingData, price: finalPrice }
    };

    cartData.items.push(newItem);
  }

  await setDoc(cartRef, cartData);
  alert("Item added to cart!");

  // Update inventory quantity or delete the listing if quantity reaches zero
  const updatedQuantity = latestListingData.quantity - 1;
  if (updatedQuantity <= 0) {
    await deleteDoc(listingRef);
  } else {
    await setDoc(listingRef, {
      [condition]: { ...latestListingData, quantity: updatedQuantity },
    }, { merge: true });
  }
};

// Helper function for handling multiple listings
const handleMultipleListings = async (selectedListings, cartData, cartRef, currentUser) => {
  for (const { listing, card } of selectedListings) {
    await addToCart({ currentUser, listing, card, setId: card.setId });
  }
  await setDoc(cartRef, cartData);
  alert("Items added to cart!");
};

export default addToCart;
