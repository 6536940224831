import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

const Loading = () => {
  const [imageError, setImageError] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#fff', // Optional: Set the background color
      }}
    >
      {imageError ? (
        <CircularProgress /> // Fallback spinner when image fails to load
      ) : (
        <img
          src={`/Round.png`}
          alt="Loading..."
          style={{
            width: '100px',
            height: '100px',
            animation: 'spin 2s linear infinite', // Apply a spin animation
          }}
          onError={() => setImageError(true)} // Trigger fallback on error
        />
      )}

      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </Box>
  );
};

export default Loading;
