class BackgroundScroll {
    constructor() {
      this.scrollPosition = 0;
    }
  
    disableScroll(isDisable) {
      if (isDisable) {
        this.scrollPosition = window.pageYOffset;
        document.body.classList.add('block-body-scroll');
        document.body.style.top = `-${this.scrollPosition}px`;
        if (this.isIos()) {
          document.body.classList.add('ios');
        }
      } else {
        document.body.classList.remove('block-body-scroll');
        document.body.style.top = '';
        window.scrollTo(0, this.scrollPosition);
      }
    }
  
    isIos() {
      return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    }
  }
  
  export const backgroundScroll = new BackgroundScroll();
  