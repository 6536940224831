import React, { useEffect, useState } from 'react';
import { doc, getDoc, setDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../auth';
import { useAppBarContext } from '../components/AppBarContext';
import { db } from '../firebase';
import { Box, Typography, Card, CardContent, CardMedia, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import Loading from '../components/Loading';

const currencySymbols = {
  AUD: '$',
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  // Add more currencies as needed
};

const CollectionCardDetail = ({ setId, id }) => {
  const [card, setCard] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [inWishlist, setInWishlist] = useState(false);
  const { selectedCountry, exchangeRates } = useAppBarContext();
  const { currentUser } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchCardDetails = async () => {
      try {
        const cardDoc = await getDoc(doc(db, 'sets', setId, 'cards', id));
        if (cardDoc.exists()) {
          setCard({ id: cardDoc.id, ...cardDoc.data() });

          const collectionDoc = await getDoc(doc(db, 'users', currentUser.uid, 'mycollection', setId, 'cards', id));
          if (collectionDoc.exists()) {
            setQuantity(collectionDoc.data().quantity || 0);
          }

          const wishlistDoc = await getDoc(doc(db, 'users', currentUser.uid, 'wishlist', id));
          if (wishlistDoc.exists()) {
            setInWishlist(true);
          }
        }
      } catch (error) {
        console.error('Error fetching card details:', error);
      }
    };

    fetchCardDetails();
  }, [id, setId, currentUser]);

  const convertPrice = (usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice;
    return `${selectedCountry} ${currencySymbols[selectedCountry]}${(usdPrice * exchangeRates[selectedCountry]).toFixed(2)}`;
  };

  const handleAddToWishlist = async () => {
    try {
      const wishlistRef = doc(db, 'users', currentUser.uid, 'wishlist', id);
      if (inWishlist) {
        await deleteDoc(wishlistRef);
        setInWishlist(false);
      } else {
        await setDoc(wishlistRef, { ...card });
        setInWishlist(true);
      }
    } catch (error) {
      console.error('Error updating wishlist:', error);
    }
  };

  const handleUpdateQuantity = async (newQuantity) => {
    if (newQuantity < 1) return;
    try {
      const collectionRef = doc(db, 'users', currentUser.uid, 'mycollection', setId, 'cards', id);
      await updateDoc(collectionRef, { quantity: newQuantity });
      setQuantity(newQuantity);
    } catch (error) {
      console.error('Error updating quantity:', error);
    }
  };

  if (!card) return <Loading />;

  const DetailRow = ({ label, value }) => (
    value ? <Typography variant="body1" sx={{ fontSize: isMobile ? '0.875rem' : '1rem' }}><strong>{label}:</strong> {value}</Typography> : null
  );

  return (
    <Box sx={{ padding: isMobile ? 1 : 2 }}>
      <Grid container spacing={isMobile ? 1 : 2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ maxWidth: '100%' }}>
            <CardMedia
              component="img"
              alt={card.name}
              image={card.image_uris?.digital?.normal || card.image_uris?.digital?.large}
              title={card.name}
              sx={{
                height: isMobile ? 300 : 400,
                objectFit: "contain",
                margin: 'auto'
              }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant={isMobile ? 'h5' : 'h4'}>{card.name}</Typography>
              <DetailRow label="Rarity" value={card.rarity} />
              <DetailRow label="Collector Number" value={card.collector_number} />
              <DetailRow label="Ink Type" value={card.ink} />
              <DetailRow label="Cost" value={card.cost} />
              <DetailRow label="Type" value={Array.isArray(card.type) ? card.type.join(', ') : card.type} />
              <DetailRow label="Classifications" value={card.classifications?.join(', ')} />
              <DetailRow label="Flavor Text" value={card.flavor_text} />
              <DetailRow label="Illustrators" value={card.illustrators?.join(', ')} />
              <DetailRow label="Strength" value={card.strength} />
              <DetailRow label="Willpower" value={card.willpower} />
              <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ marginTop: 2 }}>TCG Player Price</Typography>
              <Typography variant={isMobile ? 'h5' : 'h4'}>
                {convertPrice(card.price || 0)}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, flexDirection: isMobile ? 'column' : 'row', gap: isMobile ? 1 : 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => handleUpdateQuantity(quantity + 1)}
                  sx={{ width: isMobile ? '100%' : 'auto' }}
                >
                  Increase Quantity
                </Button>
                <Typography variant="body1" sx={{ marginX: 2 }}>{quantity}</Typography>
                <Button
                  variant="outlined"
                  onClick={() => handleUpdateQuantity(quantity - 1)}
                  sx={{ width: isMobile ? '100%' : 'auto' }}
                >
                  Decrease Quantity
                </Button>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, flexDirection: isMobile ? 'column' : 'row', gap: isMobile ? 1 : 2 }}>
                <Button
                  variant="contained"
                  color={inWishlist ? "secondary" : "primary"}
                  onClick={handleAddToWishlist}
                  sx={{ width: isMobile ? '100%' : 'auto' }}
                >
                  {inWishlist ? "Remove from Wishlist" : "Add to Wishlist"}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CollectionCardDetail;
