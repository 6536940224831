import React, { useState } from 'react';
import {
  Box, FormControl, Select, MenuItem, TextField, InputAdornment, IconButton, Typography, Avatar, Button, useMediaQuery, useTheme, Drawer, Toolbar, Paper, ToggleButtonGroup, ToggleButton
} from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { rarityIcons, inkColors, inkColorMap, cardTypes } from '../marketplace/constants';
import '../App.css'; // Import the CSS file

const CollectionFilters = ({
  state, handleSetChange, handleRarityChange, handleInkChange, handleCardTypeChange,
  handleSearchChange, handleClearSearch, handleSortChange, handleViewToggle, handleCollectionStatusChange, searchTerm
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleButtonClick = (type) => {
    handleCollectionStatusChange(type);
  };

  const sortedSets = [...state.sets].sort((a, b) => {
    const codeA = a.code;
    const codeB = b.code;

    const isNumericA = !isNaN(codeA);
    const isNumericB = !isNaN(codeB);

    if (isNumericA && isNumericB) {
      return Number(codeA) - Number(codeB);
    } else if (isNumericA) {
      return -1;
    } else if (isNumericB) {
      return 1;
    } else {
      return codeA.localeCompare(codeB);
    }
  });

  const handleAllOn = (section) => {
    if (section === 'rarity') {
      handleRarityChange(null, Object.keys(rarityIcons));
    } else if (section === 'ink') {
      handleInkChange(null, inkColors);
    } else if (section === 'cardType') {
      handleCardTypeChange(null, cardTypes);
    }
  };

  const handleAllOff = (section) => {
    if (section === 'rarity') {
      handleRarityChange(null, []);
    } else if (section === 'ink') {
      handleInkChange(null, []);
    } else if (section === 'cardType') {
      handleCardTypeChange(null, []);
    }
  };

  return (
    <>
      {/* Search and Sort Section */}
      <Box
        className={`search-sort-container ${isMobile ? "search-sort-container-mobile" : ""}`}
      >
        {/* Sets Filter */}
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            {sortedSets.map((set) => (
              <Button
                key={set.id}
                onClick={(event) => handleSetChange(event, set.id)}
                sx={{
                  width: isMobile ? "90px" : "150px",
                  height: isMobile ? "36px" : "50px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  bgcolor: state.selectedSet === set.id ? "#0C0A5A" : "#f5f5f5",
                  color: state.selectedSet === set.id ? "white" : "grey",
                  border: state.selectedSet === set.id ? "#0C0A5A" : "transparent",
                  '&:hover': {
                    bgcolor: "lightgrey",
                  },
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ fontSize: isMobile ? "0.625rem" : "0.75rem" }}
                >
                  {set.name}
                </Typography>
              </Button>
            ))}
          </Box>
        </Paper>
      </Box>

      {/* Filter Toolbar */}
      <Box
        className={`search-sort-container ${isMobile ? "search-sort-container-mobile" : ""}`}
      >
        <Toolbar className="filters-toolbar">
          <Box className="filter-controls">
            <Button
              color="inherit"
              variant="outlined"
              onClick={toggleDrawer(true)}
              className="filter-button"
            >
              Filters
            </Button>
          </Box>
          <Box className="filter-controls">
            <TextField
              variant="outlined"
              label="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClearSearch}
                      edge="end"
                      size={isMobile ? "small" : "medium"}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              size={isMobile ? "small" : "medium"}
              className="white-textfield"
            />
          </Box>
        </Toolbar>
      </Box>

      {/* View Toggle and Sort Control */}
      <Box
        className="filter-controls"
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <IconButton onClick={handleViewToggle} color="inherit">
          {state.view === "grid" ? <ViewListIcon /> : <ViewModuleIcon />}
        </IconButton>
        <FormControl variant="outlined" className="white-formcontrol">
          <Select
            value={state.sortBy}
            onChange={handleSortChange}
            size={isMobile ? "small" : "medium"}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="az">A-Z</MenuItem>
            <MenuItem value="za">Z-A</MenuItem>
            <MenuItem value="price_high">Highest Price</MenuItem>
            <MenuItem value="price_low">Lowest Price</MenuItem>
            <MenuItem value="card_low">Lowest #</MenuItem>
            <MenuItem value="card_high">Highest #</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Drawer containing the filters */}
      <Drawer
        anchor={isMobile ? "bottom" : "right"}
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{
            width: "100%",
            padding: 2,
            overflowY: "auto",
            overflowX: "hidden",
            boxSizing: "border-box",
          }}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <IconButton
            onClick={toggleDrawer(false)}
            sx={{ position: "absolute", top: 2, right: 2 }}
          >
            <CloseIcon />
          </IconButton>

          {/* Rarity Filter */}
          <Paper elevation={3} sx={{ padding: 2, marginBottom: 2, marginTop: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Rarity
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 1 }}>
              <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={() => handleAllOn('rarity')}>All On</Typography>
              <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={() => handleAllOff('rarity')}>All Off</Typography>
            </Box>
            <ToggleButtonGroup
              value={state.selectedRarities}
              onChange={handleRarityChange}
              aria-label="rarity selection"
              className="toggle-button-group"
              sx={{ display: "flex", flexWrap: "wrap" }}
              size="large"
              color="primary"
            >
              {Object.keys(rarityIcons).map((rarity) => (
                <ToggleButton
                  key={rarity}
                  value={rarity}
                  aria-label={rarity}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    variant="square"
                    src={rarityIcons[rarity]}
                    alt={rarity}
                    sx={{
                      width: isMobile ? 20 : 24,
                      height: isMobile ? 20 : 24,
                    }}
                  />
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Paper>

          {/* Ink Filter */}
          <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Ink
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 1 }}>
              <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={() => handleAllOn('ink')}>All On</Typography>
              <Typography variant="caption" sx={{ cursor: 'pointer' }}               onClick={() => handleAllOff('ink')}>All Off</Typography>
            </Box>
            <ToggleButtonGroup
              value={state.selectedInks}
              onChange={handleInkChange}
              aria-label="ink selection"
              sx={{ display: "flex", flexWrap: "wrap" }}
              size="large"
            >
              {inkColors.map((ink) => (
                <ToggleButton
                  key={ink}
                  value={ink}
                  aria-label={ink}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "lightgrey",

                    "&.Mui-selected": {
                      bgcolor: inkColorMap[ink],
                    },
                    "&.Mui-selected:hover": {
                      bgcolor: inkColorMap[ink],
                    },
                    "&:hover": {
                      bgcolor: inkColorMap[ink],
                    },
                  }}
                >
                  <Avatar
                    variant="square"
                    sx={{
                      bgcolor: inkColorMap[ink],
                      width: isMobile ? 20 : 24,
                      height: isMobile ? 20 : 24,
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{ fontSize: isMobile ? "0.625rem" : "0.75rem" }}
                    >
                      {ink.substring(0, 3)}
                    </Typography>
                  </Avatar>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Paper>

          {/* Card Type Filter */}
          <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Card Type
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 1 }}>
              <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={() => handleAllOn('cardType')}>All On</Typography>
              <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={() => handleAllOff('cardType')}>All Off</Typography>
            </Box>
            <ToggleButtonGroup
              value={state.selectedCardTypes}
              onChange={handleCardTypeChange}
              aria-label="card type selection"
              sx={{ display: "flex", flexWrap: "wrap" }}
              size="large"
            >
              {cardTypes.map((type) => (
                <ToggleButton
                  key={type}
                  value={type}
                  aria-label={type}
                  sx={{
                    height: "110%",
                    bgcolor: "lightgrey",
                    color: "#fff",
                    "&.Mui-selected": {
                      bgcolor: type === "Normal" ? "#0C0A5A" : "gold",
                    },
                    "&.Mui-selected:hover": {
                      bgcolor: type === "Normal" ? "#0C0A5A" : "gold",
                    },
                    "&:hover": {
                      bgcolor: "lightgrey",
                    },
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: isMobile ? "0.625rem" : "0.75rem",
                      color: type === "Normal" ? "#FFFFFF" : "#000000",
                    }}
                  >
                    {type}
                  </Typography>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Paper>
        </Box>
      </Drawer>

     {/* Collection Status Buttons */}
<Box sx={{ textAlign: "center", my: 2 }}>
  {/* Description Text */}
  <Typography
    variant="h6"
    gutterBottom
    sx={{ fontSize: isMobile ? '1rem' : '1.25rem' }} // Smaller font on mobile
  >
    Collection Filters
  </Typography>

  {/* Toggle Button Group */}
  <ToggleButtonGroup
    value={Object.keys(state.showInCollection).filter(key => state.showInCollection[key])}
    exclusive={false} // allow multiple selections
  >
    {/* Collected Toggle */}
    <ToggleButton
      value="collected"
      selected={state.showInCollection.collected}
      onChange={() => handleButtonClick('collected')}
      sx={{
        width: isMobile ? 120 : 150, // Smaller width on mobile
        fontSize: isMobile ? '0.75rem' : '1rem', // Smaller font on mobile
        justifyContent: 'space-between',
      }}
    >
      Collected
      {state.showInCollection.collected ? <CheckCircleIcon sx={{ color: 'green', fontSize: isMobile ? '1.2rem' : '1.5rem' }} /> : <CancelIcon sx={{ color: 'red', fontSize: isMobile ? '1.2rem' : '1.5rem' }} />}
    </ToggleButton>

    {/* Not Collected Toggle */}
    <ToggleButton
      value="notCollected"
      selected={state.showInCollection.notCollected}
      onChange={() => handleButtonClick('notCollected')}
      sx={{
        width: isMobile ? 120 : 150, // Smaller width on mobile
        fontSize: isMobile ? '0.75rem' : '1rem', // Smaller font on mobile
        justifyContent: 'space-between',
      }}
    >
      Not Collected
      {state.showInCollection.notCollected ? <CheckCircleIcon sx={{ color: 'green', fontSize: isMobile ? '1.2rem' : '1.5rem' }} /> : <CancelIcon sx={{ color: 'red', fontSize: isMobile ? '1.2rem' : '1.5rem' }} />}
    </ToggleButton>

    {/* Wishlist Only Toggle */}
    <ToggleButton
      value="wishlistOnly"
      selected={state.showInCollection.wishlistOnly}
      onChange={() => handleButtonClick('wishlistOnly')}
      sx={{
        width: isMobile ? 120 : 150, // Smaller width on mobile
        fontSize: isMobile ? '0.75rem' : '1rem', // Smaller font on mobile
        justifyContent: 'space-between',
      }}
    >
      Wishlist Only
      {state.showInCollection.wishlistOnly ? <CheckCircleIcon sx={{ color: 'green', fontSize: isMobile ? '1.2rem' : '1.5rem' }} /> : <CancelIcon sx={{ color: 'red', fontSize: isMobile ? '1.2rem' : '1.5rem' }} />}
    </ToggleButton>
  </ToggleButtonGroup>
</Box>





    </>
  );
};

export default CollectionFilters;

