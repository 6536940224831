import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Typography, FormControl, Select, MenuItem, InputLabel, RadioGroup, FormControlLabel, Radio
} from '@mui/material';
import { db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';

const SellerDispute = ({ open, onClose, onSubmit, disputeDetails, onRefundRequested }) => {
  const [sellerResponse, setSellerResponse] = useState('');
  const [resolutionAction, setResolutionAction] = useState('approve');
  const [remediationAction, setRemediationAction] = useState('');

  const handleResolution = async () => {
    if (resolutionAction === 'deny' && !sellerResponse.trim()) {
      alert("Please provide a reason for denying the dispute.");
      return;
    }

    if (!disputeDetails || !disputeDetails.sellerId || !disputeDetails.id) {
      console.error("Invalid dispute details:", disputeDetails);
      return;
    }
  
    try {
      if (remediationAction === 'Refund') {
        const saleRef = doc(db, 'users', disputeDetails.sellerId, 'sales', disputeDetails.id);
        await updateDoc(saleRef, { status: 'Refund Requested' });

        if (onRefundRequested) {
          onRefundRequested(disputeDetails);
        }
      }
  
      onSubmit({
        sellerResponse,
        resolutionAction,
        remediationAction,
      });
  
      setSellerResponse('');
      setResolutionAction('approve');
      setRemediationAction('');
      onClose();
    } catch (error) {
      console.error("Error in handleResolution:", error);
    }
  };

  const handleRemediationActionChange = (event) => {
    setRemediationAction(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Handle Dispute</DialogTitle>
      <DialogContent>
        {disputeDetails ? (
          <>
            <Typography variant="body1" gutterBottom>
              Please review the dispute details below and select a resolution action.
            </Typography>
            <Typography variant="subtitle1">Reason for Dispute</Typography>
            <Typography variant="body2">{disputeDetails.disputeReason || 'No reason provided.'}</Typography>

            <Typography variant="subtitle1" gutterBottom>
              Buyer Comments
            </Typography>
            <Typography variant="body2">{disputeDetails.buyerComments || 'No comments provided.'}</Typography>

            <FormControl component="fieldset" fullWidth margin="normal">
              <Typography variant="subtitle1">Resolution Action</Typography>
              <RadioGroup
                value={resolutionAction}
                onChange={(e) => setResolutionAction(e.target.value)}
              >
                <FormControlLabel value="approve" control={<Radio />} label="Approve Dispute" />
                <FormControlLabel value="deny" control={<Radio />} label="Deny Dispute" />
              </RadioGroup>
            </FormControl>

            {resolutionAction === 'approve' && (
              <FormControl fullWidth margin="normal">
                <InputLabel>Remediation Action</InputLabel>
                <Select
                  value={remediationAction}
                  onChange={handleRemediationActionChange}
                  variant="outlined"
                >
                  <MenuItem value="Refund">Refund</MenuItem>
                  <MenuItem value="Replace">Replace</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            )}

            {resolutionAction === 'deny' && (
              <TextField
                label="Reason for Denying Dispute"
                fullWidth
                multiline
                rows={4}
                value={sellerResponse}
                onChange={(e) => setSellerResponse(e.target.value)}
                variant="outlined"
                margin="normal"
                required
              />
            )}
          </>
        ) : (
          <Typography variant="body1">No dispute details available.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
        {disputeDetails && (
          <Button onClick={handleResolution} color="primary">
            Submit Resolution
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SellerDispute;
