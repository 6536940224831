import React, { useEffect, useState, useCallback } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import {
  Box, Typography, Table, TableHead, TableCell, TableBody, TableRow, Button, Grid, Accordion, AccordionSummary, AccordionDetails, Paper, useMediaQuery, useTheme
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const WishlistMarketplaceListings = ({ wishlistItems, convertPrice, addToCart, addedToCartItems }) => {
  const [sellerListings, setSellerListings] = useState({});
  const [usernames, setUsernames] = useState({});
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchUsernames = async (userIds) => {
    const fetchedUsernames = {};
    for (const userId of userIds) {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        fetchedUsernames[userId] = userDoc.data().username || 'Unknown Seller';
      } else {
        fetchedUsernames[userId] = 'Unknown Seller';
      }
    }
    return fetchedUsernames;
  };

  const fetchMarketplaceListings = useCallback(async () => {
    const allListings = {};
    const userIds = new Set();
  
    try {
      // Create an array of promises for fetching listings
      const listingsPromises = wishlistItems.map(async (card) => {
        const { setId, docId } = card;
  
        // Fetch card details in parallel with listings
        const cardDocPromise = getDoc(doc(db, `sets/${setId}/cards/${docId}`));
        const listingsSnapshotPromise = getDocs(collection(db, `sets/${setId}/cards/${docId}/listings`));
  
        const [cardDoc, listingsSnapshot] = await Promise.all([cardDocPromise, listingsSnapshotPromise]);
        let fullCardData = {};
  
        if (cardDoc.exists()) {
          const data = cardDoc.data();
          // Select only the needed fields
          fullCardData = {
            name: data.name,
            price: data.price,
            version: data.version,
            collector_number: data.collector_number,
            type: data.type,
            setName: data.set?.name,
            ink: data.ink,
            rarity: data.rarity,
          };
        }
  
        // Iterate through listings and add to allListings
        listingsSnapshot.forEach((doc) => {
          const listingData = doc.data();
          Object.entries(listingData).forEach(([condition, listing]) => {
            const sellerId = listing.userId || 'Unknown Seller';
            let price = listing.price || 'No Price';
  
            // Calculate price based on autoUpdatePrice and percentage
            if (listing.autoUpdatePrice && listing.percentage) {
              const cardPrice = fullCardData.price || 0;
              price = (cardPrice * (listing.percentage / 100)).toFixed(2);
            }
  
            if (sellerId !== 'Unknown Seller') {
              userIds.add(sellerId);
            }
  
            if (!allListings[sellerId]) {
              allListings[sellerId] = { listings: [], count: 0 };
            }
  
            allListings[sellerId].listings.push({
              ...listing,
              card: {
                ...card,
                ...fullCardData,
              },
              condition,
              price,
            });
            allListings[sellerId].count += 1;
          });
        });
      });
  
      // Execute all listings fetch in parallel
      await Promise.all(listingsPromises);
  
      // Fetch usernames in parallel
      const fetchedUsernames = await fetchUsernames([...userIds]);
      setUsernames(fetchedUsernames);
      setSellerListings(allListings);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching marketplace listings:', error);
      setLoading(false);
    }
  }, [wishlistItems]); 
  

  useEffect(() => {
    if (wishlistItems.length > 0) {
      fetchMarketplaceListings();
    }
  }, [wishlistItems, fetchMarketplaceListings]);

  if (loading) {
    return <Typography>Loading listings...</Typography>;
  }

  if (Object.keys(sellerListings).length === 0) {
    return <Typography>No marketplace listings found for your wishlist items.</Typography>;
  }

  return (
    <Box>
      <Typography variant="h5">Listings matching your Wishlist</Typography>
      {Object.entries(sellerListings).map(([sellerId, sellerData]) => (
        <Accordion key={sellerId}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${sellerId}-content`}
            id={`panel-${sellerId}-header`}
          >
            <Typography variant="h6">
              Seller: {usernames[sellerId] || 'Unknown Seller'} ({sellerData.count} matching listings)
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {isMobile ? (
              <Grid container spacing={2}>
                {sellerData.listings.map((listing, index) => {
                  const displayPrice = convertPrice(listing.price);

                  return (
                    <Grid item xs={12} key={index}>
                      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="h6">{listing.card.name}</Typography>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 1 }}>
                            <Typography variant="h4">{displayPrice}</Typography>
                            <Typography variant="h5">Qty: {listing.quantity}</Typography>
                            <Typography variant="body2">Condition: {listing.condition}</Typography>
                            {addedToCartItems.includes(listing.card.docName) && (
                              <CheckCircleIcon color="success" sx={{ mt: 1 }} />
                            )}
                          </Box>
                        </Box>
                        <Box sx={{ mt: 1, textAlign: 'right' }}>
                          <Button
                            startIcon={<ShoppingCartIcon />}
                            onClick={() => addToCart(listing, listing.price, listing.card.docName, listing.card.setId, listing.card)}
                          >
                            Add to Cart
                          </Button>
                        </Box>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Card Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Condition</TableCell>
                    <TableCell>Qty Available</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Add to Cart</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sellerData.listings.map((listing, index) => {
                    const displayPrice = convertPrice(listing.price);

                    return (
                        <TableRow key={index}>
                        <TableCell>
                          <Typography variant="body1">
                            {listing.card.collector_number} - {listing.card.name}
                          </Typography>
                          <Typography variant="body1">
                            {listing.card.version}
                          </Typography>
                          <Typography variant="body2">
                            {listing.card.setName}
                          </Typography>
                        </TableCell>
                        <TableCell>{listing.card.type}</TableCell>
                        <TableCell>{listing.condition}</TableCell>
                        <TableCell>{listing.quantity}</TableCell>
                        <TableCell>{displayPrice}</TableCell>
                        <TableCell>
                          <ShoppingCartIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() => addToCart(listing, listing.price, listing.card.docName, listing.card.setId)}
                          />
                          {addedToCartItems.includes(listing.card.docName) && (
                              <CheckCircleIcon color="success" sx={{ mt: 1 }} />
                            )}
                        </TableCell>
                      </TableRow>
                      
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default WishlistMarketplaceListings;
