// Footer.js
import React from 'react';
import { Box, Container, Typography, Button, Link, useMediaQuery, useTheme, Grid } from '@mui/material';

const Footer = ({ appVersion }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ bgcolor: '#0C0A5A', color: 'white', p: 1.5, mt: 'auto' }}>
      <Container maxWidth="lg">
        <Typography variant="body2" align="center">
          Lorcana Card Marketplace © {new Date().getFullYear()} | Version {appVersion}
        </Typography>
        <Grid container spacing={isMobile ? 1 : 2} justifyContent="center" sx={{ mt: 0.5 }}>
          <Grid item xs={6} sm={3}>
            <Button
              color="inherit"
              component={Link}

              href="/contact"
              sx={{
                fontSize: '0.75rem',
                textTransform: 'none',
                whiteSpace: 'normal',
                textAlign: 'center',
                lineHeight: 1.2,
                width: '100%',
              }}
            >
              Contact Us
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Button
              color="inherit"
              component={Link}

              href="/report-issue"
              sx={{
                fontSize: '0.75rem',
                textTransform: 'none',
                whiteSpace: 'normal',
                textAlign: 'center',
                lineHeight: 1.2,
                width: '100%',
              }}
            >
              Report an Issue
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Button
              color="inherit"
              component={Link}

              href="/privacy-policy"
              sx={{
                fontSize: '0.75rem',
                textTransform: 'none',
                whiteSpace: 'normal',
                textAlign: 'center',
                lineHeight: 1.2,
                width: '100%',
              }}
            >
              Privacy Policy
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Button
              color="inherit"
              component={Link}

              href="/terms-of-service"
              sx={{
                fontSize: '0.75rem',
                textTransform: 'none',
                whiteSpace: 'normal',
                textAlign: 'center',
                lineHeight: 1.2,
                width: '100%',
              }}
            >
              Terms of Service
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
