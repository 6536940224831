import React, { useRef } from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, Toolbar, AppBar, TableRow, Paper } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import { useReactToPrint } from 'react-to-print';

const Invoice = ({ sale, onClose }) => {
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const shippingCost = sale?.shippingCost || 0;
  const paymentFee = sale?.paymentFee || 0;
  const subtotal = sale?.items.reduce((acc, item) => acc + (parseFloat(item.staticPrice) * item.staticQuantity), 0) || 0;
  const grandTotal = subtotal + shippingCost + paymentFee;

  return (
    <>
    <AppBar position="fixed" sx={{ bgcolor: "#0C0A5A" }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Button
            onClick={onClose}
            variant="contained"
            color="error"
            startIcon={<CloseIcon />}
          >
            Close
          </Button>

          <Button
            onClick={handlePrint}
            variant="contained"
            color="secondary"
            startIcon={<PrintIcon />}
          >
            Print
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh', // Full viewport height
          padding: { xs: 2, md: 4 },
          marginTop: '50px', // Adjust margin to prevent content overlap with AppBar
        }}
      >
      

      <Box ref={printRef} sx={{ padding: 2, border: '1px solid #000', width: '100%', maxWidth: '210mm', minHeight: '297mm', margin: 'auto' }}>
        <Typography variant="h4" gutterBottom sx={{ fontSize: { xs: '1.5rem', md: '2rem' }, textAlign: { xs: 'center', md: 'left' } }}>
          Invoice
        </Typography>
        <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', md: 'row' }} mb={2}>
          <Box mb={{ xs: 2, md: 0 }}>
            <Typography variant="h6" sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' } }}>From:</Typography>
            <Typography variant="body1">
              {sale?.sellerDetails.firstName} {sale?.sellerDetails.lastName}
            </Typography>
            <Typography variant="body1">{sale?.sellerDetails.email}</Typography>
            <Typography variant="body1">{sale?.sellerDetails.phoneNumber}</Typography>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' } }}>Ship To:</Typography>
            {sale?.buyerAddress ? (
              <Typography variant="body1">
                {sale.buyerAddress.preferredContactName && <div>{sale.buyerAddress.preferredContactName}</div>}
                {sale.buyerAddress.addressLine1 && <div>{sale.buyerAddress.addressLine1}</div>}
                {sale.buyerAddress.addressLine2 && <div>{sale.buyerAddress.addressLine2}</div>}
                {sale.buyerAddress.city && <div>{sale.buyerAddress.city}</div>}
                {sale.buyerAddress.state && <div>{sale.buyerAddress.state}</div>}
                {sale.buyerAddress.zipCode && <div>{sale.buyerAddress.zipCode}</div>}
                {sale.buyerAddress.country && <div>{sale.buyerAddress.country}</div>}
                {sale.buyerAddress.additionalDetails && <div>{sale.buyerAddress.additionalDetails}</div>}
              </Typography>
            ) : (
              <Typography>No address available</Typography>
            )}
            <Typography variant="h6" sx={{ mt: 2, fontSize: { xs: '1.2rem', md: '1.5rem' } }}>Shipping Method:</Typography>
            <Typography variant="body1">{sale?.shippingOption || 'N/A'}</Typography>
          </Box>
        </Box>

        {/* Display the Transaction Date */}
        {sale?.transactionDate && (
  <Typography variant="body4" sx={{ mt: 2, fontSize: { xs: '1.2rem', md: '1.5rem' }, textAlign: { xs: 'center', md: 'left' } }}>
    Transaction Date: {sale?.transactionDate instanceof Date 
      ? sale.transactionDate.toLocaleDateString() 
      : sale.transactionDate.toDate().toLocaleDateString()}
  </Typography>
)}


        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell align="right">Qty</TableCell>
                <TableCell align="right">Unit Price</TableCell>
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sale?.items.map((item) => (
                <TableRow key={item.docName}>
                  <TableCell>
                    <Typography variant="body1">{`#${item.collector_number} - ${item.cardName}`}</Typography>
                    <Typography variant="body2" color="textSecondary">{item.setName}</Typography>
                  </TableCell>
                  <TableCell align="right">{item.staticQuantity}</TableCell>
                  <TableCell align="right">{`$${parseFloat(item.staticPrice).toFixed(2)}`}</TableCell>
                  <TableCell align="right">{`$${(parseFloat(item.staticPrice) * item.staticQuantity).toFixed(2)}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 4, textAlign: 'right' }}>
          <Typography variant="h6" sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' } }}>Subtotal:</Typography>
          <Typography variant="body1">${subtotal.toFixed(2)}</Typography>
          <Typography variant="h6" sx={{ mt: 2, fontSize: { xs: '1.2rem', md: '1.5rem' } }}>Shipping Cost:</Typography>
          <Typography variant="body1">${shippingCost.toFixed(2)}</Typography>
          <Typography variant="h6" sx={{ mt: 2, fontSize: { xs: '1.2rem', md: '1.5rem' } }}>{sale?.paymentOption?.method} Fee:</Typography>
          <Typography variant="body1">${paymentFee.toFixed(2)}</Typography>
          <Typography variant="h5" sx={{ mt: 2, fontSize: { xs: '1.5rem', md: '2rem' } }}>Grand Total:</Typography>
          <Typography variant="h5">${grandTotal.toFixed(2)}</Typography>
        </Box>

        <Box sx={{ mt: 4, textAlign: 'right' }}>
          <Typography variant="h6" sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' } }}>Payment Method:</Typography>
          <Typography variant="body1">{sale?.paymentOption?.method || 'N/A'}</Typography>
          <Typography variant="h6" sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' } }}>Sellers Payment Details:</Typography>
          {typeof sale?.paymentOption?.details === 'object' ? (
            Object.keys(sale.paymentOption.details).map((key, index) => (
              <Typography key={index} variant="body2">{`${key}: ${sale.paymentOption.details[key]}`}</Typography>
            ))
          ) : (
            <Typography variant="body2">{sale?.paymentOption?.details || 'N/A'}</Typography>
          )}
          {sale?.status === 'Paid' && (
            <>
              <Typography variant="h6" sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' } }}>Date Paid:</Typography>
              <Typography variant="body1">{sale?.timestamp?.toDate().toLocaleDateString() || 'N/A'}</Typography>
            </>
          )}
        </Box>
      </Box>
      
    </Box>
    </>
  );
};

export default Invoice;
