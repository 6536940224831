// src/components/AppBarContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AppBarContext = createContext();

export const useAppBarContext = () => useContext(AppBarContext);

export const AppBarProvider = ({ children }) => {
  const [selectedCountry, setSelectedCountry] = useState('AUD');
  const [exchangeRates, setExchangeRates] = useState({});

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
        setExchangeRates(response.data.rates);
      } catch (error) {
        console.error('Error fetching exchange rates:', error);
      }
    };

    fetchExchangeRates();
  }, []);

  return (
    <AppBarContext.Provider value={{ selectedCountry, setSelectedCountry, exchangeRates }}>
      {children}
    </AppBarContext.Provider>
  );
};
