import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Card, CardContent, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween); // Enable isBetween plugin for dayjs

const AllSalesSummary = () => {
  const [sales, setSales] = useState([]);
  const [sets, setSets] = useState([]);
  const [totalQty, setTotalQty] = useState(0);
  const [cardSalesTotal, setCardSalesTotal] = useState(0);
  const [postageTotal, setPostageTotal] = useState(0);
  const [feesTotal, setFeesTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [groupBy, setGroupBy] = useState('');
  const [groupedData, setGroupedData] = useState({});
  const [selectedRange, setSelectedRange] = useState('All Time');

  // Fetch sales data for all users
  const fetchSales = async () => {
    const usersSnapshot = await getDocs(collection(db, 'users'));
    let allSales = [];

    for (const userDoc of usersSnapshot.docs) {
      const salesSnapshot = await getDocs(collection(db, 'users', userDoc.id, 'sales'));
      const userSalesData = salesSnapshot.docs.map(doc => {
        const sale = { id: doc.id, ...doc.data(), userId: userDoc.id }; // Attach userId to each sale
        return sale;
      });
      allSales = [...allSales, ...userSalesData];
    }

    setSales(allSales);
  };

  useEffect(() => {
    fetchSales();
  }, []);

  useEffect(() => {
    const filteredSales = sales.filter(sale => {
      const transactionDate = sale.transactionDate;
      return filterByDateRange(transactionDate);
    });

    const totalCardsSold = filteredSales.reduce((total, sale) => total + getTotalCards(sale.items), 0);
    const totalCardSales = filteredSales.reduce((total, sale) => total + getCardSalesTotal(sale.items), 0);
    const totalPostage = filteredSales.reduce((total, sale) => total + (sale.shippingCost || 0), 0);
    const totalFees = filteredSales.reduce((total, sale) => total + getPaymentFee(sale), 0);
    const totalGrand = totalCardSales + totalPostage + totalFees;

    setTotalQty(totalCardsSold);
    setCardSalesTotal(totalCardSales);
    setPostageTotal(totalPostage);
    setFeesTotal(totalFees);
    setGrandTotal(totalGrand);

    // Group the filtered sales
    if (groupBy) {
      const grouped = groupSalesData(filteredSales, groupBy);
      setGroupedData(grouped);
    }
  }, [sales, selectedRange, groupBy]);

  const getStartAndEndDates = (range) => {
    const today = dayjs();
    switch (range) {
      case 'This Month':
        return { start: today.startOf('month'), end: today.endOf('month') };
      case 'Last Month':
        return { start: today.subtract(1, 'month').startOf('month'), end: today.subtract(1, 'month').endOf('month') };
      case 'This Week':
        return { start: today.startOf('week'), end: today.endOf('week') };
      case 'Last Week':
        return { start: today.subtract(1, 'week').startOf('week'), end: today.subtract(1, 'week').endOf('week') };
      case 'All Time':
      default:
        return { start: null, end: null }; // No filter for 'All Time'
    }
  };

  const filterByDateRange = (transactionDate) => {
    if (!transactionDate) return false;
    const saleDate = transactionDate.toDate ? dayjs(transactionDate.toDate()) : dayjs(transactionDate);
    const { start, end } = getStartAndEndDates(selectedRange);

    if (!start && !end) return true;
    return saleDate.isBetween(start, end, null, '[]');
  };

  const groupSalesData = (sales, groupBy) => {
    const groupMap = {};
    sales.forEach(sale => {
      const groupKey = groupBy === 'User' ? sale.userId :
                       groupBy === 'Set' ? sale.items[0].setName :
                       groupBy === 'Rarity' ? sale.items[0].cardRarity :
                       groupBy === 'Ink' ? sale.items[0].cardInk :
                       groupBy === 'Type' ? sale.items[0].cardType : 'Other';

      if (!groupMap[groupKey]) {
        groupMap[groupKey] = [];
      }
      groupMap[groupKey].push(sale);
    });
    return groupMap;
  };

  const getTotalCards = (items) => items.reduce((total, item) => total + item.staticQuantity, 0);

  const getCardSalesTotal = (items) => items.reduce((total, item) => total + parseFloat(item.staticPrice) * item.staticQuantity, 0);

  const getPaymentFee = (sale) => sale.paymentFee || 0;

  const renderTable = () => {
    if (!groupBy || Object.keys(groupedData).length === 0) return null;

    return (
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{groupBy}</TableCell>
              <TableCell>Total Cards Sold</TableCell>
              <TableCell>Total Sales</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedData).map(([groupKey, sales]) => {
              const totalCardsForGroup = sales.reduce((total, sale) => total + getTotalCards(sale.items), 0);
              const totalSalesForGroup = sales.reduce((total, sale) => total + getCardSalesTotal(sale.items), 0);

              return (
                <TableRow key={groupKey}>
                  <TableCell>{groupKey}</TableCell>
                  <TableCell>{totalCardsForGroup}</TableCell>
                  <TableCell>${totalSalesForGroup.toFixed(2)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Total Sales Summary</Typography>

      <FormControl fullWidth margin="normal">
        <InputLabel id="time-range-label">Date Period</InputLabel>
        <Select
          labelId="time-range-label"
          value={selectedRange}
          onChange={(e) => setSelectedRange(e.target.value)}
        >
          <MenuItem value="All Time">All Time</MenuItem>
          <MenuItem value="This Month">This Month</MenuItem>
          <MenuItem value="Last Month">Last Month</MenuItem>
          <MenuItem value="This Week">This Week</MenuItem>
          <MenuItem value="Last Week">Last Week</MenuItem>
        </Select>
      </FormControl>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {/* ... existing totals cards ... */}
      </Grid>

      {/* Grouping Buttons */}
      <Button
        variant={groupBy === 'User' ? 'contained' : 'outlined'}
        onClick={() => setGroupBy('User')}
        sx={{ margin: 1 }}
      >
        Display by User
      </Button>
      <Button
        variant={groupBy === 'Set' ? 'contained' : 'outlined'}
        onClick={() => setGroupBy('Set')}
        sx={{ margin: 1 }}
      >
        Display by Set
      </Button>
      <Button
        variant={groupBy === 'Rarity' ? 'contained' : 'outlined'}
        onClick={() => setGroupBy('Rarity')}
        sx={{ margin: 1 }}
      >
        Display by Rarity
      </Button>
      <Button
        variant={groupBy === 'Ink' ? 'contained' : 'outlined'}
        onClick={() => setGroupBy('Ink')}
        sx={{ margin: 1 }}
      >
        Display by Ink Color
      </Button>
      <Button
        variant={groupBy === 'Type' ? 'contained' : 'outlined'}
        onClick={() => setGroupBy('Type')}
        sx={{ margin: 1 }}
      >
        Display by Type
      </Button>

      {sales.length === 0 ? <CircularProgress /> : renderTable()}
    </Container>
  );
};

export default AllSalesSummary;
