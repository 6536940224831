import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Typography,
  Paper,
  Avatar,
  Box,
  Dialog,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import './PickingSlipDialog.css'; 

// Utility function for sorting by collector number
const sortByCollectorNumber = (items) => {
  return [...items].sort((a, b) => a.collector_number - b.collector_number);
};

// Utility function for grouping by set and then by card type
const groupItemsBySetAndType = (items) => {
  const groupedItems = {};

  items.forEach((item) => {
    if (!groupedItems[item.setName]) {
      groupedItems[item.setName] = {};
    }
    if (!groupedItems[item.setName][item.cardType]) {
      groupedItems[item.setName][item.cardType] = [];
    }
    groupedItems[item.setName][item.cardType].push(item);
  });

  return groupedItems;
};

const SaleItemsTable = ({ sale, showPickingColors = false, showImages = true, onSave, isSeller = false }) => {
  const groupedItems = groupItemsBySetAndType(sale.items || []);
  const [selectedImage, setSelectedImage] = useState(null); // State to store the clicked image
  const [selectedItems, setSelectedItems] = useState([]);
  const [editedItems, setEditedItems] = useState({}); // State to store all edited items
  const [reasonDialogOpen, setReasonDialogOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');

  const reasons = ['Incorrect Listing Qty', 'Wrong Listing Price', 'Discount Offer', 'Other'];

  // Handle editing of an item
  const handleEdit = (item) => {
    setEditedItems({
      ...editedItems,
      [item.docName]: {
        ...item,
        staticQuantity: item.staticQuantity,
        staticPrice: item.staticPrice,
      },
    });
  };

  // Update quantity for an item
  const handleQuantityChange = (docName, value) => {
    setEditedItems({
      ...editedItems,
      [docName]: {
        ...editedItems[docName],
        staticQuantity: Number(value),
        quantity: Number(value),
      },
    });
  };

  // Update price for an item
  const handlePriceChange = (docName, value) => {
    setEditedItems({
      ...editedItems,
      [docName]: {
        ...editedItems[docName],
        staticPrice: value,
        price: value,
      },
    });
  };

  // Open reason dialog when saving
  const handleSaveEdit = () => {
    setReasonDialogOpen(true);
  };

  // Save all changes with reason
  const handleSaveWithReason = () => {
    const updatedItems = Object.values(editedItems).map((item) => ({
      ...item,
      reasonForChange: selectedReason,
    }));
  
    // Pass both updatedItems and selectedReason to the onSave function
    onSave(updatedItems, selectedReason);
    setEditedItems({});
    setReasonDialogOpen(false);
  };
  


  // Function to close reason dialog without saving
  const handleCancelReason = () => {
    setReasonDialogOpen(false);
    setSelectedReason('');
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseDialog = () => {
    setSelectedImage(null);
  };

  const handleRowClick = (item) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(item.docName)
        ? prevSelectedItems.filter((selected) => selected !== item.docName)
        : [...prevSelectedItems, item.docName]
    );
  };

  return (
    <>
      {showPickingColors && (
        <Box
          className="picking-colors"
          display="flex"
          justifyContent="center"
          gap="1rem"
          mb={2}
        >
          <Typography variant="body1">
            <Box
              component="span"
              sx={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                backgroundColor: "#d4f8d4",
                mr: 1,
              }}
            />
            Picked
          </Typography>
          <Typography variant="body1">
            <Box
              component="span"
              sx={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                backgroundColor: "#ff9696",
                mr: 1,
              }}
            />
            Not Picked
          </Typography>
        </Box>
      )}
      <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
        <Table
          sx={{
            "& .MuiTableCell-root": {
              padding: { xs: "4px", sm: "8px" },
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
            },
          }}
        >
          {Object.keys(groupedItems).map((setName) => (
            <React.Fragment key={setName}>
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "0.875rem", sm: "1.5rem" },
                    }}
                  >
                    {setName}
                  </Typography>
                </TableCell>
              </TableRow>
              {Object.keys(groupedItems[setName]).map((cardType) => (
                <React.Fragment key={cardType}>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: { xs: "0.75rem", sm: "1.2rem" },
                          pl: 2,
                        }}
                      >
                        Card Type: {cardType}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ width: { xs: "auto", sm: "40%" }, maxWidth: "40%" }}
                    >
                      Item
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ width: { xs: "auto", sm: "20%" } }}
                    >
                      Qty
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ width: { xs: "auto", sm: "20%" } }}
                    >
                      Each
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ width: { xs: "auto", sm: "20%" } }}
                    >
                      Total
                    </TableCell>
                    {isSeller && <TableCell align="right">Action</TableCell>}
                  </TableRow>
                  {sortByCollectorNumber(groupedItems[setName][cardType]).map(
                    (item) => (
                      <TableRow
                      key={item.docName}
                      onClick={() => handleRowClick(item)}
                      sx={{
                        cursor: showPickingColors ? "pointer" : "default",
                        backgroundColor: item.staticPrice === "0" || item.staticPrice === "0.00" || item.staticPrice === 0
                        ? "#ffcccc" // Highlight in red if price is zero
                        : showPickingColors
                          ? selectedItems.includes(item.docName)
                            ? "#d4f8d4" // Picked - green color
                            : "#ff9696" // Not picked - red color
                          : "transparent",
                      }}
                    >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            width: { xs: "50%", sm: "40%" },
                            maxWidth: "50%",
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            {showImages && (
                              <Avatar
                                variant="square"
                                src={item.cardImage || "fallback_image_url"}
                                sx={{
                                  height: "80px",
                                  width: "60px",
                                  objectFit: "contain",
                                  marginRight: 2,
                                  borderRadius: 1,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleImageClick(item.cardImage);
                                }}
                              />
                            )}
                            <Box>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: { xs: "0.75rem", sm: "1.2rem" },
                                }}
                              >
                                #{item.collector_number} - {item.cardName}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: { xs: "0.75rem", sm: "1.2rem" },
                                }}
                              >
                                {item.cardVersion}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                  fontSize: { xs: "0.65rem", sm: "1.2rem" },
                                }}
                              >
                                {item.cardRarity
                                  .toLowerCase()
                                  .split("_")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")}
                              </Typography>
                              {item.reasonForChange && (
                              <Typography variant="body2" color="error">
                                Updated: {item.reasonForChange}
                              </Typography>
                            )}
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ width: { xs: "10%", sm: "20%" } }}
                        >
                          {editedItems[item.docName] ? (
                            <TextField
                              type="number"
                              value={editedItems[item.docName].staticQuantity}
                              onChange={(e) =>
                                handleQuantityChange(
                                  item.docName,
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              {item.staticQuantity}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ width: { xs: "20%", sm: "20%" } }}
                        >
                          {editedItems[item.docName] ? (
                            <TextField
                              type="number"
                              value={editedItems[item.docName].staticPrice}
                              onChange={(e) =>
                                handlePriceChange(item.docName, e.target.value)
                              }
                            />
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              ${item.staticPrice}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ width: { xs: "20%", sm: "20%" } }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            $
                            {(item.staticPrice * item.staticQuantity).toFixed(
                              2
                            )}
                          </Typography>
                        </TableCell>
                        {isSeller && (
                          <TableCell align="right">
                            {editedItems[item.docName] ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                  setEditedItems((prev) => {
                                    const newItems = { ...prev };
                                    delete newItems[item.docName];
                                    return newItems;
                                  })
                                }
                              >
                                Cancel
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleEdit(item)}
                              >
                                Edit
                              </Button>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </Table>
      </TableContainer>

      {/* Save all edits button */}
      {Object.keys(editedItems).length > 0 && (
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleSaveEdit}>
            Save All Changes
          </Button>
        </Box>
      )}

      {/* Reason selection dialog */}
      <Dialog open={reasonDialogOpen} onClose={handleCancelReason}>
        <Box padding={2}>
          <Typography variant="h6">Select Reason for Change</Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="reason-label">Reason</InputLabel>
            <Select
              labelId="reason-label"
              value={selectedReason}
              onChange={(e) => setSelectedReason(e.target.value)}
            >
              {reasons.map((reason) => (
                <MenuItem key={reason} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box display="flex" justifyContent="flex-end" marginTop={2}>
            <Button onClick={handleCancelReason} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleSaveWithReason}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </Box>
      </Dialog>

      {/* Larger image preview dialog */}
      {selectedImage && (
        <Dialog
          open={!!selectedImage}
          onClose={handleCloseDialog}
          maxWidth="md"
        >
          <Box sx={{ padding: 2 }}>
            <img
              src={selectedImage}
              alt="Card"
              style={{ width: "100%", height: "auto", objectFit: "contain" }}
            />
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default SaleItemsTable;
