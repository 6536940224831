import React from 'react';
import { Grid, Card, CardMedia, CardContent, Box, Typography, Avatar, Chip, Stack, IconButton, Tooltip, Button } from '@mui/material';
import LazyLoad from 'react-lazyload';
import { rarityIcons, inkColorMap } from '../marketplace/constants';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FavoriteIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteFilledIcon from '@mui/icons-material/Favorite';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CollectionCardGrid = ({ cards = [], collectionItems, wishlistItems, handleAddToList, handleUpdateQuantity, convertPrice, handleCardClick }) => {
  return (
    <Grid container spacing={3}>
      {cards.map((card) => {
        const quantity = collectionItems[card.docName] || 0;
        const inWishlist = wishlistItems.includes(card.docName);
        const inCollection = quantity > 0;

        return (
          <Grid item key={`${card.setId}_${card.docName}`} xs={12} sm={6} md={4} lg={3}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                backgroundColor: card.type === "Foil" ? "#fffbea" : "#ffffff",
                border: card.type === "Foil" ? "2px solid #ffd700" : "1px solid #e0e0e0",
                position: "relative",
              }}
            >
              <Box sx={{ position: "relative", paddingTop: "130%" }}>
                <LazyLoad height={400} offset={1600}>
                  <CardMedia
                    component="img"
                    alt={card.name}
                    image={card.image_uris?.digital?.normal || card.image_uris?.digital?.large}
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "fallback_image_url";
                    }}
                  ></CardMedia>
                </LazyLoad>
                {inCollection && (
                  <CheckCircleIcon
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: 'green',
                      fontSize: 24,
                    }}
                  />
                )}
              </Box>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Avatar variant="square" sx={{ bgcolor: "#0C0A5A", color: "white", height: 40 }}>
                    {card.collector_number}
                  </Avatar>
                  <Avatar variant="square" sx={{ bgcolor: "#0C0A5A", height: 40 }}>
                    <img src={rarityIcons[card.rarity]} alt={card.rarity} style={{ width: 24, height: 24 }} />
                  </Avatar>
                  {card.type === "Foil" && (
                    <Avatar
                      variant="square"
                      sx={{
                        width: 70,
                        height: 36,
                        bgcolor: "#ffd700",
                        border: "2px solid #000000",
                        color: "#000000",
                      }}
                    >
                      <Typography variant="h6" sx={{ fontSize: "10px" }}>COLD FOIL</Typography>
                    </Avatar>
                  )}
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Stack direction="column" spacing={1}>
                    <Typography gutterBottom variant="h6" component="div">
                      {card.name}
                    </Typography>
                    {card.version && (
                      <Typography variant="subtitle1" color="textSecondary">
                        {card.version}
                      </Typography>
                    )}
                  </Stack>
                </Box>
              </CardContent>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1, padding: 2 }}>
                <Chip
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                    },
                    bgcolor: "info.main",
                    color: "white",
                  }}
                  label={
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <span>TCGP Price</span>
                      {convertPrice(card.price || 0)}
                    </Box>
                  }
                />
                <Box display="flex" alignItems="center">
                  <IconButton onClick={(e) => { e.stopPropagation(); handleUpdateQuantity(card.docName, quantity - 1); }} disabled={quantity === 0}>
                    <RemoveIcon />
                  </IconButton>
                  <Typography variant="body2">{quantity}</Typography>
                  <IconButton onClick={(e) => { e.stopPropagation(); handleUpdateQuantity(card.docName, quantity + 1); }}>
                    <AddIcon />
                  </IconButton>
                  <Tooltip title={inWishlist ? "Remove from Wishlist" : "Add to Wishlist"} arrow>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleAddToList(card, 'wishlist'); }}>
                      {inWishlist ? <FavoriteFilledIcon style={{ color: 'red' }} /> : <FavoriteIcon />}
                    </IconButton>
                  </Tooltip>
                </Box>
                <Button
                  variant="text"
                  size="small"
                  onClick={(e) => { e.stopPropagation(); handleCardClick(card); }}
                >
                  View Details
                </Button>
              </Box>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CollectionCardGrid;
