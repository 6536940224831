import React, { useEffect, useState, useCallback } from 'react';
import { db } from '../firebase';
import { collection, getDocs, updateDoc, deleteField } from 'firebase/firestore';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  CircularProgress,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Switch,
  TableSortLabel,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppBarContext } from '../components/AppBarContext';
import { currencySymbols } from '../marketplace/constants';

const UserListings = ({ userId, open, onClose }) => {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { selectedCountry, exchangeRates } = useAppBarContext();
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });

  const convertPrice = useCallback((usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice;
    return `${currencySymbols[selectedCountry]}${(usdPrice * exchangeRates[selectedCountry]).toFixed(2)}`;
  }, [exchangeRates, selectedCountry]);

  useEffect(() => {
    if (open) {
      fetchUserListings();
    }
  }, [open]);

  const fetchUserListings = async () => {
    try {
      setLoading(true);
      const allListings = [];

      const setsSnapshot = await getDocs(collection(db, 'sets'));
      const setsData = setsSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));

      const listingsPromises = setsData.map(async (set) => {
        const cardsSnapshot = await getDocs(collection(db, 'sets', set.id, 'cards'));

        const cardListingsPromises = cardsSnapshot.docs.map(async (cardDoc) => {
          const cardId = cardDoc.id;
          const cardData = cardDoc.data();
          const cardName = cardData.name;
          const cardNumber = cardData.collector_number;

          const listingsSnapshot = await getDocs(collection(db, 'sets', set.id, 'cards', cardId, 'listings'));
          listingsSnapshot.docs.forEach((listingDoc) => {
            if (listingDoc.id === userId) {
              const data = listingDoc.data();
              Object.entries(data).forEach(([condition, listing]) => {
                allListings.push({
                  ...listing,
                  condition,
                  setName: set.name,
                  cardName,
                  cardNumber,
                  listingRef: listingDoc.ref,
                });
              });
            }
          });
        });

        await Promise.all(cardListingsPromises);
      });

      await Promise.all(listingsPromises);
      setListings(allListings);
    } catch (error) {
      console.error('Error fetching user listings:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedListings = [...listings].sort((a, b) => {
      if (key === 'autoUpdatePrice') {
        // Sort boolean values (true/false)
        return direction === 'asc'
          ? a.autoUpdatePrice - b.autoUpdatePrice
          : b.autoUpdatePrice - a.autoUpdatePrice;
      }
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setListings(sortedListings);
  };

  const handleQuantityChange = (index, newQuantity) => {
    setListings((prevListings) => {
      const updatedListings = [...prevListings];
      updatedListings[index].quantity = newQuantity;
      return updatedListings;
    });
  };

  const handleSave = async (index) => {
    const listing = listings[index];
    try {
      const listingRef = listing.listingRef;
      await updateDoc(listingRef, {
        [listing.condition + '.quantity']: listing.quantity,
      });
      alert('Listing updated successfully');
    } catch (error) {
      console.error('Error updating listing:', error);
    }
  };

  const handleDelete = async (index) => {
    const listing = listings[index];
    try {
      const listingRef = listing.listingRef;
      await updateDoc(listingRef, {
        [listing.condition]: deleteField(),
      });

      setListings((prevListings) => prevListings.filter((_, i) => i !== index));
      alert('Listing deleted successfully');
    } catch (error) {
      console.error('Error deleting listing:', error);
    }
  };

  const handleAutoUpdateToggle = async (index) => {
    const listing = listings[index];
    const newAutoUpdateStatus = !listing.autoUpdatePrice;
    try {
      const listingRef = listing.listingRef;
      await updateDoc(listingRef, {
        [listing.condition + '.autoUpdatePrice']: newAutoUpdateStatus,
      });
      setListings((prevListings) => {
        const updatedListings = [...prevListings];
        updatedListings[index].autoUpdatePrice = newAutoUpdateStatus;
        return updatedListings;
      });
    } catch (error) {
      console.error('Error updating auto-update status:', error);
    }
  };

  const displayPrice = (listing) => {
    const tcgPrice = listing.tcgPrice || 0;
    return listing.autoUpdatePrice
      ? convertPrice((tcgPrice * (listing.percentage / 100)).toFixed(2))
      : convertPrice(listing.price);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>User Listings</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        ) : listings.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                {['setName', 'cardName', 'cardNumber', 'condition', 'quantity', 'price'].map((column) => (
                  <TableCell key={column}>
                    <TableSortLabel
                      active={sortConfig.key === column}
                      direction={sortConfig.direction}
                      onClick={() => handleSort(column)}
                    >
                      {column.charAt(0).toUpperCase() + column.slice(1)}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === 'autoUpdatePrice'}
                    direction={sortConfig.direction}
                    onClick={() => handleSort('autoUpdatePrice')}
                  >
                    Auto Update
                  </TableSortLabel>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listings.map((listing, index) => {
                const price = parseFloat(listing.price);
                const isInvalidPrice = (!listing.autoUpdatePrice && (price === 0 || isNaN(price)));

                return (
                  <TableRow key={index}>
                    <TableCell>{listing.setName}</TableCell>
                    <TableCell>{listing.cardName}</TableCell>
                    <TableCell>{listing.cardNumber}</TableCell>
                    <TableCell>{listing.condition}</TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={listing.quantity}
                        onChange={(e) => handleQuantityChange(index, e.target.value)}
                        inputProps={{ min: 1 }}
                      />
                    </TableCell>
                    <TableCell sx={{ color: isInvalidPrice ? 'red' : 'inherit' }}>
                      {displayPrice(listing)}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={listing.autoUpdatePrice}
                        onChange={() => handleAutoUpdateToggle(index)}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" color="primary" onClick={() => handleSave(index)} sx={{ marginRight: 1 }}>
                        Save
                      </Button>
                      <IconButton color="secondary" onClick={() => handleDelete(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Typography variant="body1" align="center">
            No listings found for this user.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UserListings;
