import React from 'react';
import { Toolbar, TextField, MenuItem, Button, useMediaQuery, useTheme } from '@mui/material';

const SearchSortToolbar = ({
  searchTerm,
  sortOption,
  onSearchChange,
  onSortChange,
  onToggleCollapse,
  allCollapsed,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Toolbar
      sx={{
        flexDirection: isMobile ? 'column' : 'row',
        gap: isMobile ? 1 : 2,
        mb: 3,
        justifyContent: 'space-between',
      }}
    >
      <TextField
        label="Search"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => onSearchChange(e.target.value)}
        fullWidth={isMobile}
      />
      <TextField
        label="Sort by"
        select
        value={sortOption}
        onChange={(e) => onSortChange(e.target.value)}
        variant="outlined"
        fullWidth={isMobile}
      >
        <MenuItem value="date">Date</MenuItem>
        <MenuItem value="total">Total</MenuItem>
      </TextField>
      <Button
        variant="contained"
        color="primary"
        onClick={() => onToggleCollapse(!allCollapsed)}
        fullWidth={isMobile}
      >
        {allCollapsed ? 'Expand All' : 'Collapse All'}
      </Button>
    </Toolbar>
  );
};

export default SearchSortToolbar;
