import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDoc, getDocs, doc, updateDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { List, ListItem, Box, Typography, Link, Divider, Grid, Button, Avatar, Paper, Table, TableRow, TableCell, TableContainer } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useAppBarContext } from '../components/AppBarContext';

const OfferTracking = ({ currentUser }) => {
  const [offers, setOffers] = useState([]);
  const { selectedCountry, exchangeRates } = useAppBarContext();

  const currencySymbols = {
    AUD: '$',
    USD: '$',
    EUR: '€',
    GBP: '£',
    JPY: '¥',
  };

  const convertPrice = (usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice;
    return (usdPrice * exchangeRates[selectedCountry]).toFixed(2);
  };

  useEffect(() => {
    const fetchOffers = async () => {
      const offersRef = collection(db, 'users', currentUser.uid, 'sentOffers');
      const offersSnapshot = await getDocs(offersRef);

      const offersWithDetails = await Promise.all(
        offersSnapshot.docs.map(async (offerDoc) => {
          const offerData = { id: offerDoc.id, ...offerDoc.data() };

          if (offerData.setId && offerData.cardId) {
            const cardDoc = await getDoc(doc(db, 'sets', offerData.setId, 'cards', offerData.cardId));
            if (cardDoc.exists()) {
              offerData.cardData = cardDoc.data();
            }
          }

          if (offerData.sellerId) {
            const sellerDoc = await getDoc(doc(db, 'users', offerData.sellerId));
            if (sellerDoc.exists()) {
              offerData.sellerUsername = sellerDoc.data().username;
            }
          }

          return offerData;
        })
      );

      setOffers(offersWithDetails);
    };

    fetchOffers();
  }, [currentUser]);

  const handleAcceptOffer = async (offer) => {
    const buyerOfferRef = doc(db, 'users', currentUser.uid, 'sentOffers', offer.id);
    const sellerOfferRef = doc(db, 'users', offer.sellerId, 'offers', offer.id);

    await updateDoc(buyerOfferRef, { status: 'Accepted' });
    await updateDoc(sellerOfferRef, { status: 'Accepted' });

    setOffers(offers.map((o) => (o.id === offer.id ? { ...o, status: 'Accepted' } : o)));
  };

  const handleDeclineOffer = async (offer) => {
    const buyerOfferRef = doc(db, 'users', currentUser.uid, 'sentOffers', offer.id);
    const sellerOfferRef = doc(db, 'users', offer.sellerId, 'offers', offer.id);

    await updateDoc(buyerOfferRef, { status: 'Declined' });
    await updateDoc(sellerOfferRef, { status: 'Declined' });

    setOffers(offers.map((o) => (o.id === offer.id ? { ...o, status: 'Declined' } : o)));
  };

  const handleAddToCart = async (offer) => {
    const userId = currentUser.uid;
    const cartRef = doc(db, "carts", userId);
    const cartDoc = await getDoc(cartRef);
    const cartData = cartDoc.exists() ? cartDoc.data() : { items: [] };
  
    const existingItemIndex = cartData.items.findIndex(
      (item) => item.docName === offer.cardId && item.type === "listing" && item.listingId === offer.id
    );
  
    if (existingItemIndex > -1) {
      cartData.items[existingItemIndex].quantity += offer.quantity;
    } else {
      const newItem = {
        type: "listing",
        setId: offer.setId,
        docName: offer.cardId,
        listingId: offer.id,
        condition: offer.condition,
        quantity: offer.quantity,
        price: offer.offerPrice,
        image_uris: offer.cardData?.image_uris || {},
        name: offer.cardData?.name || "",
        sellerId: offer.sellerId || "",
        ink: offer.cardData?.ink || "",
        rarity: offer.cardData?.rarity || "",
        version: offer.cardData?.version || "",
        collector_number: offer.cardData?.collector_number || "",
      };
  
      cartData.items.push(newItem);
    }
  
    await setDoc(cartRef, cartData);
    alert("Item added to cart!");
  
    // Update inventory by reducing quantity or deleting the listing if quantity becomes zero
    const listingRef = doc(db, 'sets', offer.setId, 'cards', offer.cardId, 'listings', offer.sellerId);
    const listingDoc = await getDoc(listingRef);
  
    if (listingDoc.exists()) {
      const listingData = listingDoc.data();
      const listingKey = Object.keys(listingData)[0];
      const listingInfo = listingData[listingKey];
  
      const updatedQuantity = listingInfo.quantity - offer.quantity;
      if (updatedQuantity <= 0) {
        await deleteDoc(listingRef);  // Delete listing if quantity is zero
      } else {
        await setDoc(listingRef, {
          [listingKey]: {
            ...listingInfo,
            quantity: updatedQuantity,
          },
        }, { merge: true });
      }
    }
  
    // Remove the offer from both the buyer and seller's offers collections
    const buyerOfferRef = doc(db, 'users', currentUser.uid, 'sentOffers', offer.id);
    const sellerOfferRef = doc(db, 'users', offer.sellerId, 'offers', offer.id);
  
    await deleteDoc(buyerOfferRef);
    await deleteDoc(sellerOfferRef);
  
    setOffers(offers.filter((o) => o.id !== offer.id));
  };
  

  const handleCancelOffer = async (offer) => {
    const buyerOfferRef = doc(db, 'users', currentUser.uid, 'sentOffers', offer.id);
    const sellerOfferRef = doc(db, 'users', offer.sellerId, 'offers', offer.id);

    await deleteDoc(buyerOfferRef);
    await deleteDoc(sellerOfferRef);

    setOffers(offers.filter((o) => o.id !== offer.id));
    alert("Offer canceled successfully.");
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Accepted':
        return '#28a745';
      case 'Declined':
        return '#dc3545';
      case 'Counter':
        return '#ffc107';
      default:
        return '#6c757d';
    }
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Pending Offers
      </Typography>
      <List>
        {offers.map((offer) => (
          <React.Fragment key={offer.id}>
            <ListItem sx={{ border: "2px solid #0C0A5A", borderRadius: 2, mb: 2, p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 2, backgroundColor: "#f9f9f9", borderRadius: 2 }}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>Seller:</Typography>
                      <Link color="primary" sx={{ display: "flex", alignItems: "center", cursor: "pointer", ml: 1 }}>
                        <PersonIcon fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>{offer.sellerUsername || offer.sellerId}</Typography>
                      </Link>
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{
                        backgroundColor: getStatusColor(offer.status),
                        color: "#fff",
                        fontWeight: "bold",
                        padding: "4px 10px",
                        borderRadius: "4px",
                      }}
                    >
                      {offer.status}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={8}>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="body1">
                      Your Offer: <span style={{ fontWeight: "bold" }}>{currencySymbols[selectedCountry] || '$'}{convertPrice(offer.offerPrice)}</span>
                    </Typography>
                    {offer.counterOffer && (
                      <Typography variant="body1">
                        Counter Offer: <span style={{ fontWeight: "bold" }}>{currencySymbols[selectedCountry] || '$'}{convertPrice(offer.counterOffer)}</span>
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4} sx={{ textAlign: "right" }}>
                  {offer.status === 'Counter' && (
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Button variant="contained" color="primary" onClick={() => handleAcceptOffer(offer)}>Accept</Button>
                      <Button variant="contained" color="error" onClick={() => handleDeclineOffer(offer)}>Decline</Button>
                    </Box>
                  )}
                  {offer.status === 'Accepted' && (
                    <Box display="flex" flexDirection="column" gap={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAddToCart(offer)}
                      >
                        Add to Cart
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleCancelOffer(offer)}
                      >
                        Cancel Offer
                      </Button>
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            width: { xs: "50%", sm: "40%" },
                            maxWidth: "50%",
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            <Avatar
                              variant="square"
                              src={offer.cardData?.image_uris?.digital?.small || 'fallback_image_url'}
                              sx={{
                                height: "80px",
                                width: "60px",
                                objectFit: "contain",
                                marginRight: 2,
                                borderRadius: 1,
                              }}
                            />
                            <Box>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: { xs: "0.75rem", sm: "1.2rem" },
                                }}
                              >
                                #{offer.cardData?.collector_number} - {offer.cardData?.name}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: { xs: "0.75rem", sm: "1.2rem" },
                                }}
                              >
                                {offer.cardData?.version || offer.version}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                  fontSize: { xs: "0.65rem", sm: "1.2rem" },
                                }}
                              >
                                {offer.cardData?.rarity || offer.rarity}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body2" color="textSecondary">Qty: {offer.quantity}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body2" color="textSecondary">Listing Price: {currencySymbols[selectedCountry] || '$'}{convertPrice(offer.cardData?.price || 0)}</Typography>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default OfferTracking;
