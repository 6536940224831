import React, { useState } from "react";
import { db, functions } from "../firebase"; // Make sure to include functions from firebase
import {
  setDoc,
  doc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  TextField,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { httpsCallable } from "firebase/functions"; // httpsCallable for cloud functions
import TermsOfService from "./TermsOfService";

const RegistrationForm = ({ onClose, googleUserData = null }) => {
  const [registerEmail, setRegisterEmail] = useState(
    googleUserData?.email || ""
  );
  const [confirmEmail, setConfirmEmail] = useState(googleUserData?.email || "");
  const [registerPassword, setRegisterPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState(
    googleUserData?.displayName?.split(" ")[0] || ""
  );
  const [lastName, setLastName] = useState(
    googleUserData?.displayName?.split(" ")[1] || ""
  );
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [scrollReachedBottom, setScrollReachedBottom] = useState(false);

  // Open the terms of service dialog
  const handleOpenTermsDialog = () => {
    setTermsDialogOpen(true);
    setScrollReachedBottom(false);
  };

  const handleCloseTermsDialog = (agreed = false) => {
    if (agreed && scrollReachedBottom) {
      setAgreeToTerms(true); // Automatically check the agreement box
    }
    setTermsDialogOpen(false);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setScrollReachedBottom(true);
    }
  };

  const handleEmailRegister = async (e) => {
    e.preventDefault();
  
    if (!googleUserData) {
      if (registerEmail !== confirmEmail) {
        setError("Emails do not match");
        return;
      }
      if (registerPassword !== confirmPassword) {
        setError("Passwords do not match");
        return;
      }
      if (!validatePassword(registerPassword)) {
        setError(
          "Password must be at least 8 characters long and include an uppercase letter, one number, and one special character."
        );
        return;
      }
    }
  
    if (!agreeToTerms) {
      setError("You must agree to the terms of service to register.");
      return;
    }
  
    const usernameExists = await checkUsernameExists(username);
    if (usernameExists) {
      setError("Username is already taken. Please choose a different username.");
      return;
    }
  
    try {
      const token = uuidv4();
  
      // Save registration data to the pendingUsers collection
      await setDoc(doc(db, "pendingUsers", token), {
        email: registerEmail,
        password: registerPassword, // You might want to hash this if storing
        firstName,
        lastName,
        username,
        phoneNumber,
        createdAt: new Date(),
        expiresAt: new Date(Date.now() + 24 * 60 * 60 * 1000), // Expiry time of 24 hours
        agreedToTerms: true,
        agreedAt: new Date(),
      });
  
      // Call the sendNotificationEmail Firebase function
      const sendNotificationEmail = httpsCallable(functions, "sendNotificationEmail");
      await sendNotificationEmail({
        to: registerEmail,
        subject: "Confirm your registration",
        text: `Please confirm your registration by clicking the following link: ${window.location.origin}/confirm?token=${token}`,
      });
  
      resetForm();
      setSuccess("Registration Successful! Check your email for a confirmation link.");
    } catch (error) {
      setError(error.message);
    }
  };
  

  const checkUsernameExists = async (username) => {
    const q = query(collection(db, "users"), where("username", "==", username));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  };

  const resetForm = () => {
    setRegisterEmail(googleUserData?.email || "");
    setConfirmEmail(googleUserData?.email || "");
    setRegisterPassword("");
    setConfirmPassword("");
    setFirstName(googleUserData?.displayName?.split(" ")[0] || "");
    setLastName(googleUserData?.displayName?.split(" ")[1] || "");
    setUsername("");
    setPhoneNumber("");
    setError("");
    setSuccess("");
  };

  return (
    <Box component="form" onSubmit={handleEmailRegister}>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      {success ? (
        <Button
          onClick={onClose}
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
        >
          Close
        </Button>
      ) : (
        <>
          <TextField
            margin="normal"
            required
            fullWidth
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />

          {!googleUserData && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email"
                type="email"
                value={registerEmail}
                onChange={(e) => setRegisterEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Confirm Email"
                type="email"
                value={confirmEmail}
                onChange={(e) => setConfirmEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                value={registerPassword}
                onChange={(e) => setRegisterPassword(e.target.value)}
                helperText="At least 8 characters, including an uppercase letter, a number, and a special character."
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </>
          )}

          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={handleOpenTermsDialog}
            sx={{ mt: 2, mb: 2 }}
          >
            Show Terms of Service
          </Button>

          <FormControlLabel
            control={
              <Checkbox
                checked={agreeToTerms}
                onChange={(e) => setAgreeToTerms(e.target.checked)}
              />
            }
            label="I agree to the Terms of Service"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={!agreeToTerms}
          >
            {googleUserData ? "Sign up" : "Sign up"}
          </Button>
        </>
      )}

      <Dialog
        open={termsDialogOpen}
        onClose={() => handleCloseTermsDialog(false)}
      >
        <DialogTitle>Terms of Service</DialogTitle>
        <DialogContent dividers onScroll={handleScroll}>
          <TermsOfService />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseTermsDialog(true)} color="primary">
            Agree and Close
          </Button>
          <Button onClick={() => handleCloseTermsDialog(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RegistrationForm;
