import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ref, deleteObject } from 'firebase/storage';
import { storage, db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import ImageViewerDialog from './ImageViewerDialog';

const CardConditionIssueDialog = ({ open, onClose, onRequestNewPhotos, onCancelSale }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Issue with Card Condition Photos</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">Please select one of the following options:</Typography>
        <Button variant="contained" fullWidth style={{ marginTop: '1rem' }} onClick={onRequestNewPhotos}>
          Request New Photos
        </Button>
        <Button variant="contained" fullWidth style={{ marginTop: '1rem' }} color="error" onClick={onCancelSale}>
          Condition Not As Described / Cancel Sale
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PaymentRequestedDialog = ({
  open,
  onClose,
  purchase,
  buyerAddress,
  setBuyerAddress,
  fetchBuyerPreferences,
  handleGetInvoice,
  selectedShippingOption,
  setSelectedShippingOption,
  selectedPaymentOption,
  setSelectedPaymentOption,
  setPaymentDetails,
  paymentFee,
  totalCost,
  handleCancelPurchase,
}) => {
  const [useSavedAddress, setUseSavedAddress] = useState(true);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [issueDialogOpen, setIssueDialogOpen] = useState(false);
  const [isConditionConfirmed, setIsConditionConfirmed] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false); // New state for form validation
  const navigate = useNavigate();
  const sellerId = purchase?.sellerId;
  const saleId = purchase?.id;

  useEffect(() => {
    if (open) {
      fetchBuyerPreferences().then((prefs) => {
        setBuyerAddress(prefs || {});
      });

      const fetchImages = async () => {
        const saleRef = doc(db, `users/${sellerId}/sales/${saleId}`);
        const saleDoc = await getDoc(saleRef);
        if (saleDoc.exists()) {
          const data = saleDoc.data();
          if (data.imageUrls) {
            setUploadedImages(data.imageUrls);
          }
        }
      };

      fetchImages();
    }
  }, [open, fetchBuyerPreferences, setBuyerAddress, saleId]);

  useEffect(() => {
    // Validate the form: Check if all required fields are filled
    const isShippingValid = !!selectedShippingOption;
    const isPaymentValid = !!selectedPaymentOption;
    const isAddressValid = buyerAddress?.addressLine1 && buyerAddress?.city && buyerAddress?.state && buyerAddress?.zipCode && buyerAddress?.country;
    
    setIsFormValid(isShippingValid && isPaymentValid && isAddressValid && isConditionConfirmed);
  }, [selectedShippingOption, selectedPaymentOption, buyerAddress, isConditionConfirmed]);

  const handleDeleteImages = async () => {
    for (const url of uploadedImages) {
      const fileRef = ref(storage, url);
      await deleteObject(fileRef);
    }
    const saleRef = doc(db, `users/${sellerId}/sales/${saleId}`);
    await updateDoc(saleRef, { imageUrls: [] });
    setUploadedImages([]);
  };

  const handleConfirmCondition = async () => {
    setIsConditionConfirmed(true);
  };

  const handleRequestNewPhotos = async () => {
    console.log('Requesting new photos from the seller...');
    await handleDeleteImages(); // Delete images before updating status
    const saleRef = doc(db, `users/${sellerId}/sales/${saleId}`);
    await updateDoc(saleRef, { status: 'Pending' });
    onClose(); // Close all dialogs and return to parent component
  };

  const handleCancelSaleFromCondition = async () => {
    console.log('Cancelling the sale due to condition issue...');
    await handleDeleteImages(); // Delete images before canceling the sale
    handleCancelPurchase(); // Call the parent’s cancel sale function
    onClose(); // Close all dialogs and return to parent component
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Get Invoice</DialogTitle>
      <DialogContent>
      {purchase?.paymentMessage && (
        <Box>
        <Typography variant="subtitle" gutterBottom>
        Message from Seller
      </Typography>
  <Box 
    sx={{
      border: '1px solid #ccc', // Light gray border
      borderRadius: '4px', // Optional: rounded corners
      padding: '16px', // Space inside the box
      marginBottom: '16px', // Optional: space below the box
    }}
  >
    <Typography variant="body1" paragraph>
      {purchase?.paymentMessage}
    </Typography>
  </Box>
  </Box>
)}



        <Typography variant="subtitle1">Items</Typography>
        <List>
          {purchase?.items?.map((item) => (
            <ListItem key={item.docName} disableGutters>
            <ListItemText
                              primary={
                                <>
                                  <Typography variant="body1" component="div">
                                    {item.cardName}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {item.setName} - #{item.collector_number}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {item.cardType} - {item.cardRarity}
                                  </Typography>
                                </>
                              }
                              secondary={
                                <>
                                  <Typography variant="body2" color="textSecondary">
                                    Quantity: {item.staticQuantity}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    Price: $
                                    {typeof item.staticPrice === "number"
                                      ? item.staticPrice.toFixed(2)
                                      : item.staticPrice}
                                  </Typography>
                                </>
                              }
                            />
          </ListItem>
          ))}
        </List>

        <Button
          variant="outlined"
          color="primary"
          onClick={() => setImageDialogOpen(true)}
          fullWidth
          style={{ marginTop: '1rem' }}
        >
          View Card Condition Images
        </Button>

        {!isConditionConfirmed && uploadedImages.length > 0 && (
          <>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              style={{ marginTop: '1rem' }}
              onClick={handleConfirmCondition}
            >
              Happy with Card Condition Photos
            </Button>

            <Button
              variant="contained"
              color="warning"
              fullWidth
              style={{ marginTop: '1rem' }}
              onClick={() => setIssueDialogOpen(true)}
            >
              Not Happy with Card Condition Photos
            </Button>
          </>
        )}

        {/* Shipping and Payment Options */}
        <Typography variant="subtitle1" gutterBottom>
          Shipping Address
        </Typography>
        <FormControl component="fieldset" fullWidth margin="normal">
          <RadioGroup
            value={useSavedAddress ? 'saved' : 'manual'}
            onChange={(e) => setUseSavedAddress(e.target.value === 'saved')}
          >
            <FormControlLabel
              value="saved"
              control={<Radio />}
              label={`${buyerAddress?.addressLine1}, ${buyerAddress?.city}, ${buyerAddress?.state}, ${buyerAddress?.zipCode}, ${buyerAddress?.country}`}
            />
          </RadioGroup>
        </FormControl>

        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate('/profile')}
          fullWidth
          style={{ marginTop: '1rem' }}
        >
          Update Address in Profile
        </Button>

        <Typography variant="subtitle1">Select Shipping Option</Typography>
        <FormControl component="fieldset" fullWidth margin="normal">
          <RadioGroup value={selectedShippingOption} onChange={(e) => setSelectedShippingOption(e.target.value)}>
            {purchase?.availableShippingOptions?.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.method}
                control={<Radio />}
                label={`${option.method} - ${option.chargePerItem}`}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <Typography variant="subtitle1">Select Payment Option</Typography>
        <FormControl component="fieldset" fullWidth margin="normal">
          <RadioGroup
            value={selectedPaymentOption}
            onChange={(e) => {
              const selectedOption = e.target.value;
              setSelectedPaymentOption(selectedOption);

              const optionDetails = purchase?.paymentOptions?.find((option) => option.method === selectedOption);
              setPaymentDetails(optionDetails?.details || '');
            }}
          >
            {purchase?.paymentOptions?.map((option, index) => (
              <FormControlLabel key={index} value={option.method} control={<Radio />} label={option.method} />
            ))}
          </RadioGroup>
        </FormControl>

        <Typography variant="h6">Payment Fee: ${paymentFee.toFixed(2)}</Typography>
        <Typography variant="h6">Total Cost: ${totalCost.toFixed(2)}</Typography>
        

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleGetInvoice}
          color="primary"
          disabled={!isFormValid} // Button is disabled if the form is not valid
        >
          Get Invoice
        </Button>
      </DialogActions>

      {/* ImageViewerDialog */}
      <ImageViewerDialog open={imageDialogOpen} onClose={() => setImageDialogOpen(false)} saleId={saleId} sellerId={sellerId} />

      {/* CardConditionIssueDialog */}
      <CardConditionIssueDialog
        open={issueDialogOpen}
        onClose={() => setIssueDialogOpen(false)}
        onRequestNewPhotos={handleRequestNewPhotos}
        onCancelSale={handleCancelSaleFromCondition}
      />
    </Dialog>
  );
};

export default PaymentRequestedDialog;
