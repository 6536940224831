import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, Select, MenuItem, InputLabel, RadioGroup, FormControlLabel, Radio, Button, Typography, Box
} from '@mui/material';
import { db } from '../firebase';  // Import your Firestore setup
import { doc, updateDoc } from 'firebase/firestore';  // Firestore methods

const BuyerDispute = ({ open, onClose, onSubmit, purchase }) => {
  const [disputeReason, setDisputeReason] = useState('');
  const [buyerComments, setBuyerComments] = useState('');
  const [requestedAction, setRequestedAction] = useState('');
  const [refundPaymentMethod, setRefundPaymentMethod] = useState('');
  const [refundPaymentDetails, setRefundPaymentDetails] = useState({});

  const handleRequestDispute = async () => {
    const purchaseRef = doc(db, 'users', purchase.buyerId, 'purchases', purchase.id);
    const saleRef = doc(db, 'users', purchase.sellerId, 'sales', purchase.id); // Reference to the sales document
  
    const disputeData = {
      refundPaymentDetails: {
        refundPaymentMethod,
        ...refundPaymentDetails,
      },
      refundReason: disputeReason, // Set refund reason to match dispute reason
      refundRequested: true,
      status: 'Refund Requested',  // Update status to reflect refund request
    };
  
    try {
      // Update the purchase document
      await updateDoc(purchaseRef, disputeData);
  
      // Update the sales document
      await updateDoc(saleRef, disputeData);
    } catch (error) {
      console.error("Error updating documents: ", error);
    }
  
    onSubmit({
      disputeReason,
      buyerComments,
      requestedAction,
      refundPaymentMethod,
      refundPaymentDetails,
    });
  
    setDisputeReason('');
    setBuyerComments('');
    setRequestedAction('');
    setRefundPaymentMethod('');
    setRefundPaymentDetails({});
    onClose();
  };
  
  
  const renderRefundPaymentDetailsFields = () => {
    switch (refundPaymentMethod) {
      case 'Bank Transfer':
        return (
          <Box>
            <TextField
              label="Account Name"
              fullWidth
              value={refundPaymentDetails.accountName || ''}
              onChange={(e) =>
                setRefundPaymentDetails((prev) => ({
                  ...prev,
                  accountName: e.target.value,
                }))
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Account Number"
              fullWidth
              value={refundPaymentDetails.accountNumber || ''}
              onChange={(e) =>
                setRefundPaymentDetails((prev) => ({
                  ...prev,
                  accountNumber: e.target.value,
                }))
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="BSB"
              fullWidth
              value={refundPaymentDetails.bsb || ''}
              onChange={(e) =>
                setRefundPaymentDetails((prev) => ({
                  ...prev,
                  bsb: e.target.value,
                }))
              }
              variant="outlined"
              margin="normal"
            />
          </Box>
        );
      case 'Paypal':
        return (
          <TextField
            label="Paypal ID"
            fullWidth
            value={refundPaymentDetails.paypalID || ''}
            onChange={(e) =>
              setRefundPaymentDetails((prev) => ({
                ...prev,
                paypalID: e.target.value,
              }))
            }
            variant="outlined"
            margin="normal"
          />
        );
      case 'PayID':
        return (
          <TextField
            label="PayID"
            fullWidth
            value={refundPaymentDetails.payID || ''}
            onChange={(e) =>
              setRefundPaymentDetails((prev) => ({
                ...prev,
                payID: e.target.value,
              }))
            }
            variant="outlined"
            margin="normal"
          />
        );
      case 'Other':
        return (
          <TextField
            label="Other Payment Details"
            fullWidth
            multiline
            rows={4}
            value={refundPaymentDetails.otherDetails || ''}
            onChange={(e) =>
              setRefundPaymentDetails((prev) => ({
                ...prev,
                otherDetails: e.target.value,
              }))
            }
            variant="outlined"
            margin="normal"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Dispute Purchase</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>Reason for Dispute</InputLabel>
          <Select
            value={disputeReason}
            onChange={(e) => setDisputeReason(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="Not Received">Not Received</MenuItem>
            <MenuItem value="Damaged">Damaged</MenuItem>
            <MenuItem value="Not as Described">Not as Described</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Buyer Comments"
          fullWidth
          multiline
          rows={4}
          value={buyerComments}
          onChange={(e) => setBuyerComments(e.target.value)}
          variant="outlined"
          margin="normal"
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Requested Action</InputLabel>
          <Select
            value={requestedAction}
            onChange={(e) => setRequestedAction(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="Refund">Refund</MenuItem>
            <MenuItem value="Replace">Replace</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>

        {requestedAction === 'Refund' && (
          <>
            <Typography variant="subtitle1" gutterBottom>
              Refund Payment Details
            </Typography>
            <FormControl component="fieldset" fullWidth margin="normal">
              <Typography variant="subtitle1">Refund Payment Method</Typography>
              <RadioGroup
                value={refundPaymentMethod}
                onChange={(e) => setRefundPaymentMethod(e.target.value)}
              >
                <FormControlLabel
                  value="Bank Transfer"
                  control={<Radio />}
                  label="Bank Transfer"
                />
                <FormControlLabel
                  value="Paypal"
                  control={<Radio />}
                  label="Paypal"
                />
                <FormControlLabel
                  value="PayID"
                  control={<Radio />}
                  label="PayID"
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </FormControl>
            {renderRefundPaymentDetailsFields()}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
        <Button onClick={handleRequestDispute} color="primary">
          Submit Dispute
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BuyerDispute;
