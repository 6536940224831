import React, { useRef, useState, useEffect } from 'react';
import { TextField, Box, Button, Typography, Grid } from '@mui/material';
import { Autocomplete } from '@react-google-maps/api';

const BuyerPreferences = ({ profile, handleBuyerPreferenceChange, handleSaveBuyerPreferences }) => {
  const autocompleteRef = useRef(null);
  const [searchAddress, setSearchAddress] = useState('');
  const [isEditing, setIsEditing] = useState(false); // New state to manage edit mode

  useEffect(() => {
    // Fetch the buyer preferences when the component loads
    if (profile.buyerPreferences) {
      setSearchAddress(profile.buyerPreferences.addressLine1 || '');
    }
  }, [profile]);

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place) {
      const addressComponents = place.address_components;
      const addressData = {};

      addressComponents.forEach(component => {
        const types = component.types;
        if (types.includes('street_number')) {
          addressData.addressLine1 = component.long_name + ' ';
        }
        if (types.includes('route')) {
          addressData.addressLine1 = (addressData.addressLine1 || '') + component.long_name;
        }
        if (types.includes('locality')) {
          addressData.city = component.long_name;
        }
        if (types.includes('administrative_area_level_1')) {
          addressData.state = component.short_name;
        }
        if (types.includes('postal_code')) {
          addressData.zipCode = component.long_name;
        }
        if (types.includes('country')) {
          addressData.country = component.long_name;
        }
      });

      // Update the profile with the new address data
      Object.keys(addressData).forEach(key => {
        handleBuyerPreferenceChange({ target: { name: key, value: addressData[key] } });
      });

      // Clear the search address field
      setSearchAddress('');
    }
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Shipping Address
      </Typography>
      {!isEditing ? (
        // Display mode
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1"><strong>Contact Name:</strong> {profile.buyerPreferences.preferredContactName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1"><strong>Address Line 1:</strong> {profile.buyerPreferences.addressLine1}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1"><strong>Address Line 2:</strong> {profile.buyerPreferences.addressLine2}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1"><strong>City:</strong> {profile.buyerPreferences.city}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1"><strong>State:</strong> {profile.buyerPreferences.state}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1"><strong>Zip Code:</strong> {profile.buyerPreferences.zipCode}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1"><strong>Country:</strong> {profile.buyerPreferences.country}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Additional Details:</strong> {profile.buyerPreferences.additionalDetails}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={toggleEditMode} variant="contained" color="primary">
              Edit
            </Button>
          </Grid>
        </Grid>
      ) : (
        // Edit mode
        <>
          <TextField
            name="preferredContactName"
            label="Contact Name"
            value={profile.buyerPreferences.preferredContactName || ''}
            onChange={handleBuyerPreferenceChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <Autocomplete onLoad={(ref) => (autocompleteRef.current = ref)} onPlaceChanged={onPlaceChanged}>
            <TextField
              name="searchAddress"
              label="Search Address"
              value={searchAddress}
              onChange={(e) => setSearchAddress(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Autocomplete>
          <TextField
            name="addressLine1"
            label="Address Line 1"
            value={profile.buyerPreferences.addressLine1 || ''}
            onChange={handleBuyerPreferenceChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            name="addressLine2"
            label="Address Line 2"
            value={profile.buyerPreferences.addressLine2 || ''}
            onChange={handleBuyerPreferenceChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            name="city"
            label="City"
            value={profile.buyerPreferences.city || ''}
            onChange={handleBuyerPreferenceChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            name="state"
            label="State"
            value={profile.buyerPreferences.state || ''}
            onChange={handleBuyerPreferenceChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            name="zipCode"
            label="Zip Code"
            value={profile.buyerPreferences.zipCode || ''}
            onChange={handleBuyerPreferenceChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            name="country"
            label="Country"
            value={profile.buyerPreferences.country || ''}
            onChange={handleBuyerPreferenceChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <TextField
            name="additionalDetails"
            label="Additional Details"
            value={profile.buyerPreferences.additionalDetails || ''}
            onChange={handleBuyerPreferenceChange}
            fullWidth
            variant="outlined"
            margin="normal"
          />
          <Button onClick={handleSaveBuyerPreferences} variant="contained" color="primary">
            Save Buyer Preferences
          </Button>
          <Button onClick={toggleEditMode} variant="outlined" color="secondary">
            Cancel
          </Button>
        </>
      )}
    </Box>
  );
};

export default BuyerPreferences;
