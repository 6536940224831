import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Box, Typography } from '@mui/material';
import { doc, onSnapshot } from 'firebase/firestore'; // Firebase Firestore methods
import { useAuth } from '../auth'; // Custom hook for authentication
import { db } from '../firebase'; // Firebase Firestore
import withAnalytics from '../hoc/withAnalytics';
import Loading from '../components/Loading';

const CheckoutForm = ({ clientSecret, selectedPlan, requiresPaymentMethod }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { currentUser } = useAuth(); // Get the current user from auth context
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('pending'); // Track the payment status
  const [isPaymentComplete, setIsPaymentComplete] = useState(false); // Track if payment is completed

  // Poll Firestore for subscription status
  const pollSubscriptionStatus = () => {
    const subscriptionDocRef = doc(db, 'users', currentUser.uid); // Reference the user's Firestore document
  
    // Set up a Firestore listener to get real-time updates on subscription status
    const unsubscribe = onSnapshot(subscriptionDocRef, (doc) => {
      const subscriptionData = doc.data()?.subscription;
      console.log("Subscription data from Firestore:", subscriptionData);  // Debugging Firestore data
  
      if (subscriptionData?.status === 'active') {
        setIsPaymentComplete(true); // Update the UI to reflect payment success
        setPaymentStatus('success');
        unsubscribe(); // Stop listening for updates once the subscription is active
      } else if (subscriptionData?.status === 'failed') {
        setPaymentStatus('failed'); // Handle failed payment
        unsubscribe(); // Stop listening after failure
      }
    });
  };
  

  // Handle payment submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      setError('Stripe or elements not loaded');
      return;
    }

    setLoading(true);

    try {
      let stripeResult;
      if (requiresPaymentMethod) {
        // Confirm Setup for collecting payment method
        stripeResult = await stripe.confirmSetup({
          elements,
          confirmParams: {},
          redirect: 'if_required',
        });
      } else {
        // Confirm payment intent if a payment method exists
        stripeResult = await stripe.confirmPayment({
          elements,
          confirmParams: {},
          redirect: 'if_required',
        });
      }

      if (stripeResult.error) {
        setError(`Payment failed: ${stripeResult.error.message}`);
      } else {
        pollSubscriptionStatus(); // Start polling the subscription status from Firestore
      }
    } catch (error) {
      setError(`Failed to process payment: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>

      {/* Loading overlay */}
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loading />
        </Box>
      )}

      {/* Show payment form when status is pending */}
      {paymentStatus === "pending" && (
        <>
          <div>
            <Typography variant="h6">
              You have selected {selectedPlan?.name}.
            </Typography>
            <Typography variant="body1">
              You will be charged ${selectedPlan?.price}/
              {selectedPlan?.billingFrequency} until cancelled. Please complete your
              payment details below.
            </Typography>
          </div>

          <PaymentElement />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!stripe || !clientSecret || loading}
            fullWidth
            sx={{ mt: 2 }}
          >
            {loading ? "Processing..." : "Pay"}
          </Button>

          <Box sx={{ mt: 3, textAlign: "center" }}>
            <Typography variant="caption" color="textSecondary">
              Payments powered by
            </Typography>
            <Box
              component="img"
              sx={{ height: 24, mt: 1 }} // Adjust height of the image
              alt="Stripe"
              src="https://images.ctfassets.net/tzolbn9skp8z/5pzgFOWw2UIYusGcyKQYO0/73e8de3b9350da8b5a74b035dd6c0470/secure-payments-by-stripe.png"
            />
          </Box>
        </>
      )}

      {/* Show success message if payment succeeds */}
      {paymentStatus === "success" && (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h5" color="success.main">
            Payment Successful!
          </Typography>
          <Typography variant="body1">
            Your subscription is now active. Thank you for your payment.
          </Typography>
        </Box>
      )}

      {/* Show failure message if payment fails */}
      {paymentStatus === "failed" && (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="h5" color="error.main">
            Payment Failed!
          </Typography>
          <Typography variant="body1">
            There was an issue with your payment. Please try again.
          </Typography>
        </Box>
      )}

      {/* Show spinner while waiting for payment confirmation */}
      {loading && !isPaymentComplete && (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography variant="body1" color="textSecondary">
            Waiting for payment confirmation...
          </Typography>
          <Loading />
        </Box>
      )}

      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </form>
  );
};

export default withAnalytics(CheckoutForm);
