import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button
} from '@mui/material';

const MarkAsShippedDialog = ({ open, onClose, sale, onMarkShipped }) => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [shippingCompany, setShippingCompany] = useState('');

  const handleMarkShipped = () => {
    onMarkShipped(trackingNumber, shippingCompany);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Mark as Shipped</DialogTitle>
      <DialogContent>
        <TextField
          label="Tracking Number"
          fullWidth
          value={trackingNumber}
          onChange={(e) => setTrackingNumber(e.target.value)}
          variant="outlined"
          margin="normal"
          required
        />
        <TextField
          label="Shipping Company"
          fullWidth
          value={shippingCompany}
          onChange={(e) => setShippingCompany(e.target.value)}
          variant="outlined"
          margin="normal"
          required
        />
      </DialogContent>
      <DialogActions>
        
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
        <Button onClick={handleMarkShipped} color="primary" disabled={!trackingNumber || !shippingCompany}>
          Mark as Shipped
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MarkAsShippedDialog;
