import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Terms of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Last updated: 16/07/2024
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing and using Lorcana Card Marketplace ("the Service"), you
          accept and agree to be bound by the terms and provisions of this
          agreement. In addition, when using these particular services, you
          shall be subject to any posted guidelines or rules applicable to such
          services. Any participation in this service will constitute acceptance
          of this agreement. If you do not agree to abide by the above, please
          do not use this service.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Description of Service
        </Typography>
        <Typography variant="body1" paragraph>
          Lorcana Card Marketplace provides users with a platform to buy, sell,
          and manage Lorcana cards. Users can browse through a collection of
          Lorcana cards for sale by other players and collectors. The Service is
          offered "as is" and is subject to availability. We do not guarantee
          the availability of the Service at any time and reserve the right to
          modify or discontinue the Service without notice or liability.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. User Accounts
        </Typography>
        <Typography variant="body1" paragraph>
          To use certain features of the Service, you must create an account.
          You are responsible for maintaining the confidentiality of your
          account and password and for restricting access to your computer. You
          agree to accept responsibility for all activities that occur under
          your account or password.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. User Conduct
        </Typography>
        <Typography variant="body1" paragraph>
          You agree not to use the Service for any unlawful or prohibited
          purpose. You are responsible for all of your activity in connection
          with the Service. You shall not, and shall not permit any third party
          to, take any action that imposes or may impose (as determined by
          Lorcana Card Marketplace in its sole discretion) an unreasonable or
          disproportionately large load on Lorcana Card Marketplace’s (or its
          third-party providers’) infrastructure; interfere or attempt to
          interfere with the proper working of the Service; bypass any measures
          Lorcana Card Marketplace may use to prevent or restrict access to the
          Service.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Transactions and Fees
        </Typography>
        <Typography variant="body1" paragraph>
          Lorcana Card Marketplace charges fees for access to certain advanced
          features and services. Basic features, such as limited listing and
          browsing cards, remain free. However, users wishing to access advanced
          tools or features may be required to pay a subscription or one-time
          fee. Additionally, there is a one-time verification fee for account
          verification purposes if you wish to list items for sale. These fees
          are clearly stated at the point of purchase or upgrade.
        </Typography>
        <Typography variant="body1" paragraph>
          You are responsible for any fees associated with payment processing or
          other third-party services. Lorcana Card Marketplace reserves the
          right to change the fee structure or introduce new fees at any time,
          with reasonable notice provided to users. By continuing to use the
          Service after such changes become effective, you agree to be bound by
          the updated fee structure.
        </Typography>
        <Typography variant="body1" paragraph>
          If you fail to make payment for a subscription, cancel your
          subscription, or downgrade to a plan that does not include access to
          certain advanced features, any data associated with those features may
          be deleted within 7 days. Lorcana Card Marketplace will not be
          responsible for any loss of data resulting from non-payment or plan
          changes. You are encouraged to back up your data before making any
          such changes.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Refund Policy
        </Typography>
        <Typography variant="body1" paragraph>
          All fees paid for subscriptions, verification, or other advanced
          features are non-refundable unless otherwise stated. In certain cases,
          Lorcana Card Marketplace may, at its sole discretion, provide refunds
          or credits in the event of a service disruption or other issues.
        </Typography>
        <Typography variant="body1" paragraph>
          Users can cancel their subscription at any time. However, cancellation
          will only prevent future charges, and any fees paid for the current
          billing period will not be refunded.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Service Availability
        </Typography>
        <Typography variant="body1" paragraph>
          The Service may be subject to interruptions, delays, and other issues
          inherent in the use of the internet and electronic communications.
          Lorcana Card Marketplace does not guarantee the continuous
          availability or uptime of the Service and shall not be held liable for
          any service disruptions. You acknowledge that you use the Service at
          your own risk.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Data Loss and Security
        </Typography>
        <Typography variant="body1" paragraph>
          While we take reasonable precautions to protect user data, Lorcana
          Card Marketplace cannot guarantee the security of data transmitted
          through the Service or stored on our systems. You acknowledge that
          data loss, corruption, or unauthorized access may occur, and Lorcana
          Card Marketplace shall not be held liable for any such incidents.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          In no event shall Lorcana Card Marketplace, nor its directors,
          employees, partners, agents, suppliers, or affiliates, be liable for
          any indirect, incidental, special, consequential, or punitive damages,
          including without limitation, loss of profits, data, use, goodwill, or
          other intangible losses, resulting from (i) your access to or use of
          or inability to access or use the Service; (ii) any conduct or content
          of any third party on the Service; (iii) any content obtained from the
          Service; and (iv) unauthorized access, use, or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence) or any other legal theory, whether or not we
          have been informed of the possibility of such damage.
        </Typography>

        <Typography variant="h6" gutterBottom>
          10. Disclaimer
        </Typography>
        <Typography variant="body1" paragraph>
          Your use of the Service is at your sole risk. The Service is provided
          on an "AS IS" and "AS AVAILABLE" basis. The Service is provided
          without warranties of any kind, whether express or implied, including,
          but not limited to, implied warranties of merchantability, fitness for
          a particular purpose, non-infringement, or course of performance.
        </Typography>

        <Typography variant="h6" gutterBottom>
          11. Third-Party Services
        </Typography>
        <Typography variant="body1" paragraph>
          Lorcana Card Marketplace may utilize third-party providers to offer
          certain services, including but not limited to payment processing,
          account verification, notifications, and other functionalities. These
          services are provided by independent companies, and Lorcana Card
          Marketplace does not have control over the operations of these
          third-party providers.
        </Typography>
        <Typography variant="body1" paragraph>
          While we strive to partner with reputable third-party providers,
          Lorcana Card Marketplace shall not be held responsible for any issues
          or liabilities arising from the use of third-party services. This
          includes, but is not limited to, payment processing errors,
          verification delays, notification failures, or any other service
          disruptions caused by third-party providers.
        </Typography>
        <Typography variant="body1" paragraph>
          By using the Service, you acknowledge and agree that Lorcana Card
          Marketplace is not liable for the performance, security, or
          reliability of any third-party services and that any use of such
          services is at your own risk. Any concerns or disputes related to
          third-party services should be directed to the respective provider.
        </Typography>

        <Typography variant="h6" gutterBottom>
          12. User-Generated Content and Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          By submitting, posting, or displaying content on or through the
          Service, you grant Lorcana Card Marketplace a worldwide, non-exclusive,
          royalty-free license to use, copy, reproduce, process, adapt, modify,
          publish, transmit, display, and distribute such content in any and all
          media or distribution methods.
        </Typography>
        <Typography variant="body1" paragraph>
          You represent and warrant that you have the necessary rights to grant
          the above license to Lorcana Card Marketplace for any content you
          submit to the Service. Lorcana Card Marketplace takes no
          responsibility and assumes no liability for any content posted by you
          or any third party.
        </Typography>
        <Typography variant="body1" paragraph>
          Lorcana Card Marketplace reserves the right (but not the obligation)
          to remove or modify user content for any reason, including content
          that violates these Terms or any applicable laws.
        </Typography>

        <Typography variant="h6" gutterBottom>
          13. Privacy and Data Collection
        </Typography>
        <Typography variant="body1" paragraph>
          Lorcana Card Marketplace takes your privacy seriously. By using the
          Service, you agree to the collection, use, and sharing of your
          information as outlined in our Privacy Policy. We will not share your
          personal information with third parties except as outlined in the
          Privacy Policy.
        </Typography>
        <Typography variant="body1" paragraph>
          You agree to allow Lorcana Card Marketplace to collect and store
          certain information, including but not limited to, account details,
          transaction history, and communication logs for the purpose of
          improving our Service. For more details, please review our Privacy
          Policy.
        </Typography>

        <Typography variant="h6" gutterBottom>
          14. Termination of Accounts
        </Typography>
        <Typography variant="body1" paragraph>
          Lorcana Card Marketplace reserves the right to suspend or terminate
          your account at any time, with or without notice, for conduct that
          violates these Terms or any other policies, or for any other reason at
          our sole discretion. Upon termination, your right to use the Service
          will immediately cease.
        </Typography>
        <Typography variant="body1" paragraph>
          If your account is terminated, any data associated with advanced
          features may be deleted, and you will not be entitled to any refund of
          fees paid for such services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          15. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms shall be governed and construed in accordance with the
          laws of Australia, without regard to its conflict of law provisions.
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have had between
          us regarding the Service.
        </Typography>

        <Typography variant="h6" gutterBottom>
          16. Changes
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will try to
          provide at least 30 days' notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion. By continuing to access or use our Service after any
          revisions become effective, you agree to be bound by the revised
          terms. If you do not agree to the new terms, you are no longer
          authorized to use the Service.
        </Typography>

        <Typography variant="h6" gutterBottom>
          17. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these Terms, please contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Email:</strong> support@buylorcana.cards
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;
