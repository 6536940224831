import React, { useState, useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { Box, Button, TextField, Typography, Alert } from '@mui/material';
import { functions } from '../firebase'; // Make sure to import your Firebase configuration

const ForgotPassword = ({ onCancel }) => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const handleResetPassword = async () => {
    const sendPasswordResetEmail = httpsCallable(functions, 'sendPasswordResetEmail');
    
    try {
      await sendPasswordResetEmail({ email });
      setSuccess('Password reset link sent! Please check your email.');
      setError('');
    } catch (error) {
      setError('Failed to send password reset email. Please try again.');
      setSuccess('');
    }
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        onCancel(); // Close the dialog after 5 seconds
      }, 5000);

      // Clear the timer if the component unmounts before 5 seconds
      return () => clearTimeout(timer);
    }
  }, [success, onCancel]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Reset Password
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        required
        margin="normal"
        disabled={Boolean(success)} // Disable the text field if there's a success message
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleResetPassword}
        fullWidth
        sx={{ mt: 2 }}
        disabled={Boolean(success)} // Disable the button if there's a success message
      >
        Send Reset Link
      </Button>
      <Button
        onClick={onCancel}
        fullWidth
        variant="outlined"
        color="secondary"
        sx={{ mt: 1 }}
      >
        Back to Login
      </Button>
    </Box>
  );
};

export default ForgotPassword;
