// src/pages/PrivacyPolicy.js
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Last updated: 16/07/2024
        </Typography>

        <Typography variant="body1" paragraph>
          Welcome to Lorcana Card Marketplace. We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect your information when you use our services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect information to provide better services to our users. This includes:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Personal Information:</strong> When you register, we collect personal details such as your name, email address, password, and contact information.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Transaction Information:</strong> We collect details of your transactions on our platform, including items purchased or sold, transaction amounts, and payment information.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Usage Data:</strong> We collect information about how you use our platform, such as your browsing activity, pages viewed, and time spent on our site.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Device Information:</strong> We collect information about the devices you use to access our services, including IP address, browser type, and operating system.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use the information we collect for various purposes, including:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Providing Services:</strong> To provide and maintain our services, process transactions, and communicate with you.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Improving Services:</strong> To analyze usage and trends to improve our platform and develop new features.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Personalization:</strong> To personalize your experience by showing you content and ads that are tailored to your interests.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Security:</strong> To detect, prevent, and address fraud, security breaches, and other potentially harmful activities.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We do not share your personal information with third parties except in the following circumstances:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>With Your Consent:</strong> We may share your information with your consent or at your direction.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Service Providers:</strong> We share information with service providers who perform services on our behalf, such as payment processing and data analysis.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Legal Requirements:</strong> We may disclose your information if required by law, such as to comply with a subpoena or similar legal process.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Protecting Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Your Choices and Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to access, update, and delete your personal information. You can do this by logging into your account or contacting us directly.
        </Typography>
        <Typography variant="body1" paragraph>
          You can opt out of receiving promotional emails from us by following the unsubscribe instructions in those emails. If you opt out, we may still send you non-promotional communications, such as those about your account or transactions.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Children's Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Email:</strong> support@buylorcana.cards
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
