import React from 'react';
import { Typography, Button, Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const VerificationRequired = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Verification Required
      </Typography>
      <Typography variant="body1" gutterBottom>
        Your account needs to be verified before you can access this page.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 3 }}>
        <Button variant="contained" color="primary" onClick={() => navigate('/profile')}>
          Go to Profile
        </Button>
        <Button variant="outlined" color="primary" onClick={() => navigate('/public-marketplace')}>
          View Public Marketplace
        </Button>
      </Box>
    </Container>
  );
};

export default VerificationRequired;
