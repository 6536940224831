// AdminDashboard.js
import React from 'react';
import { Box, Button, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const navigate = useNavigate();

  const adminLinks = [
    { path: '/firestoreviewer', label: 'Firestore Viewer' },
    { path: '/usermanagement', label: 'User Management' },
    { path: '/notificationsmanager', label: 'Bulk Notifications' },
    { path: '/planmanagement', label: 'Subscription Plans' },
    { path: '/all-sales-summary', label: 'All Sales Summary' },
    { path: '/all-listings-summary', label: 'All Listings Summary' },
    { path: '/all-wishlist-summary', label: 'All Wishlist Summary' },
    { path: '/bulkmessage', label: 'Bulk Message Sender' },
    { path: '/addnewcard', label: 'Add New Card' },
    // Add other admin links as needed
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Admin Dashboard
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {adminLinks.map((link) => (
          <Grid item key={link.path} xs={12} sm={6} md={4}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => navigate(link.path)}
              sx={{ textTransform: 'none' }}
            >
              {link.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
