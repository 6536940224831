import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
    apiKey: "AIzaSyA0uZvM28NQhkhcjn8-TQyMNBz6UR4zBvc",
    authDomain: "buylorcanacards.firebaseapp.com",
    projectId: "buylorcanacards",
    storageBucket: "buylorcanacards.appspot.com",
    messagingSenderId: "778959105907",
    appId: "1:778959105907:web:acbe7a7ef029bda899df06",
    measurementId: "G-TRQ6NV9RHN"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6Lcg5EcqAAAAAI11ZZxS5QYJm6r3mX86RYKCBN51'),
    isTokenAutoRefreshEnabled: true,
});

export { db, auth, storage, functions, httpsCallable, analytics, appCheck };
