import React from 'react';
import { ListItem, ListItemText, ListItemAvatar, Avatar, Box, Stack, Typography, Chip } from '@mui/material';
import cardImage from './card.png';

const CustomAdListItemMobile = ({ title, subtitle, text, actionText, onActionClick }) => {
  return (
    <ListItem sx={{ border: '2px solid #0C0A5A', flexDirection: 'column', alignItems: 'flex-start', backgroundColor: '#fff9c4' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <ListItemAvatar sx={{ width: 100 }}>
          <Avatar
            variant="square"
            src={cardImage}
            sx={{
              height: '20%',
              width: '80%',
              objectFit: 'contain',
              border: `4px solid #F2D18B`,
            }}
          />
        </ListItemAvatar>
        <Stack spacing={1} sx={{ ml: 2 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar variant="square" sx={{ bgcolor: '#0C0A5A' }}>
              222
            </Avatar>
          </Stack>
          <ListItemText
            primary={<Typography variant="body1">{title}</Typography>}
            secondary={
              subtitle && (
                <Typography variant="body2" color="textSecondary">
                  {subtitle}
                </Typography>
              )
            }
          />
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 2 }}>
        <Typography variant="body2" color="textPrimary">
          {text}
        </Typography>
        <Chip
          label={actionText}
          onClick={onActionClick}
          clickable
          sx={{
            bgcolor: "secondary.main",
            color: 'white',
            borderRadius: '4px',
            mt: 1,
            '& .MuiChip-label': {
              whiteSpace: 'normal',
            },
          }}
        />
      </Box>
    </ListItem>
  );
};

export default CustomAdListItemMobile;
