import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import { Container, Box, Alert } from '@mui/material';
import Loading from '../components/Loading';

const Confirm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState('loading');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const confirmUser = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      if (!token) {
        setStatus('error');
        setMessage('Invalid confirmation link.');
        return;
      }

      try {
        const confirmUserFunction = httpsCallable(functions, 'confirmUser');
        await confirmUserFunction({ token });

        setStatus('success');
        setMessage('Email confirmed! Please login...');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } catch (error) {
        console.error('Error during email confirmation:', error.message);
        setStatus('error');
        setMessage('An error occurred while confirming your email.');
      }
    };

    confirmUser();
  }, [location, navigate]);

  return (
    <Container maxWidth="sm">
      <Box mt={5} textAlign="center">
        {status === 'loading' && <Loading />}
        {status === 'error' && <Alert severity="error">{message}</Alert>}
        {status === 'success' && <Alert severity="success">{message}</Alert>}
      </Box>
    </Container>
  );
};

export default Confirm;
