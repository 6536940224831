import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import { db } from '../firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';

const AllListingsSummary = () => {
  const [listings, setListings] = useState([]);
  const [totalListings, setTotalListings] = useState(0);
  const [groupBy, setGroupBy] = useState('');
  const [groupedData, setGroupedData] = useState({});

  const fetchListings = async () => {
    try {
      const setsSnapshot = await getDocs(collection(db, 'sets'));
      const setsData = setsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      let allListings = [];
  
      for (const set of setsData) {
        const cardsSnapshot = await getDocs(collection(db, 'sets', set.id, 'cards'));
  
        const listingsPromises = cardsSnapshot.docs.map(async (cardDoc) => {
          const cardData = { docName: cardDoc.id, setId: set.id, setName: set.name, ...cardDoc.data() };
  
          // Fetch each user's listings for the card
          const userListingsSnapshot = await getDocs(collection(db, 'sets', set.id, 'cards', cardDoc.id, 'listings'));
  
          // Process each user's listing document
          const userListings = userListingsSnapshot.docs.map((userListingDoc) => {
            const userId = userListingDoc.id;
            const userListingData = userListingDoc.data();
  
            // Map each condition in the user listing document to separate entries
            return Object.entries(userListingData).map(([condition, listingDetails]) => ({
              ...cardData,
              userId,
              condition,
              ...listingDetails,
            }));
          });
  
          // Flatten listings for all conditions under this card
          return userListings.flat();
        });
  
        // Flatten listings for all cards in the set
        const listingsForSet = (await Promise.all(listingsPromises)).flat();
        allListings = [...allListings, ...listingsForSet];
      }
  
      // Extract unique userIds and fetch usernames
      const userIds = [...new Set(allListings.map((listing) => listing.userId))];
      const usernames = {};
  
      await Promise.all(userIds.map(async (userId) => {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          usernames[userId] = userDoc.data().username;
        }
      }));
  
      // Map usernames to listings
      const listingsWithUsernames = allListings.map((listing) => ({
        ...listing,
        username: usernames[listing.userId] || 'Unknown User',
      }));
  
      setListings(listingsWithUsernames);
      setTotalListings(listingsWithUsernames.length);
    } catch (error) {
      console.error("Error fetching listings:", error);
    }
  };
  
  
  
  
  
  

  useEffect(() => {
    fetchListings();
  }, []);

  useEffect(() => {
    if (groupBy) {
      const grouped = groupListingsData(listings, groupBy);
      setGroupedData(grouped);
    }
  }, [listings, groupBy]);

  const groupListingsData = (listings, groupBy) => {
    const groupMap = {};
  
    listings.forEach(listing => {
      const groupKey = groupBy === 'User' ? listing.userId :
                       groupBy === 'Set' ? listing.setName :
                       groupBy === 'Rarity' ? listing.rarity :
                       groupBy === 'Ink' ? listing.ink :
                       groupBy === 'Type' ? listing.type : 'Other';
  
      if (!groupMap[groupKey]) {
        groupMap[groupKey] = {
          listings: [],
          breakdown: {
            set: {},
            type: {},
            ink: {},
            condition: {},
            totalCards: 0,
          }
        };
      }
  
      const group = groupMap[groupKey];
      group.listings.push(listing);
      group.breakdown.totalCards += listing.quantity || 1;
  
      // Aggregate by set
      if (listing.setName) {
        group.breakdown.set[listing.setName] = (group.breakdown.set[listing.setName] || 0) + 1;
      }
      
      // Aggregate by type
      if (listing.type) {
        group.breakdown.type[listing.type] = (group.breakdown.type[listing.type] || 0) + 1;
      }
  
      // Aggregate by ink color
      if (listing.ink) {
        group.breakdown.ink[listing.ink] = (group.breakdown.ink[listing.ink] || 0) + 1;
      }
  
      // Aggregate by condition
      if (listing.condition) {
        group.breakdown.condition[listing.condition] = (group.breakdown.condition[listing.condition] || 0) + 1;
      }
    });
  
    return groupMap;
  };

  const renderTable = () => {
    if (!groupBy || Object.keys(groupedData).length === 0) return null;
  
    return (
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{groupBy === 'User' ? 'Username' : groupBy}</TableCell>
              <TableCell>Total Listings</TableCell>
              {groupBy === 'User' && <TableCell>Total Cards</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedData).map(([groupKey, data]) => (
              <React.Fragment key={groupKey}>
                <TableRow>
                  <TableCell>{groupBy === 'User' ? (data.listings[0]?.username || 'Unknown User') : groupKey}</TableCell>
                  <TableCell>{data.listings.length}</TableCell>
                  {groupBy === 'User' && <TableCell>{data.breakdown.totalCards}</TableCell>}
                </TableRow>
                {groupBy === 'User' && (
                  <TableRow>
                    <TableCell colSpan={3} sx={{ paddingLeft: 4 }}>
                      <Typography variant="subtitle1" gutterBottom>Breakdown:</Typography>
                      <Typography variant="body2">
                        <strong>By Set:</strong>{" "}
                        {Object.entries(data.breakdown.set).map(([setName, count]) => (
                          <span key={setName}>{setName}: {count}, </span>
                        ))}
                      </Typography>
                      <Typography variant="body2">
                        <strong>By Type:</strong>{" "}
                        {Object.entries(data.breakdown.type).map(([type, count]) => (
                          <span key={type}>{type}: {count}, </span>
                        ))}
                      </Typography>
                      <Typography variant="body2">
                        <strong>By Ink:</strong>{" "}
                        {Object.entries(data.breakdown.ink).map(([ink, count]) => (
                          <span key={ink}>{ink}: {count}, </span>
                        ))}
                      </Typography>
                      <Typography variant="body2">
                        <strong>By Condition:</strong>{" "}
                        {Object.entries(data.breakdown.condition).map(([condition, count]) => (
                          <span key={condition}>{condition}: {count}, </span>
                        ))}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Total Listings Summary</Typography>

      <FormControl fullWidth margin="normal">
        <InputLabel id="group-by-label">Group By</InputLabel>
        <Select
          labelId="group-by-label"
          value={groupBy}
          onChange={(e) => setGroupBy(e.target.value)}
        >
          <MenuItem value="User">User</MenuItem>
          <MenuItem value="Set">Set</MenuItem>
          <MenuItem value="Rarity">Rarity</MenuItem>
          <MenuItem value="Ink">Ink Color</MenuItem>
          <MenuItem value="Type">Type</MenuItem>
        </Select>
      </FormControl>

      {/* Grouping Buttons */}
      <Grid container spacing={1} sx={{ marginTop: 2 }}>
        <Grid item>
          <Button
            variant={groupBy === 'User' ? 'contained' : 'outlined'}
            onClick={() => setGroupBy('User')}
          >
            Display by User
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={groupBy === 'Set' ? 'contained' : 'outlined'}
            onClick={() => setGroupBy('Set')}
          >
            Display by Set
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={groupBy === 'Rarity' ? 'contained' : 'outlined'}
            onClick={() => setGroupBy('Rarity')}
          >
            Display by Rarity
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={groupBy === 'Ink' ? 'contained' : 'outlined'}
            onClick={() => setGroupBy('Ink')}
          >
            Display by Ink Color
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={groupBy === 'Type' ? 'contained' : 'outlined'}
            onClick={() => setGroupBy('Type')}
          >
            Display by Type
          </Button>
        </Grid>
      </Grid>

      {listings.length === 0 ? <CircularProgress /> : renderTable()}
    </Container>
  );
};

export default AllListingsSummary;
