import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import Loading from './components/Loading';

// Mock function to simulate fetching rules from a Firestore collection
const fetchFirestoreRules = async () => {
  const rulesCollectionRef = collection(db, 'securityRules');
  const querySnapshot = await getDocs(rulesCollectionRef);
  let rulesText = '';
  
  querySnapshot.forEach((doc) => {
    rulesText += doc.data().ruleText + '\n';
  });

  return rulesText;
};

const parseRules = (rulesText) => {
  const permissions = {};
  const lines = rulesText.split('\n');

  lines.forEach((line) => {
    const match = line.match(/match \/(.*?) \{/);
    if (match) {
      const path = match[1].replace(/\/{.*?}/g, ''); // Remove placeholder parts like {userId}
      if (line.includes('allow read, write: if request.auth.uid == resource.data.uid;')) {
        permissions[path] = 'own-data-read-write';
      } else if (line.includes('allow read: if request.auth.uid == resource.data.uid;')) {
        permissions[path] = 'own-data-read';
      } else if (line.includes('allow write: if request.auth.uid == resource.data.uid;')) {
        permissions[path] = 'own-data-write';
      } else if (line.includes('allow read, write: if request.auth != null && request.auth.uid != resource.data.uid;')) {
        permissions[path] = 'other-users-data-read-write';
      } else if (line.includes('allow read: if request.auth != null && request.auth.uid != resource.data.uid;')) {
        permissions[path] = 'other-users-data-read';
      } else if (line.includes('allow write: if request.auth != null && request.auth.uid != resource.data.uid;')) {
        permissions[path] = 'other-users-data-write';
      } else if (line.includes('allow read, write: if request.auth != null;')) {
        permissions[path] = 'read-write';
      } else if (line.includes('allow read: if request.auth != null;')) {
        permissions[path] = 'read';
      } else if (line.includes('allow write: if request.auth != null;')) {
        permissions[path] = 'write';
      } else if (line.includes('allow create: if request.auth != null;')) {
        permissions[path] = 'authenticated-user';
      }
    }
  });

  return permissions;
};

const fetchCollectionData = async (collectionRef) => {
  const querySnapshot = await getDocs(collectionRef);
  const data = {};

  for (let docSnapshot of querySnapshot.docs) {
    data[docSnapshot.id] = docSnapshot.data();
  }

  return data;
};

const fetchAllFirestoreData = async () => {
  const topCollectionNames = ['bundlelistings', 'carts', 'sets', 'users', 'pendingUsers'];
  const data = {};

  for (let collectionName of topCollectionNames) {
    const collectionRef = collection(db, collectionName);
    data[collectionName] = await fetchCollectionData(collectionRef);
  }

  return data;
};

const renderDataAsText = (data, indent = 0) => {
  let text = '';
  const indentChar = '-';
  for (const key in data) {
    text += `${indentChar.repeat(indent)} ${key}:\n`;
    if (typeof data[key] === 'object' && data[key] !== null) {
      text += renderDataAsText(data[key], indent + 1);
    } else {
      text += `${indentChar.repeat(indent + 1)} ${data[key]}\n`;
    }
  }
  return text;
};

const TreeNode = ({ data, path, permissions, handlePermissionChange }) => {
  const permissionOptions = [
    'none',
    'read',
    'write',
    'read-write',
    'own-data-read-write',
    'own-data-read',
    'own-data-write',
    'other-users-data-read-write',
    'other-users-data-read',
    'other-users-data-write',
    'authenticated-user'
  ];

  return (
    <div style={{ marginLeft: '20px' }}>
      {Object.entries(data).map(([key, value]) => {
        const currentPath = path ? `${path}/${key}` : key;
        return (
          <div key={currentPath}>
            <label>
              {key}:
              <select
                onChange={(e) => handlePermissionChange(currentPath, e.target.value)}
                value={permissions[currentPath] || 'none'}
              >
                {permissionOptions.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </label>
            {typeof value === 'object' && value !== null ? (
              <TreeNode
                data={value}
                path={currentPath}
                permissions={permissions}
                handlePermissionChange={handlePermissionChange}
              />
            ) : (
              <span>{value}</span>
            )}
          </div>
        );
      })}
    </div>
  );
};

const updatePermissions = (data, path, permission, permissions) => {
  permissions[path] = permission;
  for (const key in data) {
    const currentPath = path ? `${path}/${key}` : key;
    if (typeof data[key] === 'object' && data[key] !== null) {
      updatePermissions(data[key], currentPath, permission, permissions);
    } else {
      permissions[currentPath] = permission;
    }
  }
  return permissions;
};

const generateRules = (data, path = '', permissions) => {
  let rules = '';
  for (const key in data) {
    const currentPath = path ? `${path}/${key}` : key;
    const permission = permissions[currentPath] || 'none';

    if (permission !== 'none') {
      rules += `match /${currentPath} {\n`;

      switch (permission) {
        case 'read':
          rules += `  allow read: if request.auth != null;\n`;
          break;
        case 'write':
          rules += `  allow write: if request.auth != null;\n`;
          break;
        case 'read-write':
          rules += `  allow read, write: if request.auth != null;\n`;
          break;
        case 'own-data-read-write':
          rules += `  allow read, write: if request.auth.uid == resource.data.uid;\n`;
          break;
        case 'own-data-read':
          rules += `  allow read: if request.auth.uid == resource.data.uid;\n`;
          break;
        case 'own-data-write':
          rules += `  allow write: if request.auth.uid == resource.data.uid;\n`;
          break;
        case 'other-users-data-read-write':
          rules += `  allow read, write: if request.auth != null && request.auth.uid != resource.data.uid;\n`;
          break;
        case 'other-users-data-read':
          rules += `  allow read: if request.auth != null && request.auth.uid != resource.data.uid;\n`;
          break;
        case 'other-users-data-write':
          rules += `  allow write: if request.auth != null && request.auth.uid != resource.data.uid;\n`;
          break;
        case 'authenticated-user':
          rules += `  allow read, write: if request.auth != null;\n`;
          break;
        default:
          break;
      }

      rules += `}\n`;
    }

    if (typeof data[key] === 'object' && data[key] !== null) {
      rules += generateRules(data[key], currentPath, permissions);
    }
  }
  return rules;
};

const FirestoreViewer = () => {
  const [data, setData] = useState({});
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const [rulesText, setRulesText] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firestoreData = await fetchAllFirestoreData();
        setData(firestoreData);

        const rules = await fetchFirestoreRules();
        const parsedPermissions = parseRules(rules);
        setPermissions(parsedPermissions);
      } catch (error) {
        console.error("Error fetching Firestore data: ", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const handlePermissionChange = (path, permission) => {
    setPermissions((prev) => updatePermissions(data, path, permission, { ...prev }));
  };

  const handleRulesTextChange = (event) => {
    setRulesText(event.target.value);
  };

  const handleApplyRules = () => {
    const newPermissions = parseRules(rulesText);
    setPermissions(newPermissions);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <h1>Firestore Data</h1>
      <pre>{renderDataAsText(data)}</pre>
      <div>
        <h2>Set Permissions</h2>
        <TreeNode
          data={data}
          path=""
          permissions={permissions}
          handlePermissionChange={handlePermissionChange}
        />
      </div>
      <div>
        <h2>Paste Existing Rules</h2>
        <textarea
          value={rulesText}
          onChange={handleRulesTextChange}
          placeholder="Paste your existing rules here"
          rows="10"
          cols="50"
        />
        <button onClick={handleApplyRules}>Apply Rules</button>
      </div>
      <div>
        <h2>Generated Firestore Rules</h2>
        <pre>{generateRules(data, '', permissions)}</pre>
      </div>
    </div>
  );
};

export default FirestoreViewer;
