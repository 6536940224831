import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth"; // Assuming you have an auth hook to manage authentication
import { signOut } from "firebase/auth";
import { auth, db } from "../firebase"; // Your Firebase setup
import { setDoc, doc } from "firebase/firestore";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Alert,
} from "@mui/material";
import TermsOfService from "./TermsOfService";

const TermsCheck = () => {
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [error, setError] = useState("");
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleAccept = async () => {
    if (!currentUser) {
      setError("User is not logged in.");
      return;
    }

    try {
      // Save the agreement to Firestore
      const userDocRef = doc(db, "users", currentUser.uid);
      await setDoc(
        userDocRef,
        {
          agreedToTerms: true,
          agreedAt: new Date(),
        },
        { merge: true }
      );

      // Redirect to home page after accepting
      navigate("/");
    } catch (error) {
      setError("Failed to save your agreement.");
      console.error("Error saving agreement: ", error);
    }
  };

  const handleCancel = async () => {
    // Log the user out
    await signOut(auth);
    navigate("/login"); // Redirect to login after logout
  };

  return (
    <Dialog open={true} fullScreen>
      <DialogTitle>Terms of Service</DialogTitle>
      <DialogContent dividers>
        <TermsOfService />
      </DialogContent>
      {error && <Alert severity="error">{error}</Alert>}
      <DialogActions>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreeToTerms}
              onChange={(e) => setAgreeToTerms(e.target.checked)}
            />
          }
          label="I agree to the Terms of Service"
        />
        <Button onClick={handleAccept} color="primary" disabled={!agreeToTerms}>
          Accept
        </Button>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsCheck;
