import React, { useEffect, useReducer, useState, useCallback } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  Avatar,
  Button,
  Rating,
  Divider,
  List,
  ListItem,
} from '@mui/material';
import { initialState, reducer } from '../marketplace/reducer';
import PublicProfileCardList from './PublicProfileCardList';
import { useAppBarContext } from '../components/AppBarContext';
import Loading from '../components/Loading';
import withAnalytics from '../hoc/withAnalytics';

const PublicProfile = ({ userId }) => {
  const [loading, setLoading] = useState(true);
  const [listingsLoading, setListingsLoading] = useState(true);
  const [fullListingsLoading, setFullListingsLoading] = useState(false);
  const [reviewsLoading, setReviewsLoading] = useState(true);
  const [showListings, setShowListings] = useState(false);
  const [showReviews, setShowReviews] = useState(false);
  const [userData, setUserData] = useState({});
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedCountry, exchangeRates } = useAppBarContext();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
        setLoading(false);
        fetchUserReviews();
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  const fetchUserListings = useCallback(async () => {
    try {
      setListingsLoading(true);
      setFullListingsLoading(true);
    
      const setsSnapshot = await getDocs(collection(db, 'sets'));
      const setsData = setsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    
      const cardsData = await Promise.all(
        setsData.map(async (set) => {
          const cardsSnapshot = await getDocs(collection(db, 'sets', set.id, 'cards'));
          const cards = await Promise.all(
            cardsSnapshot.docs.map(async (cardDoc) => {
              const cardData = {
                docId: cardDoc.id,
                setId: set.id,
                ...cardDoc.data(),
              };
    
              const listingsSnapshot = await getDocs(
                collection(db, 'sets', set.id, 'cards', cardDoc.id, 'listings')
              );
              
              const listingsData = listingsSnapshot.docs.reduce((acc, listingDoc) => {
                const conditions = listingDoc.data();
                Object.entries(conditions).forEach(([condition, listing]) => {
                  if (listing.userId === userId) {
                    acc.push({
                      condition,
                      ...listing,
                    });
                  }
                });
                return acc;
              }, []);
    
              cardData.listingsCount = listingsData.length;
              cardData.listings = listingsData;
              return cardData;
            })
          );
          return cards;
        })
      );
    
      dispatch({
        type: 'SET_DATA',
        payload: { cards: cardsData.flat(), loading: false },
      });
    
      setFullListingsLoading(false);
      setListingsLoading(false);
    } catch (error) {
      console.error('Error fetching user listings:', error);
      setFullListingsLoading(false);
      setListingsLoading(false);
    }
  }, [userId, dispatch]);
  
  useEffect(() => {
    fetchUserListings();
  }, [fetchUserListings]);
  
  

  const fetchUserReviews = async () => {
    try {
      setReviewsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate loading delay
      setReviewsLoading(false);
    } catch (error) {
      console.error('Error fetching user reviews:', error);
      setReviewsLoading(false);
    }
  };

  const convertPrice = (usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice;
    return `${(exchangeRates[selectedCountry] * usdPrice).toFixed(2)}`;
  };

  const averageRating =
    userData.reviews?.reduce((acc, review) => acc + review.rating, 0) /
      userData.reviews?.length || 0;

  return (
    <Container maxWidth="md" sx={{ padding: 2 }}>
      {loading ? (
        <Loading />
      ) : (
        <Paper elevation={3} sx={{ padding: 3, borderRadius: 3 }}>
          <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
            <Avatar
              src={userData.photoURL || ''}
              alt={userData.username || 'User'}
              sx={{ width: 100, height: 100, mb: 2, boxShadow: 3 }}
            />
            <Typography variant="h4" gutterBottom align="center">
              {userData.username || 'User'}
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              sx={{
                backgroundColor:
                  userData.verificationStatus === 'Verified' ? 'green' : 'red',
                color: 'white',
                padding: '6px 12px',
                borderRadius: '12px',
                mb: 2,
              }}
            >
              {userData.verificationStatus === 'Verified'
                ? 'Verified Account'
                : 'Not Verified'}
            </Typography>
          </Box>

          <Grid container spacing={2} justifyContent="center" alignItems="stretch">
            <Grid item xs={12} sm={6}>
              <Paper
                elevation={2}
                sx={{
                  textAlign: 'center',
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Rating & Reviews
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center">
                  {reviewsLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Rating value={averageRating} readOnly sx={{ mr: 1 }} />
                      <Typography variant="h4" color="primary">
                        {averageRating.toFixed(1) || 'N/A'}
                      </Typography>
                    </>
                  )}
                </Box>
                <Typography variant="caption" display="block" gutterBottom>
                  Based on {userData.reviews?.length || 0} reviews
                </Typography>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    setShowReviews(!showReviews);
                    setShowListings(false);
                  }}
                >
                  {showReviews ? 'Hide Reviews' : 'See Reviews'}
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                elevation={2}
                sx={{
                  textAlign: 'center',
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Listings
                </Typography>
                <Typography variant="h4" color="primary">
                  {listingsLoading ? <Loading /> : userData.listingCount || 0}
                </Typography>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    setShowListings(!showListings);
                    setShowReviews(false);
                  }}
                >
                  {showListings ? 'Hide Listings' : 'See Listings'}
                </Button>
              </Paper>
            </Grid>
          </Grid>

          {showListings && (
            <Box mt={3}>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="h5" gutterBottom align="center">
                Listings
              </Typography>
              {fullListingsLoading ? (
                <Loading />
              ) : (
                <PublicProfileCardList
                  cards={state.cards.filter((card) => card.listingsCount > 0)}
                  openListingModal={() => {}}
                  currentUser={{ uid: userId }}
                  convertPrice={convertPrice}
                />
              )}
            </Box>
          )}

{showReviews && !reviewsLoading && (
  <Box mt={3}>
    <Divider sx={{ mb: 2 }} />
    <Typography variant="h5" gutterBottom align="center">
      User Reviews
    </Typography>
    <List>
      {userData.reviews?.map((review, index) => (
        <ListItem key={index} sx={{ mb: 2 }}>
          <Paper elevation={2} sx={{ padding: 2, width: '100%', borderRadius: 2 }}>
            <Box display="flex" alignItems="center" mb={1}>
              <Rating value={review.rating} readOnly precision={0.5} sx={{ mr: 2 }} />
              <Typography variant="h6" color="primary">
                {review.rating.toFixed(1)}
              </Typography>
            </Box>
            <Typography variant="body1" gutterBottom>
              {review.comment}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Purchase ID: {review.purchaseId}
            </Typography>
          </Paper>
        </ListItem>
      ))}
    </List>
  </Box>
)}

        </Paper>
      )}
    </Container>
  );
};

export default withAnalytics(PublicProfile);
