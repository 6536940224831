import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Tabs,
  Tab,
  Link,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ForgotPassword from './ForgotPassword';
import RegistrationForm from './RegistrationForm';

const LoginDialog = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [googleUserData, setGoogleUserData] = useState(null); // Store Google user data
  const [isNewUser, setIsNewUser] = useState(false); // Track if the user is new
  
  const navigate = useNavigate();
  const theme = useTheme(); // Access theme
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detect if screen is small

  const handleShowForgotPassword = () => {
    setShowForgotPassword(true);
  };

  const handleCancelForgotPassword = () => {
    setShowForgotPassword(false);
  };

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        navigate('/');
      } else {
        navigate('/registration');
      }
      onClose();
    } catch (error) {
      console.error('Error logging in with email and password', error);
      alert('Login failed. Please check your email and password.');
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    
    try {
      // Sign in with Google
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      // Check if the user already exists in the Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      
      if (!userDoc.exists()) {
        // If the user does not exist, generate a random username
        const randomUsername = `u${Math.floor(100000000 + Math.random() * 900000000)}`; // Generates a random 9-digit number after 'u'
  
        // Register the user using their Firebase uid
        await setDoc(doc(db, 'users', user.uid), {
          email: user.email,
          displayName: user.displayName,
          firstName: user.displayName.split(' ')[0],
          lastName: user.displayName.split(' ')[1] || '',
          username: randomUsername,
          createdAt: new Date(),
        });
      }
  
      // Navigate to the home page or dashboard
      navigate('/');
      onClose(); // Close the dialog
    } catch (error) {
      console.error('Error logging in with Google', error);
      alert('Google login failed. Please try again.');
    }
  };
  
  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen}>
      <DialogContent>
        {showForgotPassword ? (
          <ForgotPassword onCancel={handleCancelForgotPassword} />
        ) : (
          <>
            <Tabs value={tabValue} onChange={handleTabChange} centered>
              <Tab label="Login" />
              <Tab label="Register" />
            </Tabs>

            {tabValue === 0 && (
              <Box component="form" onSubmit={handleEmailLogin}>
                <TextField
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  required
                  margin="normal"
                />
                <TextField
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  required
                  margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Login with Email
                </Button>
                <Link
                  component="button"
                  variant="body2"
                  onClick={handleShowForgotPassword}
                  sx={{ mt: 2, display: 'block', textAlign: 'center' }}
                >
                  Forgot My Password
                </Link>
                <Divider sx={{ my: 2 }}>or</Divider>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleGoogleLogin}
                >
                  Login with Google
                </Button>
              </Box>
            )}

            {tabValue === 1 && (
              <RegistrationForm 
                onClose={onClose} 
                googleUserData={isNewUser ? googleUserData : null} 
              />
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginDialog;
