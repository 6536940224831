import React from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Box, Stack, Typography, Chip, Button } from '@mui/material';
import { inkColorMap, rarityIcons } from './constants';
import CustomAdListItemMobile from '../AdSense/CustomAdListItemMobile';

const PublicMobileCardList = ({ cards, handleCardClick, convertPrice }) => {
  return (
    <List>
      {cards.map((card, index) => (
        <React.Fragment key={card.docName}>
          <ListItem sx={{ border: '2px solid #0C0A5A', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <ListItemAvatar sx={{ width: 100 }}>
                <Avatar
                  className={card.type === 'Foil' ? 'foil-avatar' : ''}
                  variant="square"
                  src={card.image_uris?.digital?.small || card.image_uris?.digital?.large}
                  sx={{
                    height: '20%',
                    width: '80%',
                    objectFit: 'contain',
                    border: `4px solid ${inkColorMap[card.ink] || '#F2D18B'}`,
                  }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'fallback_image_url';
                  }}
                />
                {card.type === 'Foil' && (
                  <Avatar
                    variant="square"
                    sx={{
                      width: 76,
                      height: 10,
                      bgcolor: '#ffd700',
                      border: '2px solid #000000',
                      color: '#000000',
                      bottom: 18,
                      left: 4,
                    }}
                  >
                    <Typography variant="h6" sx={{ fontSize: '10px' }}>
                      COLD FOIL
                    </Typography>
                  </Avatar>
                )}
              </ListItemAvatar>
              <Stack spacing={1} sx={{ ml: 2 }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Avatar variant="square" sx={{ bgcolor: '#0C0A5A' }}>
                    {card.collector_number}
                  </Avatar>
                  <Avatar variant="square" sx={{ bgcolor: '#0C0A5A' }}>
                    <img
                      src={rarityIcons[card.rarity]}
                      alt={card.rarity}
                      style={{ width: 24, height: 24 }}
                    />
                  </Avatar>
                </Stack>
                <ListItemText
                  primary={<Typography variant="body1">{card.name}</Typography>}
                  secondary={
                    card.version && (
                      <Typography variant="body2" color="textSecondary">
                        {card.version}
                      </Typography>
                    )
                  }
                />
              </Stack>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 2 }}>
              <Chip
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': { display: 'block', whiteSpace: 'normal' },
                  bgcolor: "secondary.main",
                  color: 'white',
                  borderRadius: "4px",
                  cursor: 'pointer',
                }}
                label={
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <span>Listings</span>
                    {card.listingsCount || 0}
                  </Box>
                }
                onClick={() =>
                  handleCardClick({
                    setId: card.setId,
                    docName: card.docName,
                  })
                }
                clickable
              />
              <Chip
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': { display: 'block', whiteSpace: 'normal' },
                  bgcolor: "green",
                  color: 'white',
                  cursor: 'pointer',
                  borderRadius: "4px",
                  mt: 1,
                }}
                label={
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <span>TCGP Price</span>
                    {convertPrice(card.price || 0)}
                  </Box>
                }
              />
              <Button size="small" onClick={() => handleCardClick({ setId: card.setId, docName: card.docName })} sx={{ mt: 1 }}>
                Card Info
              </Button>
            </Box>
          </ListItem>

          {/* Insert an ad every 5 items */}
          {index % 5 === 4 && (
            <ListItem sx={{ width: '100%' }}>
              <CustomAdListItemMobile
                title="Enchanted Elsa? Sell it here!"
                subtitle="List your custom items or advertise your business here!"
                text="Get in touch for info!"
                actionText="Click Here"
                onActionClick={() => window.open('https://buylorcana.cards/contact', '_blank')}
              />
            </ListItem>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default PublicMobileCardList;
