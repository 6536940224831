import React, { useRef, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Box, Typography, IconButton, Tooltip } from '@mui/material';
import ReactWebcam from 'react-webcam';
import DeleteIcon from '@mui/icons-material/Delete';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';

const CameraDialog = ({ open, onClose, onCapture, capturedImages, onRemoveImage }) => {
  const webcamRef = useRef(null);
  const [facingMode, setFacingMode] = useState('environment'); 

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    onCapture(imageSrc); // Pass the captured image directly to the parent
  };

  const handleSwitchCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <ReactWebcam
          ref={webcamRef}
          screenshotFormat="image/png"
          style={{ width: "100%", height: "auto" }}
          videoConstraints={{
            facingMode: facingMode,
          }}
        />
        <Box mt={2} display="flex" justifyContent="center" alignItems="center">
          <Button onClick={handleCapture} variant="contained">
            Capture
          </Button>
          <Tooltip title="Switch Camera" placement="top">
            <IconButton onClick={handleSwitchCamera} color="primary" sx={{ ml: 2 }}>
              <CameraswitchIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Display captured images */}
        {capturedImages.length > 0 && (
          <Box mt={2}>
            <Typography variant="body2">Captured Photos:</Typography>
            <Box display="flex" flexWrap="wrap">
              {capturedImages.map((image, index) => (
                <Box key={index} position="relative" m={1}>
                  <img
                    src={image}
                    alt={`Captured ${index}`}
                    style={{
                      width: "100px",
                      height: "auto",
                      border: "1px solid #ccc",
                    }}
                  />
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => onRemoveImage(index)}
                    style={{ position: "absolute", top: 0, right: 0 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="secondary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CameraDialog;
