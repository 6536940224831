import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const PaymentDetailsDialog = ({
  open,
  onClose,
  paymentOption,
  total,
  shippingCost,
  paymentFee,
  totalCost,
}) => {

  const details = paymentOption?.details;

  // Utility function to format the payment method and field names
  const formatText = (text) => {
    if (!text) return "N/A";
    return text
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Separate fee-related fields to display at the bottom
  const fees = {};
  const mainDetails = {};

  if (typeof details === "object" && details !== null) {
    Object.keys(details).forEach((key) => {
      if (key === "fee" || key === "feeType") {
        fees[key] = details[key];
      } else {
        mainDetails[key] = details[key];
      }
    });
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Payment Details</DialogTitle>
      <DialogContent>
        {/* Display Payment Method */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Payment Method:</Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {formatText(paymentOption?.method)}
          </Typography>
        </Box>

        {/* Display Seller's Payment Details */}
        {typeof details === "string" ? (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Seller's Payment Details:</Typography>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography variant="body2">{details}</Typography>
              <Tooltip title="Copy to clipboard">
                <IconButton
                  onClick={() => navigator.clipboard.writeText(details)}
                  size="small"
                  color="primary"
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        ) : typeof details === "object" && details !== null ? (
          <>
            <Typography variant="h6">Seller's Payment Details:</Typography>
            {Object.keys(mainDetails).map((key) => (
              <Box
                key={key}
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold", mr: 1 }}>
                  {formatText(key)}:
                </Typography>
                <Typography variant="body2">{mainDetails[key]}</Typography>
                {key !== "fee" && key !== "feeType" && (
                  <Tooltip title="Copy to clipboard">
                    <IconButton
                      onClick={() =>
                        navigator.clipboard.writeText(mainDetails[key])
                      }
                      size="small"
                      color="primary"
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            ))}
          </>
        ) : (
          <Typography variant="body2">No payment details available.</Typography>
        )}

        {/* Display fees separately */}
        {Object.keys(fees).length > 0 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">Fees:</Typography>
            {Object.keys(fees).map((key) => (
              <Box
                key={key}
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold", mr: 1 }}>
                  {formatText(key)}:
                </Typography>
                <Typography variant="body2">{fees[key]}</Typography>
              </Box>
            ))}
          </Box>
        )}

        {/* Display Totals */}
        <Box sx={{ mt: 4, textAlign: "right" }}>
          <Typography variant="h6">Totals:</Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Items Total:
            </Typography>
            <Typography variant="body2">${(total ?? 0).toFixed(2)}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Shipping Cost:
            </Typography>
            <Typography variant="body2">
              ${(shippingCost ?? 0).toFixed(2)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Payment Fee:
            </Typography>
            <Typography variant="body2">
              ${(paymentFee ?? 0).toFixed(2)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Grand Total:
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" sx={{ mr: 1 }}>
                ${(totalCost ?? 0).toFixed(2)}
              </Typography>
              <Tooltip title="Copy to clipboard">
                <IconButton
                  onClick={() =>
                    navigator.clipboard.writeText(totalCost?.toFixed(2))
                  }
                  size="small"
                  color="primary"
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentDetailsDialog;
