import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  InputAdornment,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { doc, getDoc, setDoc, deleteDoc, updateDoc, deleteField } from 'firebase/firestore';
import { db } from '../firebase';
import { conditionOptions } from './constants';
import { useAppBarContext } from '../components/AppBarContext';
import Loading from '../components/Loading';
import FeatureControl from '../context/FeatureControl'; // Import FeatureControl

const ListingModal = ({ open, onClose, card, currentUser, updateListings, tcgPrice, convertPrice, currentListingCount }) => {
  const { selectedCountry, exchangeRates } = useAppBarContext();

  const [condition, setCondition] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState('');
  const [percentage, setPercentage] = useState(100);
  const [loading, setLoading] = useState(false);
  const [autoUpdatePrice, setAutoUpdatePrice] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [existingListing, setExistingListing] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (open && condition) {
      setLoading(true);
      const fetchListing = async () => {
        const listingDoc = await getDoc(doc(db, 'sets', card.setId, 'cards', card.docId, 'listings', currentUser.uid));
        if (listingDoc.exists()) {
          const listingData = listingDoc.data()[condition];
          if (listingData) {
            setExistingListing(true); // Set this to true if listing exists
            setQuantity(listingData.quantity || 0);
            const exchangeRate = exchangeRates[selectedCountry] || 1;
            const convertedPrice = (listingData.price * exchangeRate).toFixed(2);
            setPrice(convertedPrice);
            setAutoUpdatePrice(!!listingData.autoUpdatePrice);
            setPercentage(listingData.percentage || 100);
          } else {
            setExistingListing(false); // If no listing for the condition, it’s new
            setQuantity(0);
            setPrice('');
          }
        } else {
          setExistingListing(false); // Listing does not exist
          setQuantity(0);
          setPrice('');
        }
        setLoading(false);
      };
      fetchListing();
    }
  }, [open, card, currentUser.uid, condition, exchangeRates, selectedCountry]);

  const handleSave = async () => {
    setLoading(true);
    const listingRef = doc(db, 'sets', card.setId, 'cards', card.docId, 'listings', currentUser.uid);
    let listingData = {};

    try {
      const listingDoc = await getDoc(listingRef);
      if (listingDoc.exists()) {
        listingData = listingDoc.data();
      }

      const exchangeRate = exchangeRates[selectedCountry] || 1;
      const priceInUSD = (parseFloat(price) / exchangeRate).toFixed(2);

      if (quantity === 0) {
        await updateDoc(listingRef, {
          [condition]: deleteField(),
        });
      } else {
        listingData[condition] = {
          userId: currentUser.uid,
          condition,
          quantity,
          price: priceInUSD,
          autoUpdatePrice,
          percentage,
        };
        await setDoc(listingRef, listingData, { merge: true });
      }

      const updatedDoc = await getDoc(listingRef);
      const updatedData = updatedDoc.exists() ? updatedDoc.data() : {};

      if (Object.keys(updatedData).length === 0) {
        await deleteDoc(listingRef);
      }

      updateListings(card.docId, updatedData);
    } catch (error) {
      console.error("Error saving or deleting listing:", error);
    }

    setLoading(false);
    onClose();
  };

  const handleDelete = async () => {
    setLoading(true);
    const listingRef = doc(db, 'sets', card.setId, 'cards', card.docId, 'listings', currentUser.uid);

    try {
      const listingDoc = await getDoc(listingRef);
      if (listingDoc.exists()) {
        const listingData = listingDoc.data();

        if (condition in listingData) {
          await updateDoc(listingRef, {
            [condition]: deleteField(),
          });

          const updatedDoc = await getDoc(listingRef);
          const updatedData = updatedDoc.exists() ? updatedDoc.data() : {};

          if (Object.keys(updatedData).length === 0) {
            await deleteDoc(listingRef);
          }

          updateListings(card.docId, updatedData);
        }
      }
    } catch (error) {
      console.error("Error deleting condition:", error);
    }

    setLoading(false);
    onClose();
  };

  const handlePercentageChange = (e) => {
    const newPercentage = Math.max(0, parseInt(e.target.value, 10));
    setPercentage(newPercentage);
  };

  const handleFillPrice = () => {
    const calculatedPrice = ((tcgPrice || 0) * (percentage / 100)).toFixed(2);
    const convertedPrice = convertPrice(calculatedPrice || 0);
    const numericPrice = convertedPrice.replace(/[^0-9.]/g, ''); 
    setPrice(numericPrice);
  };

  const handleConfirmDelete = () => {
    setConfirmOpen(true);
  };

  const handleConfirmClose = (confirmed) => {
    setConfirmOpen(false);
    if (confirmed) {
      handleDelete();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth fullScreen={isMobile}>
      <DialogTitle>
        {card.name}
        <IconButton
          aria-label="delete"
          color="error"
          onClick={handleConfirmDelete}
          disabled={loading || !condition}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <DeleteIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Loading />
        ) : (
          <Box>
            <FormControl fullWidth margin="normal">
              <InputLabel>Condition</InputLabel>
              <Select
                value={condition}
                onChange={(e) => setCondition(e.target.value)}
              >
                {conditionOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option.charAt(0).toUpperCase() +
                      option.slice(1).replace(/_/g, " ")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Use FeatureControl to restrict adding new listings */}
            <FeatureControl
              requiredFeatures={['5 Active Listings', 'Unlimited Listings']}
              currentQuantity={currentListingCount}
              showMessage={false}
            >
              {({ limit }) => (
                <>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Quantity"
                    type="number"
                    value={quantity}
                    onChange={(e) =>
                      setQuantity(Math.max(0, parseInt(e.target.value, 10)))
                    }
                    // Disable only if it's a new listing and the limit is reached
                    disabled={!existingListing && currentListingCount >= limit}
                  />

                  {/* Display a warning message only if it's a new listing and limit is reached */}
                  {!existingListing && currentListingCount >= limit && (
                    <Typography variant="caption" color="error">
                      You have reached the maximum allowed listings for your plan. Upgrade your plan or delete existing listings to add more.
                    </Typography>
                  )}
                </>
              )}
            </FeatureControl>

            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <TextField
                label="Percentage of TCG Price"
                type="number"
                value={percentage}
                onChange={handlePercentageChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                sx={{ width: "35%", mr: 2 }}
              />
              <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
                Total:{" "}
                {convertPrice(
                  ((tcgPrice || 0) * (percentage / 100)).toFixed(2)
                )}
              </Typography>
              <Button
                variant="outlined"
                onClick={handleFillPrice}
                disabled={autoUpdatePrice}
              >
                Fill Price
              </Button>
            </Box>

            <TextField
              fullWidth
              margin="normal"
              label="Price"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              disabled={autoUpdatePrice}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoUpdatePrice}
                  onChange={(e) => setAutoUpdatePrice(e.target.checked)}
                />
              }
              label="Auto Update Price from TCGP"
            />
            <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
              WARNING! Ticking this box will automatically update the price
              based on the TCGPlayer Market Price. This is updated once daily.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          color="primary"
          disabled={loading || (!condition && !existingListing)}
        >
          Save
        </Button>
      </DialogActions>

      {/* Confirmation Dialog */}
      <Dialog open={confirmOpen} onClose={() => handleConfirmClose(false)}>
        <DialogTitle>Delete Listing</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the {condition} listing? This action
          cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmClose(false)}>Cancel</Button>
          <Button onClick={() => handleConfirmClose(true)} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default ListingModal;
