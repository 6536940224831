import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';

// ISO 3166 country codes mapped to currency codes
const currencyCountryCodes = {
  AUD: 'au',
  USD: 'us',
  EUR: 'eu',
  GBP: 'gb',
  JPY: 'jp',
};

const CompactCurrencyPicker = ({ selectedCountry, setSelectedCountry }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageError, setImageError] = useState(false); // State to track if the flag image failed to load

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (currency) => {
    setSelectedCountry(currency);
    setAnchorEl(null);
    setImageError(false); // Reset image error when a new currency is selected
  };

  const handleImageError = () => {
    setImageError(true); // Set image error to true when the image fails to load
  };

  return (
    <>
      <Tooltip title="Select Currency">
        <IconButton
          onClick={handleClick}
          sx={{
            color: 'white',
            padding: { xs: '4px', sm: '8px' },  // Adjust padding for mobile
          }}
        >
          {/* Display the selected country's flag with a fallback to the country code */}
          {!imageError ? (
            <img
              src={`https://flagcdn.com/w40/${currencyCountryCodes[selectedCountry]}.png?${Date.now()}`} // Add query to force reload
              srcSet={`https://flagcdn.com/w80/${currencyCountryCodes[selectedCountry]}.png 2x,
                       https://flagcdn.com/w120/${currencyCountryCodes[selectedCountry]}.png 3x`}
              height="20"
              width="30"
              alt={`${selectedCountry} flag`}
              onError={handleImageError} // Fallback to text if the image fails to load
              style={{
                border: '1px solid white',  // Add white border around the flag
                borderRadius: '2px',         // Optional: make the corners slightly rounded
                display: 'block',            // Ensure the image is a block element
                minWidth: '30px',            // Ensure a minimum width in mobile view
                minHeight: '20px',           // Ensure a minimum height in mobile view
              }}
            />
          ) : (
            <span style={{ color: 'white', fontSize: '16px', textTransform: 'uppercase' }}>
              {selectedCountry}
            </span>
          )}
        </IconButton>
      </Tooltip>

      {/* Menu for selecting currency/language */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          sx: {
            maxHeight: { xs: '200px', sm: '300px' }, // Limit height for mobile and desktop
            minWidth: { xs: '100px', sm: '200px' },  // Adjust width for mobile and desktop
          },
        }}
      >
        {Object.keys(currencyCountryCodes).map((currency) => (
          <MenuItem
            key={currency}
            selected={currency === selectedCountry} // Highlight the currently selected currency
            onClick={() => handleClose(currency)}
            sx={{
              fontSize: { xs: '0.9rem', sm: '1rem' },  // Smaller font for mobile
              padding: { xs: '6px 12px', sm: '8px 16px' }, // Adjust padding for mobile
            }}
          >
            {/* Render flag image next to the currency code with a white border, fallback to currency code if image fails */}
            <img
              src={`https://flagcdn.com/w40/${currencyCountryCodes[currency]}.png?${Date.now()}`} // Add query to force reload
              srcSet={`https://flagcdn.com/w80/${currencyCountryCodes[currency]}.png 2x,
                       https://flagcdn.com/w120/${currencyCountryCodes[currency]}.png 3x`}
              height="20"
              width="30"
              alt={`${currency} flag`}
              onError={(e) => e.target.style.display = 'none'} // Hide the image if it fails to load
              style={{
                border: '1px solid white',  // Add white border around the flag
                borderRadius: '2px',        // Optional: make the corners slightly rounded
                marginRight: '8px',         // Add margin for spacing next to the currency code
                display: 'block',           // Ensure image is a block element
                minWidth: '30px',           // Ensure a minimum width in mobile view
                minHeight: '20px',          // Ensure a minimum height in mobile view
              }}
            />
            <span>{currency}</span> {/* Display the currency code */}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CompactCurrencyPicker;
