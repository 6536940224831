import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/system";
import { Box, Button, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

// Styled input and button styles
const StyledInputWithAdornment = styled("div")`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;

  input {
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.375;
    color: black;
    background: #fff;
    border: 1px solid grey;
    border-radius: 8px;
    padding: 10px 12px;
    width: 4rem;
    text-align: center;

    &:hover {
      border-color: blue;
    }

    &:focus {
      border-color: blue;
      box-shadow: 0 0 0 3px lightblue;
    }

    &:focus-visible {
      outline: 0;
    }
  }

  .adornment {
    position: absolute;
    left: 10px;
    font-size: 0.875rem;
    color: grey;
  }
`;

// Number pad button style
const NumPadButton = styled(Button)`
  width: 50px;
  height: 50px;
  margin: 5px;
  font-size: 1.25rem;
  background-color: #f0f0f0;

  &:hover {
    background-color: blue;
    color: white;
  }
`;

// Green tick button style
const GreenTickButton = styled(IconButton)`
  color: green;
  &:hover {
    background-color: lightgreen;
  }
`;

// Red X button style
const RedCancelButton = styled(IconButton)`
  color: red;
  &:hover {
    background-color: lightcoral;
  }
`;

const PriceInputWithNumberPad = ({ price, onPriceChange }) => {
  const [localPrice, setLocalPrice] = useState(price || ""); // Initialize with price or empty string
  const [showNumberPad, setShowNumberPad] = useState(false);
  const inputRef = useRef(null); // To track the input field focus
  const numPadRef = useRef(null); // To track the number pad focus

  useEffect(() => {
    setLocalPrice(price); // Keep the input updated if the prop changes
  }, [price]);

  const handleKeypadClick = (value) => {
    setLocalPrice((prev) => {
      if (value === "clear") {
        return ""; // Clear the input
      } else if (value === "." && !prev.includes(".")) {
        return prev + value; // Add decimal point if not already present
      } else if (!isNaN(value)) {
        return prev + value; // Add the numeric value
      }
      return prev;
    });
  };

  const handleFinishInput = () => {
    const numericPrice = parseFloat(localPrice);
    if (!isNaN(numericPrice)) {
      onPriceChange(numericPrice); // Send the numeric value
    } else {
      onPriceChange(0); // If invalid, send 0
    }
    setShowNumberPad(false); // Hide the number pad
  };

  const handleCancelInput = () => {
    // Reset input and hide the number pad
    setLocalPrice(price); // Reset to the original price
    setShowNumberPad(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="left" gap={2}>
      {/* Input field */}
      <StyledInputWithAdornment>
        <input
          ref={inputRef} // Track input reference
          value={localPrice}
          onChange={(e) => setLocalPrice(e.target.value)}
          type="text" // Keep as text to handle decimals and empty values
          onFocus={() => setShowNumberPad(true)} // Show number pad on focus
        />
        <span className="adornment">$</span>
      </StyledInputWithAdornment>

      {/* Number Pad, shown only when the input is focused */}
      {showNumberPad && (
        <Box
          ref={numPadRef}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box display="flex" justifyContent="center">
            <NumPadButton onClick={() => handleKeypadClick("1")}>
              1
            </NumPadButton>
            <NumPadButton onClick={() => handleKeypadClick("2")}>
              2
            </NumPadButton>
            <NumPadButton onClick={() => handleKeypadClick("3")}>
              3
            </NumPadButton>
          </Box>
          <Box display="flex" justifyContent="center">
            <NumPadButton onClick={() => handleKeypadClick("4")}>
              4
            </NumPadButton>
            <NumPadButton onClick={() => handleKeypadClick("5")}>
              5
            </NumPadButton>
            <NumPadButton onClick={() => handleKeypadClick("6")}>
              6
            </NumPadButton>
          </Box>
          <Box display="flex" justifyContent="center">
            <NumPadButton onClick={() => handleKeypadClick("7")}>
              7
            </NumPadButton>
            <NumPadButton onClick={() => handleKeypadClick("8")}>
              8
            </NumPadButton>
            <NumPadButton onClick={() => handleKeypadClick("9")}>
              9
            </NumPadButton>
          </Box>
          <Box display="flex" justifyContent="center">
            <NumPadButton onClick={() => handleKeypadClick("clear")}>
              Clear
            </NumPadButton>
            <NumPadButton onClick={() => handleKeypadClick("0")}>
              0
            </NumPadButton>
            <NumPadButton onClick={() => handleKeypadClick(".")}>
              .
            </NumPadButton>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            {/* Red X button for canceling input */}
            <RedCancelButton onClick={handleCancelInput}>
              <CancelIcon fontSize="large" />
            </RedCancelButton>

            {/* Green tick button for finishing input */}
            <GreenTickButton onClick={handleFinishInput}>
              <CheckCircleIcon fontSize="large" />
            </GreenTickButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PriceInputWithNumberPad;
