import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, FormControl, Select, MenuItem, Link, Dialog, DialogTitle, DialogContent, IconButton, useMediaQuery, useTheme, Button, Card, CardContent, CardActions, Grid, CardHeader
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import SortIcon from '@mui/icons-material/Sort';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import PublicProfile from '../pages/PublicProfile';
import { db } from '../firebase';
import { getDocs, collection } from 'firebase/firestore';
import withAnalytics from '../hoc/withAnalytics';

const PublicListings = ({ cardId, setId, sortBy, filterCondition, convertPrice, handleSortChange, handleFilterChange, card }) => {
  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [listings, setListings] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = (userId) => {
    setSelectedUserId(userId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUserId(null);
  };

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const listingsSnapshot = await getDocs(collection(db, 'sets', setId, 'cards', cardId, 'listings'));
        const listingsData = listingsSnapshot.docs.flatMap(doc =>
          Object.entries(doc.data()).map(([condition, data]) => ({
            ...data,
            docName: doc.id,
            condition,
          }))
        );
        setListings(listingsData);
      } catch (error) {
        console.error('Error fetching listings:', error);
      }
    };

    fetchListings();
  }, [cardId, setId]);

  const sortedListings = listings.sort((a, b) => {
    if (sortBy === 'price') {
      return a.price - b.price;
    }
    return 0;
  });

  const filteredListings = sortedListings.filter(listing => {
    if (filterCondition === '') {
      return true;
    }
    return listing.condition === filterCondition;
  });

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h4" gutterBottom>Listings ({filteredListings.length})</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <FormControl sx={{ minWidth: 120 }}>
          <Select
            value={sortBy}
            displayEmpty
            inputProps={{ 'aria-label': 'Sort By' }}
            disabled={listings.length === 0}
            onChange={handleSortChange}
            startAdornment={<SortIcon />}
          >
            <MenuItem value="price">Price</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 120 }}>
          <Select
            value={filterCondition}
            displayEmpty
            inputProps={{ 'aria-label': 'Filter By Condition' }}
            disabled={listings.length === 0}
            onChange={handleFilterChange}
            startAdornment={<FilterListIcon />}
          >
            <MenuItem value="">All Conditions</MenuItem>
            <MenuItem value="mint">Mint</MenuItem>
            <MenuItem value="near_mint">Near Mint</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {isMobile ? (
        <Grid container spacing={2}>
          {filteredListings.map((listing, index) => {
            const finalPrice = listing.autoUpdatePrice 
              ? (card.price * (listing.percentage / 100)).toFixed(2) 
              : listing.price;
            const displayPrice = convertPrice(finalPrice || 0);
      return (
        <Grid item xs={12} key={index}>
          <Card>
            <CardHeader
              title={
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <Typography variant="h6">Seller:</Typography>
                  <Link
                    onClick={() => alert('Sign in required to view seller profile')}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography variant="h6">
                      <PersonIcon fontSize="small" sx={{ mr: 0.5 }} />{" "}
                      <Box component="span" sx={{ filter: 'blur(5px)' }}>
                        Hidden Seller
                      </Box>
                    </Typography>
                  </Link>
                </Box>
              }
              sx={{ paddingBottom: 0, backgroundColor: "rgba(0, 0, 0, 0.04)" }}
            />
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  gap: 1,
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {displayPrice}
                </Typography>

                <Typography variant="h6">Qty: {listing.quantity}</Typography>
                <Typography variant="body2">
                  Condition: {listing.condition}
                </Typography>
              </Box>
            </CardContent>

            <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
                      startIcon={<ShoppingCartIcon />}
                      onClick={() => alert('Sign in required to add to cart')}
                      sx={{ color: 'grey' }}
                    >
                      Sign in required
                    </Button>
            </CardActions>
          </Card>
        </Grid>
      );
    })}
  </Grid>
) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Seller</TableCell>
              <TableCell>Condition</TableCell>
              <TableCell>Qty Available</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Add to Cart</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredListings.map((listing, index) => {
              const finalPrice = listing.autoUpdatePrice 
                ? (card.price * (listing.percentage / 100)).toFixed(2) 
                : listing.price;
              const displayPrice = convertPrice(finalPrice || 0);

              return (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="body1">
                      <PersonIcon fontSize="small" /> 
                      <Box component="span" sx={{ filter: 'blur(5px)' }}>
                        Hidden Seller
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell>{listing.condition}</TableCell>
                  <TableCell>{listing.quantity}</TableCell>
                  <TableCell>{displayPrice}</TableCell>
                  <TableCell>
                    <ShoppingCartIcon
                      sx={{ cursor: 'pointer', color: 'grey' }}
                      onClick={() => alert('Sign in required to add to cart')}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedUserId && <PublicProfile userId={selectedUserId} />}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default withAnalytics(PublicListings);
