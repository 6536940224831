import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box, Grid, Paper, Dialog, DialogTitle, DialogActions, DialogContent, FormControlLabel, Checkbox, useMediaQuery, useTheme } from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import heroImage from '../assets/hero-image.jpg';
import '../App.css';
import Joyride from 'react-joyride';
import { useAuth } from '../auth';
import { db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [run, setRun] = useState(false); // Initially not running the tour
  const [openDialog, setOpenDialog] = useState(false); // Dialog state
  const [dontShowAgain, setDontShowAgain] = useState(false); // Track if the user doesn't want to see the dialog again

  const steps = [
    {
      target: '.marketplace-button',
      content: 'Click here to view the marketplace where you can buy or sell cards.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.inventory-button',
      content: 'Manage your inventory by clicking here.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.sales-button',
      content: 'View all your sales here.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.purchases-button',
      content: 'See your purchases by clicking this button.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.collection-button',
      content: 'In My Collection, you can add your cards you already have and also add cards to your Wishlist.',
      placement: 'bottom',
      disableBeacon: true,
    },
    {
      target: '.profile-button',
      content: 'All of your preferences are in here, for buying, selling, notifications etc.',
      placement: 'bottom',
      disableBeacon: true,
    },
  ];

  useEffect(() => {
    const fetchUserPreference = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          if (userData.dontShowWelcomeDialog) {
            setOpenDialog(false);
          } else {
            setOpenDialog(true);
          }
        } else {
          setOpenDialog(true);
        }
      }
    };

    fetchUserPreference();
  }, [currentUser]);

  const handleStartTour = async () => {
    if (currentUser) {
      const userRef = doc(db, 'users', currentUser.uid);
      await setDoc(userRef, { dontShowWelcomeDialog: dontShowAgain }, { merge: true });
    }
    setOpenDialog(false); // Close the dialog
    setRun(true); // Start the Joyride tour
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (status === 'finished' || status === 'skipped') {
      setRun(false); // Stop the tour when it's finished or skipped
    }
  };

  const handleCheckboxChange = async (event) => {
    const value = event.target.checked;
    setDontShowAgain(value); // Update state
  
    if (currentUser) {
      const userRef = doc(db, 'users', currentUser.uid);
      await setDoc(userRef, { dontShowWelcomeDialog: value }, { merge: true }); // Update Firestore
    }
  };
  

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "100vh",
        width: "100%",
        backgroundImage: `url(${heroImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        textAlign: "center",
        overflow: "hidden",
        margin: 0,
        padding: 0,
      }}
    >
      <Joyride
        steps={steps}
        run={run}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        locale={{
          last: 'Finish',
        }}
        styles={{
          options: {
            zIndex: 10000,
            arrowColor: '#fff', // Color of arrow
            backgroundColor: '#555', // Background color of the tooltip
            textColor: '#fff', // Text color
            overlayColor: 'rgba(0, 0, 0, 0.4)', // Overlay color
          },
        }}
      />

      {/* Welcome Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="welcome-dialog-title"
      >
        <DialogTitle id="welcome-dialog-title">Welcome to Lorcana Card Marketplace!</DialogTitle>
        <DialogContent>
          <Typography>
            First time here? Click below for a quick tour of the main features or jump straight in and explore!
          </Typography>
          

        </DialogContent>
        <DialogActions>
        <FormControlLabel
            control={
              <Checkbox
                checked={dontShowAgain}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label="Don't show this again"
          />
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Close
          </Button>
          <Button onClick={handleStartTour} color="primary" variant="contained">
            Start Tour
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 1,
        }}
      />
      <Container
        sx={{
          position: "relative",
          zIndex: 2,
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Box marginTop={4} textAlign="center">
          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontSize: { xs: "2rem", md: "3rem" },
            }}
          >
            Lorcana Card Marketplace
          </Typography>
          <Typography variant="h5" gutterBottom>
            Buy, Sell and Manage your Lorcana Cards
          </Typography>
        </Box>
        <Box marginTop={4}>
          <Grid container spacing={isMobile ? 2 : 4}>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: "center" }}>
                <StorefrontIcon
                  style={{ fontSize: isMobile ? 50 : 80, color: "#3f51b5" }}
                />
                <Typography variant="h6" gutterBottom>
                  Card Marketplace
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/marketplace"
                  className="marketplace-button"
                >
                  Go to Marketplace
                </Button>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: "center" }}>
                <Inventory2OutlinedIcon
                  style={{ fontSize: isMobile ? 50 : 80, color: "#3f51b5" }}
                />
                <Typography variant="h6" gutterBottom>
                  My Inventory
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/inventory-management"
                  className="inventory-button"
                >
                  Manage Inventory
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: "center" }}>
                <MonetizationOnOutlinedIcon
                  style={{ fontSize: isMobile ? 50 : 80, color: "#3f51b5" }}
                />
                <Typography variant="h6" gutterBottom>
                  My Sales
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/sales"
                  className="sales-button"
                >
                  View Sold Items
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: "center" }}>
                <ShoppingBagOutlinedIcon
                  style={{ fontSize: isMobile ? 50 : 80, color: "#3f51b5" }}
                />
                <Typography variant="h6" gutterBottom>
                  My Purchases
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/purchases"
                  className="purchases-button"
                >
                  View Purchases
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: "center" }}>
                <FactCheckOutlinedIcon
                  style={{ fontSize: isMobile ? 50 : 80, color: "#3f51b5" }}
                />
                <Typography variant="h6" gutterBottom>
                  My Collection
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/mycollection"
                  className="collection-button"
                >
                  View Collection
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: "center" }}>
                <PersonOutlineOutlinedIcon
                  style={{ fontSize: isMobile ? 50 : 80, color: "#3f51b5" }}
                />
                <Typography variant="h6" gutterBottom>
                  My Profile
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/profile"
                  className="profile-button"
                >
                  View Profile
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: "center" }}>
                <StorefrontIcon
                  style={{ fontSize: isMobile ? 50 : 80, color: "#3f51b5" }}
                />
                <Typography variant="h6" gutterBottom>
                  Bundle Marketplace
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/bundle-marketplace"
                  disabled
                >
                  Coming Soon
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: "center" }}>
                <DynamicFeedIcon
                  style={{ fontSize: isMobile ? 50 : 80, color: "#3f51b5" }}
                />
                <Typography variant="h6" gutterBottom>
                  My Sale Bundles
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to="/bundle-management"
                  disabled
                >
                  Coming Soon
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Home;
