import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Typography, Box, Grid, Card, CardContent } from '@mui/material';
import Loading from '../components/Loading';
import { useAppBarContext } from '../components/AppBarContext';

const ProfileDashboard = ({ refreshTrigger }) => {  // Accept refreshTrigger as a prop
  const { currentUser } = useAuth();
  const [listingCount, setListingCount] = useState(0);
  const [listingValue, setListingValue] = useState(0);
  const [loading, setLoading] = useState(true);
  
  // Accessing selected country and exchange rates from AppBarContext
  const { selectedCountry, exchangeRates } = useAppBarContext();

  // Conversion function to handle currency exchange
  const convertPrice = (usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice;
    return (exchangeRates[selectedCountry] * usdPrice).toFixed(2);
  };

  const fetchUserProfile = async () => {
    if (currentUser) {
      try {
        // Fetch the user's profile
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setListingCount(userData.listingCount || 0);
          setListingValue(userData.listingValue || 0);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    }
  };

  useEffect(() => {
    fetchUserProfile();  // Fetch on mount or when refreshTrigger changes
  }, [currentUser, refreshTrigger]);  // Re-fetch whenever refreshTrigger changes

  if (loading) {
    return <Loading />;
  }

  return (
    <Box mt={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Your Listings</Typography>
              <Typography variant="h4">{listingCount}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total TCGP Value</Typography>
              <Typography variant="h4">
                {selectedCountry} ${convertPrice(listingValue)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileDashboard;
