import React, { useState } from 'react';
import { 
  List, ListItem, ListItemText, ListItemIcon, ListItemAvatar, Avatar, Box, Typography, IconButton, Tooltip, 
  Chip, Dialog, DialogTitle, DialogActions, DialogContent, TextField, Stack, Button 
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteFilledIcon from '@mui/icons-material/Favorite';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { inkColorMap, rarityIcons } from '../marketplace/constants';

const WishlistCardList = ({ items, convertPrice, handleAddToList, handleUpdateQuantity }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [quantityToAdd, setQuantityToAdd] = useState(1);

  const handleWishlistRemove = (card) => {
    setSelectedCard(card);
    setOpenDialog(true);
  };

  const handleConfirmRemove = () => {
    handleAddToList(selectedCard, 'wishlist');
    if (quantityToAdd > 0) {
      handleUpdateQuantity(selectedCard.docId, quantityToAdd);
    }
    setOpenDialog(false);
    setSelectedCard(null);
    setQuantityToAdd(1);
  };

  const handleRemoveWithoutAdding = () => {
    handleAddToList(selectedCard, 'wishlist');
    setOpenDialog(false);
    setSelectedCard(null);
    setQuantityToAdd(1);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedCard(null);
    setQuantityToAdd(1);
  };

  return (
    <>
      <Typography variant="body1" gutterBottom>
        {items.length} {items.length === 1 ? 'card' : 'cards'} in Wishlist
      </Typography>

      <List>
        {items.map((card) => {
          const inCollection = card.quantity > 0;
          const inWishlist = card.inWishlist;

          return (
            <ListItem
              key={`${card.setId}_${card.docId}`}
              sx={{
                border: "2px solid #0C0A5A",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "flex-start", sm: "center" },
                padding: 2,
                mb: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <ListItemAvatar sx={{ width: 100, position: 'relative' }}>
                <Avatar
                  className={card.type === "Foil" ? "foil-avatar" : ""}
                  variant="square"
                  src={card.image_uris?.digital?.small}
                  alt={card.name}
                  sx={{
                    height: "20%",
                    width: "80%",
                    objectFit: "contain",
                    border: `4px solid ${inkColorMap[card.ink] || "#F2D18B"}`,
                  }}
                />
                {card.type === "Foil" && (
                  <Avatar
                    variant="square"
                    sx={{
                      width: 76,
                      height: 10,
                      bgcolor: "#ffd700",
                      border: "2px solid #000000",
                      color: "#000000",
                      bottom: 18,
                      left: 4,
                    }}
                  >
                    <Typography variant="h6" sx={{ fontSize: "10px" }}>
                      COLD FOIL
                    </Typography>
                  </Avatar>
                )}
              </ListItemAvatar>
              <Stack spacing={1} sx={{ marginLeft: 0 }}>
                <ListItemIcon>
                  <Avatar variant="square" sx={{ bgcolor: "#0C0A5A" }}>
                    {card.collector_number}
                  </Avatar>
                </ListItemIcon>
                <ListItemIcon>
                  <Avatar variant="square" sx={{ bgcolor: "#0C0A5A" }}>
                    <img
                      src={rarityIcons[card.rarity]}
                      alt={card.rarity}
                      style={{ width: 24, height: 24 }}
                    />
                  </Avatar>
                </ListItemIcon>
              </Stack>
              <ListItemText
                primary={<Typography variant="body1">{card.name}</Typography>}
                secondary={
                  <>
                    {card.version && (
                      <Typography variant="body2" color="textSecondary">
                        {card.version}
                      </Typography>
                    )}
                    {card.setName && (
                      <Box mt={2}>
                        <Typography variant="body2" color="textSecondary">
                          {card.setName}
                        </Typography>
                      </Box>
                    )}
                  </>
              }
            />
            </Box>

              {/* Actions and price */}
              <Box sx={{ width: "100%", mt: { xs: 2, sm: 0 }, display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 2}}>
                {/* Chip for price */}
                <Chip
                  sx={{
                    height: "auto",
                    borderRadius: "4px",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                    },
                    bgcolor: "green",
                    color: "white",
                    width: "40%",
                  }}
                  label={
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <span>TCGP Price</span>
                      {convertPrice(card.price || 0)}
                    </Box>
                  }
                />

                {/* Icons for collection and wishlist status */}
                <Box sx={{ display: "flex", gap: 1, flexDirection: "row" }}>
                  <Tooltip title={inCollection ? "In My Collection" : "Not In My Collection"} arrow>
                    <IconButton>
                      {inCollection ? <CheckCircleIcon style={{ color: 'green' }} /> : <UnpublishedIcon sx={{ color: 'orange' }} />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={inWishlist ? "Remove from Wishlist" : "Add to Wishlist"} arrow>
                    <IconButton onClick={() => handleWishlistRemove(card)}>
                      {inWishlist ? <FavoriteFilledIcon style={{ color: 'red' }} /> : <FavoriteIcon />}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </ListItem>
          );
        })}
      </List>

      {/* Dialog to confirm removal and ask to add to collection */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Remove from Wishlist</DialogTitle>
        <DialogContent>
          <Typography>
            Would you like to add "{selectedCard?.name}" to your collection? If yes, specify the quantity.
          </Typography>
          <TextField
            label="Quantity to add"
            type="number"
            value={quantityToAdd}
            onChange={(e) => setQuantityToAdd(Number(e.target.value))}
            inputProps={{ min: 0 }}
            fullWidth
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRemoveWithoutAdding} color="secondary">
            No, just remove from Wishlist
          </Button>
          <Button onClick={handleConfirmRemove} color="primary">
            Yes, add to Collection
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WishlistCardList;
