import React, { useState } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Snackbar,
  Alert,
  Divider,
} from '@mui/material';
import { useAuth } from '../auth';
import { functions, httpsCallable } from '../firebase';
import Papa from 'papaparse';
import Loading from '../components/Loading';

const ImportExportCollectionComponent = ({ open, onClose }) => {
  const { currentUser } = useAuth();
  const [importFile, setImportFile] = useState(null);
  const [importOption, setImportOption] = useState('update'); // 'update', 'overwrite', or 'skip'
  const [exporting, setExporting] = useState(false);
  const [importing, setImporting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Mapping for set IDs to set numbers (you need to define this mapping)
  const setIdToNumberMap = {
    "setId1": "SetNumber1",
    "setId2": "SetNumber2",
    // Add more mappings as required
  };

  const handleExport = async (includeAllCards = false) => {
    setExporting(true);
    try {
      const exportCollection = httpsCallable(functions, 'exportCollection');
      await exportCollection({ userId: currentUser.uid, includeAllCards });

      setSnackbar({ open: true, message: 'Export initiated. You will receive an email with the CSV file shortly.', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Error exporting collection.', severity: 'error' });
    } finally {
      setExporting(false);
    }
  };

  const handleImport = async () => {
    if (!importFile) return;
    setImporting(true);
    try {
      const fileText = await importFile.text();

      // Map set numbers to set IDs
      const parsedData = Papa.parse(fileText, { header: true });
      const updatedData = parsedData.data.map((row) => {
        const setNumber = row.setNumber;
        row.setId = Object.keys(setIdToNumberMap).find(key => setIdToNumberMap[key] === setNumber);
        return row;
      });

      const importCollection = httpsCallable(functions, 'importCollection');
      const response = await importCollection({
        userId: currentUser.uid,
        csvData: Papa.unparse(updatedData),
        importOption,
      });

      setSnackbar({ open: true, message: 'Import successful!', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Error importing collection.', severity: 'error' });
    } finally {
      setImporting(false);
      setImportFile(null);
      onClose();
    }
  };

  const handleFileChange = (event) => {
    setImportFile(event.target.files[0]);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Import/Export Collection</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="h6">Instructions</Typography>
          <Typography variant="body1">
            <strong>Export:</strong> Use the "Export My Collection" button to export your current collection. The exported CSV file will be sent to the email listed in your profile.
          </Typography>
          <Typography variant="body1">
            <strong>Import:</strong> Use the "Import Collection" button to import your collection from a CSV file. Ensure the file format matches the expected structure, you can export your collection to see the format and use the same file to import after modifiying. You can also direct import a Glimmer Scan CSV file.
          </Typography>
          <Typography variant="body2">
            Glimmer Scan available for iOS: https://apps.apple.com/au/app/glimmer-scan/id6502996383
          </Typography>
          
          <Divider sx={{ my: 2 }} />

          <Button
            variant="contained"
            color="primary"
            onClick={() => handleExport(false)}
            disabled={exporting}
            startIcon={exporting && <Loading />}
          >
            Export My Collection
          </Button>
          <Box mt={2} display="flex" flexDirection="column" gap={1}>
            <Button
              variant="outlined"
              component="label"
              disabled={importing}
            >
              {importFile ? importFile.name : 'Choose CSV File'}
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                hidden
              />
            </Button>
            <FormControl fullWidth>
              <InputLabel>Import Option</InputLabel>
              <Select
                value={importOption}
                onChange={(e) => setImportOption(e.target.value)}
                label="Import Option"
              >
                <MenuItem value="update">Update Existing</MenuItem>
                <MenuItem value="overwrite">Overwrite Existing</MenuItem>
                <MenuItem value="skip">Skip Existing</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleImport}
              disabled={!importFile || importing}
              startIcon={importing && <Loading />}
              sx={{ mt: 2 }}
            >
              Import Collection
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={importing || exporting}>
          Close
        </Button>
      </DialogActions>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default ImportExportCollectionComponent;
