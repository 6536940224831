import React, { useRef, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Typography,
  Box,
  Dialog as MuiDialog,
  DialogContentText,
  DialogActions as MuiDialogActions
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';
import SaleItemsTable from './SaleItemsTable';
import './PickingSlipDialog.css'; // Import CSS file for print styles

const PickingSlipDialog = ({
  open,
  onClose,
  selectedSale,
  getTotalCards,
  usernames,
}) => {
  const [showImages, setShowImages] = useState(false); // State for showing images
  const [printDialogOpen, setPrintDialogOpen] = useState(false); // Dialog for asking about printing images
  const pickingSlipRef = useRef();
  const shippingLabelRef = useRef();

  // Trigger the actual print action for the picking slip
  const handlePrintPickingSlip = useReactToPrint({
    content: () => pickingSlipRef.current,
  });

  // Trigger the print for the shipping label
  const handlePrintShippingLabel = useReactToPrint({
    content: () => shippingLabelRef.current,
  });

  // Open the dialog to ask if images should be included in the print
  const handlePreparePrint = () => {
    setPrintDialogOpen(true);
  };

  // Close the print confirmation dialog
  const handleClosePrintDialog = () => {
    setPrintDialogOpen(false);
  };

  // Proceed to print after the user chooses whether to include images or not
  const handlePrintWithImages = (includeImages) => {
    setShowImages(includeImages);
    setPrintDialogOpen(false);
    handlePrintPickingSlip(); // Trigger the print after selection
  };

  const getFormattedDate = (date) => {
    if (!date) return 'N/A'; // Handle case when date is not available

    // Check if date is an instance of Date or a Firestore timestamp
    if (date instanceof Date) {
      return date.toLocaleDateString();
    } else if (date.toDate) {
      return date.toDate().toLocaleDateString();
    } else {
      return 'N/A'; // Fallback if the format is unexpected
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen>

      <DialogContent>
        {selectedSale && (
          <div ref={pickingSlipRef} className="print-friendly">
            {/* Buyer and Sale Details Section */}
            <Typography variant="h6">Picking Slip</Typography>
            <Box mb={2}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Sale Date:
              </Typography>
              <Typography variant="body2" gutterBottom>
              {getFormattedDate(selectedSale.transactionDate)}
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Buyer Details:
              </Typography>
              <Typography variant="body2" gutterBottom>
                Username: {usernames[selectedSale?.buyerId]}
              </Typography>
              <div
                ref={shippingLabelRef}
                style={{
                  marginTop: "20px",
                  padding: "20px",
                  border: "1px solid black",
                }}
              >
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Name:
              </Typography>
              <Typography variant="body2" gutterBottom>
                {selectedSale?.buyerAddress?.preferredContactName || "N/A"}
              </Typography>

              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Address:
              </Typography>
              <Typography variant="body2">
                {selectedSale?.buyerAddress?.addressLine1 && (
                  <div>{selectedSale.buyerAddress.addressLine1}</div>
                )}
                {selectedSale?.buyerAddress?.addressLine2 && (
                  <div>{selectedSale.buyerAddress.addressLine2}</div>
                )}
                {selectedSale?.buyerAddress?.city && (
                  <div>{selectedSale.buyerAddress.city}</div>
                )}
                {selectedSale?.buyerAddress?.state && (
                  <div>{selectedSale.buyerAddress.state}</div>
                )}
                {selectedSale?.buyerAddress?.zipCode && (
                  <div>{selectedSale.buyerAddress.zipCode}</div>
                )}
                {selectedSale?.buyerAddress?.country && (
                  <div>{selectedSale.buyerAddress.country}</div>
                )}
                {selectedSale?.buyerAddress?.additionalDetails && (
                  <div>{selectedSale.buyerAddress.additionalDetails}</div>
                )}
              </Typography>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Shipping Method:
              </Typography>
                <Typography variant="body2" gutterBottom>
                {selectedSale.shippingOption || "N/A"}
              </Typography>
              </div>
            </Box>

            {/* Total Cards */}
            <Typography>
              Total Cards: {getTotalCards(selectedSale.items)}
            </Typography>
            <Divider sx={{ my: 2 }} />

            {/* Sale Items Table */}
            <SaleItemsTable
              sale={selectedSale}
              showPickingColors={true}
              showImages={showImages}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handlePreparePrint}
          variant="contained"
          startIcon={<PrintIcon />}
        >
          Print Picking Slip
        </Button>
        <Button
          onClick={handlePrintShippingLabel}
          variant="contained"
          startIcon={<PrintIcon />}
        >
          Print Shipping Label
        </Button>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>

      {/* Dialog asking if images should be printed */}
      <MuiDialog open={printDialogOpen} onClose={handleClosePrintDialog}>
        <DialogTitle>Print Picking Slip</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to print the picking slip with card images?
          </DialogContentText>
        </DialogContent>
        <MuiDialogActions>
          <Button onClick={() => handlePrintWithImages(false)} color="primary">
            No, print without images
          </Button>
          <Button onClick={() => handlePrintWithImages(true)} color="primary">
            Yes, print with images
          </Button>
        </MuiDialogActions>
      </MuiDialog>
    </Dialog>
  );
};

export default PickingSlipDialog;
