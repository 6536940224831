import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useAuth } from '../auth';
import { getFunctions, httpsCallable } from "firebase/functions";

const Verification = ({ open, onClose, onVerified }) => {
  const { currentUser } = useAuth();
  const [verificationResult, setVerificationResult] = useState(null);

  const handleVerify = async () => {
    try {
      const functions = getFunctions();
      const createVerificationSession = httpsCallable(functions, 'createVerificationSession');
      
      const response = await createVerificationSession({ user_id: currentUser.uid });

      const data = response.data;

      if (!data.publishableKey) {
        throw new Error('Publishable Key is undefined.');
      }

      const stripe = window.Stripe(data.publishableKey);
      const { error } = await stripe.verifyIdentity(data.client_secret);

      if (!error) {
        setVerificationResult('Verification submitted successfully.');
        onVerified('Verified');
        setTimeout(() => {
          setVerificationResult(null);
          onClose();
        }, 3000);
      } else {
        setVerificationResult(`Verification failed: ${error.message}`);
        onVerified('Not Verified');
      }
    } catch (e) {
      setVerificationResult(`Verification failed: ${e.message}`);
      onVerified('Not Verified');
    }
  };

  const handleClose = () => {
    setVerificationResult(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Account Verification</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary" paragraph>
          The verification check is conducted by Stripe. Please complete the verification process.
        </Typography>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography>Please verify your identity:</Typography>
          <Button onClick={handleVerify} color="primary">Verify</Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">Cancel</Button>
      </DialogActions>
      {verificationResult && (
        <DialogContent>
          <Typography>{verificationResult}</Typography>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default Verification;
