import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Avatar,
  Box,
  Chip,
  Stack,
  Typography,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FavoriteIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteFilledIcon from '@mui/icons-material/Favorite';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { inkColorMap, rarityIcons } from '../marketplace/constants';

const MobileCollectionCardList = ({ cards, collectionItems, wishlistItems, convertPrice, handleAddToList, handleUpdateQuantity, handleCardClick }) => {
  return (
    <List>
      {cards.map((card) => {
        const quantity = collectionItems[card.docName] || 0;
        const inWishlist = wishlistItems.includes(card.docName);
        const inCollection = quantity > 0;

        return (
          <ListItem
            key={`${card.setId}_${card.docName}`}
            sx={{
              border: "2px solid #0C0A5A",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <ListItemAvatar sx={{ width: 100, position: 'relative' }}>
                <Avatar
                  className={card.type === "Foil" ? "foil-avatar" : ""}
                  variant="square"
                  src={card.image_uris?.digital?.small}
                  alt={card.name}
                  sx={{
                    height: "20%",
                    width: "80%",
                    objectFit: "contain",
                    border: `4px solid ${inkColorMap[card.ink] || "#F2D18B"}`,
                  }}
                />
                {card.type === "Foil" && (
                  <Avatar
                    variant="square"
                    sx={{
                      width: 76,
                      height: 10,
                      bgcolor: "#ffd700",
                      border: "2px solid #000000",
                      color: "#000000",
                      bottom: 18,
                      left: 4,
                    }}
                  >
                    <Typography variant="h6" sx={{ fontSize: "10px" }}>
                      COLD FOIL
                    </Typography>
                  </Avatar>
                )}
              </ListItemAvatar>
              <Stack spacing={1} sx={{ marginLeft: 2 }}>
                <ListItemIcon>
                  <Avatar variant="square" sx={{ bgcolor: "#0C0A5A" }}>
                    {card.collector_number}
                  </Avatar>
                </ListItemIcon>
                <ListItemIcon>
                  <Avatar variant="square" sx={{ bgcolor: "#0C0A5A" }}>
                    <img
                      src={rarityIcons[card.rarity]}
                      alt={card.rarity}
                      style={{ width: 24, height: 24 }}
                    />
                  </Avatar>
                </ListItemIcon>
              </Stack>
              <ListItemText
                primary={<Typography variant="body1">{card.name}</Typography>}
                secondary={
                  card.version && (
                    <Typography variant="body2" color="textSecondary">
                      {card.version}
                    </Typography>
                  )
                }
                sx={{ marginLeft: 2 }}
              />
            </Box>
            <Box sx={{ width: "100%", marginTop: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1, flexWrap: "wrap" }}>
                <IconButton onClick={() => handleUpdateQuantity(card.docName, quantity - 1)} disabled={quantity === 0}>
                  <RemoveIcon />
                </IconButton>
                <Typography variant="body2">{quantity}</Typography>
                <IconButton onClick={() => handleUpdateQuantity(card.docName, quantity + 1)}>
                  <AddIcon />
                </IconButton>
                <Tooltip title={inCollection ? "In My Collection" : "Not In My Collection"} arrow>
                  {inCollection ? <CheckCircleIcon style={{ color: 'green' }} /> : <UnpublishedIcon sx={{ color: 'orange' }} />}
                </Tooltip>
                <Tooltip title={inWishlist ? "Remove from Wishlist" : "Add to Wishlist"} arrow>
                  <IconButton onClick={() => handleAddToList(card, 'wishlist')}>
                    {inWishlist ? <FavoriteFilledIcon style={{ color: 'red' }} /> : <FavoriteIcon />}
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, marginTop: 1 }}>
                <Chip
                  sx={{
                    height: "auto",
                    borderRadius: "4px",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                    },
                    bgcolor: "green",
                    color: "white",
                    width: "50%",
                  }}
                  label={
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <span>TCGP Price</span>
                      {convertPrice(card.price || 0)}
                    </Box>
                  }
                />
                <Button
                  variant="text"
                  size="small"
                  sx={{ width: "50%" }}
                  onClick={() => handleCardClick(card)}
                >
                  View Details
                </Button>
              </Box>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
};

export default MobileCollectionCardList;
