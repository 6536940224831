import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage'; 
import { db, storage } from '../firebase'; 

const ImageViewerDialog = ({ open, onClose, saleId, sellerId }) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    if (open) {
      // Fetch uploaded images from Firestore
      const fetchImages = async () => {
        const saleRef = doc(db, `users/${sellerId}/sales/${saleId}`);
        const saleDoc = await getDoc(saleRef);
        if (saleDoc.exists()) {
          const data = saleDoc.data();
          if (data.imageUrls) {
            setUploadedImages(data.imageUrls);
            setSelectedImages([]); // Reset selected images when dialog opens
          }
        }
      };
      fetchImages();
    }
  }, [open, saleId, sellerId]);

  // Function to trigger download of selected images
  const handleDownloadSelected = async () => {
    for (const [index, imagePath] of selectedImages.entries()) {
      const imageRef = ref(storage, imagePath); // Create reference to the file in Firebase Storage
      const downloadURL = await getDownloadURL(imageRef); // Get the download URL
      const response = await fetch(downloadURL); // Fetch the file using the URL
      const blob = await response.blob(); // Convert the response to a blob
      const link = document.createElement('a'); // Create an anchor element
      link.href = URL.createObjectURL(blob); // Create a local URL for the blob
      link.download = `CardCondition_${index}.jpg`; // Set the file name for download
      link.click(); // Programmatically click the link to trigger download
      URL.revokeObjectURL(link.href); // Revoke the local URL after download to free memory
    }
  };

  // Handle image selection
  const handleSelectImage = (imagePath) => {
    setSelectedImages((prevSelected) =>
      prevSelected.includes(imagePath)
        ? prevSelected.filter((path) => path !== imagePath)
        : [...prevSelected, imagePath]
    );
  };

  // Select all images
  const handleSelectAll = () => {
    setSelectedImages(uploadedImages);
  };

  // Deselect all images
  const handleSelectNone = () => {
    setSelectedImages([]);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Card Images</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary" paragraph>
          Note: These photos will be deleted once the sale is completed. We recommend downloading them for your records.
        </Typography>

        {uploadedImages.length > 0 ? (
          <>
            <Box display="flex" justifyContent="space-between" marginBottom={2}>
              <Button variant="outlined" onClick={handleSelectAll}>
                Select All
              </Button>
              <Button variant="outlined" onClick={handleSelectNone}>
                Select None
              </Button>
            </Box>

            <Box display="flex" flexWrap="wrap" gap={2}>
              {uploadedImages.map((imagePath, index) => (
                <Box key={index} display="flex" flexDirection="column" alignItems="center">
                  <img src={imagePath} alt={`${index}`} width={100} height={100} />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedImages.includes(imagePath)}
                        onChange={() => handleSelectImage(imagePath)}
                      />
                    }
                    label="Select"
                  />
                </Box>
              ))}
            </Box>
          </>
        ) : (
          <Typography>No images uploaded.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadSelected}
          disabled={selectedImages.length === 0}
        >
          Download Selected
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageViewerDialog;
