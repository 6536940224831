import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Tooltip, Box, Button, Avatar } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LoginDialog from '../pages/LoginDialog';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth';

const PublicAppBar = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);

  const handleLogin = () => {
    setLoginDialogOpen(true);
  };

  const handleCloseLoginDialog = () => {
    setLoginDialogOpen(false);
  };

  const handleHomeNavigation = () => {
    navigate('/');
  };

  const handleMarketplaceNavigation = () => {
    navigate('/public-marketplace');
  };

  return (
    <>
      <AppBar position="sticky" sx={{ bgcolor: "#0C0A5A" }}>
        <Toolbar>
          <Tooltip title="Home">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="home"
              onClick={handleHomeNavigation}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Marketplace">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="storefront"
              onClick={handleMarketplaceNavigation}
            >
              <StorefrontIcon />
            </IconButton>
          </Tooltip>


          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Avatar
              variant="square"
              alt="Lorcana Card Marketplace Logo"
              src="/logo192.png"
              sx={{ width: 40, height: 40, marginRight: 1 }}
            />
            <Typography variant="h6">
              Lorcana Card Marketplace
            </Typography>
          </Box>

          {!currentUser && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleLogin}
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <LoginDialog open={loginDialogOpen} onClose={handleCloseLoginDialog} />
    </>
  );
};

export default PublicAppBar;
