import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, doc, setDoc, getDocs, addDoc } from 'firebase/firestore';
import { Container, TextField, Select, MenuItem, Button, Typography, Checkbox, FormControlLabel } from '@mui/material';

const AddCard = () => {
  const [sets, setSets] = useState([]);
  const [selectedSet, setSelectedSet] = useState('');
  const [cardData, setCardData] = useState({
    classifications: [],
    collector_number: '',
    cost: 0,
    flavor_text: '',
    id: '',
    illustrators: [],
    image_uris: { digital: { large: '', normal: '', small: '' } },
    images: { digital: { large: '', normal: '', small: '' } },
    ink: '',
    inkwell: false,
    keywords: [],
    lang: 'en',
    layout: 'normal',
    legalities: { core: '' },
    lore: 0,
    move_cost: null,
    name: '',
    price: 0,
    prices: { usd: 0, usd_foil: 0 },
    rarity: '',
    released_at: '',
    set: { code: '', id: '', name: '' },
    strength: 0,
    tcgplayer_id: 0,
    text: '',
    type: '',
    version: '',
    willpower: 0
  });
  const [jsonInput, setJsonInput] = useState('');

  useEffect(() => {
    const fetchSets = async () => {
      const setsSnapshot = await getDocs(collection(db, "sets"));
      const setsData = setsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSets(setsData);
    };
    fetchSets();
  }, []);

  const handleInputChange = (field, value) => {
    setCardData(prevData => ({ ...prevData, [field]: value }));
  };

  const handleArrayChange = (field, index, value) => {
    const updatedArray = [...cardData[field]];
    updatedArray[index] = value;
    setCardData(prevData => ({ ...prevData, [field]: updatedArray }));
  };

  const handleImageUriChange = (section, size, value) => {
    setCardData(prevData => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        digital: { ...prevData[section].digital, [size]: value }
      }
    }));
  };

  const handleImportJson = async () => {
    try {
      // Log the JSON input for debugging
      console.log("Raw JSON Input:", jsonInput);
  
      // Trim and parse the JSON input
      const parsedData = JSON.parse(jsonInput.trim());
      console.log("Parsed JSON Data:", parsedData);
  
      const cardsData = Array.isArray(parsedData) ? parsedData : [parsedData]; // Handle single or multiple cards
  
      for (const card of cardsData) {
        // Ensure prices are numbers
        if (typeof card.prices?.usd === "string") {
          card.prices.usd = parseFloat(card.prices.usd);
        }
        if (typeof card.prices?.usd_foil === "string") {
          card.prices.usd_foil = parseFloat(card.prices.usd_foil);
        }
  
        const setRef = doc(db, "sets", selectedSet);
  
        // Normal version
        if (card.prices?.usd || card.rarity !== 'Enchanted') {
          const normalCardId = `${card.id}_normal`;
          const normalCardRef = doc(setRef, "cards", normalCardId);
          await setDoc(normalCardRef, {
            ...card,
            type: 'Normal',
            price: card.prices?.usd || null,
            images: card.image_uris,
          }, { merge: true });
        }
  
        // Foil version
        if (card.prices?.usd_foil) {
          const foilCardId = `${card.id}_foil`;
          const foilCardRef = doc(setRef, "cards", foilCardId);
          await setDoc(foilCardRef, {
            ...card,
            type: 'Foil',
            price: card.prices.usd_foil,
            images: card.image_uris,
          }, { merge: true });
        }
      }
      alert('Card(s) added successfully!');
    } catch (error) {
      console.error("Error parsing JSON:", error);
      alert("Invalid JSON format. Please check your input.");
    }
  };
  
  
  
  

  const handleSubmit = async () => {
    try {
      const setRef = collection(db, "sets", selectedSet, "cards");
      await addDoc(setRef, cardData);
      alert('Card added successfully!');
    } catch (error) {
      alert('Error adding card: ', error.message);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Add New Card</Typography>
      
      <Select
        value={selectedSet}
        onChange={e => setSelectedSet(e.target.value)}
        displayEmpty
        fullWidth
      >
        <MenuItem value="" disabled>Select a Set</MenuItem>
        {sets.map(set => (
          <MenuItem key={set.id} value={set.id}>{set.name}</MenuItem>
        ))}
      </Select>
      
      <TextField label="Name" value={cardData.name} onChange={e => handleInputChange('name', e.target.value)} fullWidth />
      <TextField label="Collector Number" value={cardData.collector_number} onChange={e => handleInputChange('collector_number', e.target.value)} fullWidth />
      <TextField label="Cost" type="number" value={cardData.cost} onChange={e => handleInputChange('cost', e.target.value)} fullWidth />
      <TextField label="Flavor Text" value={cardData.flavor_text} onChange={e => handleInputChange('flavor_text', e.target.value)} fullWidth />
      <TextField label="ID" value={cardData.id} onChange={e => handleInputChange('id', e.target.value)} fullWidth />
      <TextField label="Ink" value={cardData.ink} onChange={e => handleInputChange('ink', e.target.value)} fullWidth />
      <FormControlLabel
        control={<Checkbox checked={cardData.inkwell} onChange={e => handleInputChange('inkwell', e.target.checked)} />}
        label="Inkwell"
      />
      <TextField label="Language" value={cardData.lang} onChange={e => handleInputChange('lang', e.target.value)} fullWidth />
      <TextField label="Layout" value={cardData.layout} onChange={e => handleInputChange('layout', e.target.value)} fullWidth />
      <TextField label="Legalities (Core)" value={cardData.legalities.core} onChange={e => handleInputChange('legalities.core', e.target.value)} fullWidth />
      <TextField label="Lore" type="number" value={cardData.lore} onChange={e => handleInputChange('lore', e.target.value)} fullWidth />
      <TextField label="Move Cost" type="number" value={cardData.move_cost} onChange={e => handleInputChange('move_cost', e.target.value)} fullWidth />
      <TextField label="Price" type="number" value={cardData.price} onChange={e => handleInputChange('price', e.target.value)} fullWidth />
      <TextField label="USD Price" type="number" value={cardData.prices.usd} onChange={e => handleInputChange('prices.usd', e.target.value)} fullWidth />
      <TextField label="USD Foil Price" type="number" value={cardData.prices.usd_foil} onChange={e => handleInputChange('prices.usd_foil', e.target.value)} fullWidth />
      <TextField label="Rarity" value={cardData.rarity} onChange={e => handleInputChange('rarity', e.target.value)} fullWidth />
      <TextField label="Released At" value={cardData.released_at} onChange={e => handleInputChange('released_at', e.target.value)} fullWidth />
      <TextField label="Strength" type="number" value={cardData.strength} onChange={e => handleInputChange('strength', e.target.value)} fullWidth />
      <TextField label="TCGPlayer ID" type="number" value={cardData.tcgplayer_id} onChange={e => handleInputChange('tcgplayer_id', e.target.value)} fullWidth />
      <TextField label="Text" value={cardData.text} onChange={e => handleInputChange('text', e.target.value)} fullWidth />
      <TextField label="Type" value={cardData.type} onChange={e => handleInputChange('type', e.target.value)} fullWidth />
      <TextField label="Version" value={cardData.version} onChange={e => handleInputChange('version', e.target.value)} fullWidth />
      <TextField label="Willpower" type="number" value={cardData.willpower} onChange={e => handleInputChange('willpower', e.target.value)} fullWidth />
      
      <Typography variant="h6" gutterBottom>Classifications</Typography>
      {cardData.classifications.map((classification, index) => (
        <TextField
          key={index}
          label={`Classification ${index + 1}`}
          value={classification}
          onChange={e => handleArrayChange('classifications', index, e.target.value)}
          fullWidth
        />
      ))}

      <Typography variant="h6" gutterBottom>Import JSON Data</Typography>
      <TextField
        label="Paste JSON Here"
        multiline
        rows={6}
        value={jsonInput}
        onChange={(e) => setJsonInput(e.target.value)}
        fullWidth
      />
      <Button variant="contained" color="secondary" onClick={handleImportJson}>
        Import JSON
      </Button>
      
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Add Card
      </Button>
    </Container>
  );
};

export default AddCard;
