import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, CircularProgress, Box, useMediaQuery } from '@mui/material';
import { useAuth } from '../auth';
import { useTheme } from '@mui/material/styles';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAppBarContext } from '../components/AppBarContext';
import { currencySymbols } from '../marketplace/constants';

const CollectionDashboard = ({ onSetCardClick }) => {
  const [dashboardData, setDashboardData] = useState({});
  const [loadingStates, setLoadingStates] = useState({});
  const { currentUser } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { selectedCountry, exchangeRates } = useAppBarContext();

  const convertPrice = (usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice.toFixed(2);
    return `${currencySymbols[selectedCountry]}${(usdPrice * exchangeRates[selectedCountry]).toFixed(2)}`;
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      const setsRef = collection(db, 'sets');
      const setsSnapshot = await getDocs(setsRef);
      const allSets = setsSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name, code: doc.data().code }));
      
      // Sort sets by numeric codes first, followed by alphabetic ones
      const sortedSets = allSets.sort((a, b) => {
        const isANumeric = /^\d+$/.test(a.code);
        const isBNumeric = /^\d+$/.test(b.code);
        if (isANumeric && isBNumeric) {
          return parseInt(a.code) - parseInt(b.code);
        } else if (isANumeric) {
          return -1;
        } else if (isBNumeric) {
          return 1;
        } else {
          return a.code.localeCompare(b.code);
        }
      });

      sortedSets.forEach(set => {
        setDashboardData(prevData => ({
          ...prevData,
          [set.id]: {
            setName: set.name,
            normalCards: '-',
            foilCards: '-',
            totalValue: '-',
          }
        }));
        setLoadingStates(prevState => ({
          ...prevState,
          [set.id]: { normal: true, foil: true, total: true }
        }));
      });

      return sortedSets;
    };

    const fetchCollectionData = async () => {
      const sortedSets = await fetchInitialData();

      const fetchSetData = sortedSets.map(async (set) => {
        const collectionPath = `users/${currentUser.uid}/mycollection/${set.id}/cards`;
        const collectionRef = collection(db, collectionPath);
        const collectionSnapshot = await getDocs(collectionRef);

        let normalCards = 0;
        let foilCards = 0;
        let totalValue = 0;

        const cardFetchPromises = collectionSnapshot.docs.map(async (docSnap) => {
          const cardData = docSnap.data();
          const { id: cardId } = docSnap;

          const cardInSetRef = doc(db, `sets/${set.id}/cards/${cardId}`);
          const cardInSetDoc = await getDoc(cardInSetRef);

          let price = 0;
          if (cardInSetDoc.exists()) {
            price = cardInSetDoc.data()?.price || 0;
          }

          if (docSnap.id.endsWith('_foil')) {
            foilCards += cardData.quantity || 0;
          } else if (docSnap.id.endsWith('_normal')) {
            normalCards += cardData.quantity || 0;
          }

          totalValue += price * (cardData.quantity || 1);
        });

        await Promise.all(cardFetchPromises);

        return { setId: set.id, normalCards, foilCards, totalValue };
      });

      const setDataResults = await Promise.all(fetchSetData);

      setDataResults.forEach((setData) => {
        setDashboardData(prevData => ({
          ...prevData,
          [setData.setId]: {
            ...prevData[setData.setId],
            normalCards: setData.normalCards,
            foilCards: setData.foilCards,
            totalValue: setData.totalValue,
          }
        }));

        setLoadingStates(prevState => ({
          ...prevState,
          [setData.setId]: { normal: false, foil: false, total: false }
        }));
      });
    };

    fetchCollectionData();
  }, [currentUser]);

  return (
    <Grid container spacing={isMobile ? 2 : 3} sx={{ marginBottom: 2 }}>
      {Object.keys(dashboardData).length > 0 && (
        Object.keys(dashboardData).map((setId) => {
          const setData = dashboardData[setId];
          const loadingState = loadingStates[setId];

          return (
            <Grid item xs={4} sm={4} md={4} lg={3} key={setId}>
              <Card 
                sx={{ padding: isMobile ? 1 : 2, height: isMobile ? '160px' : '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => onSetCardClick(setId)}
              >
                <CardContent sx={{ textAlign: 'center', flexGrow: 1 }}>
                  <Typography variant="h6" align="center" gutterBottom sx={{ fontSize: isMobile ? '0.7rem' : '1.25rem' }}>
                    {setData.setName}
                  </Typography>

                  {/* Normal Cards */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row' }}>
                    <Box sx={{ textAlign: 'center', marginRight: isMobile ? 0 : 2, marginBottom: isMobile ? 1 : 0 }}>
                      {loadingState?.normal ? (
                        <CircularProgress size={isMobile ? 20 : 40} />
                      ) : (
                        <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: isMobile ? '0.9rem' : '2rem' }}>
                          {setData.normalCards}
                        </Typography>
                      )}
                      <Typography variant="body2" sx={{ fontSize: isMobile ? '0.6rem' : 'inherit' }}>Normal</Typography>
                    </Box>

                    {/* Foil Cards */}
                    <Box sx={{ textAlign: 'center', marginLeft: isMobile ? 0 : 2 }}>
                      {loadingState?.foil ? (
                        <CircularProgress size={isMobile ? 20 : 40} />
                      ) : (
                        <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: isMobile ? '0.9rem' : '2rem' }}>
                          {setData.foilCards}
                        </Typography>
                      )}
                      <Typography variant="body2" sx={{ fontSize: isMobile ? '0.6rem' : 'inherit' }}>Foil</Typography>
                    </Box>
                  </Box>

                  {/* TCGP Value */}
                  <Typography variant="body2" align="center" sx={{ marginTop: 1, fontSize: isMobile ? '0.6rem' : 'inherit' }}>
                    {loadingState?.total ? (
                      <CircularProgress size={isMobile ? 20 : 40} />
                    ) : (
                      <>TCGPValue: {convertPrice(setData.totalValue)}</>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })
      )}
    </Grid>
  );
};

export default CollectionDashboard;
