import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserIdFromUsername } from './getUserIdFromUsername';
import PublicProfile from './PublicProfile';
import { Container, Typography } from '@mui/material';
import Loading from '../components/Loading';

const PublicProfileHandler = () => {
  const { username } = useParams();
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserId = async () => {
      const fetchedUserId = await getUserIdFromUsername(username);
      if (fetchedUserId) {
        setUserId(fetchedUserId);
      } else {
        console.error('User not found');
      }
      setLoading(false);
    };

    fetchUserId();
  }, [username]);

  if (loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (!userId) {
    return (
      <Container>
        <Typography variant="h5">User not found</Typography>
      </Container>
    );
  }

  return <PublicProfile userId={userId} />;
};

export default PublicProfileHandler;
