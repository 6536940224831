import React, { useState } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Snackbar,
  Alert,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useAuth } from '../auth';
import { functions, httpsCallable } from '../firebase'; 
import Loading from '../components/Loading';
import FeatureControl from '../context/FeatureControl';

const ImportExportComponent = ({ open, onClose }) => {
  const { currentUser } = useAuth();
  const [importFile, setImportFile] = useState(null);
  const [importOption, setImportOption] = useState('update'); // 'update', 'overwrite', or 'skip'
  const [exporting, setExporting] = useState(false);
  const [importing, setImporting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Export Listings using Firebase Function
  const handleExport = async (includeAllCards = false) => {
    setExporting(true);
    try {
      console.log('Starting export...');

      // Call the Firebase function to export listings
      const exportListings = httpsCallable(functions, 'exportListingsAndEmail');
      await exportListings({ userId: currentUser.uid, includeAllCards });

      console.log('Export initiated.');
      setSnackbar({ open: true, message: 'Export initiated. You will receive an email with the CSV file shortly.', severity: 'success' });
    } catch (error) {
      console.error('Error exporting listings:', error);
      setSnackbar({ open: true, message: 'Error exporting listings.', severity: 'error' });
    } finally {
      setExporting(false);
    }
  };

  // Import Listings from CSV
  const handleImport = async () => {
    if (!importFile) return;
    setImporting(true);
    try {
      const fileText = await importFile.text();

      const importListings = httpsCallable(functions, 'importListings');
      const response = await importListings({
        userId: currentUser.uid,
        csvData: fileText,
        importOption,
      });

      console.log('Import response:', response.data.result);
      setSnackbar({ open: true, message: 'Import successful!', severity: 'success' });
    } catch (error) {
      console.error('Error importing listings:', error);
      setSnackbar({ open: true, message: 'Error importing listings. Check the console for more details.', severity: 'error' });
    } finally {
      setImporting(false);
      setImportFile(null);
      onClose();
    }
  };

  // Handle File Selection
  const handleFileChange = (event) => {
    setImportFile(event.target.files[0]);
  };

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen={isMobile}>
      <DialogTitle>Import/Export Listings</DialogTitle>
      <Button onClick={onClose} color="primary" disabled={importing || exporting}>
          Close
        </Button>
      <FeatureControl requiredFeatures={["Import & Export Listings"]}>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          {/* Instructions Section */}
          <Typography variant="h6">Instructions</Typography>
          <Typography variant="body1">
            <strong>Export:</strong> Use the "Export My Listings" button to export your current listings, or "Export All Cards" to export all available cards, you need to select this option if you want to add new listings. The exported CSV file will be sent to the email listed in your profile.
          </Typography>
          <Typography variant="body1">
            <strong>Update Listings:</strong> Open the exported CSV in a spreadsheet editor (e.g., Excel or Google Sheets). Update the following fields for the items you want to add/update:
          </Typography>
          <ul>
            <li><strong>condition</strong>: The condition of the card (Mint, Near Mint, Lightly Played, Moderately Played, Heavily Played, Damaged).</li>
            <li><strong>quantity</strong>: The number of cards available.</li>
            <li><strong>price</strong>: The price of the card if setting manual price.</li>
            <li><strong>percentage</strong>: The percentage of TCG Player Price for automatic price updates (if applicable otherwise leave blank).</li>
            <li><strong>autoUpdatePrice</strong>: Set to "1" to enable automatic price updates, or leave blank for manual pricing.</li>
          </ul>
          <Typography variant="body1">
            <strong>Reimport:</strong> After making your updates, save the CSV and use the "Import Listings" button to reimport your listings. 
            You can choose to update existing records, overwrite them, or skip if they already exist.

            To delete a listing, adjust the quantity to 0.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <em>Note: Rows missing essential data (like condition or quantity) will be ignored during the import.</em>
          </Typography>
          
          <Divider sx={{ my: 2 }} />

          {/* Export/Import Section */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleExport(false)} // Export user listings only
            disabled={exporting}
            startIcon={exporting && <Loading />}
          >
            Export My Listings
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleExport(true)} // Export all cards from all sets
            disabled={exporting}
            startIcon={exporting && <Loading />}
            sx={{ mt: 2 }}
          >
            Export All Cards
          </Button>
          <Box mt={2} display="flex" flexDirection="column" gap={1}>
            <Button
              variant="outlined"
              component="label"
              disabled={importing}
            >
              {importFile ? importFile.name : 'Choose CSV File'}
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                hidden
              />
            </Button>
            <FormControl fullWidth>
              <InputLabel>Import Option</InputLabel>
              <Select
                value={importOption}
                onChange={(e) => setImportOption(e.target.value)}
                label="Import Option"
              >
                <MenuItem value="update">Update Existing</MenuItem>
                <MenuItem value="overwrite">Overwrite Existing</MenuItem>
                <MenuItem value="skip">Skip Existing</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleImport}
              disabled={!importFile || importing}
              startIcon={importing && <Loading />}
              sx={{ mt: 2 }}
            >
              Import Listings
            </Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={importing || exporting}>
          Close
        </Button>
      </DialogActions>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      </FeatureControl>
    </Dialog>
    

  );
};

export default ImportExportComponent;
