import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// Styled input with buttons
const StyledInputWithButtons = styled("div")(
  ({ theme }) => `
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;

  input {
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.375;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    border-radius: 8px;
    padding: 10px 12px;
    width: 2.5rem;
    text-align: left;

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[700] : blue[200]};
    }

    &:focus-visible {
      outline: 0;
    }
  }
`
);

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  border: 1px solid;
  border-radius: 999px;
  border-color: ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
  background: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 120ms;

  &:hover {
    cursor: pointer;
    background: ${theme.palette.mode === "dark" ? blue[700] : blue[500]};
    border-color: ${theme.palette.mode === "dark" ? blue[500] : blue[400]};
    color: ${grey[50]};
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`
);

const blue = {
  100: "#daecff",
  200: "#b6daff",
  300: "#66b2ff",
  400: "#3399ff",
  500: "#007fff",
  600: "#0072e5",
  700: "#0059B2",
  800: "#004c99",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

// QuantityInput component with increment/decrement buttons
export default function QuantityInput({ value, onChange, ...props }) {
  const [localValue, setLocalValue] = useState(value);

  // Handle local changes and debounce the parent callback
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      onChange(localValue);
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [localValue, onChange]);

  const handleIncrement = () => {
    setLocalValue((prev) => Math.min(prev + 1, 99));
  };

  const handleDecrement = () => {
    setLocalValue((prev) => Math.max(prev - 1, 0));
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      {/* Decrement button */}
      <StyledButton onClick={handleDecrement}>
        <RemoveIcon fontSize="small" />
      </StyledButton>

      {/* Quantity Input */}
      <StyledInputWithButtons>
        <input
          value={localValue}
          onChange={(e) => setLocalValue(Number(e.target.value))}
          aria-label="Quantity Input"
          min={0}
          max={99}
          {...props}
        />
      </StyledInputWithButtons>

      {/* Increment button */}
      <StyledButton className="increment" onClick={handleIncrement}>
        <AddIcon fontSize="small" />
      </StyledButton>
    </Box>
  );
}
