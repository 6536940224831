import React, { useState } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Snackbar,
  Alert,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useAuth } from '../auth';
import { functions, httpsCallable } from '../firebase';
import Papa from 'papaparse';
import Loading from '../components/Loading';
import { conditionOptions } from './constants';
import FeatureControl from '../context/FeatureControl';

const ImportExportListingsComponent = ({ open, onClose }) => {
  const { currentUser } = useAuth();
  const [importFile, setImportFile] = useState(null);
  const [importOption, setImportOption] = useState('update'); // 'update', 'overwrite', or 'skip'
  const [condition, setCondition] = useState('Mint'); // Default condition
  const [price, setPrice] = useState(0); // Default price for manual entries
  const [useTCGPPrice, setUseTCGPPrice] = useState(false); // New state for TCGP Price
  const [percentage, setPercentage] = useState(100); // Percentage for auto-update
  const [exporting, setExporting] = useState(false);
  const [importing, setImporting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Export Listings using Firebase Function
  const handleExport = async (includeAllListings = false) => {
    setExporting(true);
    try {
      const exportListings = httpsCallable(functions, 'exportListingsAndEmail');
      await exportListings({ userId: currentUser.uid, includeAllListings });

      setSnackbar({ open: true, message: 'Export initiated. You will receive an email with the CSV file shortly.', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Error exporting listings.', severity: 'error' });
    } finally {
      setExporting(false);
    }
  };

  // Import Listings from CSV
  const handleImport = async () => {
    if (!importFile) return;
    setImporting(true);
    try {
      const fileText = await importFile.text();
      const parsedData = Papa.parse(fileText, { header: true });
  
      // Transform the CSV data to fit the expected format

      const updatedData = parsedData.data.flatMap((row) => {
        const rows = [];
      
        // Common fields shared between Normal and Foil
        const commonFields = {
          Set: row.Set,
          "Card Number": row["Card Number"],
          Name: row.Name,
          Color: row.Color,
          Price: useTCGPPrice ? "NaN" : price, // Note uppercase 'P'
          autoUpdatePrice: useTCGPPrice,
          percentage: useTCGPPrice ? percentage : "",
          condition: condition, // Ensure 'condition' matches backend (lowercase)
        };
      
        // Handle Normal version
        if (parseInt(row.Normal, 10) > 0) {
          const normalData = {
            ...commonFields,
            cardType: "Normal",
            quantity: parseInt(row.Normal, 10),
          };
      
          rows.push(normalData);
        }
      
        // Handle Foil version
        if (parseInt(row.Foil, 10) > 0) {
          const foilData = {
            ...commonFields,
            cardType: "Foil",
            quantity: parseInt(row.Foil, 10),
          };
      
          rows.push(foilData);
        }
      
        return rows;
      });
      
  
  
      const importListings = httpsCallable(functions, 'importListingsFromCollectionFormat');
      const response = await importListings({
        userId: currentUser.uid,
        csvData: Papa.unparse(updatedData),
        importOption,
      });
  
      setSnackbar({ open: true, message: 'Import successful!', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Error importing listings.', severity: 'error' });
    } finally {
      setImporting(false);
      setImportFile(null);
      onClose();
    }
  };

  const handleFileChange = (event) => {
    setImportFile(event.target.files[0]);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen={isMobile}>
      <DialogTitle>Import Listings from Glimmer Scan</DialogTitle>
      <Button onClick={onClose} color="primary" disabled={importing || exporting}>
          Close
        </Button>
      <FeatureControl requiredFeatures={["Import & Export Listings"]}>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="h6">Instructions</Typography>
          <Typography variant="body1">
            Export CSV from Glimmer Scan as Normal CSV, then import it here. It is recomended to clear database in Glimmer Scan before scanning cards in for listing. Condition and Price from the form below, will be applied to all cards in the CSV file. Selecting Use TCGP Price will use the TCGPlayer Market Price for the card. If you select this option, you can also set a percentage to adjust the price by. If you do not select this option, you can set a manual price for all cards.
          </Typography>
          <Typography variant="body2">
            Glimmer Scan available for iOS: https://apps.apple.com/au/app/glimmer-scan/id6502996383
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Box mt={2} display="flex" flexDirection="column" gap={1}>
            <Button
              variant="outlined"
              component="label"
              disabled={importing}
            >
              {importFile ? importFile.name : 'Choose CSV File'}
              <input
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                hidden
              />
            </Button>

            <FormControl fullWidth>
              <InputLabel>Import Option</InputLabel>
              <Select
                value={importOption}
                onChange={(e) => setImportOption(e.target.value)}
                label="Import Option"
              >
                <MenuItem value="update">Update Existing</MenuItem>
                <MenuItem value="overwrite">Overwrite Existing</MenuItem>
                <MenuItem value="skip">Skip Existing</MenuItem>
              </Select>
            </FormControl>

            {/* Dropdown for Condition */}
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Condition</InputLabel>
              <Select
                value={condition}
                onChange={(e) => setCondition(e.target.value)}
                label="Condition"
              >
                {conditionOptions.map((conditionOption) => (
                  <MenuItem key={conditionOption} value={conditionOption}>
                    {conditionOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Checkbox for TCGP Price */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={useTCGPPrice}
                  onChange={(e) => setUseTCGPPrice(e.target.checked)}
                />
              }
              label="Use TCGP Price"
            />

            {/* Percentage field for TCGP Price (only if TCGP is used) */}
            {useTCGPPrice && (
              <TextField
                label="TCGP Price Percentage"
                type="number"
                value={percentage}
                onChange={(e) => setPercentage(e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />
            )}

            {/* Price input only shown when TCGP Price is not used */}
            {!useTCGPPrice && (
              <TextField
                label="Price"
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />
            )}

            <Button
              variant="contained"
              color="secondary"
              onClick={handleImport}
              disabled={!importFile || importing}
              startIcon={importing && <Loading />}
              sx={{ mt: 2 }}
            >
              Import Listings
            </Button>
          </Box>
        </Box>
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={importing || exporting}>
          Close
        </Button>
      </DialogActions>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      </FeatureControl>
    </Dialog>
    
  );
};

export default ImportExportListingsComponent;
