import React, { useEffect, useState, useCallback, useRef } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc, collection, addDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import {
  List, ListItem, ListItemText, Snackbar, ListItemAvatar, Avatar, ListItemSecondaryAction, Box, Button, Typography, Divider, Container, Dialog, DialogTitle, DialogContent, IconButton, Link, useMediaQuery, useTheme
} from '@mui/material';
import { useAuth } from '../auth';
import CloseIcon from '@mui/icons-material/Close';
import CardDetail from './CardDetail'; // Import the CardDetail component
import BundleMarketplaceDetails from './BundleMarketplaceDetails'; // Import the BundleDetails component
import { useAppBarContext } from '../components/AppBarContext'; // Import the AppBarContext
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PersonIcon from '@mui/icons-material/Person';
import PublicProfile from '../pages/PublicProfile'; // Import PublicProfile component
import Loading from '../components/Loading';
import withAnalytics from '../hoc/withAnalytics';
import MessageBox from '../components/MessageBox';
import { Alert } from '@mui/material';

const currencySymbols = {
  AUD: '$',
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  // Add more currencies as needed
};

const Cart = () => {
  const { currentUser } = useAuth();
  const { selectedCountry, exchangeRates } = useAppBarContext(); // Use the AppBarContext
  const [cartItems, setCartItems] = useState([]);
  const [usernames, setUsernames] = useState({});
  const [loading, setLoading] = useState(true);
  const [itemDetails, setItemDetails] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [messageBoxOpen, setMessageBoxOpen] = useState(false);
  const [messageBoxTitle, setMessageBoxTitle] = useState('');
  const [messageBoxMessage, setMessageBoxMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [expiredItems, setExpiredItems] = useState([]);

  const useDebounce = (callback, delay) => {
    const timer = useRef();
    const debouncedCallback = useCallback((...args) => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        callback(...args);
      }, delay);
    }, [callback, delay]);
    return debouncedCallback;
  };

  const handleRemoveExpiredItem = async (itemToRemove) => {
    try {
      // Remove the selected item from the expired items list
      const updatedExpiredItems = expiredItems.filter(
        (item) =>
          item.docName !== itemToRemove.docName ||
          item.setId !== itemToRemove.setId ||
          item.listingId !== itemToRemove.listingId ||
          item.condition !== itemToRemove.condition
      );
  
      // Update the Firestore document
      await setDoc(doc(db, 'expiredCarts', currentUser.uid), { items: updatedExpiredItems });
      setExpiredItems(updatedExpiredItems);
  
      // Show success message
      setSnackbarMessage('Item removed from expired cart!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error removing expired cart item:', error);
    }
  };
  

  useEffect(() => {
    const fetchCartItems = async () => {
      if (!currentUser) return;
  
      try {
        // Fetch active cart items
        const cartRef = doc(db, 'carts', currentUser.uid);
        const cartDoc = await getDoc(cartRef);
  
        if (!cartDoc.exists()) {
          await setDoc(cartRef, { items: [] });
        }
        const cartData = cartDoc.exists() ? cartDoc.data() : { items: [] };
        const activeItems = cartData.items || [];
  
        // Fetch expired cart items
        const expiredRef = doc(db, 'expiredCarts', currentUser.uid);
        const expiredDoc = await getDoc(expiredRef);
        const expiredData = expiredDoc.exists() ? expiredDoc.data() : { items: [] };
        const expiredItems = expiredData.items || [];
  
        // Fetch details for active cart items
        const detailedActiveItems = await Promise.all(
          activeItems.map(async (item) => {
            if (!item.setId || !item.docName) {
              console.error('Missing setId or docName:', item);
              return item;
            }
            const cardDoc = await getDoc(doc(db, 'sets', item.setId, 'cards', item.docName));
            return cardDoc.exists() ? { ...item, cardData: cardDoc.data() } : item;
          })
        );
  
        // Fetch details for expired cart items
        const detailedExpiredItems = await Promise.all(
          expiredItems.map(async (item) => {
            if (!item.setId || !item.docName) {
              console.error('Missing setId or docName:', item);
              return item;
            }
            const cardDoc = await getDoc(doc(db, 'sets', item.setId, 'cards', item.docName));
            return cardDoc.exists() ? { ...item, cardData: cardDoc.data() } : item;
          })
        );
  
        setCartItems(detailedActiveItems);
        setExpiredItems(detailedExpiredItems);
  
        // Fetch usernames for both active and expired items
        const sellerIds = Array.from(
          new Set([
            ...detailedActiveItems.map(item => (item.type === 'bundle' ? item.userId : item.sellerId)),
            ...detailedExpiredItems.map(item => (item.type === 'bundle' ? item.userId : item.sellerId))
          ])
        );
  
        const usernamePromises = sellerIds.map(async (id) => {
          const userDoc = await getDoc(doc(db, 'users', id));
          return { id, username: userDoc.exists() ? userDoc.data().username : id };
        });
  
        const usernameResults = await Promise.all(usernamePromises);
        const usernameMap = usernameResults.reduce((acc, { id, username }) => {
          acc[id] = username;
          return acc;
        }, {});
  
        setUsernames(usernameMap);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cart and expired items:", error);
      }
    };
  
    // Real-time listener for active cart items
    const cartRef = doc(db, 'carts', currentUser?.uid);
    const unsubscribeCart = cartRef ? onSnapshot(cartRef, async (snapshot) => {
      if (snapshot.exists()) {
        const cartData = snapshot.data();
        const activeItems = cartData.items || [];
  
        const detailedActiveItems = await Promise.all(
          activeItems.map(async (item) => {
            if (!item.setId || !item.docName) return item;
            const cardDoc = await getDoc(doc(db, 'sets', item.setId, 'cards', item.docName));
            return cardDoc.exists() ? { ...item, cardData: cardDoc.data() } : item;
          })
        );
        setCartItems(detailedActiveItems);
      } else {
        setCartItems([]);
      }
    }) : null;
  
    // Real-time listener for expired cart items
    const expiredRef = doc(db, 'expiredCarts', currentUser?.uid);
    const unsubscribeExpired = expiredRef ? onSnapshot(expiredRef, async (snapshot) => {
      if (snapshot.exists()) {
        const expiredData = snapshot.data();
        const expiredItems = expiredData.items || [];
  
        const detailedExpiredItems = await Promise.all(
          expiredItems.map(async (item) => {
            if (!item.setId || !item.docName) return item;
            const cardDoc = await getDoc(doc(db, 'sets', item.setId, 'cards', item.docName));
            return cardDoc.exists() ? { ...item, cardData: cardDoc.data() } : item;
          })
        );
        setExpiredItems(detailedExpiredItems);
      } else {
        setExpiredItems([]);
      }
    }) : null;
  
    // Fetch initial data
    fetchCartItems();
  
    // Cleanup function to unsubscribe listeners
    return () => {
      if (unsubscribeCart) unsubscribeCart();
      if (unsubscribeExpired) unsubscribeExpired();
    };
  }, [currentUser]);
  
  
  const handleAddToCart = async (item) => {
    try {
      // Add item to active cart
      const updatedCart = [...cartItems, { ...item, addedAt: Date.now() }];
      await setDoc(doc(db, 'carts', currentUser.uid), { items: updatedCart });
      setCartItems(updatedCart);
  
      // Update inventory quantity or delete if quantity is zero
      const listingRef = doc(db, 'sets', item.setId, 'cards', item.docName, 'listings', item.listingId);
      const listingDoc = await getDoc(listingRef);
  
      if (listingDoc.exists()) {
        const condition = item.listing.condition;
        const listingData = listingDoc.data()[condition];
        const updatedQuantity = listingData.quantity - 1;
  
        if (updatedQuantity <= 0) {
          // Delete the listing if no quantity is left
          await deleteDoc(listingRef);
        } else {
          // Update the listing with reduced quantity
          await setDoc(
            listingRef,
            {
              [condition]: {
                ...listingData,
                quantity: updatedQuantity,
              },
            },
            { merge: true }
          );
        }
      }
  
      // Remove item from expired items
      const updatedExpiredItems = expiredItems.filter(
        expiredItem => expiredItem.docName !== item.docName
      );
      await setDoc(doc(db, 'expiredCarts', currentUser.uid), { items: updatedExpiredItems });
      setExpiredItems(updatedExpiredItems);
  
      // Show success message
      setSnackbarMessage('Item re-added to cart!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };
  
  

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleItemClick = async (item) => {
    try {
      setDialogOpen(true);

      if (item.type === 'listing') {
        if (!item.setId || !item.docName) {
          console.error('Missing setId or docName:', item);
          return;
        }
        const cardDoc = await getDoc(doc(db, 'sets', item.setId, 'cards', item.docName));
        const listingDoc = await getDoc(doc(db, 'sets', item.setId, 'cards', item.docName, 'listings', item.listingId));
        if (cardDoc.exists() && listingDoc.exists()) {
          setItemDetails({ cardData: { ...cardDoc.data(), id: cardDoc.id, setId: item.setId }, listingData: listingDoc.data(), type: 'listing' });
        } else {
          setItemDetails(null);
        }
      } else if (item.type === 'bundle') {
        const bundleRef = doc(db, 'bundlelistings', item.userId, 'bundles', item.id);
        const bundleDoc = await getDoc(bundleRef);
        if (bundleDoc.exists()) {
          setItemDetails({ bundleData: bundleDoc.data(), type: 'bundle' });
        } else {
          setItemDetails(null);
        }
      }
    } catch (error) {
      setItemDetails(null);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setItemDetails(null);
  };

  const handleProfileDialogClose = () => {
    setProfileDialogOpen(false);
    setSelectedUserId(null);
  };

  const handleOpenProfileDialog = (userId) => {
    setSelectedUserId(userId);
    setProfileDialogOpen(true);
  };

  const handleCheckout = async (sellerId) => {
    const sellerItems = cartItems.filter(item => (item.type === 'bundle' ? item.userId : item.sellerId) === sellerId);
  
    const salesRef = collection(db, 'users', sellerId, 'sales');
  
    const saleItems = await Promise.all(sellerItems.map(async item => {
      const cardDoc = await getDoc(doc(db, 'sets', item.setId, 'cards', item.docName));
      const convertedPrice = convertPrice(item.price);
      const staticPrice = `${convertedPrice}`;
  
      // Include the full listing object here
      return {
        docName: item.docName,
        setId: item.setId,
        setName: item.cardData?.set?.name || '',
        listingId: item.listingId,
        quantity: item.quantity,
        price: item.price,
        staticPrice: staticPrice,
        staticQuantity: item.quantity,
        cardName: item.cardData?.name || '',
        cardVersion: item.cardData?.version || '',
        cardImage: item.cardData?.image_uris?.digital?.small || '',
        cardType: item.cardData?.type || '',
        cardRarity: item.cardData?.rarity || '',
        cardInk: item.cardData?.ink || '',
        collector_number: cardDoc.exists() ? cardDoc.data().collector_number : '',
        listing: item.listing, // Include the full listing data here
      };
    }));
  
    const saleDetails = {
      buyerId: currentUser.uid,
      items: saleItems,
      total: saleItems.reduce((sum, item) => sum + (item.staticPrice * item.quantity), 0),
      status: 'Pending',
      timestamp: new Date(),
    };
  
    try {
      // Create a new sale document
      const saleDoc = await addDoc(salesRef, saleDetails);
  
      const purchaseDetails = {
        buyerId: currentUser.uid,
        items: saleItems,
        sellerId: sellerId,
        total: saleDetails.total,
        status: 'Pending',
        timestamp: saleDetails.timestamp,
      };
  
      const purchasesRef = doc(db, 'users', currentUser.uid, 'purchases', saleDoc.id);
      await setDoc(purchasesRef, purchaseDetails);
  
      // Remove items from the cart after checkout
      const remainingItems = cartItems.filter(item => (item.type === 'bundle' ? item.userId : item.sellerId) !== sellerId);
      await setDoc(doc(db, 'carts', currentUser.uid), { items: remainingItems });
      setCartItems(remainingItems);
  
      // Show success message
      setMessageBoxTitle('Checkout Complete');
      setMessageBoxMessage(`Checked out items from seller ${usernames[sellerId] || sellerId}.`);
      setMessageBoxOpen(true);
    } catch (error) {
      console.error('Error during checkout:', error);
      setMessageBoxTitle('Checkout Error');
      setMessageBoxMessage('There was an error during checkout. Please try again.');
      setMessageBoxOpen(true);
    }
  };
  

const handleRemoveItem = async (itemToRemove) => {
  try {
    const remainingItems = cartItems.filter(item => 
      item.docName !== itemToRemove.docName || 
      item.setId !== itemToRemove.setId || 
      item.listingId !== itemToRemove.listingId || 
      item.condition !== itemToRemove.condition
    );

    await setDoc(doc(db, 'carts', currentUser.uid), { items: remainingItems });
    setCartItems(remainingItems);

    // Update inventory quantity
    if (itemToRemove.setId && itemToRemove.docName && itemToRemove.listingId) {
      const listingRef = doc(db, 'sets', itemToRemove.setId, 'cards', itemToRemove.docName, 'listings', itemToRemove.listingId);
      const listingDoc = await getDoc(listingRef);

      if (listingDoc.exists()) {
        const listingData = listingDoc.data();
        const listingKey = Object.keys(listingData)[0];
        const listingInfo = listingData[listingKey];

        const updatedQuantity = listingInfo.quantity + itemToRemove.quantity; // Increase inventory quantity
        await setDoc(listingRef, {
          [listingKey]: {
            ...listingInfo,
            quantity: updatedQuantity,
          },
        }, { merge: true });
      }
    }
  } catch (error) {
    console.error("Error in handleRemoveItem:", error);
  }
};

const handleQuantityChange = async (item, delta) => {
  try {
    const listingRef = doc(db, 'sets', item.setId, 'cards', item.docName, 'listings', item.listingId);
    const condition = item.listing.condition;
    let listingDoc = await getDoc(listingRef);

    // Prevent incrementing if the listing doesn't exist
    if (!listingDoc.exists() && delta > 0) {
      setSnackbarMessage("This listing has been removed and cannot be increased.");
      setSnackbarOpen(true);
      return;
    }

    // Restore listing with exactly 1 quantity if it doesn't exist and delta is negative (decreasing)
    let wasRestored = false;
    if (!listingDoc.exists() && delta < 0) {
      console.log("Listing does not exist, restoring with data from cart item:", item.listing);

      const restoredListingData = {
        [condition]: {
          userId: item.listing.userId,
          condition: item.listing.condition,
          quantity: 1, // Re-add with exactly 1 quantity to match decrease action
          price: item.listing.price,
          autoUpdatePrice: item.listing.autoUpdatePrice,
          percentage: item.listing.percentage,
        },
      };

      await setDoc(listingRef, restoredListingData, { merge: true });
      listingDoc = await getDoc(listingRef); // Re-fetch after restoring
      wasRestored = true; // Flag to skip further quantity adjustment
    }

    const listingData = listingDoc.data();
    const listingInfo = listingData[condition];
    const availableStock = listingInfo.quantity;
    const currentQuantityInCart = item.quantity || 0;
    const newQuantityInCart = currentQuantityInCart + delta;

    // Prevent adding more than available stock
    if (delta > 0 && newQuantityInCart > currentQuantityInCart + availableStock) {
      setSnackbarMessage(`Only ${availableStock + currentQuantityInCart} available in stock.`);
      setSnackbarOpen(true);
      return;
    }

    // Update the cart quantity to the new valid quantity
    const updatedCartItems = cartItems.map(cartItem => 
      cartItem.docName === item.docName && cartItem.listingId === item.listingId
        ? { ...cartItem, quantity: newQuantityInCart }
        : cartItem
    ).filter(cartItem => cartItem.quantity > 0);

    await setDoc(doc(db, 'carts', currentUser.uid), { items: updatedCartItems });
    setCartItems(updatedCartItems);

    // Adjust listing quantity in the database or delete if zero (skip if it was just restored)
    if (!wasRestored) {
      const adjustedQuantity = availableStock - delta;
      if (adjustedQuantity <= 0) {
        await deleteDoc(listingRef);
        setSnackbarMessage("Maximum qty reached.");
        setSnackbarOpen(true);
      } else {
        await setDoc(listingRef, {
          [condition]: {
            ...listingInfo,
            quantity: adjustedQuantity,
          },
        }, { merge: true });
      }
    }

  } catch (error) {
    console.error("Error in handleQuantityChange:", error);
  }
};

const debouncedHandleQuantityChange = useDebounce(handleQuantityChange, 300);










  const convertPrice = (price) => {
    if (!exchangeRates[selectedCountry]) return price;
    return (price * exchangeRates[selectedCountry]).toFixed(2);
  };

  const groupCartItems = (items) => {
    const groupedItems = {};

    items.forEach((item) => {
      const key = `${item.docName}_${item.setId}_${item.listingId}_${item.condition}`;
      if (groupedItems[key]) {
        groupedItems[key].quantity += item.quantity;
      } else {
        groupedItems[key] = { ...item, quantity: item.quantity };
      }
    });

    return Object.values(groupedItems);
  };

  if (loading) {
    return <Loading />;
  }

  const groupedItems = groupCartItems(cartItems).reduce((acc, item) => {
    const sellerKey = item.type === 'bundle' ? item.userId : item.sellerId;
    if (!acc[sellerKey]) {
      acc[sellerKey] = [];
    }
    acc[sellerKey].push(item);
    return acc;
  }, {});

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        My Cart
      </Typography>
      <Typography variant="h7" gutterBottom>
        Cart will be cleared after 1 hour since last item added.
      </Typography>
  
      {cartItems.length === 0 ? (
        <Typography variant="h6" color="textSecondary">
          Your cart is empty.
        </Typography>
      ) : (
        Object.keys(groupedItems).map((sellerKey) => {
          const sellerItems = groupedItems[sellerKey];
          const total = sellerItems.reduce(
            (sum, item) => sum + item.price * item.quantity,
            0
          );

        return (
          <Box key={sellerKey} mb={4}>
            <Typography variant="h6" gutterBottom>
              Seller:
              <Link
                onClick={() => handleOpenProfileDialog(sellerKey)}
                sx={{ ml: 1 }}
                style={{ cursor: "pointer" }}
              >
                <PersonIcon fontSize="small" />{" "}
                {usernames[sellerKey] || sellerKey}
              </Link>
            </Typography>
            <List>
              {sellerItems.map((item) => (
                <ListItem
                  button
                  onClick={() => handleItemClick(item)}
                  key={`${item.docName}_${item.setId}_${item.listingId}_${item.condition}`}
                  sx={{
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: isMobile ? "flex-start" : "center",
                  }}
                >
                  <ListItemAvatar sx={{ width: 100 }}>
                    <Avatar
                      variant="square"
                      src={
                        item.cardData?.image_uris?.digital?.small ||
                        "fallback_image_url"
                      }
                      sx={{
                        height: "20%",
                        width: "80%",
                        objectFit: "contain",
                        border: `4px solid #000000`,
                      }}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "fallback_image_url";
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      item.type === "bundle"
                        ? item.name
                        : item.cardData?.name
                    }
                    secondary={
                      item.type === "bundle" ? (
                        ""
                      ) : (
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            display="block"
                            style={{ fontWeight: "bold" }}
                          >
                           {item.version}
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            display="block"
                          >
                            Card Number: {item.collector_number}
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            display="block"
                          >
                            Card Type: {item.cardData?.type}
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            display="block"
                          >
                            Condition: {item.listing.condition}
                          </Typography>
                        </React.Fragment>
                      )
                    }
                  />

                  <ListItemSecondaryAction>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={() => debouncedHandleQuantityChange(item, -1)}>
                <RemoveIcon />
              </IconButton>
              <Typography sx={{ mx: 1 }}>{item.quantity}</Typography>
              <IconButton onClick={() => debouncedHandleQuantityChange(item, 1)}>
                <AddIcon />
              </IconButton>
            </Box>
                    <Typography>{`Price Each: ${
                      currencySymbols[selectedCountry]
                    }${convertPrice(item.price)}`}</Typography>
                    <IconButton
                      edge="end"
                      color="inherit"
                      onClick={() => handleRemoveItem(item)}
                    >

                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Typography variant="h6" gutterBottom>
              Total: {currencySymbols[selectedCountry]}
              {convertPrice(total)}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleCheckout(sellerKey)}
            >
              Checkout Items from {usernames[sellerKey] || sellerKey}
            </Button>
            <Divider sx={{ my: 2 }} />
          </Box>
        );
      })
    )}

{expiredItems.length > 0 && (
  <Box
    sx={{
      mt: 4,
      p: 3,
      border: '2px solid #d32f2f',
      borderRadius: '8px',
      backgroundColor: '#fce4ec',
    }}
  >
    <Typography variant="h5" gutterBottom>
      Expired Cart Items
    </Typography>
    <Typography variant="body2" color="textSecondary" gutterBottom>
      These items were removed due to inactivity. You can re-add them to your cart or remove them permanently.
    </Typography>

    {/* Group expired items by seller */}
    {Object.entries(
      expiredItems.reduce((acc, item) => {
        const sellerKey = item.sellerId || item.userId;
        if (!acc[sellerKey]) acc[sellerKey] = [];
        acc[sellerKey].push(item);
        return acc;
      }, {})
    ).map(([sellerId, items]) => (
      <Box key={sellerId} sx={{ mt: 3 }}>
        {/* Display seller information */}
        <Typography variant="h6" gutterBottom>
          Seller:
          <Link
            onClick={() => handleOpenProfileDialog(sellerId)}
            sx={{ ml: 1 }}
            style={{ cursor: 'pointer' }}
          >
            <PersonIcon fontSize="small" /> {usernames[sellerId] || sellerId}
          </Link>
        </Typography>
        <List>
          {items.map((item) => (
            <ListItem
              key={`${item.docName}_${item.setId}_${item.listingId}_${item.condition}`}
              sx={{
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'flex-start' : 'center',
              }}
            >
              <ListItemAvatar sx={{ width: 100 }}>
                <Avatar
                  variant="square"
                  src={item.cardData?.image_uris?.digital?.small || 'fallback_image_url'}
                  sx={{
                    height: '80%',
                    width: '80%',
                    objectFit: 'contain',
                    border: '2px solid #000',
                  }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'fallback_image_url';
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={item.cardData?.name}
                secondary={
                  <>
                    <Typography variant="body2" display="block" style={{ fontWeight: 'bold' }}>
                      {item.version}
                    </Typography>
                    <Typography variant="body2" display="block">
                      Card Number: {item.collector_number}
                    </Typography>
                    <Typography variant="body2" display="block">
                      Condition: {item.condition}
                    </Typography>
                    <Typography variant="body2" display="block">
                      Price: {currencySymbols[selectedCountry]}{convertPrice(item.price)}
                    </Typography>
                  </>
                }
              />
              <ListItemSecondaryAction>
                {/* Re-add to cart button */}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleAddToCart(item)}
                >
                  Re-add to Cart
                </Button>
                {/* Remove from expired cart button */}
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleRemoveExpiredItem(item)}
                  sx={{ ml: 2 }}
                >
                  Remove
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
    ))}
  </Box>
)}





<Snackbar
  open={snackbarOpen}
  autoHideDuration={3000}
  onClose={handleSnackbarClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
  sx={{
    mt: 10, // Adjust this value as needed to set the space from the top
  }}
>
  <Alert onClose={handleSnackbarClose} variant="filled" severity="error">
    {snackbarMessage}
  </Alert>
</Snackbar>


<MessageBox
  open={messageBoxOpen}
  onClose={() => setMessageBoxOpen(false)}
  title={messageBoxTitle}
  message={messageBoxMessage}
/>

      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <IconButton edge="end" color="inherit" onClick={handleDialogClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {itemDetails ? (
            itemDetails.type === 'listing' ? (
              <Box>
                <CardDetail setId={itemDetails.cardData.setId} id={itemDetails.cardData.id} />
                <Typography variant="h6">{`Seller: ${usernames[itemDetails.listingData.sellerId] || itemDetails.listingData.sellerId}`}</Typography>
                <Typography variant="h6">{`Price: ${currencySymbols[selectedCountry]}${convertPrice(itemDetails.listingData.price)}`}</Typography>
              </Box>
            ) : (
              <Box>
                <BundleMarketplaceDetails bundle={itemDetails.bundleData} onClose={handleDialogClose} />
                <Typography variant="h6">{`Seller: ${usernames[itemDetails.bundleData.userId] || itemDetails.bundleData.userId}`}</Typography>
              </Box>
            )
          ) : (
            <Loading />
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={profileDialogOpen} onClose={handleProfileDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <IconButton edge="end" color="inherit" onClick={handleProfileDialogClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedUserId && <PublicProfile userId={selectedUserId} />}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default withAnalytics(Cart);