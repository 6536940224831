import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Extract query parameters
  const searchParams = new URLSearchParams(location.search);
  const paymentIntent = searchParams.get('payment_intent');
  const paymentIntentClientSecret = searchParams.get('payment_intent_client_secret');
  const redirectStatus = searchParams.get('redirect_status');

  // Automatically redirect after checking query params
  useEffect(() => {
    if (!paymentIntent || !paymentIntentClientSecret || redirectStatus !== 'succeeded') {
      // Redirect to home if any required data is missing or status is not succeeded
      navigate('/');
    } else {
      const timer = setTimeout(() => {
        navigate('/profile');
      }, 5000); // 5-second delay
      return () => clearTimeout(timer);
    }
  }, [navigate, paymentIntent, paymentIntentClientSecret, redirectStatus]);

  // Render success message if the required params are present
  return paymentIntent && paymentIntentClientSecret && redirectStatus === 'succeeded' ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Payment Successful!
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Confirmation ID: {paymentIntent}
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Redirecting you to your profile...
      </Typography>
      <CircularProgress />
    </Box>
  ) : null;
};

export default PaymentSuccess;
