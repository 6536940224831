import { rarityOrder, inkColors, cardTypes } from './constants';

export const initialState = {
    sets: [],
    cards: [],
    selectedSet: '',
    selectedRarities: Object.keys(rarityOrder),
    selectedInks: inkColors,
    selectedCardTypes: cardTypes,
    searchTerm: '',
    sortBy: 'card_low',
    view: 'list',
    loading: true,
    selectedCard: null,
    showWithListings: true,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.payload };
    case "SET_SELECTED_SET":
      return { ...state, selectedSet: action.payload };
    case "SET_SELECTED_RARITIES":
      return { ...state, selectedRarities: action.payload };
    case "SET_SELECTED_INKS":
      return { ...state, selectedInks: action.payload };
    case "SET_SELECTED_CARD_TYPES":
      return { ...state, selectedCardTypes: action.payload };
    case "SET_SEARCH_TERM":
      return { ...state, searchTerm: action.payload };
    case "SET_SORT_BY":
      return { ...state, sortBy: action.payload };
    case "SET_VIEW":
      return { ...state, view: action.payload };
    case "UPDATE_CARDS":
      return { ...state, cards: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_SELECTED_CARD":
      return { ...state, selectedCard: action.payload };
    case "SET_SHOW_WITH_LISTINGS":
      return { ...state, showWithListings: action.payload };
    case 'SET_VERIFIED':
        return { ...state, isVerified: action.payload };
    default:
      return state;
  }
};
