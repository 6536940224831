// src/pages/Registration.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth';
import { db } from '../firebase';
import { doc, setDoc, getDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';

const Registration = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    username: '', // Added username field
  });
  const [usernameError, setUsernameError] = useState('');

  useEffect(() => {
    const fetchProfile = async () => {
      if (currentUser) {
        const docRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setProfile(docSnap.data());
        } else {
          setProfile((prev) => ({
            ...prev,
            email: currentUser.email,
          }));
        }
        setLoading(false);
      }
    };

    fetchProfile();
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const checkUsernameExists = async (username) => {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('username', '==', username));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await checkUsernameExists(profile.username)) {
      setUsernameError('Username is already taken. Please choose another one.');
      return;
    }
    try {
      const docRef = doc(db, 'users', currentUser.uid);
      await setDoc(docRef, profile);
      alert('Profile created successfully');
      navigate('/profile'); // Navigate to the profile page
    } catch (error) {
      console.error('Error creating profile:', error);
      alert('Error creating profile. Please try again.');
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Complete Your Profile
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          name="firstName"
          label="First Name"
          value={profile.firstName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="lastName"
          label="Last Name"
          value={profile.lastName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="email"
          label="Email"
          value={profile.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          name="phoneNumber"
          label="Phone Number"
          value={profile.phoneNumber}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="username"
          label="Username"
          value={profile.username}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          error={Boolean(usernameError)}
          helperText={usernameError}
        />
        <Button type="submit" variant="contained" color="primary">
          Complete Profile
        </Button>
      </Box>
    </Container>
  );
};

export default Registration;
